var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "48%", persistent: "" },
      model: {
        value: _vm.helpDialog,
        callback: function($$v) {
          _vm.helpDialog = $$v
        },
        expression: "helpDialog"
      }
    },
    [
      _c(
        "div",
        { staticClass: "inputhelpdialog" },
        [
          _c(
            "v-card",
            { staticClass: "pa-4" },
            [
              _c("v-card-title", [
                _c("div", { staticClass: "border_main_title" }, [
                  _vm._v(" " + _vm._s(_vm.dialogdata.mainTitle) + " ")
                ])
              ]),
              _vm.dialogdata.subTitle !== null
                ? _c(
                    "div",
                    [
                      _c("v-card-text", [
                        _c("div", { staticClass: "text_color linefeed" }, [
                          _vm._v(_vm._s(_vm.dialogdata.subTitle))
                        ])
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.dialogdata.subDisplayFlg
                ? _c(
                    "div",
                    _vm._l(_vm.dialogdata.sub, function(sub) {
                      return _c(
                        "v-card-text",
                        { key: sub.subTitle, staticClass: "mt-6 left_margin" },
                        [
                          sub.subTitle !== null
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "my-0" },
                                    [
                                      _c("v-col", { attrs: { cols: "9" } }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "border_sub_title text_color"
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(sub.subTitle) + " "
                                            )
                                          ]
                                        )
                                      ]),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "sub_text text_color",
                                          attrs: { cols: "9" }
                                        },
                                        [_vm._v(_vm._s(sub.subText))]
                                      )
                                    ],
                                    1
                                  ),
                                  sub.displayType === "text"
                                    ? _c(
                                        "div",
                                        _vm._l(sub.details, function(detail) {
                                          return _c(
                                            "v-container",
                                            {
                                              key: detail.detailTitle,
                                              staticClass: "py-0"
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                { staticClass: "my-0" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "text_color",
                                                      attrs: { cols: "2" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          detail.detailTitle
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "sub_text text_color",
                                                      attrs: { cols: "9" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          detail.detailText
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        }),
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _c(
                                "div",
                                {
                                  staticClass:
                                    "border_sub_title text_color mb-3"
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("HELP_TEXT_CATEGORY_AND_EXPLAIN")
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                          sub.displayType === "table"
                            ? _c(
                                "table",
                                { attrs: { border: "1", width: "95%" } },
                                [
                                  _c("tr", { attrs: { bgcolor: "#f0f0f0" } }, [
                                    _c("th", [
                                      _vm._v(
                                        _vm._s(_vm.$t("HELP_TEXT_CATEGORY"))
                                      )
                                    ]),
                                    _c("th", [
                                      _vm._v(
                                        _vm._s(_vm.$t("HELP_TEXT_EXPLAIN"))
                                      )
                                    ])
                                  ]),
                                  _vm._l(sub.details, function(detail) {
                                    return _c(
                                      "tr",
                                      {
                                        key: detail.detailTitle,
                                        staticClass: "py-0"
                                      },
                                      [
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "table_text text_color",
                                            attrs: { width: "18%" }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(detail.detailTitle) +
                                                " "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "table_text text_color linefeed"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(detail.detailText) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  })
                                ],
                                2
                              )
                            : _vm._e()
                        ]
                      )
                    }),
                    1
                  )
                : _vm._e(),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "end" } },
                    [
                      _c(
                        "v-btn",
                        { staticClass: "mr-2", on: { click: _vm.helpClose } },
                        [_vm._v(_vm._s(_vm.$t("WORD_CLOSE")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }