<template>
  <div class="usertable">
    <div class="boxContainer">
      <div class="areatitle">{{ $t("PAGE_SUBTITLE_MANAGEMENT_USER_TABLE") }}</div>
      <div v-if="isUserLoading">
        <v-progress-circular
          :size="20"
          :width="3"
          color="#00b842"
          indeterminate
          class="ml-2"
        ></v-progress-circular>
      </div>
    </div>
    <v-card width="100%" height="100%" class="mx-auto my-auto scroll">
      <UserCreateDialog
        :dialog="isUserCreate"
        @close="callbackUserCreateDialogClose"
      >
      </UserCreateDialog>
      <UserTableChangeDialog
        :dialog="isUserChange"
        @close="callbackUserTableChangeDialogClose"
      />
      <UserDeleteDialog
        :dialog="isUserDelete"
        @close="callbackUserDeleteDialogClose"
      />
      <v-data-table
        :headers="userTableHeaders"
        :items="user"
        :footer-props="TABLE_FOOTER_SETTING_MIDDLE"
      >
        <template v-slot:[`item.createdAt`]="{ item }">
          <span>{{ new Date(item.createdAt).toLocaleString() }}</span>
        </template>
        <template v-slot:[`item.username`]="{ item }">
          <div class="user_name">{{ item.username }}</div>
        </template>
        <template v-slot:[`item.role`]="{ item }">
          <span>{{ getRoleName(item.role) }}</span>
        </template>
        <template v-slot:[`item.update`]="{ item }">
          <v-icon :disabled="isUserLoading" small @click="changeRow(item)">
            mdi-pen
          </v-icon>
        </template>
        <template v-slot:[`item.delete`]="{ item }">
          <v-icon :disabled="isUserLoading" small @click="deleteRow(item)">
            mdi-close
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <div class="flexarea btnarea">
      <v-spacer></v-spacer>
      <v-btn
        :disabled="isUserLoading"
        small
        color="info"
        @click="isLimitOverUsers"
      >
        {{ $t("WORD_MANAGEMENT_USER_ADD") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import UserCreateDialog from "@/components/parts/ManageUser/UserCreateDialog.vue";
import UserTableChangeDialog from "@/components/parts/ManageUser/UserTableChangeDialog.vue";
import UserDeleteDialog from "@/components/parts/ManageUser/UserDeleteDialog.vue";
export default {
  components: {
    UserCreateDialog,
    UserTableChangeDialog,
    UserDeleteDialog
  },
  data() {
    return {
      isUserCreate: false,
      isUserChange: false,
      isUserDelete: false
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    tenant() {
      return this.$store.getters["tenant/getTenantData"];
    },
    isUserLoading() {
      return this.$store.getters["user/isUserLoading"];
    },
    userTableHeaders() {
      return this.$t("MANAGE_USER_HEADER");
    },
    TABLE_FOOTER_SETTING_MIDDLE() {
      // テーブル定義
      return this.$t("TABLE_FOOTER_SETTING_MIDDLE");
    }
  },
  created: function() {
    this.$store.dispatch("user/getUser", {});
  },
  methods: {
    /**
     * 「ユーザー作成ダイアログ」を閉じる
     */
    callbackUserCreateDialogClose() {
      this.isUserCreate = false;
    },
    /**
     * 「ユーザー作成ダイアログ」を開く
     */
    isLimitOverUsers() {
      let users = this.$store.getters["user/user"];
      if (users.length >= this.tenant.usersLimitNum) {
        Swal.fire({
          title: this.$t("MSG_ERR_MAIN_OVER_USERS_MAX_NUM"),
          text: this.$t("MSG_ERR_SUB_USER_ADD_LIMIT", { limit: this.tenant.usersLimitNum }),
          icon: "error"
        });
      } else {
        this.isUserCreate = true;
      }
    },
    /**
     * 「ユーザー変更ダイアログ」を閉じる
     */
    callbackUserTableChangeDialogClose() {
      this.isUserChange = false;
    },
    /**
     * 「ユーザー変更ダイアログ」を開く
     * @param {Object} selectedUser - 選択行のユーザー
     */
    changeRow(selectedUser) {
      this.$store.commit("user/setSelectedUser", selectedUser);
      this.isUserChange = true;
    },
    /**
     * 「ユーザー削除ダイアログ」を閉じる
     */
    callbackUserDeleteDialogClose() {
      this.isUserDelete = false;
    },
    /**
     * 「ユーザー削除ダイアログ」を開く
     * @param {Object} selectedUser - 選択行のユーザー
     */
    deleteRow(selectedUser) {
      this.$store.commit("user/setSelectedUser", selectedUser);
      this.isUserDelete = true;
    },
    /**
     * ユーザーロール名を定義する
     * @param {String} userRole - ユーザーのロール
     */
    getRoleName(userRole) {
      return this.$t("WORD_ROLENAME")[userRole];
    }
  }
};
</script>

<style scoped>
.usertable {
  height: 100%;
}
.scroll {
  max-height: 82vh;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
}
.areatitle {
  border-left: solid 6px #1976d2ca;
  margin-bottom: 8px;
  padding-left: 8px;
}
.user_name {
  width: 473px;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
}
.btnarea {
  margin-top: 10px;
}
.boxContainer {
  display: flex;
}
</style>
