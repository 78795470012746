<template>
  <div>

    <!-- 実績入力 -->
    <v-card flat>
      <v-form ref="jissekiform">
        <v-row dense v-for="jisseki in jissekis" :key="jisseki.mappingId">
          <v-col>
            <div class="font-weight-bold detailtitle">
              {{ mappingName(jisseki.mappingId) }}
            </div>
            <div>
              <EditingItem
                v-model="jisseki.value"
                :type="getType(jisseki.mappingId)"
                :inputType="getInputType(jisseki.mappingId)"
                :rules="rules(jisseki.mappingId)"
                :items="listDataItems(getCategory(jisseki.mappingId), jisseki.value)"
                :min="getMin(jisseki.mappingId)"
                :max="getMax(jisseki.mappingId)"
                :step="getStep(jisseki.mappingId)"
                :bgcolor="getJisseki(jisseki.mappingId) === JISSEKI_REQUIRED ? 'pink lighten-5' : ''"
                :calcObject1Type="getType(jisseki.mappingId) === TYPE_CALC ? getCalcObject1Type(jisseki.mappingId) : null"
              ></EditingItem>
            </div>
          </v-col>
        </v-row>
      </v-form>
      <v-card-actions class="justify-center">
        <v-btn outlined class="btn-regist" width="90%" @click="regist(false)">{{ $t("WORD_REGIST") }}</v-btn>
      </v-card-actions>
      <v-card-actions class="justify-center">
        <v-btn outlined class="btn-complete" width="90%" @click="regist(true)">{{ $t("WORD_REGIST_COMPLETE") }}</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import Define from "@/define.js";
import * as Util from "@/util/utils.js";
import EditingItem from "@/components/parts/EditingItem";
import Swal from "sweetalert2";
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("tile");
import { mixinValidator } from "@/mixins/mixinValidator.js";
import { mixinError } from "@/mixins/mixinError.js";
export default {
  components: {
    EditingItem
  },
  mixins: [mixinValidator, mixinError],
  data() {
    return {
      jissekis: [],

      // 入力規則
      required: value => this.isValidRequired(value, true),

      JISSEKI_REQUIRED: Define.JISSEKI_REQUIRED,
      DISPLAY_TYPE_CARD_DETAIL: Define.DISPLAY_TYPE_CARD_DETAIL,
      TYPE_CALC: Define.TYPE_CALC
    };
  },
  computed: {
    isOpenMobileCardDetail() {
      return this.$store.getters["tile/isOpenMobileCardDetail"];
    },
    tileInfo() {
      return this.$store.getters["tile/cardDetailInfo"];
    },
    mappings() {
      return this.$store.getters["setting/getMappingList"];
    },
    jissekiMappings() {
      return this.$store.getters["setting/getJissekiMappings"];
    },
    getType() {
      return id => {
        return this.$store.getters["setting/getMappingType"](id);
      };
    },
    rules() {
      return mappingId => {
        if (Util.isRequiredJissekiInput(this.mappings, mappingId)) {
          return [this.required];
        } else {
          return [];
        }
      };
    },
    listDataItems() {
      return (category, value) => {
        let items = this.$store.getters["info/getInfoDataItems"](category);
        // 設定値が現在選択中のカテゴリに存在しない場合はリストの先頭に追加する
        if (value !== "" && !items.some(d => d.text === value)) {
          items.unshift({ text: value, value: value });
        }
        return items;
      };
    },
    isKeySettings() {
      return mappingId => {
        return Util.isKeySettings(this.getMappingList, mappingId);
      };
    },
    selectedWorkspaceId() {
      return this.$store.getters["auth/selectedWorkSpaceId"];
    },
    getInputType() {
      return id => {
        return this.$store.getters["setting/getMappingInputType"](id);
      };
    },
    getCategory() {
      return id => {
        return this.$store.getters["setting/getMappingCategory"](id);
      };
    },
    getMin() {
      return id => {
        return this.$store.getters["setting/getMappingMin"](id);
      };
    },
    getMax() {
      return id => {
        return this.$store.getters["setting/getMappingMax"](id);
      };
    },
    getStep() {
      return id => {
        return this.$store.getters["setting/getMappingStep"](id);
      };
    },
    isUpdatingJisseki() {
      return this.$store.getters["isUpdatingJisseki"];
    },
    isDeletingJisseki() {
      return this.$store.getters["isDeletingJisseki"];
    },
    getJisseki() {
      return id => {
        return this.$store.getters["setting/getMappingJisseki"](id);
      };
    },
    displayFontSize() {
      return this.$store.getters["display/displayFontSize"];
    },
    getCalcObject1Type() {
      return mappingId => {
        return this.$store.getters["setting/getCalcObject1Type"](mappingId);
      };
    }
  },
  watch: {
    isOpenMobileCardDetail: {
      immediate: true,
      handler: function() {
        if (this.isOpenMobileCardDetail) {
          if (this.jissekis.length === 0) {
            for (const jissekiMapping of this.jissekiMappings) {
              this.jissekis.push({
                mappingId: jissekiMapping.mappingId,
                value: ""
              });
            }
          }
        } else {
          this.jissekis = [];
        }
      }
    },
    isUpdatingJisseki: {
      immediate: true,
      handler: function() {
        if (this.isUpdatingJisseki) {
          this.jissekis = [];
        } else {
          if (this.jissekis.length === 0) {
            for (const jissekiMapping of this.jissekiMappings) {
              this.jissekis.push({
                mappingId: jissekiMapping.mappingId,
                value: ""
              });
            }
          }
        }
      }
    }
  },
  methods: {
    ...mapActions(["jissekiUpdate"]),

    mappingName(id) {
      if (!id) return "";
      return this.$store.getters["setting/getMappingName"](id);
    },
    showErrorMsg(code) {
      if (code === Define.ERR_MAP_NO_SETTINGS) {
        Swal.fire({
          html: this.$t("MSG_SETTING_MAP_DATA"),
          icon: "error"
        });
      } else {
        let msg = this.createErrorMessageBySettingSave(0, code);
        Swal.fire({
          title: msg.mainText,
          text: msg.subText,
          icon: "error"
        });
      }
    },
    regist(isComplete) {
      if (!this.$refs.jissekiform.validate()) {
        this.showErrorMsg(Define.ERR_INPUT_ITEM_INVALID);
        return;
      }

      // カード情報
      const tileData = {
        tile_id: this.tileInfo.tileId,
        pos_col: this.tileInfo.posCol,
        pos_row: this.tileInfo.posRow,
        order: this.tileInfo.order,
        is_complete: isComplete ? true : this.tileInfo.isComplete,
        color: this.tileInfo.color,
        version: this.tileInfo.version,
        is_confirmed: this.tileInfo.isConfirmed,
        status: this.tileInfo.status
      };

      // カード実績情報
      let jissekiData = [];
      for (const jisseki of this.jissekis) {
        let dataType = this.getType(jisseki.mappingId);
        // データ形式＝計算時の処理
        let calcObject1Type = this.getCalcObject1Type(jisseki.mappingId);

        jissekiData.push({
          mapping_id: jisseki.mappingId,
          value:
            this.isFloatConsideringCalc(dataType, calcObject1Type) && Util.checkNotEmpty(jisseki.value)
              ? String(Util.computeRoundNum(jisseki.value, Define.ROUND_NUM))
              : jisseki.value
        });
      }

      this.$store.commit("setIsUpdatingJisseki", true);
      this.$store.commit("setIsLoading", true);

      // 更新
      this.jissekiUpdate({tileData: tileData, jissekiData: jissekiData});
    },

    /**
     * 実績のデータ形式がFloatかどうか判断する
     * @param {Array} dataType - 実績のデータ形式
     * @param {String} calcObject1Type - 計算対象1のデータ形式
     * @returns {Boolean} - True:Floatである、Fale:Floatでない
     */
    isFloatConsideringCalc(dataType, calcObject1Type) {
      return Util.isFloatConsideringCalc(dataType, calcObject1Type);
    }
  }
};
</script>

<style scoped>
.detailtitle {
  color: #333;
  margin-top: 3px;
}
.btn-regist {
  color: #fff;
  background-color: #2196f3;
  border-color: #2196f3;
}
.btn-complete {
  color: #fff;
  background-color: #575757;
  border-color: #575757;
}
</style>
