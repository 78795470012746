var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "option" }, [
    !_vm.authApplicationSetting
      ? _c(
          "div",
          { staticClass: "attention" },
          [
            _c("v-icon", { attrs: { color: "error" } }, [_vm._v("mdi-alert")]),
            _vm._v(" " + _vm._s(_vm.$t("TEXT_APPLICATION_ROLE")) + " ")
          ],
          1
        )
      : _vm._e(),
    _c("div", [
      _c(
        "div",
        { staticClass: "areatitle" },
        [
          _vm._v(
            " " + _vm._s(_vm.$t("PAGE_TITLE_APPLICATION_SERRING_OPTION")) + " "
          ),
          _c("HelpButton", { attrs: { helpobject: _vm.optionHelpDialogData } })
        ],
        1
      ),
      _c(
        "div",
        [
          _c("v-switch", {
            attrs: {
              color: "#00b842",
              label: _vm.$t("WORD_APPLICATION_SETTING_OPTION_INCOMPLETE"),
              disabled: !_vm.authApplicationSetting
            },
            model: {
              value: _vm.localOption.option001,
              callback: function($$v) {
                _vm.$set(_vm.localOption, "option001", $$v)
              },
              expression: "localOption.option001"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        [
          _c("v-switch", {
            attrs: {
              color: "#00b842",
              label: _vm.$t("WORD_APPLICATION_SETTING_OPTION_CHANGE"),
              disabled: !_vm.authApplicationSetting
            },
            model: {
              value: _vm.localOption.option002,
              callback: function($$v) {
                _vm.$set(_vm.localOption, "option002", $$v)
              },
              expression: "localOption.option002"
            }
          })
        ],
        1
      ),
      _vm.tenant.isEditableOption003
        ? _c(
            "div",
            [
              _c("v-switch", {
                attrs: {
                  color: "#00b842",
                  label: _vm.$t(
                    "WORD_APPLICATION_SETTING_OPTION_MULTILINGUAL_API"
                  ),
                  disabled: !_vm.authApplicationSetting
                },
                model: {
                  value: _vm.localOption.option003,
                  callback: function($$v) {
                    _vm.$set(_vm.localOption, "option003", $$v)
                  },
                  expression: "localOption.option003"
                }
              })
            ],
            1
          )
        : _vm._e()
    ]),
    _c(
      "div",
      { staticClass: "dataarea" },
      [
        _c(
          "v-btn",
          {
            staticClass: "info savebtn",
            attrs: { disabled: !_vm.authApplicationSetting },
            on: {
              click: function($event) {
                return _vm.save()
              }
            }
          },
          [_vm._v(" " + _vm._s(_vm.$t("WORD_APPLICATION_SETTING_SAVE")) + " ")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }