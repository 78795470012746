var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "800px", persistent: "" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "mx-auto", attrs: { width: "800px" } },
            [
              _c("v-card-title", [
                _vm._v(_vm._s(_vm.$t("PAGE_TITLE_USER_ROLE_CHANGE")))
              ]),
              _c("v-card-text", [
                _vm._v(
                  _vm._s(_vm.$t("TEXT_USER_CHANGE_NAME")) +
                    _vm._s(_vm.selectedUser.username)
                )
              ]),
              _c("v-card-text", [
                _vm._v(
                  _vm._s(_vm.$t("TEXT_USER_CHANGE_ROLE")) +
                    _vm._s(_vm.getRoleName(_vm.selectedUser.role))
                )
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-form",
                    { ref: "form" },
                    [
                      _c(
                        "v-radio-group",
                        {
                          attrs: { row: "" },
                          model: {
                            value: _vm.role,
                            callback: function($$v) {
                              _vm.role = $$v
                            },
                            expression: "role"
                          }
                        },
                        [
                          _c("v-radio", {
                            attrs: {
                              label: _vm.$t("WORD_ROLENAME")[0],
                              value: "0"
                            }
                          }),
                          _c("v-radio", {
                            attrs: {
                              label: _vm.$t("WORD_ROLENAME")[1],
                              value: "1"
                            }
                          }),
                          _c("v-radio", {
                            attrs: {
                              label: _vm.$t("WORD_ROLENAME")[2],
                              value: "2"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "info",
                      attrs: { width: "25%" },
                      on: { click: _vm.submit }
                    },
                    [_vm._v(_vm._s(_vm.$t("WORD_CHANGE")))]
                  ),
                  _c(
                    "v-btn",
                    { attrs: { width: "25%" }, on: { click: _vm.closeDialog } },
                    [_vm._v(_vm._s(_vm.$t("WORD_CANCEL")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }