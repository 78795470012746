var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "usertable" },
    [
      _c("div", { staticClass: "boxContainer" }, [
        _c("div", { staticClass: "areatitle" }, [
          _vm._v(_vm._s(_vm.$t("PAGE_SUBTITLE_MANAGEMENT_WORKSPACE_TABLE")))
        ]),
        _vm.isWorkSpaceLoading
          ? _c(
              "div",
              [
                _c("v-progress-circular", {
                  staticClass: "ml-2",
                  attrs: {
                    size: 20,
                    width: 3,
                    color: "#00b842",
                    indeterminate: ""
                  }
                })
              ],
              1
            )
          : _vm._e()
      ]),
      _c(
        "v-card",
        {
          staticClass: "mx-auto my-auto scroll",
          attrs: { width: "100%", height: "100%" }
        },
        [
          _c("WorkSpaceCreateDialog", {
            attrs: { dialog: _vm.isWorkSpaceCreate },
            on: { close: _vm.callbackWorkSpaceCreateDialogClose }
          }),
          _c("WorkSpaceChangeDialog", {
            attrs: { dialog: _vm.isWorkSpaceChange },
            on: { close: _vm.callbackWorkSpaceChangeDialogClose }
          }),
          _c("WorkSpaceDeleteDialog", {
            attrs: { dialog: _vm.isWorkSpaceDelete },
            on: { close: _vm.callbackWorkSpaceDeleteDialogClose }
          }),
          _c("v-card-text", { staticStyle: { color: "#ff5252" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("TEXT_WORKSPACE_NUM_LIMIT", {
                    workspacenum: _vm.tenant.workSpaceNum
                  })
                ) +
                " "
            )
          ]),
          _c("v-data-table", {
            attrs: {
              headers: _vm.workSpaceTableHeaders,
              items: _vm.workspaces,
              "footer-props": _vm.TABLE_FOOTER_SETTING_MIDDLE
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.createdAt",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(new Date(item.createdAt).toLocaleString())
                        )
                      ])
                    ]
                  }
                },
                {
                  key: "item.update",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-icon",
                        {
                          attrs: {
                            disabled: _vm.isWorkSpaceLoading,
                            small: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.changeRow(item)
                            }
                          }
                        },
                        [_vm._v(" mdi-pen ")]
                      )
                    ]
                  }
                },
                {
                  key: "item.delete",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-icon",
                        {
                          attrs: {
                            disabled: _vm.isWorkSpaceLoading,
                            small: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.deleteRow(item)
                            }
                          }
                        },
                        [_vm._v(" mdi-close ")]
                      )
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "flexarea btnarea" },
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: {
                disabled: _vm.isWorkSpaceLoading,
                small: "",
                color: "info"
              },
              on: { click: _vm.isLimitOverWorkSpace }
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("WORD_MANAGEMENT_WORKSPACE_ADD")) + " "
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }