<template>
  <v-row dense align="center">
    <v-col cols="2" align="center">
      <v-btn
        small
        height="40"
        outlined
        @click="decreaseNumber"
        @mousedown="whileMouseDown(decreaseNumber)"
        @mouseup="clearTimer"
      >
        -
      </v-btn>
    </v-col>
    <v-col cols="8" align="center">
      <v-text-field
        :key="resetKey"
        outlined
        type="number"
        hide-details="auto"
        dense
        :value="innerValue"
        :min="min"
        :max="max"
        :rules="rules"
        style="margin-left:4px;"
        :background-color="bgcolor"
        @input="inputValue"
      />
    </v-col>
    <v-col cols="2" align="center">
      <v-btn
        small
        height="40"
        outlined
        @click="increaseNumber"
        @mousedown="whileMouseDown(increaseNumber)"
        @mouseup="clearTimer"
      >
        +
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import BigNumber from "bignumber.js";
export default {
  name: "SpinnerButtonInput",

  props: {
    value: {
      type: String,
      default: ""
    },
    min: {
      default: 0,
      type: Number
    },
    max: {
      default: 10,
      type: Number
    },
    step: {
      default: 1,
      type: Number
    },
    rules: { type: Array, default: () => [], require: false },
    bgcolor: { type: String, default: "", require: false }
  },

  data: function() {
    return {
      resetKey: 0,
      innerValue: this.value,
      timer: null
    };
  },

  computed: {
    dp() {
      let p;
      switch (this.step) {
        case 0.1:
          p = 1;
          break;
        case 0.01:
          p = 2;
          break;
        case 0.001:
          p = 3;
          break;
        default:
          p = 0;
          break;
      }
      return p;
    }
  },

  watch: {
    innerValue: function(value) {
      let num = Number(value);

      if (num < this.min) {
        this.innerValue = String(this.min);
        this.refresh();
      }

      if (num > this.max) {
        this.innerValue = String(this.max);
        this.refresh();
      }

      if (num <= this.max && num >= this.min) {
        this.$emit("input", value);
      }
    }
  },

  methods: {
    refresh() {
      // 強制的にリフレッシュ ←「key」を更新するとレンダリングが発生する
      this.resetKey++;
    },

    clearTimer() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },

    whileMouseDown(callback) {
      if (this.timer === null) {
        this.timer = setInterval(() => {
          callback();
        }, 200);
      }
    },

    increaseNumber() {
      if (this.innerValue === "") {
        this.innerValue = String(this.min);
        return;
      }
      if (this.step === 1) {
        this.innerValue = String(Number(this.innerValue) + this.step);
      } else {
        this.innerValue = String(BigNumber(Number(this.innerValue)).dp(this.dp).plus(BigNumber(this.step).dp(this.dp)));
      }
    },

    decreaseNumber() {
      if (this.innerValue === "") {
        this.innerValue = String(this.min);
        return;
      }
      if (this.step === 1) {
        this.innerValue = String(Number(this.innerValue) - this.step);
      } else {
        this.innerValue = String(BigNumber(Number(this.innerValue)).dp(this.dp).minus(BigNumber(this.step).dp(this.dp)));
      }
    },

    inputValue(value) {
      if (value === undefined) {
        this.innerValue = "";
        return;
      }
      this.innerValue = String(value);
    }
  }
};
</script>

<style scoped>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
