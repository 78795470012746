<template>
  <v-card :outlined="outlined" tile>
    <v-card-text>
      <v-row dense>
        <v-col align="left" cols="2"> {{ $t("WORD_TOTAL") }} </v-col>
        <v-col align="right" cols="10"> {{ title }} </v-col>
      </v-row>
      <v-row dense>
        <v-col align="left" cols="2" class="pb-0">
          <v-card color="cyan darken-1" dark tile elevation="1" class="alabel">{{ $t("WORD_YOTEI") }}</v-card>
        </v-col>
        <v-col ref="column" align="right" cols="10" class="pb-0">
          <label style="font-size: 22px;">{{ displayYotei }}</label>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col align="left" cols="2" class="pb-0">
          <v-card color="pink darken-1" dark tile elevation="1" class="alabel">{{ $t("WORD_JISSEKI") }}</v-card>
        </v-col>
        <v-col ref="column" align="right" cols="10" class="pb-0">
          <label style="font-size: 22px;">{{ displayJisseki }}</label>
        </v-col>
      </v-row>
      <v-progress-linear v-model="progress" height="15" color="success" background-color="grey" class="progressbarliner">
        {{ progress }}%
      </v-progress-linear>
    </v-card-text>
  </v-card>
</template>

<script>
import * as Util from "@/util/utils.js";
export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    yotei: {
      type: Number,
      default: 0
    },
    jisseki: {
      type: Number,
      default: 0
    },
    outlined: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    displayYotei() {
      // 3桁ごとのカンマ区切り表示
      return this.yotei.toLocaleString(undefined, {
        maximumFractionDigits: 20
      });
    },
    displayJisseki() {
      // 3桁ごとのカンマ区切り表示
      return this.jisseki.toLocaleString(undefined, {
        maximumFractionDigits: 20
      });
    },
    progress (){
      return Util.calcProgress(this.jisseki, this.yotei);
    }
  }
};
</script>

<style scoped>
.alabel {
  font-weight: bold;
  font-size: 12px;
  padding-top: 1px;
  padding-left: 4px;
}
.progressbarliner {
  margin-top: 4px;
  color: #fff;
  border-top: solid 1px #bbb;
  pointer-events: none;
}
</style>
