var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pagetitles" }, [
    _c(
      "div",
      { staticClass: "title" },
      [
        _vm._v(" " + _vm._s(_vm.titles) + " "),
        _vm.linkUrl !== ""
          ? _c(
              "v-btn",
              {
                staticClass: "mx-1 mb-1",
                attrs: {
                  href: _vm.linkUrl,
                  target: "_blank",
                  small: "",
                  elevation: "1",
                  color: "warning"
                }
              },
              [
                _vm.linkIcon !== ""
                  ? _c("v-icon", [_vm._v(_vm._s(_vm.linkIcon))])
                  : _vm._e(),
                _vm._v(" " + _vm._s(_vm.linkStr) + " ")
              ],
              1
            )
          : _vm._e()
      ],
      1
    ),
    _vm.descriptions !== ""
      ? _c(
          "div",
          { staticClass: "desc flexarea" },
          [
            _c("v-icon", [_vm._v("mdi-hand-pointing-right")]),
            _vm._v(" " + _vm._s(_vm.descriptions) + " ")
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }