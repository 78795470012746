import rootState from "./index";
import Define from "@/define.js";
import SwalMsg from "@/swalMsg.js";
import Swal from "sweetalert2";

const state = {};
const getters = {
  /**
   * 共通情報リストを取得する
   * @returns {Array} 共通情報リスト
   */
  getInfo() {
    return rootState.state.category;
  },
  /**
   * セレクトボックス用の共通情報カテゴリ選択肢を取得する
   * @returns {Array} 共通情報カテゴリ選択肢
   */
  getInfoCategoryItems() {
    let items = [];
    for (const item of rootState.state.category) {
      items.push({ text: item.category, value: item.id });
    }
    return items;
  },
  /**
   * IDを指定してセレクトボックス用の共通情報データ選択肢を取得する
   * @param id ID
   * @returns {Array} 共通情報データ選択肢
   */
  getInfoDataItems: () => id => {
    let items = [];
    let category = rootState.state.category.filter(d => d.id === id);
    if (category.length > 0) {
      let datas = category[0].data.split(",");
      for (const data of datas) {
        items.push({ text: data, value: data });
      }
    }
    return items;
  }
};
const mutations = {
  initState() {}
};
const actions = {
  /**
   * [WS通信-送信] 共通情報作成/更新/削除
   */
  createUpdateDeleteInfo(state, info) {
    const socket = rootState.state.socket;
    if (!socket) {
      Swal.fire(SwalMsg.WS_NOT_CONNECT_ERR);
      return false;
    }
    socket.sendObj({
      type: Define.WS_CODE_SAVE_INFO,
      info: info,
      work_space_id: state.rootState.auth.user.workSpaceId || null
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
