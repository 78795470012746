<template>
  <div :class="cellFrameClass">
    <!-- カレンダー負荷上限 -->
    <v-text-field 
      class="disp-text centered-input"
      v-model="calendarSettingListByDate.max"
      variant="plain"
      hide-details="true"
      :rules="[check_progress_max]"
      :disabled="!isValidRole"
      @change="valueChange()"
    >
    </v-text-field>
    <!-- カレンダー負荷上限 -->
  </div>
</template>

<script>
import Define from "@/define.js";
import moment from "moment";
import { mixinValidator } from "@/mixins/mixinValidator.js";
export default {
  components: {
  },
  props: {
    calendarSettingListByDate: {
      type: Object,
      default: () => {}
    },
    isValidRole: {
      type: Boolean,
      default: true,
      require: true
    },
    calcProgressDataType: { 
      type: Number,
      default: Define.TYPE_STRING,
      require: true
    },
    calcObject1Type: {
      type: Number,
      default: null,
      require: true
    }
  },
  mixins: [mixinValidator],
  data() {
    return {
      check_progress_max: value => value === "" ? true : this.isValidProgressMax(value, this.calcProgressDataType, this.calcObject1Type)
    }
  },
  computed: {
    /**
     * 対象日の曜日
     */
    days () {
      return moment(this.calendarSettingListByDate.dateInfo).day();
    },
    /**
     * セルのクラスを取得する
     * @returns {String} - セルのクラス
     */
    cellFrameClass () {
      var cellFrameClass = "cell-flame";
      switch(this.days){
        case Define.DAYS_SUNDAY:
        cellFrameClass += " cell-flame-sunday";
        break;
        case Define.DAYS_SATURDAY:
        cellFrameClass += " cell-flame-saturday";
        break;
        default:
          break;
      }
      return cellFrameClass;
    }
  },
  methods: {
    valueChange () {
      this.calendarSettingListByDate.isChange = true;
    }
  }
};
</script>

<style scoped>
.cell-flame {
  border: solid 1px #bbb;
  border-left: 0;
  flex: 1;
}
.cell-flame-sunday {
  background-color: rgba(248, 187, 208, 0.2);
}
.cell-flame-saturday {
  background-color: rgba(187, 222, 251, 0.2);
}
.disp-text {
  margin: 0px 1px 0px 1px;
  font-size: 15px;
}
</style>
