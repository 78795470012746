<template>
  <div>
    <div class="areatitle mb-5">
      {{ $t("PAGE_SUBTITLE_APPLICATON_SETTING_MULTILINGUAL_API_FROM_FREELY_TO_OUTER") }}
    </div>
    <div class="ml-5">
      <v-form ref="form">
        <ApiSettingEvent :eventId="WEBHOOK_EVENT_ID_JISSEKI_REGIST" :items="jissekiRegistItems"/>
      </v-form>
    </div>
    <!-- 保存ボタン -->
    <div class="dataarea">
        <v-btn :disabled="!isValidRole" class="info savebtn" @click="save()">
          {{ $t("WORD_APPLICATION_SETTING_SAVE") }}
        </v-btn>
      </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Define from "@/define.js";
import * as Util from "@/util/utils.js";
import ApiSettingEvent from "@/components/parts/ApiSettingEvent";
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("apiSetting");
export default {
  components: {
    ApiSettingEvent
  },
  data() {
    return {
      items: [],
      jissekiRegistItems: [],
      WEBHOOK_EVENT_ID_JISSEKI_REGIST: Define.WEBHOOK_EVENT_ID_JISSEKI_REGIST
    };
  },
  computed: {
    isValidRole() {
      let user = this.$store.getters["auth/user"];
      let role = this.$store.getters["role/roleByWorkspaceId"](this.selectedWorkspaceId, user.role);
      return Util.getAuthority(Define.EVENT_APPLICATION_SETTING, role);
    },
    selectedWorkspaceId() {
      return this.$store.getters["auth/selectedWorkSpaceId"];
    },
    apiSettings() {
      return this.$store.getters["apiSetting/getApiSetting"];
    },
    optionHelpDialogData() {
      return this.$t("EXPLANATION_DIALOG.applicationSettingOptionHelp");
    }
  },
  watch: {
    apiSettings: {
      immediate: true,
      handler: function(value) {
        this.items = Util.deepCopy(value);
        this.jissekiRegistItems = Util.deepCopy(this.items.filter(d => d.eventId === this.WEBHOOK_EVENT_ID_JISSEKI_REGIST));
      }
    }
  },
  methods: {
    ...mapActions(["createUpdateDeleteApiSetting"]),
    getDeleteList() {
      const idList = this.items.map(d => d.id).filter(d => d);
      return this.apiSettings.filter(d => !idList.includes(d.id));
    },
    save() {
      if (!this.$refs.form.validate()) {
        Swal.fire({
          icon: "error",
          title: this.$t("MSG_ERR_INPUT_ITEM_INVALID"),
          text: this.$t("MSG_ERR_SUB_INPUT_ITEM_INVALID")
        });
        return;
      }

      let syncNum = this.jissekiRegistItems.filter(d => d.sync == true).length;
      if (syncNum > 1) {
        Swal.fire({
          icon: "error",
          title: this.$t("MSG_ERR_MULTILINGUAL_SETTING_INVALID"),
          text: this.$t("MSG_ERR_SUB_MULTILINGUAL_SETTING_INVALID")
        });
        return;
      }

      this.items = []
      this.items = this.items.concat(this.jissekiRegistItems)

      let items = Util.deepCopy(this.items);
      for(let item of items) {
        // ヘッダーの整形
        let tmp = {}
        for(let header of item.header) {
          tmp[header.name] = header.value
        }
        item.header = tmp;

        // ボディの整形
        item.body = JSON.parse(item.body)
      }
      // パラメータ設定
      let param = {
        insertUpdateList: items,
        deleteList: this.getDeleteList()
      };

      console.log(param);

      // APIコール
      this.createUpdateDeleteApiSetting(param);
    }
  }
};
</script>

<style scoped>
.areatitle {
  border-left: solid 6px #1976d2ca;
  margin-top: 8px;
  margin-bottom: 8px;
  padding-left: 8px;
}
.dataarea {
  margin: 20px 5px 20px 5px;
  width: 1820px;
}
.savebtn {
  margin-left: 28%;
  width: 40%;
}
</style>
