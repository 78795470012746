export default {
  namespaced: true,
  state: {
    language: "ja"
  },
  getters: {
    selectedLanguage: state => state.language
  },
  mutations: {
    initState() {
      // 言語設定はログアウトで初期化しない
      // state.language = "ja";
    },
    setLanguage(state, language) {
      state.language = language;
    }
  },
  actions: {
    setSelectedLanguage({ commit }, language) {
      commit("setLanguage", language);
    }
  }
};
