var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "extention" }, [
    !_vm.authApplicationSetting
      ? _c(
          "div",
          { staticClass: "attention" },
          [
            _c("v-icon", { attrs: { color: "error" } }, [_vm._v("mdi-alert")]),
            _vm._v(" " + _vm._s(_vm.$t("TEXT_APPLICATION_ROLE")) + " ")
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      [
        _c("ApplicationSettingExpansionCategory", {
          attrs: { authApplicationSetting: !_vm.authApplicationSetting }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }