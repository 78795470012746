<template>
  <div class="planarea">
    <v-row class="searcharea">
      <v-col>
        <DatePickWeek ref="datePick" :keydate="keydate" @setDate="setHeader($event)"></DatePickWeek>
      </v-col>
      <v-col lg="5" xl="5">
        <SearchBox :lineid="planAreaCode" @getCombo="setCombo"></SearchBox>
      </v-col>
      <v-col>
        <!-- カード検索 -->
        <v-tooltip
          bottom
          open-delay="300"
          color="rgba(60,64,67,0.95)"
          transition="fade"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon class="round-btn" elevation="2" v-bind="attrs" v-on="on" @click="openCardSearchDialog()">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("TOOLTIP_CARD_SEARCH") }}</span>
        </v-tooltip>
        <!-- 一か月表示 -->
        <v-tooltip
          bottom
          open-delay="300"
          color="rgba(60,64,67,0.95)"
          transition="fade"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon class="round-btn ml-3" elevation="2" v-bind="attrs" v-on="on" @click="openMonthViewDialog()">
              <v-icon>mdi-calendar-month</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("TOOLTIP_MONTH_VIEW") }}</span>
        </v-tooltip>
      </v-col>
      <CardSearchDialog
        :dialog="isOpenCardSearchDialog"
        @changeDate="changeDate($event)"
      />
      <MonthViewDialog
        :dialog="isOpenMonthViewDialog"
        @changeDate="changeDate($event)"
      />
      <v-col v-if="!loading">
        <v-switch
          v-model="swEnabled"
          class="swEnabled"
          :label="$t('WORD_CARD_MOVE')"
          color="#00b842"
          :disabled="tenant.plan === planTrial && tenant.trialEndFlg || !authCardAction"
        ></v-switch>
      </v-col>
      <v-col v-if="loading">
        <div class="text-center">
          <v-progress-circular
            :size="32"
            :width="4"
            color="#00b842"
            indeterminate
            class="mt-1"
          ></v-progress-circular>
        </div>
      </v-col>
    </v-row>
    <div class="headerarea" :style="areaWidthHeader">
      <div
        v-for="index in displayDays"
        :key="index"
        :class="
          isColOpens[index - 1]
            ? getClassOpen(days(index - 1).value)
            : getClassClose(days(index - 1).value)
        "
        @click="changeColOpen(days(index - 1).value)"
      >
        {{ days(index - 1).header }}
      </div>
    </div>
    <div class="scroll" :style="areaWidth">
      <LineListProgressbar
        v-for="tilelist in plantilelist.tileList"
        :key="tilelist.lineId"
        :enabled="enabled"
        :cardtempnum="cardtempnum"
        :arealist="tilelist"
        :keyword="keyword"
        :is-col-opens="isColOpens"
      ></LineListProgressbar>
    </div>
  </div>
</template>

<script>
import LineListProgressbar from "@/components/parts/LineListProgressbar.vue";
import SearchBox from "@/components/parts/SearchBox.vue";
import DatePickWeek from "@/components/parts/DatePickWeek.vue";
import CardSearchDialog from "@/components/parts/CardSearchDialog.vue";
import MonthViewDialog from "@/components/parts/MonthViewDialog.vue";
import Define from "@/define.js";
import moment from "moment";
import * as Util from "@/util/utils.js";
import Vue from "vue";
import Swal from "sweetalert2";
export default {
  components: {
    LineListProgressbar,
    SearchBox,
    DatePickWeek,
    CardSearchDialog,
    MonthViewDialog
  },
  props: {
    enabled: {
      type: Boolean,
      default: true
    },
    plantilelist: {
      type: Object,
      default: () => ({
        tileList: []
      })
    },
    cardtempnum: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      planAreaCode: Define.PLAN_AREA_CODE,
      planTrial: Define.PLAN_TRIAL,
      firstdate: moment(),
      keydate: "",
      keyword: { value: "", mappingId: "" },
      isColOpens: [],
      isOpenCardSearchDialog: false,
      isOpenMonthViewDialog: false
    };
  },
  computed: {
    days() {
      return dayIndex => {
        return this.setDate(dayIndex);
      };
    },
    swEnabled: {
      get() {
        let user = this.$store.getters["auth/user"];
        if (user.role === Define.USER_ROLE_PRACTITIONER) {
          return false;
        } else {
          return this.$store.getters["setting/getEnabled"];
        }
      },
      set() {
        this.$store.commit("setting/switchEnabled");
      }
    },
    tenant() {
      return this.$store.getters["tenant/getTenantData"];
    },
    loading: {
      get() {
        return this.$store.getters["setting/loading"];
      }
    },
    selectedWorkspaceId() {
      return this.$store.getters["auth/selectedWorkSpaceId"];
    },
    displayCol() {
      return this.$store.getters["display/displayCol"];
    },
    areaWidthHeader() {
      return {
        width: Util.getAreaWidth(this.isColOpens, true)
      };
    },
    areaWidth() {
      return {
        width: Util.getAreaWidth(this.isColOpens, false)
      };
    },
    displayDays() {
      return this.$store.getters["display/displayDays"];
    },
    authCardAction() {
      let user = this.$store.getters["auth/user"];
      return Util.getAuthority(Define.EVENT_CARD_ACTION, user.role);
    },
    isExistSetting() {
        return this.$store.getters["setting/isExistSetting"];
    },
  },
  watch: {
    selectedWorkspaceId: {
      handler: function() {
        this.setIsColOpens();
      }
    },
    displayCol: {
      immediate: true,
      deep: true,
      handler: function() {
        this.setIsColOpens();
      }
    }
  },
  methods: {
    /**
     * コンボボックスで選択されたキーワードとそのマッピングID、日付を代入
     * @param {Object} keyword - キーワード、マッピングID、日付
     */
    setCombo(keyword) {
      this.keyword = {
        value: keyword.value,
        mappingId: keyword.mappingId
      };
      this.keydate = keyword.posCol;
    },
    /**
     * DatePick.vueで取得した日付を列ヘッダの変数に代入、emitでイベント発火
     * @param {Date} date - 取得した日付
     */
    setHeader(date) {
      this.firstdate = date;
      this.setIsColOpens();
      this.$emit("catchDate", this.firstdate);
    },
    /**
     * DatePick.vueに外部からの日付変更を通知する
     * @param {Date} date - 取得した日付
     */
    changeDate(date) {
      this.$refs.datePick.setSelectDate(date)
    },
    /**
     * 列ヘッダに表示する文字列と比較用データを含むオブジェクトを返す
     * @param {Number} num - numの分だけfirstdateから＋される。そのままfirstdateを表示する場合はnum=0
     * @returns {Object} result - header: 画面表示文字列, value: isToday比較用
     */
    setDate(num) {
      let header = Define.DATE_FORMAT_WEEK;
      let value = Define.DATE_FORMAT_DAY_HYPHEN;
      let result = { header: "", value: "" };
      let locale = this.$i18n.locale;
      moment.locale(locale);
      if (num === Define.DAY_FIRST) {
        result.header = moment(this.firstdate).format(header);
        result.value = moment(this.firstdate).format(value);
      } else {
        result.header = moment(this.firstdate)
          .add(num, "days")
          .format(header);
        result.value = moment(this.firstdate)
          .add(num, "days")
          .format(value);
      }
      return result;
    },
    /**
     * 非折畳状態のクラスを取得する
     * @param {Date} date - 比較対象の日付
     * @returns {String} - 当てるcss名
     */
    getClassOpen(date) {
      let format = Define.DATE_FORMAT_DAY_HYPHEN;
      let headerOpen = Define.CSS_DAY_OPEN_HEADER;
      let todayHeaderOpen = Define.CSS_IS_TODAY_DAY_OPEN_HEADER;
      let today = moment().format(format);
      return date === today ? todayHeaderOpen : headerOpen;
    },
    /**
     * 折畳状態のクラスを取得する
     * @param {Date} date - 比較対象の日付
     * @returns {String} - 当てるcss名
     */
    getClassClose(date) {
      let format = Define.DATE_FORMAT_DAY_HYPHEN;
      let headerClose = Define.CSS_DAY_CLOSE_HEADER;
      let todayHeaderClose = Define.CSS_IS_TODAY_DAY_CLOSE_HEADER;
      let today = moment().format(format);
      return date === today ? todayHeaderClose : headerClose;
    },
    /**
     * 対象日付の折畳状態を変更する
     * @param {Date} date - 対象日付
     */
    changeColOpen(date) {
      let value = {
        boardId: this.selectedWorkspaceId,
        dateId: date
      };
      this.$store.commit("display/changeColOpen", value);
    },
    /**
     * 表示日付の折畳状態を設定する
     */
    setIsColOpens() {
      this.isColOpens = [];
      for (let i = 0; i < this.displayDays; i++) {
        Vue.set(this.isColOpens, i, Util.getIsOpen(this.displayCol, this.selectedWorkspaceId, this.days(i).value));
      }
    },
    /**
     * カード検索ダイアログを開く
     */
    openCardSearchDialog() {
      if(!this.isExistSetting){
        Swal.fire({
          html: this.$t("MSG_REQUIRED_SETTING"),
          icon: "error"
        });
        return;
      }
      this.$store.commit("tile/setIsOpenCardSearch", true);
    },
    /**
     * 一か月表示ダイアログを開く
     */
    openMonthViewDialog() {
      if(!this.isExistSetting){
        Swal.fire({
          html: this.$t("MSG_REQUIRED_SETTING"),
          icon: "error"
        });
        return;
      }
      this.$store.commit("tile/setIsOpenMonthView", true);
    }
  }
};
</script>

<style scoped>
.planarea {
  margin: 5px 2px 5px 1px;
}
.searcharea {
  min-width: 1350px;
  max-width: 100%;
  height: 64px;
}
.col {
  max-height: 100%;
}
.swEnabled {
  margin-top: 4px;
}
.scroll {
  max-height: 82vh;
  margin-bottom: -3px;
  background-color: #ededed;
  overflow-x: hidden;
  overflow-y: scroll;
}
.headeropen {
  text-align: center;
  margin-left: -1px;
  padding: 3px;
  width: 180px;
  color: #fff;
  font-weight: bold;
  border-right: solid 1px #bbb;
  border-left: solid 1px #bbb;
  cursor: pointer;
}
.headerclose {
  text-align: center;
  margin-left: -1px;
  padding: 3px;
  width: 65px;
  color: #fff;
  font-weight: bold;
  border-right: solid 1px #bbb;
  border-left: solid 1px #bbb;
  cursor: pointer;
}
.headerarea {
  height: 30px;
  padding-left: 80px;
  display: flex;
  background-color: #1976d2f7;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.istoday {
  background-color: #ffa726;
}
.round-btn {
  background-color: #fff;
}
</style>
