<template>
  <div class="option">
    <div v-if="!authApplicationSetting" class="attention">
      <v-icon color="error">mdi-alert</v-icon>
      {{ $t("TEXT_APPLICATION_ROLE") }}
    </div>
    <div>
      <div class="areatitle">
        {{ $t("PAGE_TITLE_APPLICATION_SERRING_OPTION") }}
        <HelpButton :helpobject="optionHelpDialogData"></HelpButton>
      </div>
      <div>
        <v-switch
          v-model="localOption.option001"
          color="#00b842"
          :label="$t('WORD_APPLICATION_SETTING_OPTION_INCOMPLETE')"
          :disabled="!authApplicationSetting"
        ></v-switch>
      </div>
      <div>
        <v-switch
          v-model="localOption.option002"
          color="#00b842"
          :label="$t('WORD_APPLICATION_SETTING_OPTION_CHANGE')"
          :disabled="!authApplicationSetting"
        ></v-switch>
      </div>
      <div v-if="tenant.isEditableOption003">
        <v-switch
          v-model="localOption.option003"
          color="#00b842"
          :label="$t('WORD_APPLICATION_SETTING_OPTION_MULTILINGUAL_API')"
          :disabled="!authApplicationSetting"
        ></v-switch>
      </div>
    </div>
    <!-- 保存ボタン -->
    <div class="dataarea">
      <v-btn :disabled="!authApplicationSetting" class="info savebtn" @click="save()">
        {{ $t("WORD_APPLICATION_SETTING_SAVE") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import Define from "@/define.js";
import * as Util from "@/util/utils.js";
import { createNamespacedHelpers } from "vuex";
import HelpButton from "@/components/parts/HelpButton";
const { mapActions } = createNamespacedHelpers("option");
export default {
  components: {
    HelpButton
  },
  data() {
    return {
      localOption: {}
    };
  },
  computed: {
    authApplicationSetting() {
      let user = this.$store.getters["auth/user"];
      let role = this.$store.getters["role/roleByWorkspaceId"](this.selectedWorkspaceId, user.role);
      return Util.getAuthority(Define.EVENT_APPLICATION_SETTING, role);
    },
    selectedWorkspaceId() {
      return this.$store.getters["auth/selectedWorkSpaceId"];
    },
    optionHelpDialogData() {
      return this.$t("EXPLANATION_DIALOG.applicationSettingOptionHelp");
    },
    option: {
      get() {
        return this.$store.getters["option/getOption"];
      }
    },
    tenant() {
      return this.$store.getters["tenant/getTenantData"];
    },
  },
  watch: {
    option: {
      immediate: true,
      handler: function(value) {
        this.localOption = Util.deepCopy(value);
      }
    }
  },
  methods: {
    ...mapActions(["createUpdateOption"]),
    save() {
      this.createUpdateOption(this.localOption);
    }
  }
};
</script>

<style scoped>
.option {
  margin: 5px;
  width: 1820px;
  min-height: 735px;
}
.areatitle {
  border-left: solid 6px #1976d2ca;
  margin-bottom: 8px;
  padding-left: 8px;
}
.attention {
  color: #ff5252;
  margin: 0 12px 24px 12px;
}
.dataarea {
  margin: 20px 5px 20px 5px;
  width: 1820px;
}
.savebtn {
  margin-left: 28%;
  width: 40%;
}
</style>
