var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.cellFrameClass },
    [
      _c("v-text-field", {
        staticClass: "disp-text centered-input",
        attrs: {
          variant: "plain",
          "hide-details": "true",
          rules: [_vm.check_progress_max],
          disabled: !_vm.isValidRole
        },
        on: {
          change: function($event) {
            return _vm.valueChange()
          }
        },
        model: {
          value: _vm.calendarSettingListByDate.max,
          callback: function($$v) {
            _vm.$set(_vm.calendarSettingListByDate, "max", $$v)
          },
          expression: "calendarSettingListByDate.max"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }