var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        fullscreen: "",
        "hide-overlay": "",
        transition: "v-dialog-transition"
      },
      model: {
        value: _vm.isOpenMobileCardDetail,
        callback: function($$v) {
          _vm.isOpenMobileCardDetail = $$v
        },
        expression: "isOpenMobileCardDetail"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { dark: "", color: "grey darken-3" } },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    id: "closebtn_" + _vm.cardDetailInfo.tileId,
                    icon: "",
                    dark: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.closeMobileCardDetail()
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              )
            ],
            1
          ),
          _c(
            "v-tabs",
            {
              ref: "tabs",
              attrs: { color: "info" },
              model: {
                value: _vm.tab,
                callback: function($$v) {
                  _vm.tab = $$v
                },
                expression: "tab"
              }
            },
            [
              _c("v-tab", [_vm._v(_vm._s(_vm.$t("TABNAME_CARD_INFO")))]),
              _vm.isValidJisseki
                ? _c("v-tab", [_vm._v(_vm._s(_vm.$t("TABNAME_CARD_JISSEKI")))])
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "tabdiv" },
            [
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.tab,
                    callback: function($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab"
                  }
                },
                [
                  _c(
                    "v-tab-item",
                    [
                      _c("MobileCardDetailInfo", {
                        on: {
                          close: function($event) {
                            return _vm.closeMobileCardDetail()
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-tab-item", [_c("MobileCardDetailJissekiInfo")], 1)
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }