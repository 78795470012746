var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "areatitle" },
        [
          _vm._v(
            " " +
              _vm._s(_vm.$t("PAGE_TITLE_APPLICATION_SERRING_CATEGORY")) +
              " "
          ),
          _c("HelpButton", {
            attrs: { helpobject: _vm.categoryHelpDialogData }
          })
        ],
        1
      ),
      _c(
        "v-form",
        { ref: "form" },
        [
          _c("v-data-table", {
            attrs: {
              headers: _vm.categoryTableHeaders,
              items: _vm.items,
              "footer-props": _vm.TABLE_FOOTER_SETTING_MIDDLE
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.category",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("v-text-field", {
                        attrs: {
                          dense: "",
                          outlined: "",
                          "hide-details": "auto",
                          disabled: _vm.authApplicationSetting,
                          rules: [_vm.required, _vm.category_max_length],
                          placeholder: _vm.$t(
                            "PLACEHOLDER_APPLICATION_SETTING_CATEGORY"
                          )
                        },
                        model: {
                          value: item.category,
                          callback: function($$v) {
                            _vm.$set(item, "category", $$v)
                          },
                          expression: "item.category"
                        }
                      })
                    ]
                  }
                },
                {
                  key: "item.data",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("v-text-field", {
                        attrs: {
                          dense: "",
                          outlined: "",
                          "hide-details": "auto",
                          disabled: _vm.authApplicationSetting,
                          rules: [_vm.required],
                          placeholder: _vm.$t(
                            "PLACEHOLDER_APPLICATION_SETTING_DATA"
                          )
                        },
                        model: {
                          value: item.data,
                          callback: function($$v) {
                            _vm.$set(item, "data", $$v)
                          },
                          expression: "item.data"
                        }
                      })
                    ]
                  }
                },
                {
                  key: "item.action",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-icon",
                        {
                          attrs: {
                            small: "",
                            disabled: _vm.authApplicationSetting
                          },
                          on: {
                            click: function($event) {
                              return _vm.deleteRow(item)
                            }
                          }
                        },
                        [_vm._v(" mdi-close ")]
                      )
                    ]
                  }
                }
              ],
              null,
              true
            )
          }),
          _c(
            "div",
            { staticClass: "flexarea" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "info",
                  attrs: {
                    disabled:
                      _vm.authApplicationSetting || _vm.items.length >= 50
                  },
                  on: {
                    click: function($event) {
                      return _vm.addRow()
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("WORD_ADD")) + " ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "dataarea" },
        [
          _c(
            "v-btn",
            {
              staticClass: "info savebtn",
              attrs: {
                disabled:
                  _vm.authApplicationSetting ||
                  (_vm.items.length === 0 && _vm.categorys.length === 0)
              },
              on: {
                click: function($event) {
                  return _vm.save()
                }
              }
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("WORD_APPLICATION_SETTING_SAVE")) + " "
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }