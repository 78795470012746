<template>
  <div>
    <div class="subtitle">{{ $t("PAGE_SUBTITLE_CARD_JISSEKI_TABLE") }}</div>
    <v-card flat class="jisseki-table  areamargin">
      <div>
        <v-data-table
          :headers="headers"
          :items="items"
          dense
          class="datatable"
          :footer-props="TABLE_FOOTER_SETTING_SHORT"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-icon v-if="authCardAction" small @click="deleteRow(item)">
              mdi-close
            </v-icon>
          </template>
        </v-data-table>
      </div>
    </v-card>
  </div>
</template>

<script>
import Define from "@/define.js";
import Swal from "sweetalert2";
import * as Util from "@/util/utils.js";
export default {
  data() {
    return {
      headers: []
    };
  },
  computed: {
    isOpenCardDetail() {
      return this.$store.getters["tile/isOpenCardDetail"];
    },
    tileInfo() {
      return this.$store.getters["tile/cardDetailInfo"];
    },
    jissekiMappings() {
      return this.$store.getters["setting/getJissekiMappings"];
    },
    items() {
      return this.$store.getters["tile/cardJissekiInfo"];
    },
    authCardAction() {
      let user = this.$store.getters["auth/user"];
      return Util.getAuthority(Define.EVENT_CARD_ACTION, user.role);
    },
    selectedWorkspaceId() {
      return this.$store.getters["auth/selectedWorkSpaceId"];
    },
    isUpdatingJisseki() {
      return this.$store.getters["isUpdatingJisseki"];
    },
    isDeletingJisseki() {
      return this.$store.getters["isDeletingJisseki"];
    },
    TABLE_FOOTER_SETTING_SHORT() {
      return this.$t("TABLE_FOOTER_SETTING_SHORT");
    }
  },
  watch: {
    isOpenCardDetail: {
      immediate: true,
      handler: function() {
        if (this.isOpenCardDetail) {
          this.createHeader(this.jissekiMappings);
        }
      }
    }
  },
  methods: {
    createHeader(jissekiMappings) {
      this.headers = [];

      // 登録日時
      this.headers.push({
        text: this.$t("WORD_REGISTERED_DATE"),
        value: "createdAtStr"
      });

      // マッピングから生成するヘッダー
      for (const jissekiMapping of jissekiMappings) {
        this.headers.push({
          text: jissekiMapping.name,
          value: jissekiMapping.mappingId
        });
      }
      // 削除ボタン
      this.headers.push({
        text: "",
        value: "action"
      });
    },
    deleteRow(item) {
      Swal.fire({
        title: this.$t("MSG_DELETE_JISSEKI_CONFIRM"),
        text: "",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: this.$t("WORD_DELETE"),
        confirmButtonColor: "#ff5252",
        cancelButtonText: this.$t("WORD_CANCEL")
      }).then(result => {
        if (result.value) {
          this.confirmClickOK(item);
        }
      });
    },
    confirmClickOK(item) {
      // カード情報
      const tileData = {
        tile_id: this.tileInfo.tileId,
        pos_col: this.tileInfo.posCol,
        pos_row: this.tileInfo.posRow,
        order: this.tileInfo.order,
        is_complete: this.tileInfo.isComplete,
        color: this.tileInfo.color,
        version: this.tileInfo.version,
        is_confirmed: this.tileInfo.isConfirmed,
        status: this.tileInfo.status
      };

      this.$store.commit("setIsDeletingJisseki", true);
      this.$store.commit("setIsLoading", true);

      this.$store.dispatch("tile/jissekiDelete", {
        tileData: tileData,
        groupId: item.groupId
      });
    }
  }
};
</script>

<style scoped>
.subtitle {
  border-left: solid 6px #1976d2ca;
  color: #333;
  margin: 4px;
  padding-left: 2px;
}
.jisseki-table {
  border: solid 1px #aaa;
}
.areamargin {
  margin: 0 10px 10px 10px;
}
.datatable {
  width: 99%;
  padding: 0 10px;
  right: 0;
}
</style>
