<template>
  <div v-if="tileinfo.tileId != null" :class="cardStyle" @click="cardClick()" @dblclick="cardDbClick()">
    <!-- 赤バッジ -->
    <v-badge :value="isDysplayStatusBadge" bordered right color="error" overlap offset-x="12" offset-y="12">
    <!-- 青バッジ -->
    <v-badge :value="isDysplayFileBadge" :content="fileNum" bordered left color="blue" :dot="fileIsDot" :offset-x="fileOffset" :offset-y="fileOffset">
      <Card :cardTempNum="cardTempNum" :tileinfo="tileinfo" />
    </v-badge>
    </v-badge>
  </div>
</template>

<script>
import Define from "@/define.js";
import Card from "@/components/parts/CardPattern/CardPattern";
export default {
  components: {
    Card
  },
  props: {
    tileinfo: {
      type: Object,
      default: () => ({
        tileId: "",
        isComplete: false,
        order: 0,
        posCol: "",
        posRow: "",
        isConfirmed: false,
        status: 0,
        displayData: [
          {
            value: "",
            mappingId: ""
          }
        ],
        tileDetailData: [],
        fileDataNum: 0
      })
    }
  },
  data() {
    return {
      isEnableOpenMobileDialog: true
    };
  },
  computed: {
    /**
     * テンプレートNo.
     */
    cardTempNum() {
      return this.$store.getters["tile/getTileTempNum"];
    },
    /**
     * [変更通知]カード確定状態
     * 0：未確定, 1：確定, 2：未確定(確定後)
     */
    status() {
      let option = this.$store.getters["option/getOption"];
      return (option.option002) ? this.tileinfo.status : Define.CARD_STATUS_CONFIRMED;
    },
    /**
     * [変更通知]赤バッジを表示するかどうか(カード右上)
     */
    isDysplayStatusBadge(){
      return this.status == Define.CARD_STATUS_CHANGE_AFTER_CONFIRMED;
    },
    /**
     * [変更通知]カードのスタイル(実線/点線)
     */
    cardStyle() {
      let style = "card";

      // モードで幅を決める
      if(this.isModePc){
        style += " card-width-pc";
      }else{
        style += " card-width-mobile";
      }

      // 確定状態で枠線を決める
      if (this.status == Define.CARD_STATUS_CONFIRMED){
        style += " card-border-solid";
      }else{
        style += " card-border-dotted";
      }
      return style;
    },
    /**
     * [カード補助情報のバッジ表示]設定値
     * 0：なし, 1：バッジのみ, 2；バッジと数
     */
    fileDisplayType(){
      return this.$store.getters["setting/getFileDisplayType"];
    },
    /**
     * [カード補助情報のバッジ表示]青バッジを表示するかどうか(カード左上)
     */
    isDysplayFileBadge(){
      return this.fileDisplayType && this.tileinfo.fileDataNum !== 0;
    },
    /**
     * [カード補助情報のバッジ表示]青バッジに表示するファイル数
     */
    fileNum(){
      return this.fileDisplayType == Define.FILE_DISPLAY_BADGE_NUMBER ? this.tileinfo.fileDataNum : null;
    },
    /**
     * [カード補助情報のバッジ表示]ドット表示かどうか
     */
    fileIsDot(){
      return this.fileDisplayType == Define.FILE_DISPLAY_BADGE;
    },
    /**
     * [カード補助情報のバッジ表示]青バッジのオフセット
     */
    fileOffset(){
      return this.fileDisplayType == Define.FILE_DISPLAY_BADGE ? 5 : 12;
    },
    isModePc() {
      return this.$store.getters["isModePc"];
    },
  },
  methods: {
    /**
     *クリックイベント(モバイル版専用)
     */
    cardClick() {
      if(!this.isModePc && this.isEnableOpenMobileDialog) {
        this.isEnableOpenMobileDialog = false;
        this.openDetail();
        setTimeout(this.isEnableOpenMobileDialogTrue, 1000);
      }
    },
    isEnableOpenMobileDialogTrue(){
      this.isEnableOpenMobileDialog = true;
    },
    /**
     *ダブルクリックイベント(PC版専用)
     */
    cardDbClick() {
      if(this.isModePc) {
        this.openDetail();
      }
    },
    openDetail() {
      this.$store.dispatch("setOpenDetailTileId", this.tileinfo.tileId);
      this.$store.dispatch("tile/getTileMoveHistoryData", this.tileinfo.tileId);
      this.$store.dispatch("tile/getTileDetailData", this.tileinfo.tileId);
      this.$store.commit("tile/setCardDetailInfo", this.tileinfo);
      this.$store.commit("tile/setIsAddCard", false);
      if(this.isModePc){
        this.$store.commit("tile/setIsOpenCardDetail", true);
      }else{
        this.$store.commit("tile/setIsOpenMobileCardDetail", true);
      }
    }
  }
};
</script>

<style scoped>
.card {
  margin: 4px 2px;
  height: 82px;
}
.card-width-pc {
  width: 170px;
}
.card-width-mobile {
  width: 270px;
}
.card-border-solid {
  border: solid 2px #888;
  border-radius: 3px;
}
.card-border-dotted  {
  border: dotted 2px #888;
  border-radius: 3px;
}
</style>
