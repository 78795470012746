var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticStyle: { display: "flex", "align-items": "center" } },
        [
          _c(
            "div",
            { staticClass: "subtitle", staticStyle: { height: "22px" } },
            [_vm._v(" " + _vm._s(_vm.$t("PAGE_TITLE_CARD_HISTORY")) + " ")]
          ),
          _c("HelpButton", { attrs: { helpobject: _vm.helpDialogData } })
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { lg: "7", xl: "7" } },
            [
              _c("div", { staticStyle: { height: "26px" } }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("PAGE_SUBTITLE_CARD_HISTORY_LIST")) + " "
                )
              ]),
              _c(
                "v-card",
                {
                  staticClass: "card-history scroll areamargin",
                  attrs: { flat: "" }
                },
                [
                  _c(
                    "div",
                    [
                      _c("v-data-table", {
                        staticClass: "datatable",
                        attrs: {
                          headers: _vm.historyTableHeaders,
                          items: _vm.items,
                          dense: "",
                          "footer-props": _vm.TABLE_FOOTER_SETTING_LONG
                        },
                        on: { "click:row": _vm.displayDetail },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "header.sort",
                              fn: function(ref) {
                                var header = ref.header
                                return [
                                  _c("div", { staticClass: "sort" }, [
                                    _vm._v(_vm._s(header.sort))
                                  ])
                                ]
                              }
                            },
                            {
                              key: "item.name",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c("div", { staticClass: "user_name" }, [
                                    _vm._v(_vm._s(item.name))
                                  ])
                                ]
                              }
                            },
                            {
                              key: "item.date",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.updateDate(item.updatedAt)) +
                                        " "
                                    )
                                  ])
                                ]
                              }
                            },
                            {
                              key: "item.content",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c("div", [
                                    _vm._v(_vm._s(_vm.getHistoryMsg(item)))
                                  ])
                                ]
                              }
                            },
                            {
                              key: "item.detail",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _vm.isDisplayDetailButton(item)
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-2",
                                              attrs: { small: "" }
                                            },
                                            [_vm._v(" mdi-arrow-right-thick ")]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { lg: "5", xl: "5" } },
            [
              _c("div", { staticStyle: { height: "26px" } }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("PAGE_SUBTITLE_CARD_HISTORY_DETAIL")) +
                    " "
                )
              ]),
              _c(
                "v-card",
                {
                  staticClass: "card-history scroll areamargin",
                  attrs: { flat: "" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "detailname",
                      staticStyle: { "font-weight": "bold" }
                    },
                    [_vm._v(_vm._s(_vm.detailName))]
                  ),
                  _vm._l(_vm.detailList, function(detail) {
                    return _c(
                      "div",
                      { key: detail.moveHistoryId },
                      [
                        _c(
                          "v-row",
                          { staticClass: "areamargin" },
                          [
                            _c(
                              "v-col",
                              { attrs: { lg: "3", xl: "3" } },
                              [
                                _c(
                                  "v-card",
                                  {
                                    staticClass: "detailtext",
                                    attrs: { flat: "" }
                                  },
                                  [
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: {
                                          bottom: "",
                                          "open-delay": "300",
                                          color: "rgba(60,64,67,0.95)",
                                          transition: "fade"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var name = ref.on
                                                return [
                                                  _c(
                                                    "v-card-text",
                                                    _vm._g(
                                                      {
                                                        staticClass: "name",
                                                        staticStyle: {
                                                          color: "black"
                                                        }
                                                      },
                                                      name
                                                    ),
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.getShortString(
                                                              detail.name
                                                            )[0]
                                                          ) +
                                                          " "
                                                      ),
                                                      _c("br"),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.getShortString(
                                                              detail.name
                                                            )[1]
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [
                                        _c("label", [
                                          _vm._v(_vm._s(detail.name))
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { lg: "4", xl: "4" } },
                              [
                                _c("v-text-field", {
                                  staticClass: "detailtext",
                                  attrs: {
                                    dense: "",
                                    "hide-details": "",
                                    readonly: "",
                                    outlined: ""
                                  },
                                  model: {
                                    value: detail.from,
                                    callback: function($$v) {
                                      _vm.$set(detail, "from", $$v)
                                    },
                                    expression: "detail.from"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { lg: "1", xl: "1" } },
                              [
                                _c(
                                  "v-card",
                                  { attrs: { flat: "" } },
                                  [
                                    _c(
                                      "v-card-text",
                                      {
                                        staticClass: "arrow",
                                        staticStyle: { color: "black" }
                                      },
                                      [_vm._v(_vm._s(detail.arrow))]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c("v-col", { attrs: { lg: "4", xl: "4" } }, [
                              _vm.isToOutLine
                                ? _c(
                                    "div",
                                    [
                                      _c("v-text-field", {
                                        staticClass: "detailtext",
                                        attrs: {
                                          dense: "",
                                          "hide-details": "",
                                          readonly: "",
                                          outlined: ""
                                        },
                                        model: {
                                          value: detail.to,
                                          callback: function($$v) {
                                            _vm.$set(detail, "to", $$v)
                                          },
                                          expression: "detail.to"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }