var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.isValidRole
        ? _c(
            "div",
            { staticClass: "attention" },
            [
              _c("v-icon", { attrs: { color: "error" } }, [
                _vm._v("mdi-alert")
              ]),
              _vm._v(" " + _vm._s(_vm.$t("TEXT_APPLICATION_ROLE")) + " ")
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-form",
        {
          ref: "form",
          attrs: { dence: "" },
          model: {
            value: _vm.valid,
            callback: function($$v) {
              _vm.valid = $$v
            },
            expression: "valid"
          }
        },
        [
          _vm.loadingFlg && !_vm.isExistSetting
            ? _c("div", { staticClass: "dataarea" }, [
                _c("div", [
                  _c("div", { staticClass: "areatitle" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("PAGE_SUBTITLE_APPLICATON_SETTING_AUTO")
                        ) +
                        " "
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "ml-4" },
                    [
                      _c(
                        "div",
                        { staticClass: "my-1" },
                        [
                          _c("lavel", { staticClass: "lbl-auto" }, [
                            _vm._v(
                              " " + _vm._s(_vm.$t("MSG_AUTO_SETTING_1")) + " "
                            ),
                            _c("br"),
                            _vm._v(
                              " " + _vm._s(_vm.$t("MSG_AUTO_SETTING_2")) + " "
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "success mx-1 mb-1",
                          attrs: { disabled: !_vm.isValidRole },
                          on: {
                            click: function($event) {
                              return _vm.setSeizoTanto()
                            }
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "mr-2",
                              attrs: { right: "", dark: "" }
                            },
                            [_vm._v("mdi-human")]
                          ),
                          _vm._v(
                            " " + _vm._s(_vm.$t("WORD_SEIZOU_TANTO")) + " "
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "success mx-1 mb-1",
                          attrs: { disabled: !_vm.isValidRole },
                          on: {
                            click: function($event) {
                              return _vm.setSeizoKikai()
                            }
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "mr-2",
                              attrs: { right: "", dark: "" }
                            },
                            [_vm._v("mdi-seat-outline")]
                          ),
                          _vm._v(
                            " " + _vm._s(_vm.$t("WORD_SEIZOU_KIKAI")) + " "
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "error mx-1 mb-1",
                          attrs: { disabled: !_vm.isValidRole },
                          on: {
                            click: function($event) {
                              return _vm.setInit()
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("WORD_SEIZOU_RESET")) + " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              ])
            : _vm._e(),
          _c("div", { staticClass: "cardselect" }, [
            _c(
              "div",
              { staticClass: "areatitle" },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("PAGE_SUBTITLE_APPLICATON_SETTING_TEMPLATE")
                    ) +
                    " "
                ),
                _c("HelpButton", {
                  attrs: { helpobject: _vm.tempalteHelpDialogData }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "flexarea" },
              [
                _c("v-spacer"),
                _c(
                  "v-radio-group",
                  {
                    attrs: { row: "", disabled: !_vm.isValidRole },
                    model: {
                      value: _vm.settingObj.tileTempNum,
                      callback: function($$v) {
                        _vm.$set(_vm.settingObj, "tileTempNum", $$v)
                      },
                      expression: "settingObj.tileTempNum"
                    }
                  },
                  [
                    _c("v-radio", { attrs: { name: "0" } }),
                    _c("ApplicationSettingCardPattern", {
                      attrs: { pattern: 0 },
                      on: { select: _vm.setPattern }
                    }),
                    _c("v-radio", { attrs: { name: "1" } }),
                    _c("ApplicationSettingCardPattern", {
                      attrs: { pattern: 1 },
                      on: { select: _vm.setPattern }
                    }),
                    _c("v-radio", { attrs: { name: "2" } }),
                    _c("ApplicationSettingCardPattern", {
                      attrs: { pattern: 2 },
                      on: { select: _vm.setPattern }
                    }),
                    _c("v-radio", { attrs: { name: "3" } }),
                    _c("ApplicationSettingCardPattern", {
                      attrs: { pattern: 3 },
                      on: { select: _vm.setPattern }
                    })
                  ],
                  1
                ),
                _c("v-spacer")
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "dataarea" },
            [
              _c(
                "div",
                { staticClass: "areatitle" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("PAGE_SUBTITLE_APPLICATON_SETTING_DATA_MAPPING")
                      ) +
                      " "
                  ),
                  _c("HelpButton", {
                    attrs: { helpobject: _vm.mappingHelpDialogData }
                  }),
                  _c("span", { staticClass: "required" }, [
                    _vm._v(" " + _vm._s(_vm.requiredWord) + " ")
                  ])
                ],
                1
              ),
              _c("v-data-table", {
                staticClass: "tablearea",
                attrs: {
                  headers: _vm.mappingTableHeaders,
                  "item-key": "id",
                  items: _vm.settingObj.mappingDataList,
                  "footer-props": _vm.TABLE_FOOTER_SETTING_MIDDLE
                },
                scopedSlots: _vm._u(
                  [
                    _vm.isValidRole
                      ? {
                          key: "top",
                          fn: function() {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "red--text",
                                  style: { "white-space": "pre-wrap" }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.warningDeleteMapping) + " "
                                  )
                                ]
                              )
                            ]
                          },
                          proxy: true
                        }
                      : null,
                    {
                      key: "item.name",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("v-text-field", {
                            attrs: {
                              dense: "",
                              outlined: "",
                              "hide-details": "auto",
                              disabled: !_vm.isValidRole,
                              rules: [
                                _vm.check_required,
                                _vm.check_string,
                                _vm.check_percent
                              ]
                            },
                            model: {
                              value: item.name,
                              callback: function($$v) {
                                _vm.$set(item, "name", $$v)
                              },
                              expression: "item.name"
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "item.type",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("v-select", {
                            attrs: {
                              dense: "",
                              outlined: "",
                              "hide-details": "",
                              disabled:
                                !_vm.isValidRole || item.state !== "add",
                              items: _vm.listTypeItems
                            },
                            on: {
                              change: function($event) {
                                return _vm.resetByType(item)
                              }
                            },
                            model: {
                              value: item.type,
                              callback: function($$v) {
                                _vm.$set(item, "type", $$v)
                              },
                              expression: "item.type"
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "item.inputType",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  dense: "",
                                  outlined: "",
                                  "hide-details": "",
                                  disabled: !_vm.isValidRole,
                                  items: _vm.listInputTypeItems(item.type)
                                },
                                model: {
                                  value: item.inputType,
                                  callback: function($$v) {
                                    _vm.$set(item, "inputType", $$v)
                                  },
                                  expression: "item.inputType"
                                }
                              }),
                              _vm.isDisplayCategory(item.type, item.inputType)
                                ? _c("v-select", {
                                    class: "ml-1",
                                    style: "width: 250px",
                                    attrs: {
                                      dense: "",
                                      outlined: "",
                                      "hide-details": "auto",
                                      disabled: !_vm.isValidRole,
                                      items: _vm.listCategoryItems,
                                      rules: [_vm.check_required],
                                      label: _vm.$t(
                                        "WORD_APPLICATION_SETTING_CATEGORY"
                                      )
                                    },
                                    on: { click: _vm.clickSelectCategory },
                                    model: {
                                      value: item.category,
                                      callback: function($$v) {
                                        _vm.$set(item, "category", $$v)
                                      },
                                      expression: "item.category"
                                    }
                                  })
                                : _vm._e(),
                              _vm.isDisplayNumParam(item.type, item.inputType)
                                ? _c("v-text-field", {
                                    class: "ml-1",
                                    style: "width: 140px",
                                    attrs: {
                                      dense: "",
                                      outlined: "",
                                      "hide-details": "auto",
                                      disabled: !_vm.isValidRole,
                                      rules: _vm.minRules(item.type),
                                      label: _vm.$t(
                                        "WORD_APPLICATION_SETTING_MINNUM"
                                      )
                                    },
                                    model: {
                                      value: item.min,
                                      callback: function($$v) {
                                        _vm.$set(item, "min", $$v)
                                      },
                                      expression: "item.min"
                                    }
                                  })
                                : _vm._e(),
                              _vm.isDisplayNumParam(item.type, item.inputType)
                                ? _c("v-text-field", {
                                    class: "ml-1",
                                    style: "width: 140px",
                                    attrs: {
                                      dense: "",
                                      outlined: "",
                                      "hide-details": "auto",
                                      disabled: !_vm.isValidRole,
                                      rules: _vm.maxRules(item.type, item.min),
                                      label: _vm.$t(
                                        "WORD_APPLICATION_SETTING_MAXNUM"
                                      )
                                    },
                                    model: {
                                      value: item.max,
                                      callback: function($$v) {
                                        _vm.$set(item, "max", $$v)
                                      },
                                      expression: "item.max"
                                    }
                                  })
                                : _vm._e(),
                              _vm.isDisplayNumParam(item.type, item.inputType)
                                ? _c("v-select", {
                                    class: "ml-1",
                                    style: "width: 140px",
                                    attrs: {
                                      dense: "",
                                      outlined: "",
                                      "hide-details": "auto",
                                      disabled: !_vm.isValidRole,
                                      items: _vm.listStepItems(item.type),
                                      rules: [_vm.check_required],
                                      label: _vm.$t(
                                        "WORD_APPLICATION_SETTING_STEP"
                                      )
                                    },
                                    model: {
                                      value: item.step,
                                      callback: function($$v) {
                                        _vm.$set(item, "step", $$v)
                                      },
                                      expression: "item.step"
                                    }
                                  })
                                : _vm._e(),
                              _vm.isDisplayCalc(item.type, item.inputType)
                                ? _c("v-select", {
                                    class: "ml-1",
                                    style: "width: 175px",
                                    attrs: {
                                      dense: "",
                                      outlined: "",
                                      "hide-details": "auto",
                                      disabled:
                                        !_vm.isValidRole ||
                                        item.state !== "add",
                                      items: _vm.listCalcObject1Items,
                                      rules: [_vm.check_required],
                                      label: _vm.$t("WORD_CALC_OBJECT1")
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.resetByCalcObjact1(item)
                                      }
                                    },
                                    model: {
                                      value: item.calcObject1,
                                      callback: function($$v) {
                                        _vm.$set(item, "calcObject1", $$v)
                                      },
                                      expression: "item.calcObject1"
                                    }
                                  })
                                : _vm._e(),
                              _vm.isDisplayCalc(item.type, item.inputType)
                                ? _c("v-select", {
                                    class: "ml-1",
                                    style: "width: 120px",
                                    attrs: {
                                      dense: "",
                                      outlined: "",
                                      "hide-details": "auto",
                                      disabled:
                                        !_vm.isValidRole ||
                                        item.state !== "add",
                                      items: _vm.listShisokuEnzanshiItems(
                                        _vm.getTypeFromMappingId(
                                          item.calcObject1
                                        )
                                      ),
                                      rules: [_vm.check_required],
                                      label: _vm.$t("WORD_SHISOKU_ENZANSHI")
                                    },
                                    model: {
                                      value: item.shisokuEnzanshi,
                                      callback: function($$v) {
                                        _vm.$set(item, "shisokuEnzanshi", $$v)
                                      },
                                      expression: "item.shisokuEnzanshi"
                                    }
                                  })
                                : _vm._e(),
                              _vm.isDisplayCalc(item.type, item.inputType)
                                ? _c("v-select", {
                                    class: "ml-1",
                                    style: "width: 175px",
                                    attrs: {
                                      dense: "",
                                      outlined: "",
                                      "hide-details": "auto",
                                      disabled:
                                        !_vm.isValidRole ||
                                        item.state !== "add",
                                      items: _vm.listCalcObject2Items(
                                        _vm.getTypeFromMappingId(
                                          item.calcObject1
                                        )
                                      ),
                                      rules: [_vm.check_required],
                                      label: _vm.$t("WORD_CALC_OBJECT2")
                                    },
                                    model: {
                                      value: item.calcObject2,
                                      callback: function($$v) {
                                        _vm.$set(item, "calcObject2", $$v)
                                      },
                                      expression: "item.calcObject2"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: "item.column",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("v-select", {
                            attrs: {
                              dense: "",
                              outlined: "",
                              "hide-details": "",
                              disabled:
                                !_vm.isValidRole || item.type === _vm.TYPE_CALC,
                              items: _vm.listColumnItems
                            },
                            model: {
                              value: item.column,
                              callback: function($$v) {
                                _vm.$set(item, "column", $$v)
                              },
                              expression: "item.column"
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "item.pos",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("v-select", {
                            attrs: {
                              dense: "",
                              outlined: "",
                              "hide-details": "",
                              disabled: !_vm.isValidRole,
                              items: _vm.listPosItems
                            },
                            model: {
                              value: item.pos,
                              callback: function($$v) {
                                _vm.$set(item, "pos", $$v)
                              },
                              expression: "item.pos"
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "item.jisseki",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("v-select", {
                            attrs: {
                              dense: "",
                              outlined: "",
                              "hide-details": "",
                              disabled: !_vm.isValidRole,
                              items: _vm.listJissekiItems
                            },
                            model: {
                              value: item.jisseki,
                              callback: function($$v) {
                                _vm.$set(item, "jisseki", $$v)
                              },
                              expression: "item.jisseki"
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "item.jissekicalc",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("v-select", {
                            attrs: {
                              dense: "",
                              outlined: "",
                              "hide-details": "",
                              disabled:
                                !_vm.isValidRole ||
                                !_vm.isEditableJissekiCalc(
                                  item.type,
                                  item.jisseki,
                                  _vm.getTypeFromMappingId(item.calcObject1)
                                ),
                              items: _vm.listJissekiCalcItems
                            },
                            model: {
                              value: item.jissekicalc,
                              callback: function($$v) {
                                _vm.$set(item, "jissekicalc", $$v)
                              },
                              expression: "item.jissekicalc"
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "item.key",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("v-checkbox", {
                            attrs: {
                              small: "",
                              disabled:
                                !_vm.isValidRole || item.type === _vm.TYPE_CALC
                            },
                            model: {
                              value: item.key,
                              callback: function($$v) {
                                _vm.$set(item, "key", $$v)
                              },
                              expression: "item.key"
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "item.action",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "v-icon",
                            {
                              attrs: { small: "", disabled: !_vm.isValidRole },
                              on: {
                                click: function($event) {
                                  return _vm.deleteMappingRow(item)
                                }
                              }
                            },
                            [_vm._v(" mdi-close ")]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              }),
              _c(
                "div",
                { staticClass: "flexarea" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "info",
                      attrs: { disabled: !_vm.isValidRole },
                      on: { click: _vm.addMappingList }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("WORD_ADD")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "dataarea" },
            [
              _c(
                "div",
                { staticClass: "areatitle" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "PAGE_SUBTITLE_APPLICATON_SETTING_LOAD_CALCULATE"
                        )
                      ) +
                      " "
                  ),
                  _c("HelpButton", {
                    attrs: { helpobject: _vm.calcHelpDialogData }
                  })
                ],
                1
              ),
              _c("v-select", {
                staticClass: "selecter",
                attrs: {
                  dense: "",
                  outlined: "",
                  "hide-details": "",
                  disabled: !_vm.isValidRole,
                  items: _vm.listComputeItems,
                  "item-value": "mappingId",
                  "item-text": "name"
                },
                model: {
                  value: _vm.settingObj.calcProgressData,
                  callback: function($$v) {
                    _vm.$set(_vm.settingObj, "calcProgressData", $$v)
                  },
                  expression: "settingObj.calcProgressData"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "dataarea" },
            [
              _c(
                "div",
                { staticClass: "areatitle" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("PAGE_SUBTITLE_APPLICATON_SETTING_PLANNED_AREA")
                      ) +
                      " "
                  ),
                  _c("HelpButton", {
                    attrs: { helpobject: _vm.areaHelpDialogData }
                  }),
                  _c("span", { staticClass: "required" }, [
                    _vm._v(" " + _vm._s(_vm.requiredWord) + " ")
                  ])
                ],
                1
              ),
              _c("v-data-table", {
                staticClass: "tablearea",
                attrs: {
                  headers: _vm.areaTableHeaders,
                  items: _vm.settingObj.planAreaList,
                  "footer-props": _vm.TABLE_FOOTER_SETTING_MIDDLE
                },
                scopedSlots: _vm._u(
                  [
                    _vm.isValidRole
                      ? {
                          key: "top",
                          fn: function() {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "red--text",
                                  style: { "white-space": "pre-wrap" }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.warningDeleteArea) + " "
                                  )
                                ]
                              )
                            ]
                          },
                          proxy: true
                        }
                      : null,
                    {
                      key: "item.name",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("v-text-field", {
                            attrs: {
                              dense: "",
                              outlined: "",
                              "hide-details": "auto",
                              disabled: !_vm.isValidRole,
                              rules: [
                                _vm.check_required,
                                _vm.check_string,
                                _vm.check_percent
                              ]
                            },
                            model: {
                              value: item.name,
                              callback: function($$v) {
                                _vm.$set(item, "name", $$v)
                              },
                              expression: "item.name"
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "item.max",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("v-text-field", {
                            attrs: {
                              dense: "",
                              outlined: "",
                              "hide-details": "auto",
                              disabled: !_vm.isValidRole,
                              rules: [_vm.check_progress_max]
                            },
                            model: {
                              value: item.max,
                              callback: function($$v) {
                                _vm.$set(item, "max", $$v)
                              },
                              expression: "item.max"
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "item.order",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("v-select", {
                            attrs: {
                              dense: "",
                              outlined: "",
                              "hide-details": "",
                              disabled: !_vm.isValidRole,
                              items: _vm.listOrderItems
                            },
                            model: {
                              value: item.order,
                              callback: function($$v) {
                                _vm.$set(item, "order", $$v)
                              },
                              expression: "item.order"
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "item.action",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "v-icon",
                            {
                              attrs: { small: "", disabled: !_vm.isValidRole },
                              on: {
                                click: function($event) {
                                  return _vm.deleteAreaRow(item)
                                }
                              }
                            },
                            [_vm._v(" mdi-close ")]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              }),
              _c(
                "div",
                { staticClass: "flexarea" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "info",
                      attrs: { disabled: !_vm.isValidRole },
                      on: { click: _vm.addAreaList }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("WORD_ADD")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-expansion-panels",
            { staticClass: "dataarea", attrs: { multiple: "" } },
            [
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", [
                    _c(
                      "div",
                      { staticClass: "areatitle" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "PAGE_SUBTITLE_APPLICATON_SETTING_INPUT_FILE"
                              )
                            ) +
                            " "
                        ),
                        _c("HelpButton", {
                          attrs: { helpobject: _vm.fileHelpDialogData }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("v-expansion-panel-content", [
                    _c(
                      "div",
                      { staticClass: "dataarea" },
                      [
                        _c("InputFileSetting", {
                          attrs: {
                            inputFileSetting: _vm.settingObj.inputFileSetting,
                            userRole: !_vm.isValidRole,
                            mappingDataList: _vm.settingObj.mappingDataList
                          },
                          on: {
                            input: function($event) {
                              _vm.settingObj.inputFileSetting = $event
                            }
                          }
                        })
                      ],
                      1
                    )
                  ])
                ],
                1
              ),
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", [
                    _c(
                      "div",
                      { staticClass: "areatitle" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("PAGE_SUBTITLE_APPLICATON_SETTING_COLOR")
                            ) +
                            " "
                        ),
                        _c("HelpButton", {
                          attrs: {
                            helpobject: _vm.colorConditionsHelpDialogData
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c("ColorConditionSettings", {
                        attrs: {
                          "color-condition-list":
                            _vm.settingObj.colorConditionList,
                          "mapping-data-list": _vm.listMapping,
                          "user-role": !_vm.isValidRole
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", [
                    _c(
                      "div",
                      { staticClass: "areatitle" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("PAGE_SUBTITLE_APPLICATON_SETTING_DETAIL")
                            ) +
                            " "
                        ),
                        _c("HelpButton", {
                          attrs: { helpobject: _vm.detailHelpDialogData }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("v-expansion-panel-content", [
                    _c(
                      "div",
                      { staticClass: "dataarea" },
                      [
                        _c(
                          "v-row",
                          { attrs: { dense: "", align: "center" } },
                          [
                            _c("v-col", { attrs: { cols: "2" } }, [
                              _c("label", { staticClass: "detail-title" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "PAGE_SUBTITLE_APPLICATON_SETTING_DETAIL_CARD_MOVE"
                                    )
                                  )
                                )
                              ])
                            ]),
                            _c(
                              "v-col",
                              { attrs: { cols: "10" } },
                              [
                                _c(
                                  "v-radio-group",
                                  {
                                    staticClass: "my-2",
                                    attrs: {
                                      row: "",
                                      "hide-details": "",
                                      disabled: !_vm.isValidRole
                                    },
                                    model: {
                                      value: _vm.settingObj.cardMoveJudge,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.settingObj,
                                          "cardMoveJudge",
                                          $$v
                                        )
                                      },
                                      expression: "settingObj.cardMoveJudge"
                                    }
                                  },
                                  [
                                    _c("v-radio", {
                                      attrs: {
                                        label: _vm.$t(
                                          "WORD_APPLICATION_SETTING_CARD_MOVE_OUTORDER"
                                        ),
                                        value: 0
                                      }
                                    }),
                                    _c("v-radio", {
                                      attrs: {
                                        label: _vm.$t(
                                          "WORD_APPLICATION_SETTING_CARD_MOVE_INORDER"
                                        ),
                                        value: 1
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          { attrs: { dense: "", align: "center" } },
                          [
                            _c("v-col", { attrs: { cols: "2" } }, [
                              _c("label", { staticClass: "detail-title" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "PAGE_SUBTITLE_APPLICATON_SETTING_DETAIL_LOAD_DISPLAY"
                                    )
                                  )
                                )
                              ])
                            ]),
                            _c(
                              "v-col",
                              { attrs: { cols: "10" } },
                              [
                                _c(
                                  "v-radio-group",
                                  {
                                    staticClass: "my-2",
                                    attrs: {
                                      row: "",
                                      "hide-details": "",
                                      disabled: !_vm.isValidRole
                                    },
                                    model: {
                                      value: _vm.settingObj.loadDisplayType,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.settingObj,
                                          "loadDisplayType",
                                          $$v
                                        )
                                      },
                                      expression: "settingObj.loadDisplayType"
                                    }
                                  },
                                  [
                                    _c("v-radio", {
                                      attrs: {
                                        label: _vm.$t(
                                          "WORD_APPLICATION_SETTING_LOAD_PERCENT"
                                        ),
                                        value: 0
                                      }
                                    }),
                                    _c("v-radio", {
                                      attrs: {
                                        label: _vm.$t(
                                          "WORD_APPLICATION_SETTING_LOAD_PERCENT_DETAIL"
                                        ),
                                        value: 1
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          { attrs: { dense: "", align: "center" } },
                          [
                            _c("v-col", { attrs: { cols: "2" } }, [
                              _c("label", { staticClass: "detail-title" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "PAGE_SUBTITLE_APPLICATON_SETTING_DETAIL_FILE_BADGE_DISPLAY"
                                    )
                                  )
                                )
                              ])
                            ]),
                            _c(
                              "v-col",
                              { attrs: { cols: "10" } },
                              [
                                _c(
                                  "v-radio-group",
                                  {
                                    staticClass: "my-2",
                                    attrs: {
                                      row: "",
                                      "hide-details": "",
                                      disabled: !_vm.isValidRole
                                    },
                                    model: {
                                      value: _vm.settingObj.fileDisplayType,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.settingObj,
                                          "fileDisplayType",
                                          $$v
                                        )
                                      },
                                      expression: "settingObj.fileDisplayType"
                                    }
                                  },
                                  [
                                    _c("v-radio", {
                                      attrs: {
                                        label: _vm.$t(
                                          "WORD_APPLICATION_SETTING_FILE_DISPLAY_NONE"
                                        ),
                                        value: 0
                                      }
                                    }),
                                    _c("v-radio", {
                                      attrs: {
                                        label: _vm.$t(
                                          "WORD_APPLICATION_SETTING_FILE_DISPLAY_BADGE"
                                        ),
                                        value: 1
                                      }
                                    }),
                                    _c("v-radio", {
                                      attrs: {
                                        label: _vm.$t(
                                          "WORD_APPLICATION_SETTING_FILE_DISPLAY_BADGE_NUMBER"
                                        ),
                                        value: 2
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          { attrs: { dense: "", align: "center" } },
                          [
                            _c("v-col", { attrs: { cols: "2" } }, [
                              _c("label", { staticClass: "detail-title" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "PAGE_SUBTITLE_APPLICATON_SETTING_DETAIL_DATE_DISPLAY"
                                    )
                                  )
                                )
                              ])
                            ]),
                            _c(
                              "v-col",
                              { attrs: { cols: "10" } },
                              [
                                _c(
                                  "v-radio-group",
                                  {
                                    staticClass: "my-2",
                                    attrs: {
                                      row: "",
                                      "hide-details": "",
                                      disabled: !_vm.isValidRole
                                    },
                                    model: {
                                      value: _vm.settingObj.dateDisplayType,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.settingObj,
                                          "dateDisplayType",
                                          $$v
                                        )
                                      },
                                      expression: "settingObj.dateDisplayType"
                                    }
                                  },
                                  [
                                    _c("v-radio", {
                                      attrs: {
                                        label: _vm.$t(
                                          "WORD_APPLICATION_SETTING_DATE_YYYYMMDD"
                                        ),
                                        value: 0
                                      }
                                    }),
                                    _c("v-radio", {
                                      attrs: {
                                        label: _vm.$t(
                                          "WORD_APPLICATION_SETTING_DATE_MMDD"
                                        ),
                                        value: 1
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "dataarea" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "info savebtn",
                  attrs: { disabled: !_vm.isValidRole },
                  on: { click: _vm.settingSave }
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("WORD_APPLICATION_SETTING_SAVE")) + " "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }