<template>
  <div>
    <div class="areatitle mb-5">
      {{ $t("PAGE_SUBTITLE_APPLICATON_SETTING_MULTILINGUAL_API_FROM_OUTER_TO_FREELY") }}
    </div>
    <div class="ml-5 mb-5">
      <div class="d-flex flex-row align-baseline api-key-field">
        <v-text-field v-model="apiKey" dense outlined hide-details readonly width="100"></v-text-field>
        <v-btn :disabled="!isValidRole" class="info ml-2" @click="displayApiKey()">{{ $t("WORD_APPLICATION_SETTING_MULTILINGUAL_API_KEY_DISPLAY") }}</v-btn>
        <v-btn v-if="apiKey != ''" :disabled="!isValidRole" class="error ml-2" @click="deleteApiKey()">{{ $t("WORD_APPLICATION_SETTING_MULTILINGUAL_API_KEY_DELETE") }}</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import Define from "@/define.js";
import * as Util from "@/util/utils.js";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      apiKey: ""
    };
  },
  computed: {
    isValidRole() {
      let user = this.$store.getters["auth/user"];
      let role = this.$store.getters["role/roleByWorkspaceId"](this.selectedWorkspaceId, user.role);
      return Util.getAuthority(Define.EVENT_APPLICATION_SETTING, role);
    },
    selectedWorkspaceId() {
      return this.$store.getters["auth/selectedWorkSpaceId"];
    },
    optionHelpDialogData() {
      return this.$t("EXPLANATION_DIALOG.applicationSettingOptionHelp");
    }
  },
  methods: {
    displayApiKey() {
      // loadingダイアログ表示
      this.$store.commit("setIsLoading", true);
        Util.axiosPostReq(Define.API_TOKEN)
          .then(response => {
            console.log(response.status);
            this.apiKey = response.data;
            // loadingダイアログ非表示
            this.$store.commit("setIsLoading", false);
          })
          .catch(error => {
            console.log(error.message);
            // loadingダイアログ非表示
            this.$store.commit("setIsLoading", false);
            Swal.fire({
              icon: "error",
              title: this.$t("MSG_ERR_MULTILINGUAL_API_KEY_ACQUISITION_FAILURE"),
              text: this.$t("MSG_ERR_SUB_MULTILINGUAL_API_KEY_ACQUISITION_FAILURE")
            });
          });
    },
    deleteApiKey() {
      Swal.fire({
        icon: "question",
        html: this.$t("MSG_DELETE_APIKEY_CONFIRM"),
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          // loadingダイアログ表示
          this.$store.commit("setIsLoading", true);
            Util.axiosDeleteReq(Define.API_TOKEN)
              .then(response => {
                console.log(response.status);
                // loadingダイアログ非表示
                this.$store.commit("setIsLoading", false);
                this.apiKey = "";
              })
              .catch(error => {
                console.log(error.message);
                // loadingダイアログ非表示
                this.$store.commit("setIsLoading", false);
                Swal.fire({
                  icon: "error",
                  title: this.$t("MSG_ERR_MULTILINGUAL_API_KEY_DELETE_FAILURE"),
                  text: this.$t("MSG_ERR_SUB_MULTILINGUAL_API_KEY_DELETE_FAILURE")
                });
              });
        }
      });
    }
  }
};
</script>

<style scoped>
.areatitle {
  border-left: solid 6px #1976d2ca;
  margin-top: 8px;
  margin-bottom: 8px;
  padding-left: 8px;
}
.api-key-field {
  width: 800px;
}
</style>
