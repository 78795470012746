<template>
  <v-app>
    <LoadingDialog ref="loadingdialog"></LoadingDialog>
    <transition mode="out-in">
      <router-view />
    </transition>
  </v-app>
</template>

<script>
import Define from "@/define.js";
import LoadingDialog from "@/components/parts/LoadingDialog";
export default {
  name: "App",
  components: {
    LoadingDialog
  },
  computed: {
    isLoading() {
      return this.$store.getters["isLoading"];
    },
    token() {
      return this.$store.getters["auth/token"];
    }
  },
  watch: {
    isLoading: function(value) {
      if (value) {
        this.$refs.loadingdialog.loadingOpen();
      } else {
        this.$refs.loadingdialog.loadingClose();
      }
    }
  },
  created: function() {
    // トークンを既に持っていればヘッダー情報に追加
    if (this.token) {
      this.$axios.defaults.headers.common[
        "Authorization"
      ] = `JWT ${this.token}`;
    }

    // axios通信のResponceを監視する
    this.$axios.interceptors.response.use(
      response => {
        // APIのリクエストに成功した場合はそのまま結果を返す
        return response;
      },
      error => {
        let res = error.response;
        if (error.config && res) {
          if (
            this.isErrAuthentication(res.status) ||
            this.isErrRefreshToken(res.data, res.status) ||
            this.isErrAccessToken(res.data, res.status) ||
            this.isErrDecodeAccessToken(res.data, res.status) ||
            this.isExistUser(res.data, res.status)
          ) {
            // 認証系エラーはログアウトをする
            this.$store.dispatch("auth/logout");
          } else {
            // それ以外のエラーはログアウトの必要がないのでそのまま返す
            return Promise.reject(error);
          }
        } else {
          return Promise.reject(error);
        }
      }
    );
  },
  mounted: function() {
    let self = this;
    // トークンを更新する
    let token = self.$store.getters["auth/token"];
    if (token) {
      self.$store.dispatch("auth/refreshToken", token);
    }
    // 以降15分(900000msac)毎にトークンを更新する
    this.$setInterval(() => {
      let token = self.$store.getters["auth/token"];
      if (token) {
        self.$store.dispatch("auth/refreshToken", token);
      }
    }, Define.TIME_INTERVAL_REFRESHTOKEN);
  },
  methods: {
    /**
     * 認証エラー(401)かどうかを判定する関数
     * @param {string} status - ステータスコード
     * @return {string} - 判定結果
     */
    isErrAuthentication(status) {
      return status === Define.HTTP_RESPOSE_STATUSCODE_401;
    },
    /**
     * リフレッシュトークンエラーかどうかを判定する関数
     * @param {string} responsedata - レスポンスデータ
     * @param {string} status - ステータスコード
     * @return {string} - 判定結果
     */
    isErrRefreshToken(responsedata, status) {
      let result = false;
      if (responsedata.detail && responsedata.detail.length > 0) {
        if (
          responsedata.detail[0] === "Refresh has expired." &&
          status === Define.HTTP_RESPOSE_STATUSCODE_400
        ) {
          result = true;
        }
      }
      return result;
    },
    /**
     * アクセストークンエラーかどうかを判定する関数
     * @param {string} responsedata - レスポンスデータ
     * @param {string} status - ステータスコード
     * @return {string} - 判定結果
     */
    isErrAccessToken(responsedata, status) {
      let result = false;
      if (responsedata.detail && responsedata.detail.length > 0) {
        if (
          responsedata.detail[0] === "Signature has expired." &&
          status === Define.HTTP_RESPOSE_STATUSCODE_400
        ) {
          result = true;
        }
      }
      return result;
    },
    /**
     * アクセストークンデコードエラーかどうかを判定する関数
     * @param {string} responsedata - レスポンスデータ
     * @param {string} status - ステータスコード
     * @return {string} - 判定結果
     */
    isErrDecodeAccessToken(responsedata, status) {
      let result = false;
      if (responsedata.detail && responsedata.detail.length > 0) {
        if (
          responsedata.detail[0] === "Error decoding signature." &&
          status === Define.HTTP_RESPOSE_STATUSCODE_400
        ) {
          result = true;
        }
      }
      return result;
    },
    /**
     * ユーザーが存在してるかどうかを判定する関数
     * @param {string} responsedata - レスポンスデータ
     * @param {string} status - ステータスコード
     * @return {string} - 判定結果
     */
    isExistUser(responsedata, status) {
      let result = false;
      if (responsedata.detail && responsedata.detail.length > 0) {
        if (
          responsedata.detail[0] === "User doesn't exist." &&
          status === Define.HTTP_RESPOSE_STATUSCODE_400
        ) {
          result = true;
        }
      }
      return result;
    }
  }
};
</script>
