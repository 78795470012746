<template>
  <div class="extention">
    <div v-if="!authApplicationSetting" class="attention">
      <v-icon color="error">mdi-alert</v-icon>
      {{ $t("TEXT_APPLICATION_ROLE") }}
    </div>
    <div>
      <ApplicationSettingExpansionCategory :authApplicationSetting="!authApplicationSetting" />
    </div>
  </div>
</template>

<script>
import Define from "@/define.js";
import ApplicationSettingExpansionCategory from "@/components/parts/ApplicationSettingExpansionCategory";
import * as Util from "@/util/utils.js";
export default {
  components: {
    ApplicationSettingExpansionCategory
  },
  data() {
    return {};
  },
  computed: {
    authApplicationSetting() {
      let user = this.$store.getters["auth/user"];
      let role = this.$store.getters["role/roleByWorkspaceId"](this.selectedWorkspaceId, user.role);
      return Util.getAuthority(Define.EVENT_APPLICATION_SETTING, role);
    },
    selectedWorkspaceId() {
      return this.$store.getters["auth/selectedWorkSpaceId"];
    },
  },
  watch: {},
  methods: {}
};
</script>

<style scoped>
.extention {
  margin: 5px;
  width: 1820px;
  min-height: 735px;
}
.attention {
  color: #ff5252;
  margin: 0 12px 24px 12px;
}
</style>
