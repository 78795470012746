var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "templatepage" },
    [
      _c(
        "v-card",
        {
          staticClass: "templatecard mx-auto mt-16",
          attrs: { outlined: "", tile: "", color: "rgb(0, 0, 0, 0)" }
        },
        [
          _c(
            "div",
            { staticClass: "templatetitle" },
            [
              _c(
                "v-layout",
                { staticClass: "justify-center" },
                [
                  _c("v-img", {
                    attrs: {
                      src: require("@/assets/Freely_squair.png"),
                      "max-height": "250",
                      "max-width": "250"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                { ref: "form" },
                [
                  _c("v-card-subtitle", [
                    _vm._v(" 登録しているメールアドレスを入力してください。 ")
                  ]),
                  _c("v-text-field", {
                    attrs: {
                      placeholder: " ",
                      outlined: "",
                      label: "メールアドレス",
                      rules: [
                        _vm.required,
                        _vm.email_max_length,
                        _vm.email_format
                      ],
                      counter: "254"
                    },
                    model: {
                      value: _vm.email,
                      callback: function($$v) {
                        _vm.email = $$v
                      },
                      expression: "email"
                    }
                  }),
                  _c(
                    "v-card-actions",
                    { staticClass: "justify-center" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "templatebtn",
                          attrs: { height: "50", color: "success" },
                          on: { click: _vm.submitEmail }
                        },
                        [_vm._v("パスワード初期化用メール送信")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "justify-end" },
            [
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "blue" },
                  on: { click: _vm.toLogin }
                },
                [_vm._v("ログイン画面に戻る")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }