<template>
  <div>
    <div class="subtitle">{{ $t("PAGE_SUBTITLE_CARD_JISSEKI_SUMMARY") }}</div>
    <v-row class="mx-1" dense>
      <v-col v-for="item in jissekiCalcMappings" :key="item.mappingId" cols="2">
        <CardDetailJissekiSummaryCard
          :title="item.name"
          :yotei="yotei(item.mappingId)"
          :jisseki="cardJissekiSum(item.mappingId)"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CardDetailJissekiSummaryCard from "@/components/parts/CardDetailJissekiSummaryCard";
export default {
  components: {
    CardDetailJissekiSummaryCard
  },
  computed: {
    jissekiCalcMappings() {
      return this.$store.getters["setting/getJissekiCalcMappings"];
    },
    cardJissekiSum() {
      return mappingId => {
        return Number(this.$store.getters["tile/cardJissekiSum"](mappingId));
      };
    },
    yotei() {
      return mappingId => {
        let card = this.$store.getters["tile/cardDetailInfo"];
        let cardDetail = card.tileDetailData.filter(d => d.mappingId == mappingId);
        if(cardDetail.length == 0 || cardDetail.value == ""){ return 0;}
        return Number(cardDetail[0].value);
      }
  },
  }
};
</script>

<style scoped>
.subtitle {
  border-left: solid 6px #1976d2ca;
  color: #333;
  margin: 4px;
  padding-left: 2px;
}
</style>
