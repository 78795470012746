var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "subtitle" }, [
        _vm._v(_vm._s(_vm.$t("PAGE_SUBTITLE_CARD_JISSEKI_TABLE")))
      ]),
      _c(
        "v-card",
        { staticClass: "jisseki-table  areamargin", attrs: { flat: "" } },
        [
          _c(
            "div",
            [
              _c("v-data-table", {
                staticClass: "datatable",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.items,
                  dense: "",
                  "footer-props": _vm.TABLE_FOOTER_SETTING_SHORT
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.action",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _vm.authCardAction
                            ? _c(
                                "v-icon",
                                {
                                  attrs: { small: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.deleteRow(item)
                                    }
                                  }
                                },
                                [_vm._v(" mdi-close ")]
                              )
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }