import moment from "moment";

// 定義
export default Object.freeze({
  // 最大文字長
  LENGTH_MAX_STRING: 256,
  LENGTH_MAX_EMAIL: 254,
  LENGTH_MAX_PASSWORD: 128,
  LENGTH_MAX_WORKSPACE: 50,
  LENGTH_MAX_CATEGORY: 40,
  LENGTH_MAX_PURPOSE: 500,
  // カテゴリ項目数
  NUM_MAX_CATEGORY_VALUE: 50,
  
  // ファイル取り込みの設定部分
  // ヘッダー行
  IMPORT_SETTING_HEADER_WITH: "1",
  IMPORT_SETTING_HEADER_WITHOUT: "0",
  // 値の囲い文字
  IMPORT_SETTING_CHARACTER_DOUBLE: "0",
  IMPORT_SETTING_CHARACTER_SINGLE: "1",
  // 取り込みカードの初期位置
  IMPORT_SETTING_LOCATION_NOPLAN: "0",
  IMPORT_SETTING_LOCATION_ABSOLUTE: "1",
  IMPORT_SETTING_LOCATION_RELATIVE: "2",
  // カード位置固定
  IMPORT_SETTING_NOT_FIXED_POS: false,
  IMPORT_SETTING_FIXED_POS: true,
  // キーが同一のデータが存在した場合の動作
  IMPORT_SETTING_SAME_KEY_UPDATE: "1",
  IMPORT_SETTING_SAME_KEY_ERROR: "2",
  IMPORT_SETTING_SAME_KEY_SKIP: "3",
  // ファイル取り込み(更新)時の確定状態の動作
  IMPORT_SETTING_UPDATE_CONFIRM_UNCONFIRM: "1",
  IMPORT_SETTING_UPDATE_CONFIRM_KEEP: "2",

  // CSV最大行数
  CSV_ROW_LIMIT: 300,
  // CSV最大列数
  CSV_COL_LIMIT: 50,
  // 未計画エリアカード数上限
  FREEAREA_MAX_TILENUM: 300,
  // 成功コード
  SUCCESS: 0,
  // エラーコード
  // 拡張子不正
  ERR_EXTENSION: 1,
  // 最大行不正
  ERR_ROW_MAX: 2,
  //文字数最大値不正
  ERR_LENGTH_MAX: 3,
  // データ形式不正
  ERR_DATA_TYPE: 4,
  // ファイル未取り込み不正
  ERR_NO_FILE: 5,
  // 重複不正
  ERR_DUPLICATE: 6,
  // 数値の範囲外エラー
  ERR_NUMBER_RANGE: 7,
  // マッピングデータ未設定
  ERR_MAP_NO_SETTINGS: 8,
  // 入力項目のエラー（個別にvalidationした結果）
  ERR_INPUT_ITEM_INVALID: 9,
  // 抽出範囲未設定
  ERR_EXTRACTION_RANGE_FROM_END_NOT_SET: 10,
  // データマッピング削除の確認
  CONFIRM_DELETE_DATAMAPPING: 11,
  // 計画エリア削除の確認
  CONFIRM_DELETE_PLANAREA: 12,
  // 指定位置 - 日付位置エラー(不正データ)
  ERR_ABSOLUTE_DATE: 13,
  // 指定位置 - 計画エリア位置エラー(不正データ)
  ERR_ABSOLUTE_PLAN: 14,
  // 指定位置 - 日付位置エラー(指定列にデータが存在しない)
  ERR_ABSOLUTE_NOT_EXIST_COLDATA_DATE: 15,
  // 指定位置 - 計画エリア位置エラー(指定列にデータが存在しない)
  ERR_ABSOLUTE_NOT_EXIST_COLDATA_PLAN: 16,
  // ファイル読み込みエラー
  ERR_CANNOT_READ_FILE: 17,
  // ファイル読み込みエラー
  ERR_PARSE_CSV: 18,
  // Key設定エラー
  ERR_KEY_SETTINGS: 18,
  // Key項目データ空エラー
  ERR_KEY_DATA_EMPTY: 19,
  // 複数複製 - 10枚以上のカードを追加する時のエラー
  ERR_COPY_OVER_10: 20,
  // 実績設定を10以上設定した場合のエラー
  ERR_JISSEKI_SETTINGS: 21,
  // 更新エラー(未計画エリアのカードを確定状態にしようとした)
  ERR_UPDATE_STATUS_CONFIRM: 22,
  // 更新エラー(未計画エリアのカードを完了状態にしようとした)
  ERR_UPDATE_STATUS_COMPLETE: 23,
  // 削除対象のデータマッピングが不正
  ERR_DEL_DATAMAPPING: 24,
  // サーバーエラー
  ERR_SURVER: 500,
  // 排他エラー
  ERR_EXCLUSION: 9999,
  // ユニークキー制約違反
  ERR_VIOLATE_UNIQUE_KEY: 800,
  // ユニークキー制約違反
  ERR_IMPORT_CSV_VIOLATE_UNIQUE_KEY: 801,

  // アプリケーション設定のタブ番号
  APPLICATION_SETTING_TAB_BASE: 0,
  APPLICATION_SETTING_TAB_EXPANSION: 1,
  APPLICATION_SETTING_TAB_OPTION: 2,
  APPLICATION_SETTING_TAB_CALENDAR: 3,
  APPLICATION_SETTING_TAB_MULTILINGUAL_API: 4,

  // カード詳細のタブ番号
  CARD_DETAIL_TAB_INFO: 0,
  CARD_DETAIL_TAB_JISSEKI: 1,
  CARD_DETAIL_TAB_HISTORY: 2,

  // データ形式
  TYPE_NOTHING: -1,
  TYPE_STRING: 0,
  TYPE_INTEGER: 1,
  TYPE_FLOAT: 2,
  TYPE_DATE: 3,
  TYPE_TIME: 4,
  TYPE_CALC: 5,

  // データ入力形式の選択肢
  INPUT_TYPE_DEFAULT: 0,
  INPUT_TYPE_LIST: 1,
  INPUT_TYPE_SPINNER: 2,
  INPUT_TYPE_SLIDER: 3,

  // 四則演算子
  CALC_PLUS: 1,
  CALC_MINUS: 2,
  CALC_TIMES: 3,
  CALC_DIVIDED: 4,

  // スケール
  SCALE_1: "1",
  SCALE_01: "0.1",
  SCALE_001: "0.01",
  SCALE_0001: "0.001",

  // 実績入力
  JISSEKI_NOTHING: 0,
  JISSEKI_OPTIONAL: 1,
  JISSEKI_REQUIRED: 2,

  // 実績集計方法
  JISSEKICALC_NOTHING: 0,
  JISSEKICALC_SUM: 1,

  // API
  API_TENANT: "/api/1.0/tenant/",
  API_AUTH_LOGIN: "/api/1.0/auth/login/",
  API_AUTH_LOGOUT: "/api/1.0/auth/logout/",
  API_AUTH_REG: "/api/1.0/auth/registration/",
  API_AUTH_REG_CONFIRM: "/api/1.0/auth/registration/verify-email/",
  API_AUTH_PASSRESET: "/api/1.0/auth/password/reset/",
  API_AUTH_PASSRESET_CONFIRM: "/api/1.0/auth/password/reset/confirm/ ",
  API_AUTH_PASSWORD_CHANGE: "/api/1.0/auth/password/change/",
  API_AUTH_USER: "/api/1.0/auth/user/",
  API_TOKEN_REFRESH: "/api/1.0/token/refresh/",
  API_TENANT_NCK: "/api/1.0/nck/tenant/",
  API_USER_NCK: "/api/1.0/nck/user/",
  API_USER_BY_TENANT_LIST: "/api/1.0/userbytenantlist/",
  API_USER_BY_TENANT_UPDATE: "/api/1.0/userbytenantupdate/",
  API_USER_BY_TENANT_DEL: "/api/1.0/userbytenantdelete/",
  API_EXPORT: "/api/1.0/export/",
  API_GET_FILE_DATA: "/api/1.0/file-data/",
  API_GET_TILE_DATE_BY_KEY: "/api/1.0/get-tile-data-by-key/",
  API_GET_MULTIPLE_TILE_DATE_BY_KEY: "/api/1.0/get-multiple-tile-data-by-key/",
  API_WORKSPACE: "/api/1.0/workspace/",
  API_WORKSPACELIST: "/api/1.0/workspacelist/",
  API_WORKSPACEUPDATE: "/api/1.0/workspaceupdate/",
  // 外部連携API
  API_TOKEN: "/api/1.0/open-api-key/",
  API_WEBHOOK_TEST: "/api/1.0/webhook-test/",

  // websocketと通信する際に用いるコード
  WS_CODE_GET_INITIAL_DATA: "0",
  WS_CODE_SAVE_SETTING: "1",
  WS_CODE_CREATE_TILE: "2",
  WS_CODE_MOVE_TILE: "3",
  WS_CODE_TILE_DETAIL_UPDATE: "4",
  WS_CODE_DELETE_TILE: "5",
  WS_CODE_GET_AUTOCOMPLETE: "6",
  WS_CODE_SAVE_OPTION: "7",
  WS_CODE_CHANGE_DATE: "8",
  WS_CODE_IMPORT_CSV: "9",
  WS_CODE_GET_HISTORY_DATA: "10",
  WS_CODE_GET_DETAIL_CHANGE_DATA: "11",
  WS_CODE_GET_DETAIL_DATA: "12",
  WS_CODE_SAVE_INFO: "13",
  WS_CODE_JISSEKI_GET: "14",
  WS_CODE_JISSEKI_UPDATE: "15",
  WS_CODE_JISSEKI_DELETE: "16",
  WS_CODE_GET_SEARCH_TILE_DATA: "17",
  WS_CODE_GET_TILE_LOAD_LIST: "18",
  WS_CODE_SAVE_API_SETTING: "19",
  WS_CODE_GET_CALENDAR_SETTING: "20",
  WS_CODE_SAVE_CALENDAR_SETTING: "21",
  // カードのテンプレートコード
  TILE_TEMP_0: 0,
  TILE_TEMP_1: 1,
  TILE_TEMP_2: 2,
  TILE_TEMP_3: 3,
  // カードに表示する要素数
  TILE_VALUE_NUM_3: 3,
  TILE_VALUE_NUM_6: 6,
  TILE_VALUE_NUM_9: 9,
  //---------------------------
  // ----計画ボード----
  // 計画・未計画エリア識別
  FREE_AREA_CODE: 0,
  PLAN_AREA_CODE: 1,
  // 日付ヘッダ識別
  DAY_FIRST: 0,
  // 日付の表示数
  DISP_DAYS_7: 7,
  DISP_DAYS_10: 10,
  // 曜日
  DAYS_SUNDAY: 0,
  DAYS_SATURDAY: 6,
  // 日付ヘッダcss名
  CSS_DAY_OPEN_HEADER: "headeropen",
  CSS_DAY_CLOSE_HEADER: "headerclose",
  CSS_IS_TODAY_DAY_OPEN_HEADER: "istoday headeropen",
  CSS_IS_TODAY_DAY_CLOSE_HEADER: "istoday headerclose",
  // ラインタイトル、文字数上限
  MAX_LINE_NAME: 20,
  MAX_LINE_NAME_CLOSE: 3,
  // ラインタイトル(一か月表示)、文字数上限
  MAX_MONTH_VIEW_LINE_NAME: 11,
  MAX_MONTH_VIEW_LINE_NAME_NO_LOAD: 5,
  MAX_MONTH_VIEW_LINE_NAME_NOT_SET: 7,
  // 日付がNULL === 未計画エリア、の判断
  IS_DATE_FREE: null,
  // ラインIDがNULL === 未設定行、の判断
  IS_NO_SETTINGS: null,
  // プログレスバー、色の変わるライン
  PROGRESS_LINE_ORANGE: 80,
  PROGRESS_LINE_RED: 100,
  // プログレスバー、色
  PROGRESS_COLOR_GREEN: "success",
  PROGRESS_COLOR_ORANGE: "warning",
  PROGRESS_COLOR_RED: "error",
  // draggableのidに使用する区切り文字
  SEPARATE_DRAGGABLE_ID: "_",
  // カードカラーコード
  COLOR_CODE_TILE: "#fff9c4",
  COLOR_CODE_TILE_IS_COMPLETE: "#ccc",
  COLOR_CODE_TILE_ERROR: "red",
  // カードの表示番号
  TILE_DISPLAY1: 0,
  TILE_DISPLAY2: 1,
  TILE_DISPLAY3: 2,
  TILE_DISPLAY4: 3,
  TILE_DISPLAY5: 4,
  TILE_DISPLAY6: 5,
  TILE_DISPLAY7: 6,
  TILE_DISPLAY8: 7,
  TILE_DISPLAY9: 8,
  // HTTPレスポンスステータスコード
  HTTP_RESPOSE_STATUSCODE_400: 400,
  HTTP_RESPOSE_STATUSCODE_401: 401,
  // トークンの更新周期
  TIME_INTERVAL_REFRESHTOKEN: 900000,
  // 時刻フォーマット
  DATE_FORMAT_DAY_SLASH: "YYYY/MM/DD",
  DATE_FORMAT_DAY_SLASH_CHECK: "YYYY/M/D",
  DATE_FORMAT_MONTH_SLASH_CHECK: "YYYY/M",
  DATE_FORMAT_DAY_HYPHEN: "YYYY-MM-DD",
  DATE_FORMAT_DAY_HYPHEN_CHECK: "YYYY-M-D",
  DATE_FORMAT_DAY_NOT_SEPARATE: "YYYYMMDD",
  DATE_FORMAT_WEEK: "DD (ddd)",
  DATE_FORMAT_TIME: "HH:mm:ss",
  DATE_FORMAT_TIME_HM: "HH:mm",
  DATE_FORMAT_TIME_CHECK: "YYYY-M-D H:m",
  DATE_FORMAT_MILLISECOND: "X",
  DATE_FORMAT_DAY_MIN: "MM/DD HH:mm",
  DATE_FORMAT_DAY: "MM/DD",
  DATE_FORMAT_E_MMDD: "M/D (ddd)",
  DATE_FORMAT_J_MMDD: "M月D日 (ddd)",
  //ページコード(ページタイトルとその説明を検索するために使用)
  PAGE_CODE_SETTING: 1,
  PAGE_CODE_IMPORT: 2,
  PAGE_CODE_MANAGEMENT: 3,
  PAGE_CODE_EXPORT: 4,
  // PAGE_TITLE_DESCRIPTIONを検索して存在しなかった場合に使用するオブジェクト
  PAGE_TITLE_DESCRIPTION_EMPTY_OBJECT: {
    title: "",
    description: ""
  },
  // csv→文字列変換時の文字コード
  CHARACTER_CODE: "Shift_JIS",
  // ハッシュ変換関数
  HASH_FUNCITON: "sha256",
  // 無料お試しプランの期間
  TRIAL_DATE: 30,
  // 利用プランコード
  // 無料お試しプラン
  PLAN_TRIAL: 0,
  // 有料プラン
  PLAN_PRODUCT: 1,
  // 無料お試しプラン期間中
  IN_TRIAL: false,
  // 無料お試しプラン期間外
  OUT_TRIAL: true,
  // ユーザーのロール
  USER_ROLE_MANAGER: 0, // 管理者
  USER_ROLE_PLANNER: 1, // 計画者
  USER_ROLE_PRACTITIONER: 2, // 実施者
  // イベントID
  EVENT_APPLICATION_SETTING: 0, // アプリケーション設定
  EVENT_CARD_ACTION: 1, // カード操作
  EVENT_FILE_INPUT: 2, // ファイル取込
  EVENT_FILE_OUTPUT: 3, // ファイル出力
  EVENT_ACCESS_MANAGEMENT: 4, // 管理者機能
  EVENT_INCOMPLETE_ACTION: 5, // 未完了カード検知機能
  EVENT_CALENDAR_SETTING: 6, // カレンダー設定
  // イベントIDおよびユーザーのロールごとの権限
  AUTHORITY: [
    //[管理者, 計画者, 実施者]
    [true, false, false], // アプリケーション設定
    [true, true, false], // カード操作
    [true, true, false], // ファイル取込
    [true, true, false], // ファイル出力
    [true, false, false], // 管理者機能
    [true, true, false], // 未完了カード検知機能
    [true, true, false] // カレンダー設定
  ],
  // カードの色設定の条件数上限
  APP_SETTING_ADD_LIMIT_COLOR_CONDITIONS: 10,
  // マッピングデータ、エリアデータ追加上限
  APP_SETTING_ADD_LIMIT_MAPPING: 50,
  APP_SETTING_ADD_LIMIT_AREA: 50,
  // 負荷上限の最小値、最大値
  NUMBER_RANGE_MIN_INTEGER: 0,
  NUMBER_RANGE_MAX_INTEGER: 9999999,
  NUMBER_RANGE_MIN_FLOAT: 0.0,
  NUMBER_RANGE_MAX_FLOAT: 9999999.999,
  // 数値(小数点あり)の最大値、最小値
  FLOAT_NUMBER_VALUE_RANGE_MAX: 999999999999.999,
  FLOAT_NUMBER_VALUE_RANGE_MIN: -999999999999.999,
  // 四捨五入する小数の桁数
  ROUND_NUM: 4,
  // 計画エリア1ページ当たりの表示最大件数
  PLANAREA_MAX_PAGE_NUM: 50,
  // テナント毎のユーザー追加上限
  USER_ADD_MAX_NUM: 30,
  // カード操作する時の枚数上限
  CARD_OPERATION_MAX_NUM: 50,
  // カード情報補助でアップロードできるファイルサイズの上限
  FILE_SIZE_MAX: 1048576,
  // 画像サムネイルサイズ(PC)
  HEIGHT_THMBNAIL: 365,
  WIDTH_THMBNAIL: 365,
  // 画像サムネイルサイズ(Mobile)
  MOBILE_HEIGHT_THMBNAIL: 280,
  MOBILE_WIDTH_THMBNAIL: 280,
  // 別タブ表示の画像サイズ
  HEIGHT_OPEN_CONTENT: 800,
  WIDTH_OPEN_CONTENT: 1900,
  // マニュアルのリンク
  URL_MANUAL: "https://nck-freely-manualbucket.s3.ap-northeast-1.amazonaws.com/freely_manual.pdf",
  // 本契約ページのリンク
  URL_AGREEMENT: "https://biz.nck-tky.co.jp/services/freely/freely-agreement",
  // お問い合わせページのリンク
  URL_CONTACT: "https://biz.nck-tky.co.jp/services/freely/freely-contact",
  // 動画マニュアルのリンク
  URL_MOVIE: "https://kaizen-navi.biz/freely/video-manual",

  // 条件
  conditions: [
    {
      label: "＝",
      conditionId: "eq",
      func: (value, comparison_value) => {
        if (Number.isNaN(value) && Number.isNaN(comparison_value)) return true;

        return value == comparison_value;
      }
    },
    {
      label: "≠",
      conditionId: "neq",
      func: (value, comparison_value) => {
        if (Number.isNaN(value) && Number.isNaN(comparison_value)) return false;

        return value != comparison_value;
      }
    },
    {
      label: "＜",
      conditionId: "lt",
      func: (value, comparison_value) => {
        return value < comparison_value;
      }
    },
    {
      label: "≦",
      conditionId: "lte",
      func: (value, comparison_value) => {
        return value <= comparison_value;
      }
    },
    {
      label: "＞",
      conditionId: "gt",
      func: (value, comparison_value) => {
        return value > comparison_value;
      }
    },
    {
      label: "≧",
      conditionId: "gte",
      func: (value, comparison_value) => {
        return value >= comparison_value;
      }
    },
  ],

  TOGGLE_COLOR: "#00b842",

  // スタイル
  STYLE_DATA_AREA: { "margin": "20px 5px 20px 5px", "width": "1820px" },
  STYLE_CARD_COLOR_DATA_AREA: { "margin": "20px 5px 20px 5px", "width": "1720px" },
  STYLE_AREA_TITLE: { "border-left": "solid 6px #1976d2ca", "margin-bottom": "8px", "padding-left": "8px" },
  STYLE_BASE_PAGE: {
    "margin": "20px",
    "padding-left": "20px",
    "height": "calc(100% - 40px)",
    "width": "calc(100% - 40px)",
    "background-color": "#fff",
    "border": "solid 1px #aaa",
    "overflow": "auto",
    "white-space": "nowrap"
  },
  STYLE_TABLE_AREA: { "margin-left": "25px", "margin-right": "25px", "padding": "2px 0" },
  STYLE_BAGE_BTN: { "margin-left": "20%", "width": "40%" },
  STYLE_COLOR_BOX: { "height": "20px", "width": "20px" },
  STYLE_DATE_PICKER: { "width": "200px" },
  STYLE_THUMBNAIL_BOX:  {
    "display": "flex",
    "flex-wrap": "wrap",
    "justify-content": "center",
    "align-content": "center",
    "border": "1px solid",
    "background-color": "#9E9E9E",
    "height": "400px",
    "width": "400px"
  },

  // 処理の状態
  STATE_IDLE: 0,
  STATE_COMPLATE: 1,
  STATE_ERR_OTHER: -10,
  STATE_ERR_PASSWORD_EXCEPTION: -11,

  RESERVED_WORD: {
    SYSDATE_PLUS_1: {
      expr: "%SYSDATE_PLUS_1%",
      convert: () => {
        return moment()
          .add(1, "day")
          .startOf("day");
      }
    },
    SYSDATE_PLUS_3: {
      expr: "%SYSDATE_PLUS_3%",
      convert: () => {
        return moment()
          .add(3, "day")
          .startOf("day");
      }
    },
    SYSDATE_PLUS_5: {
      expr: "%SYSDATE_PLUS_5%",
      convert: () => {
        return moment()
          .add(5, "day")
          .startOf("day");
      }
    },
    DATE_POS_COL: {
      expr: "%DATE_POS_COL%"
    }
  },

  // フォントサイズ(小中大)
  FONT_SIZE_SMALL: 0,
  FONT_SIZE_MEDIUM: 1,
  FONT_SIZE_LARGE: 2,

  // フォント適用タイプ
  DISPLAY_TYPE_CARD: 0, // カード表示
  DISPLAY_TYPE_CARD_DETAIL: 1, // カード詳細表示

  STYLE_FONT_SIZE_TABLE: [
    // [小, 中, 大]
    [{fontSize: "9px"}, {fontSize: "12px"}, {fontSize: "15px"}], // カード表示
    [{fontSize: "14px"}, {fontSize: "16px"}, {fontSize: "18px"}] // カード詳細表示
  ],

  // 確定状態
  CARD_STATUS_UNCONFIRMED: 0, // 未確定
  CARD_STATUS_CONFIRMED: 1, // 確定
  CARD_STATUS_CHANGE_AFTER_CONFIRMED: 2, // 未確定(確定後)

  // カード履歴用フラグ
  HISTORY_TYPE_MOVED: 0, // カード移動履歴
  HISTORY_TYPE_COMPLETE: 1, // 完了状態変更履歴
  HISTORY_TYPE_DETAIL_CHANGED: 2, // 詳細データ変更履歴
  HISTORY_TYPE_IMPORT_FILE_UPDATE: 3, // ファイル取り込み時カード更新履歴
  HISTORY_TYPE_CONFIRMED: 100, // 確定状態変更履歴
  FLG_TYPE_COMPLETED: 0, // 完了フラグ
  FLG_TYPE_CONFIRMED: 100, // 確定フラグ

  CARD_MOVE_JUDGE_AREA: 0,
  CARD_MOVE_JUDGE_AREA_ORDER: 1,
  
  LOAD_DISPLAY_PERCENT: 0,
  LOAD_DISPLAY_PERCENT_DETAIL: 1,

  // カード補助情報のバッジ表示形式
  FILE_DISPLAY_NONE: 0,
  FILE_DISPLAY_BADGE: 1,
  FILE_DISPLAY_BADGE_NUMBER: 2,

  DATE_DISPLAY_YYYYMMDD: 0,
  DATE_DISPLAY_MMDD: 1,

  // カード履歴詳細用定型文
  ARROW_RIGHT: "→",

  METHOD_NAME_GET: "GET",
  METHOD_NAME_POST: "POST",
  METHOD_NAME_PUT: "PUT",
  METHOD_NAME_PATCH: "PATCH",
  METHOD_NAME_DELETE: "DELETE",

  METHOD_ID_GET: 0,
  METHOD_ID_POST: 1,
  METHOD_ID_PUT: 2,
  METHOD_ID_PATCH: 3,
  METHOD_ID_DELETE: 4,
  
  WEBHOOK_RENKEISAKI_ID_KINTONE: 0,
  WEBHOOK_RENKEISAKI_ID_HANYOU:99,

  WEBHOOK_EVENT_ID_JISSEKI_REGIST: 0
});

// 部品からの呼び出し方法
// import Define from '@/define.js'
// Define.SAMPLE_1;
