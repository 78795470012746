var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "800px", persistent: "" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "mx-auto", attrs: { width: "800px" } },
            [
              _c("v-card-title", [
                _vm._v(_vm._s(_vm.$t("PAGE_TITLE_WORKSPACE_CHANGE")))
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-form",
                    { ref: "form" },
                    [
                      _c("v-text-field", {
                        attrs: {
                          placeholder: " ",
                          outlined: "",
                          label: _vm.$t("WORD_MANAGEMENT_WORKSPACE_NAME"),
                          rules: [
                            _vm.required,
                            _vm.max_length,
                            _vm.check_percent
                          ],
                          counter: "50"
                        },
                        model: {
                          value: _vm.name,
                          callback: function($$v) {
                            _vm.name = $$v
                          },
                          expression: "name"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "info",
                      attrs: { width: "25%" },
                      on: { click: _vm.submit }
                    },
                    [_vm._v(_vm._s(_vm.$t("WORD_CHANGE")))]
                  ),
                  _c(
                    "v-btn",
                    { attrs: { width: "25%" }, on: { click: _vm.closeDialog } },
                    [_vm._v(_vm._s(_vm.$t("WORD_CANCEL")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }