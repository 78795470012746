<template>
  <div class="applicationsetting">
    <PageTitle 
      :titles="pagetitle" 
      :descriptions="pagedescription" 
      :linkStr="wMovie" 
      :linkUrl="URL_MOVIE" 
      :linkIcon="'mdi-video'"
    />
    <v-tabs v-model="tab" height="30" background-color="blue darken-1" ref="tabs" dark @change="onTabChange(tab)">
      <v-tab>{{ $t("TABNAME_APPLICATION_SETTING_BASE") }}</v-tab>
      <v-tab>{{ $t("TABNAME_APPLICATION_SETTING_EXPANSION") }}</v-tab>
      <v-tab>{{ $t("TABNAME_APPLICATION_SETTING_OPTION") }}</v-tab>
      <v-tab>{{ $t("TABNAME_APPLICATION_SETTING_CALENDAR") }}</v-tab>
      <v-tab v-if="option003">{{ $t("TABNAME_APPLICATION_SETTING_MULTILINGUAL_API") }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" class="px-2 py-2" style="" :touchless="true">
      <!-- 基本設定 -->
      <v-tab-item>
        <ApplicationSettingBase />
      </v-tab-item>
      <!-- 拡張設定 -->
      <v-tab-item>
        <ApplicationSettingExpansion />
      </v-tab-item>
      <!-- オプション機能 -->
      <v-tab-item>
        <ApplicationSettingOption />
      </v-tab-item>
      <!-- カレンダー設定機能 -->
      <v-tab-item>
        <ApplicationSettingCalendar
          :tab="isOpenCalendarSetting"        
        />
      </v-tab-item>
      <!-- API連係機能 -->
      <v-tab-item>
        <ApplicationSettingApi />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import PageTitle from "@/components/parts/PageTitle";
import Define from "@/define.js";
import ApplicationSettingBase from "@/components/parts/ApplicationSettingBase";
import ApplicationSettingExpansion from "@/components/parts/ApplicationSettingExpansion";
import ApplicationSettingOption from "@/components/parts/ApplicationSettingOption";
import ApplicationSettingApi from "@/components/parts/ApplicationSettingApi";
import ApplicationSettingCalendar from "@/components/parts/ApplicationSettingCalendar";
import { mixinUtil } from "@/mixins/mixinUtils.js";
export default {
  components: {
    PageTitle,
    ApplicationSettingBase,
    ApplicationSettingExpansion,
    ApplicationSettingOption,
    ApplicationSettingApi,
    ApplicationSettingCalendar
  },
  mixins: [mixinUtil],
  data() {
    return {
      tab: Define.APPLICATION_SETTING_TAB_BASE,
      URL_MOVIE: Define.URL_MOVIE,
      isOpenCalendarSetting: false
    };
  },
  computed: {
    pagetitle() {
      let pageObject = this.getPageTitleDescriptionObj(Define.PAGE_CODE_SETTING);
      return pageObject.title;
    },
    pagedescription() {
      let pageObject = this.getPageTitleDescriptionObj(Define.PAGE_CODE_SETTING);
      return pageObject.description;
    },
    locale() {
      return this.$i18n.locale;
    },
    wMovie() {
      return this.$t("WORD_EXPLANATION_MOVIE");
    },
    option003() {
      let option = this.$store.getters["option/getOption"];
      return option.option003;
    },
    
  },
  watch: {
    locale: {
      handler: function() {
        this.$refs.tabs.callSlider();
      }
    }
  },
  methods: {
    onTabChange(value) {
      if (value == Define.APPLICATION_SETTING_TAB_CALENDAR) {
        this.isOpenCalendarSetting = true;
      }
      else {
        this.isOpenCalendarSetting = false;
      }
    }
  }
};
</script>

<style scoped>
.applicationsetting {
  margin: 20px;
  padding-right: 20px;
  padding-left: 20px;
  height: calc(100% - 40px);
  width: calc(100% - 40px);
  background-color: #fff;
  border: solid 1px #aaa;
  overflow-x: auto;
  overflow-y: auto;
  white-space: nowrap;
}
</style>
