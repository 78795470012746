<template>
  <div>
    <v-dialog v-model="dialog" width="800px" persistent>
      <v-card width="800px" class="mx-auto">
        <v-card-title>{{ $t("PAGE_TITLE_USER_ROLE_CHANGE") }}</v-card-title>
        <v-card-text>{{ $t("TEXT_USER_CHANGE_NAME") }}{{ selectedUser.username }}</v-card-text>
        <v-card-text>{{ $t("TEXT_USER_CHANGE_ROLE") }}{{ getRoleName(selectedUser.role) }}</v-card-text>
        <v-card-text>
          <v-form ref="form">
            <v-radio-group v-model="role" row>
              <v-radio :label="$t('WORD_ROLENAME')[0]" value="0"></v-radio>
              <v-radio :label="$t('WORD_ROLENAME')[1]" value="1"></v-radio>
              <v-radio :label="$t('WORD_ROLENAME')[2]" value="2"></v-radio>
            </v-radio-group>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn width="25%" class="info" @click="submit">{{ $t("WORD_CHANGE") }}</v-btn>
          <v-btn width="25%" class="" @click="closeDialog">{{ $t("WORD_CANCEL") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Swal from "sweetalert2";
export default {
  name: "UserTableChangeDialog",
  props: {
    dialog: Boolean
  },
  data: function() {
    return {
      // 入力項目
      role: "0"
    };
  },
  computed: {
    selectedUser() {
      return this.$store.getters["user/selectedUser"];
    },
    user() {
      return this.$store.getters["auth/user"];
    }
  },
  watch: {
    dialog: function(value) {
      if (value) {
        this.role = String(this.selectedUser.role);
      }
    }
  },
  methods: {
    /**
     * ユーザーロール名を定義する
     * @param {String} userRole - ユーザーのロール
     */
    getRoleName(userRole) {
      return this.$t("WORD_ROLENAME")[userRole];
    },
    /**
     * 入力情報をAPIに伝搬する
     */
    submit() {
      if (this.isSelf(this.selectedUser, this.user)) {
        Swal.fire({
          icon: "error",
          title: this.$t("MSG_ERR_MAIN_CHANGE"),
          text: this.$t("MSG_ERR_SUB_CHANGE")
        });
        this.closeDialog();
      } else {
        this.$store
          .dispatch("user/updateUser", {
            user_id: this.selectedUser.userId,
            param: { role: this.role }
          })
          .then(() => {
            this.closeDialog();
          });
      }
    },
    /**
     * クローズイベントを親部品に通知する
     */
    closeDialog() {
      this.$emit("close");
    },
    /**
     * 選択行のユーザーが自身かどうか判定する
     * @param {Object} selectedUser - 選択行のユーザー
     * @param {Object} user - 自身
     * @return {Object} - 判定結果
     */
    isSelf(selectedUser, user) {
      return selectedUser.userId === user.userId;
    }
  }
};
</script>

<style scoped></style>
