<template>
  <!-- 日付の場合 -->
  <v-input v-if="type == TYPE_DATE || (type == TYPE_CALC && calcObject1Type == TYPE_DATE)" :value="innerValue" hide-details>
    <v-text-field
      ref="inputField"
      :key="resetKey"
      v-model="innerValue"
      :disabled="!editable"
      dense
      outlined
      hide-details="auto"
      :rules="innerRules(type)"
    >
      <template v-slot:append>
        <v-menu v-model="menu" offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on }">
            <v-icon color="grey darken-2" v-on="on">
              mdi-calendar
            </v-icon>
          </template>
          <v-date-picker
            v-model="datePicker"
            :locale="locale"
            :day-format="date => new Date(date).getDate()"
            @click="menu = false"
            @change="innerValue = changeFormat(datePicker)"
          />
        </v-menu>
      </template>
    </v-text-field>
  </v-input>
  <!-- 日付以外の場合 -->
  <EditingItem v-else
    v-model="innerValue"
    :type="type"
    :calcObject1Type="calcObject1Type"
    :editable="editable"
    :rules="rules"
  ></EditingItem>
</template>

<script>
import moment from "moment";

import Define from "@/define.js";
import * as Util from "@/util/utils.js";
import EditingItem from "@/components/parts/EditingItem";
export default {
  name: "ColorConditionSettingsEditingItem",
  components: { EditingItem },
  props: {
    value: { type: String, default: "", require: true },
    type: { type: Number, default: 0, require: true },
    calcObject1Type: { type: Number, default: 0, require: true },
    editable: { type: Boolean, default: true, require: true },
    rules: { type: Array, default: () => [], require: false }
  },
  data() {
    return {
      resetKey: 0,
      TYPE_DATE: Define.TYPE_DATE,
      TYPE_CALC: Define.TYPE_CALC,
      menu: false,
      datePicker: moment().format(Define.DATE_FORMAT_DAY_HYPHEN)
    };
  },
  computed: {
    innerValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    },
    innerRules() {
      return () => {
        return [...this.rules, this.checkDate];
      };
    },
    locale() {
      return this.$i18n.locale;
    }
  },
  watch: {
    type: function() {
      this.refresh();
      this.validate();
    },
    calcObject1Type: function() {
      this.refresh();
      this.validate();
    }
  },
  beforeMount() {
    this.innerValue = this.value;
  },
  methods: {
    refresh() {
      // 強制的にリフレッシュ ←「key」を更新するとレンダリングが発生する
      this.resetKey++;
    },
    validate() {
      this.$nextTick(() => {
        // validate()は走るけどvalid=falseの際にこのままではエラーメッセージが表示されないため
        // フォーカス in-out を行って強制的に（エラーの場合は）メッセージを表示させる
        if (this.$refs.inputField) {
          this.$refs.inputField.focus();
          this.$nextTick(() => this.$refs.inputField.blur());
        }
      });
    },
    checkDate(value) {
      if (
        Object.values(Define.RESERVED_WORD)
          .map(item => item.expr)
          .includes(value)
      ) {
        return true;
      } else if (Util.checkNotEmpty(value) && Util.checkDateType(value)) {
        return true;
      } else {
        return this.$t("MSG_ERR_COLORSETTINGS_DATE_OR_RESERVED_WORD");
      }
    },
    changeFormat(date) {
      this.menu = false;
      this.datePicker = moment().format(Define.DATE_FORMAT_DAY_HYPHEN);
      return Util.checkDateType(date)
        ? moment(date).format(Define.DATE_FORMAT_DAY_SLASH)
        : "";
    }
  }
};
</script>
