var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "areatitle mb-5" }, [
      _vm._v(
        " " +
          _vm._s(
            _vm.$t(
              "PAGE_SUBTITLE_APPLICATON_SETTING_MULTILINGUAL_API_FROM_OUTER_TO_FREELY"
            )
          ) +
          " "
      )
    ]),
    _c("div", { staticClass: "ml-5 mb-5" }, [
      _c(
        "div",
        { staticClass: "d-flex flex-row align-baseline api-key-field" },
        [
          _c("v-text-field", {
            attrs: {
              dense: "",
              outlined: "",
              "hide-details": "",
              readonly: "",
              width: "100"
            },
            model: {
              value: _vm.apiKey,
              callback: function($$v) {
                _vm.apiKey = $$v
              },
              expression: "apiKey"
            }
          }),
          _c(
            "v-btn",
            {
              staticClass: "info ml-2",
              attrs: { disabled: !_vm.isValidRole },
              on: {
                click: function($event) {
                  return _vm.displayApiKey()
                }
              }
            },
            [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "WORD_APPLICATION_SETTING_MULTILINGUAL_API_KEY_DISPLAY"
                  )
                )
              )
            ]
          ),
          _vm.apiKey != ""
            ? _c(
                "v-btn",
                {
                  staticClass: "error ml-2",
                  attrs: { disabled: !_vm.isValidRole },
                  on: {
                    click: function($event) {
                      return _vm.deleteApiKey()
                    }
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "WORD_APPLICATION_SETTING_MULTILINGUAL_API_KEY_DELETE"
                      )
                    )
                  )
                ]
              )
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }