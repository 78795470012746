<template>
  <div class="areamargin">
    <v-expansion-panels v-model="isOpen">

      <!-- 実績入力 -->
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="subtitle">{{ $t("PAGE_SUBTITLE_CARD_JISSEKI_INPUT") }}</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <MobileCardDetailJissekiInput />
        </v-expansion-panel-content>
      </v-expansion-panel>
      
      <!-- 実績進捗 -->
      <v-expansion-panel v-if="getIsValidJissekiCalc">
        <v-expansion-panel-header>
          <div class="subtitle">{{ $t("PAGE_SUBTITLE_CARD_JISSEKI_SUMMARY") }}</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <MobileCardDetailJissekiSummary />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- 実績一覧 -->
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="subtitle">{{ $t("PAGE_SUBTITLE_CARD_JISSEKI_TABLE") }}</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <MobileCardDetailJissekiTable />
        </v-expansion-panel-content>
      </v-expansion-panel>

    </v-expansion-panels>
  </div>
</template>

<script>
import MobileCardDetailJissekiInput from "@/components/mobile/parts/MobileCardDetailJissekiInput.vue";
import MobileCardDetailJissekiSummary from "@/components/mobile/parts/MobileCardDetailJissekiSummary.vue";
import MobileCardDetailJissekiTable from "@/components/mobile/parts/MobileCardDetailJissekiTable.vue";
export default {
  components: {
    MobileCardDetailJissekiInput,
    MobileCardDetailJissekiSummary,
    MobileCardDetailJissekiTable
  },
  data() {
    return {
      isOpen: 0
    };
  },
  computed: {
    isOpenMobileCardDetail() {
      return this.$store.getters["tile/isOpenMobileCardDetail"];
    },
    getIsValidJissekiCalc() {
      return this.$store.getters["setting/getIsValidJissekiCalc"];
    }
  },
  watch: {
    isOpenMobileCardDetail: {
      immediate: true,
      handler: function() {
        if (this.isOpenMobileCardDetail) {
          this.isOpen = 0;
        }
      }
    },
  }
}
</script>

<style scoped>
.areamargin {
  margin: 10px;
}
.subtitle {
  border-left: solid 4px #1976d2ca;
  color: #333;
  padding-left: 4px;
}
</style>
