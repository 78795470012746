<template>
  <v-card flat>
    <v-card :style="thmbnailBoxStyle" @dblclick="openContent" class="mx-auto">
      <div v-if="isLoading" class="pt-10 pl-10">{{ MSG_LOADING_FILE }}</div>
      <v-img
        v-if="isImageOrPdf(parameter.name)"
        :src="imageSource"
        :max-height="isModePc ? imageHeight - 2 : undefined"
        :max-width="isModePc ? imageWidth - 2 : undefined"
        :contain="isModePc"
      ></v-img>
      <v-tooltip v-if="canOpenContent" right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="disp == true"
            class="mt-7"
            absolute
            fab
            dark
            small
            right
            top
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="openContent"
          >
            <v-icon dark>mdi-open-in-new</v-icon>
          </v-btn>
        </template>
        <span>{{ MSG_TOOLTIP_OPEN_CONTENT }}</span>
      </v-tooltip>
      <v-tooltip v-if="parameter.name" right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="disp == true"
            ref="download"
            class="mb-7"
            absolute
            fab
            dark
            small
            right
            bottom
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="downloadContent"
          >
            <v-icon dark>mdi-download</v-icon>
          </v-btn>
        </template>
        <span>{{ MSG_TOOLTIP_DOWNLOAD_FILE }}</span>
      </v-tooltip>
    </v-card>

    <v-file-input
      v-if="disp == true"
      ref="input"
      :key="restKey"
      v-model="file"
      prepend-icon="mdi-file-document-outline"
      class="mr-4 my-0 py-0"
      :placeholder="label"
      clearable
      @change="onChange"
      @click:clear="onDelete"
      :disabled="!editable"
      hide-details="auto"
    ></v-file-input>
    <label v-else class="file-label">{{ file ? file.name : "" }}</label>
  </v-card>
</template>

<script>
import * as Util from "@/util/utils.js";
import Define from "@/define.js";
import { mixinValidator } from "@/mixins/mixinValidator.js";

export default {
  name: "FileControl",
  components: {},
  mixins: [mixinValidator],
  props: {
    parameter: {
      type: Object,
      default: () => {
        return {
          tileId: "",
          index: 0,
          isEdited: false,
          fileDataId: "",
          name: "",
          source: "",
          version: 0
        };
      },
      require: true
    },
    imageHeight: { type: Number, default: Define.HEIGHT_THMBNAIL, require: false },
    imageWidth: { type: Number, default: Define.WIDTH_THMBNAIL, require: false },
    editable: { type: Boolean, default: true, require: true },
    disp: { type: Boolean, default: true, require: false }
  },
  data() {
    return {
      restKey: 0,
      isLoading: false,
      file: null,
      conversionState: Define.STATE_IDLE,
      imageSource: ""
    };
  },
  computed: {
    thmbnailBoxStyle() {
      return [
        Define.STYLE_THUMBNAIL_BOX,
        { height: `${this.imageHeight}px`, width: `${this.imageWidth}px` }
      ];
    },
    label() {
      return this.$t("MSG_NO_FILE_SELECTED");
    },
    canOpenContent() {
      return (
        this.parameter.name &&
        this.isImageOrPdf(this.parameter.name) &&
        !this.parameter.isEdited &&
        this.conversionState == Define.STATE_COMPLATE
      );
    },
    selectedWorkspaceId() {
      return this.$store.getters["auth/selectedWorkSpaceId"];
    },
    MSG_LOADING_FILE() {
      return this.$t("MSG_LOADING_FILE");
    },
    MSG_TOOLTIP_OPEN_CONTENT() {
      return this.$t("MSG_TOOLTIP_OPEN_CONTENT");
    },
    MSG_TOOLTIP_DOWNLOAD_FILE() {
      return this.$t("MSG_TOOLTIP_DOWNLOAD_FILE");
    },
    isModePc() {
      return this.$store.getters["isModePc"];
    },
  },
  watch: {},
  mounted() {},
  methods: {
    initialize() {
      this.resetControl();

      const tileId = this.parameter.tileId;
      const index = this.parameter.index;
      const workSpaceId = this.selectedWorkspaceId;
      if (!tileId) {
        // 新規作成用に開かれた場合 ファイルは存在しないので処理を抜ける
        return;
      }

      this.isLoading = true;
      const query = `tile_id=${tileId}&index=${index}&work_space_id=${workSpaceId}`;
      Util.axiosGetReq(`${Define.API_GET_FILE_DATA}?${query}`)
        .then(response => {
          this.setResponseToParameter(response);
          if (Util.isPdf(this.parameter.name)) {
            this.convertPdfBase64ToImageData(this.parameter.source);
          } else {
            this.imageSource = this.parameter.source;
            this.conversionState = Define.STATE_COMPLATE;
          }
          if (this.parameter.name) {
            this.file = new File(
              [], // DBの値：ファイル名を表示するためだけのためデータは不要
              this.parameter.name
            );

            let blob = Util.toBlob(this.parameter.source);
            // 親コンポーネントにファイルサイズを通知する
            this.$emit("set-file-size", {index : this.parameter.index, size : blob.size});

            this.restKey++;
          }
          this.$emit("input", this.parameter);
          this.isLoading = false;
        })
        .catch(error => {
          this.isLoading = false;
          console.log(error);
        });
    },
    resetControl() {
      this.clearControl();
      this.setParameter(false, "", "", "", 0);
    },
    clearControl() {
      this.file = null;
      this.imageSource = "";
    },
    isImageOrPdf(filename) {
      return Util.isImageOrPdf(filename);
    },

    async convertPdfBase64ToImageData(source) {
      const result = await Util.getImageDataFromPdfBase64(
        source,
        1,
        this.imageHeight - 2,
        this.imageWidth - 2
      );
      this.conversionState = result.state;
      this.imageSource = result.image;
    },

    onChange(file) {
      if (!file) {
        // ファイル参照ダイアログでキャンセルされた場合
        return;
      }

      // 親コンポーネントにファイルサイズを通知する
      this.$emit("set-file-size", {index : this.parameter.index, size : file.size});

      this.isLoading = true;
      Util.getBase64(file)
        .then(fileData => {
          this.clearControl();
          this.file = file;
          if (Util.isPdf(file.name)) {
            this.convertPdfBase64ToImageData(fileData);
          } else {
            this.imageSource = fileData;
            this.conversionState = Define.STATE_COMPLATE;
          }
          this.inputValue(file.name, fileData);
          this.$refs.input.focus();
          this.isLoading = false;
        })
        .catch(error => {
          this.isLoading = false;
          console.log(error);
        });
    },
    onDelete() {
      this.clearControl();
      this.inputValue("", "");
      this.$refs.input.blur();

      // 親コンポーネントにファイルサイズを通知する(削除のため0固定)
      this.$emit("set-file-size", {index : this.parameter.index, size : 0});
    },
    openContent() {
      // ファイルがセットされていなければ何もしない（ボタンが見えて入れbセットされているはずだが一応ガード）
      if (!this.canOpenContent) return;

      const routeData = this.$router.resolve({
        path: `/show-image/${this.parameter.tileId}/${this.parameter.index}`
      });
      window.open(routeData.href, "_blank");
    },
    downloadContent() {
      // ファイルがセットされていなければ何もしない（ボタンが見えて入れbセットされているはずだが一応ガード）
      if (!this.parameter.name) return;

      const blob = Util.toBlob(this.parameter.source);
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = this.parameter.name;
      link.click();
    },
    setResponseToParameter(response) {
      this.setParameter(
        false,
        response.data.name,
        response.data.source,
        response.data.fileDataId,
        response.data.version
      );
    },
    setFileDataToParameter(name, source) {
      this.setParameter(
        true,
        name,
        source,
        this.parameter.fileDataId,
        this.parameter.version
      );
    },
    setParameter(
      isEdited,
      name,
      source,
      fileDataId,
      version = 0
    ) {
      this.parameter.isEdited = isEdited;
      this.parameter.name = name;
      this.parameter.source = source;
      this.parameter.fileDataId = fileDataId;
      if (this.parameter.version == 0 && version == 0) {
        // 新規登録の場合にここに来る
        this.parameter.version = 1;
      } else {
        this.parameter.version = version;
      }
    },
    inputValue(name, source) {
      this.setFileDataToParameter(name, source);
      this.$emit("input", this.parameter);
    }
  }
};
</script>

<style scoped>
.file-label{
  color:#1976d2ca;
  margin-left: 5px;
}
</style>
