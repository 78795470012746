<template>
  <!-- 未計画エリアでの利用 -->
  <div v-if="tilelist.dateInfo === isDateFree" class="listprogressFreeAreaOpen">
    <ListItem
      :lineid="lineid"
      :dateid="tilelist.dateInfo"
      :enabled="enabled"
      :cardtempnum="cardtempnum"
      :tileinfo="tilelist.tileList"
      :keyword="keyword"
      :isfreearea="freeareatrue"
    ></ListItem>
    <div class="progressbar white_back"></div>
  </div>
  <!-- 未計画済エリアでの利用 -->
  <div v-else :class="setClass(isRowOpen, isColOpen)">
    <!-- カード表示(折畳なし) -->
    <template v-if="isRowOpen && isColOpen">
      <ListItem
        :lineid="lineid"
        :dateid="tilelist.dateInfo"
        :enabled="enabled"
        :cardtempnum="cardtempnum"
        :tileinfo="tilelist.tileList"
        :keyword="keyword"
      ></ListItem>
      <div v-if="isDisplayProgressbar()" class="progressbar white_back">
        <Progressbar
          :progress="tilelist.progressbarValue"
          :sum="tilelist.progressbarSum"
          :max="tilelist.progressbarMax"
        ></Progressbar>
      </div>
      <div v-else class="progressbar"></div>
    </template>
    <!-- カード枚数表示(折畳あり) -->
    <template v-else>
      <div
        v-if="tilelist.tileList.length > 0"
        :class="setCloseClass(isRowOpen, isColOpen)"
      >
        <v-chip label small class="my-1 ch" color="orange lighten-2">
          {{ tilelist.tileList.length }}
        </v-chip>
      </div>
    </template>
  </div>
</template>

<script>
import ListItem from "@/components/parts/ListItem.vue";
import Progressbar from "@/components/parts/Progressbar.vue";
import Define from "@/define.js";
import * as Util from "@/util/utils.js";
import moment from "moment";
export default {
  components: {
    ListItem,
    Progressbar
  },
  props: {
    lineid: {
      type: String,
      default: ""
    },
    enabled: {
      type: Boolean,
      default: true
    },
    tilelist: {
      type: Object,
      default: () => ({
        dateInfo: "",
        progressbarValue: 0,
        progressbarSum: 0,
        progressbarMax: 0,
        tileList: []
      })
    },
    cardtempnum: {
      type: Number,
      default: 0
    },
    keyword: {
      type: Object,
      default: () => {}
    },
    progressbarflg: {
      type: Boolean,
      default: false
    },
    isRowOpen: {
      type: Boolean,
      default: true
    },
    isColOpen: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isDateFree: Define.IS_DATE_FREE,
      freeareatrue: true,
      days: ""
    };
  },
  computed: {
    setClassOpen() {
      let result = "";
      if (this.days === Define.DAYS_SUNDAY) {
        result = "sunday";
      } else if (this.days === Define.DAYS_SATURDAY) {
        result = "saturday";
      } else {
        // 平日
      }
      if (this.displayDays == Define.DISP_DAYS_1) {
        result += " listprogressOpen1";
      } else if (this.displayDays == Define.DISP_DAYS_2) {
        result += " listprogressOpen2";
      } else if (this.displayDays == Define.DISP_DAYS_3) {
        result += " listprogressOpen3";
      } else {
        result += " listprogressOpen7_10";
      }
      return result;
    },
    setRowClassClose() {
      let result = "";
      if (this.days === Define.DAYS_SUNDAY) {
        result = "sunday";
      } else if (this.days === Define.DAYS_SATURDAY) {
        result = "saturday";
      } else {
        // 平日
      }
      if (this.displayDays == Define.DISP_DAYS_1) {
        result += " listprogressRowClose1";
      } else if (this.displayDays == Define.DISP_DAYS_2) {
        result += " listprogressRowClose2";
      } else if (this.displayDays == Define.DISP_DAYS_3) {
        result += " listprogressRowClose3";
      } else {
        result += " listprogressRowClose7_10";
      }
      return result;
    },
    setColClassClose() {
      let result;
      if (this.days === Define.DAYS_SUNDAY) {
        result = "sunday listprogressColClose";
      } else if (this.days === Define.DAYS_SATURDAY) {
        result = "saturday listprogressColClose";
      } else {
        result = "listprogressColClose";
      }
      return result;
    },
    setClassClose() {
      let result;
      if (this.days === Define.DAYS_SUNDAY) {
        result = "sunday listprogressClose";
      } else if (this.days === Define.DAYS_SATURDAY) {
        result = "saturday listprogressClose";
      } else {
        result = "listprogressClose";
      }
      return result;
    },
    selectedWorkspaceId() {
      return this.$store.getters["auth/selectedWorkSpaceId"];
    },
    displayDays() {
      return this.$store.getters["display/displayDays"];
    },
  },
  methods: {
    setClass(isRowOpen, isColOpen) {
      let result;
      if (isRowOpen && isColOpen) {
        result = this.setClassOpen;
      } else if (!isRowOpen && isColOpen) {
        result = this.setRowClassClose;
      } else if (isRowOpen && !isColOpen) {
        result = this.setColClassClose;
      } else if (!isRowOpen && !isColOpen) {
        result = this.setClassClose;
      }
      return result;
    },
    setCloseClass(isRowOpen, isColOpen) {
      let result;
      if (isRowOpen && !isColOpen) {
        result = "tilelistColclose";
      } else {
        result = "tilelistclose";
      }
      return result;
    },
    /**
     * カレンダー設定で負荷上限を0に設定時、負荷バーを表示しない
     * カレンダー設定で負荷上限が0でない時、負荷バーを表示する(計画エリアの負荷上限=0であても)
     */
    isDisplayProgressbar () {
      let max = this.tilelist.progressbarMax;
      return max == 0 ? false : (max ? true : this.progressbarFlg);
    },
  },
  beforeMount() {
    if (Util.checkNotNull(this.tilelist.dateInfo)) {
      if (Util.checkDateType(this.tilelist.dateInfo)) {
        this.days = moment(this.tilelist.dateInfo).day();
      }
    }
  }
};
</script>

<style scoped>
.listprogressOpen1 {
  width: 1260px;
  min-height: 120px;
  border: solid 1px #bbb;
  border-left: 0;
}
.listprogressOpen2 {
  width: 630px;
  min-height: 120px;
  border: solid 1px #bbb;
  border-left: 0;
}
.listprogressOpen3 {
  width: 420px;
  min-height: 120px;
  border: solid 1px #bbb;
  border-left: 0;
}
.listprogressOpen7_10 {
  width: 180px;
  min-height: 120px;
  border: solid 1px #bbb;
  border-left: 0;
}
.listprogressRowClose1 {
  width: 1260px;
  min-height: 20px;
  border: solid 1px #bbb;
  border-left: 0;
}
.listprogressRowClose2 {
  width: 630px;
  min-height: 20px;
  border: solid 1px #bbb;
  border-left: 0;
}
.listprogressRowClose3 {
  width: 420px;
  min-height: 20px;
  border: solid 1px #bbb;
  border-left: 0;
}
.listprogressRowClose7_10 {
  width: 179px;
  min-height: 20px;
  border: solid 1px #bbb;
  border-left: 0;
}
.listprogressColClose {
  width: 65px;
  min-height: 120px;
  border: solid 1px #bbb;
  border-left: 0;
}
.listprogressClose {
  width: 65px;
  min-height: 20px;
  border: solid 1px #bbb;
  border-left: 0;
}
.listprogressFreeAreaOpen {
  width: 528px;
  min-height: 79vh;
  margin: -3px 0 -3px 0;
  border: solid 1px #bbb;
  border-top: 0;
}
.tilelistclose {
  text-align: center;
  font-weight: bold;
  font-size: 17.1px;
}
.tilelistColclose {
  text-align: center;
  font-weight: bold;
  font-size: 17.1px;
  padding: 45px 0;
}
.progressbar {
  text-align: center;
  height: 20px;
}
.white_back {
  background-color: #fff;
}
.ch {
  color: #666;
}
</style>
