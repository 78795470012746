import rootState from "./index";
import moment from "moment";
import * as Util from "@/util/utils.js";
import Define from "@/define.js";

const state = {
  tenantData: {}
};

const getters = {
  /**
   * websocketより送られてきたデータからテナントデータのみ抽出して返す
   * @param {Object} state - storeのstate
   * @returns {Object} - 抽出したテナントデータ
   */
  getTenantData(state, getters) {
    let tenantData = Util.parseObject(rootState.state.tenantData);
    //trialEndFlgがDefine.IN_TRIAL(=false)のときは期間内
    //             Define.OUT_TRIAL(=true)のときは期間外
    //trialEndDate: 無料お試しプラン終了日
    if (!tenantData.createdAt) {
      tenantData.trialEndFlg = Define.IN_TRIAL;
      tenantData.trialEndDate = null;
      return tenantData;
    }
    if (tenantData.plan === Define.PLAN_TRIAL) {
      // 無料お試しプラン終了日
      let trialEndDate = getters.getTrialEndDate(tenantData.createdAt);
      // 無料お試しプラン終了日は無料お試しプラン期間内に含む
      let trialFlg = getters.getTrialEndFlg(trialEndDate);
      tenantData.trialEndDate = trialEndDate;
      tenantData.trialEndFlg = trialFlg;
    } else {
      tenantData.trialEndDate = null;
      tenantData.trialEndFlg = Define.IN_TRIAL;
    }
    return tenantData;
  },
  /**
   * 引数で受け取った日付が無料お試しプランの期限内か判定する
   * @param {moment} trialEndDate - 判定対象の日付データ
   * @returns {Boolean} - 判定結果 true:期限外、false:期限内
   */
  getTrialEndFlg: () => trialEndDate => {
    let todayDate = moment().format(Define.DATE_FORMAT_DAY_HYPHEN);
    // 無料お試しプラン終了日は無料お試しプラン期間内に含む
    return moment(todayDate).isAfter(trialEndDate);
  },
  /**
   * 無料お試しプランの最終日を返す
   * @param {Date} createdAt - 登録日
   * @returns {moment} - 無料お試しプラン最終日
   */
  getTrialEndDate: () => createdAt => {
    return moment(createdAt)
      .add(Define.TRIAL_DATE, "days")
      .format(Define.DATE_FORMAT_DAY_HYPHEN);
  }
};

const actions = {};

const mutations = {
  initState(state) {
    state.tenantData = {};
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
