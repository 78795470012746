<template>
  <div class="pagetitles">
    <div class="title">
      {{ titles }}
      <v-btn
        v-if="linkUrl !== ''"
        :href="linkUrl"
        target="_blank"
        small
        class="mx-1 mb-1"
        elevation="1"
        color="warning"
      >
        <v-icon v-if="linkIcon !== ''">{{linkIcon}}</v-icon>
        {{ linkStr }}
      </v-btn>
    </div>
    <div v-if="descriptions !== ''" class="desc flexarea">
      <v-icon>mdi-hand-pointing-right</v-icon>
      {{ descriptions }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    titles: {
      type: String,
      default: ""
    },
    descriptions: {
      type: String,
      default: ""
    },
    // 以下はタイトル横にリンクボタンを設置したい場合にのみ利用する
    linkUrl: {
      type: String,
      default: ""
    },
    linkStr: {
      type: String,
      default: ""
    },
    linkIcon: {
      type: String,
      default: ""
    }
  }
};
</script>

<style scoped>
.pagetitles {
  margin: 6px 0;
}
.title {
  padding-left: 4px;
  border-left: solid 10px #1976d2ca;
  font-weight: bold;
}
.desc {
  margin: 12px 0 24px 12px;
  white-space: pre-wrap;
}
</style>
