var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { style: _vm.dataAreaStyle },
    [
      _c("v-data-table", {
        style: _vm.tableAreaStyle,
        attrs: {
          headers: _vm.colorConditionsHeaders,
          "item-key": "id",
          items: _vm.colorConditionList,
          "hide-default-footer": ""
        },
        scopedSlots: _vm._u(
          [
            {
              key: "item.mappingId",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("v-select", {
                    attrs: {
                      dense: "",
                      outlined: "",
                      "hide-details": "auto",
                      disabled: _vm.userRole,
                      items: _vm.mappingDataList,
                      "item-text": "name",
                      "item-value": "mappingId",
                      rules: [_vm.check_required]
                    },
                    model: {
                      value: item.mappingId,
                      callback: function($$v) {
                        _vm.$set(item, "mappingId", $$v)
                      },
                      expression: "item.mappingId"
                    }
                  })
                ]
              }
            },
            {
              key: "item.condition",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("v-select", {
                    attrs: {
                      dense: "",
                      outlined: "",
                      "hide-details": "auto",
                      disabled: _vm.userRole,
                      items: _vm.conditions,
                      "item-value": "conditionId",
                      "item-text": "label",
                      rules: [_vm.check_required]
                    },
                    model: {
                      value: item.operator,
                      callback: function($$v) {
                        _vm.$set(item, "operator", $$v)
                      },
                      expression: "item.operator"
                    }
                  })
                ]
              }
            },
            {
              key: "item.value",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("ColorConditionSettingsEditingItem", {
                    attrs: {
                      type: _vm.getType(item.mappingId),
                      calcObject1Type: _vm.getCalcObject1Type(item.mappingId),
                      editable: !_vm.userRole,
                      rules: []
                    },
                    model: {
                      value: item.value,
                      callback: function($$v) {
                        _vm.$set(item, "value", $$v)
                      },
                      expression: "item.value"
                    }
                  })
                ]
              }
            },
            {
              key: "item.color",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("v-select", {
                    attrs: {
                      dense: "",
                      outlined: "",
                      "hide-details": "auto",
                      disabled: _vm.userRole,
                      items: _vm.colors,
                      "item-value": "color",
                      "item-text": "label",
                      rules: [_vm.check_required]
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "selection",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("div", {
                                style: _vm.colorBoxlStyle(item.color)
                              }),
                              _c("span", { staticClass: "pl-2" }, [
                                _vm._v(_vm._s(item.label))
                              ])
                            ]
                          }
                        },
                        {
                          key: "item",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("div", {
                                style: _vm.colorBoxlStyle(item.color)
                              }),
                              _c("span", { staticClass: "pl-2" }, [
                                _vm._v(_vm._s(item.label))
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: item.color,
                      callback: function($$v) {
                        _vm.$set(item, "color", $$v)
                      },
                      expression: "item.color"
                    }
                  })
                ]
              }
            },
            {
              key: "item.action",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "v-icon",
                    {
                      attrs: { small: "", disabled: _vm.userRole },
                      on: {
                        click: function($event) {
                          return _vm.deleteColorConditionRow(item)
                        }
                      }
                    },
                    [_vm._v(" mdi-close ")]
                  )
                ]
              }
            }
          ],
          null,
          true
        )
      }),
      _c(
        "div",
        { staticClass: "flexarea" },
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              staticClass: "info",
              attrs: { disabled: _vm.userRole },
              on: { click: _vm.addColorConditionList }
            },
            [_vm._v(" " + _vm._s(_vm.$t("WORD_ADD")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }