import { render, staticRenderFns } from "./CardDetailJissekiTable.vue?vue&type=template&id=6fc14f4a&scoped=true&"
import script from "./CardDetailJissekiTable.vue?vue&type=script&lang=js&"
export * from "./CardDetailJissekiTable.vue?vue&type=script&lang=js&"
import style0 from "./CardDetailJissekiTable.vue?vue&type=style&index=0&id=6fc14f4a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fc14f4a",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VCard,VDataTable,VIcon})


/* hot reload */
if (module.hot) {
  var api = require("C:\\Freely\\repo_nck_plan_board\\nck_plan_board_frontend\\vue\\code\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6fc14f4a')) {
      api.createRecord('6fc14f4a', component.options)
    } else {
      api.reload('6fc14f4a', component.options)
    }
    module.hot.accept("./CardDetailJissekiTable.vue?vue&type=template&id=6fc14f4a&scoped=true&", function () {
      api.rerender('6fc14f4a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/parts/CardDetailJissekiTable.vue"
export default component.exports