var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "applicationsetting" },
    [
      _c("PageTitle", {
        attrs: {
          titles: _vm.pagetitle,
          descriptions: _vm.pagedescription,
          linkStr: _vm.wMovie,
          linkUrl: _vm.URL_MOVIE,
          linkIcon: "mdi-video"
        }
      }),
      _c(
        "v-tabs",
        {
          ref: "tabs",
          attrs: {
            height: "30",
            "background-color": "blue darken-1",
            dark: ""
          },
          on: {
            change: function($event) {
              return _vm.onTabChange(_vm.tab)
            }
          },
          model: {
            value: _vm.tab,
            callback: function($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        [
          _c("v-tab", [
            _vm._v(_vm._s(_vm.$t("TABNAME_APPLICATION_SETTING_BASE")))
          ]),
          _c("v-tab", [
            _vm._v(_vm._s(_vm.$t("TABNAME_APPLICATION_SETTING_EXPANSION")))
          ]),
          _c("v-tab", [
            _vm._v(_vm._s(_vm.$t("TABNAME_APPLICATION_SETTING_OPTION")))
          ]),
          _c("v-tab", [
            _vm._v(_vm._s(_vm.$t("TABNAME_APPLICATION_SETTING_CALENDAR")))
          ]),
          _vm.option003
            ? _c("v-tab", [
                _vm._v(
                  _vm._s(_vm.$t("TABNAME_APPLICATION_SETTING_MULTILINGUAL_API"))
                )
              ])
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-tabs-items",
        {
          staticClass: "px-2 py-2",
          attrs: { touchless: true },
          model: {
            value: _vm.tab,
            callback: function($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        [
          _c("v-tab-item", [_c("ApplicationSettingBase")], 1),
          _c("v-tab-item", [_c("ApplicationSettingExpansion")], 1),
          _c("v-tab-item", [_c("ApplicationSettingOption")], 1),
          _c(
            "v-tab-item",
            [
              _c("ApplicationSettingCalendar", {
                attrs: { tab: _vm.isOpenCalendarSetting }
              })
            ],
            1
          ),
          _c("v-tab-item", [_c("ApplicationSettingApi")], 1)
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }