var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "subtitle" }, [
        _vm._v(_vm._s(_vm.$t("PAGE_SUBTITLE_CARD_JISSEKI_INPUT")))
      ]),
      _c(
        "v-card",
        { staticClass: "jisseki-input areamargin", attrs: { flat: "" } },
        [
          _c(
            "div",
            { staticClass: "datatable" },
            [
              _c(
                "v-form",
                { ref: "jissekiform" },
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    _vm._l(_vm.jissekis, function(jisseki) {
                      return _c(
                        "v-col",
                        {
                          key: jisseki.mappingId,
                          staticClass: "flexarea detail",
                          attrs: { cols: "4" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "dispdetail1" },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: {
                                    bottom: "",
                                    "open-delay": "300",
                                    color: "rgba(60,64,67,0.95)",
                                    transition: "fade"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var tbl = ref.on
                                          return [
                                            _c(
                                              "div",
                                              _vm._g(
                                                {
                                                  staticClass:
                                                    "font-weight-bold dispfont detailtitle",
                                                  style: _vm.getStyleFontSize(
                                                    _vm.DISPLAY_TYPE_CARD_DETAIL,
                                                    _vm.displayFontSize
                                                  )
                                                },
                                                tbl
                                              ),
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.getShortString(
                                                        _vm.mappingName(
                                                          jisseki.mappingId
                                                        ),
                                                        10,
                                                        20
                                                      )[0]
                                                    ) +
                                                    " "
                                                ),
                                                _c("br"),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.getShortString(
                                                        _vm.mappingName(
                                                          jisseki.mappingId
                                                        ),
                                                        10,
                                                        20
                                                      )[1]
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _c("label", [
                                    _vm._v(
                                      _vm._s(_vm.mappingName(jisseki.mappingId))
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "dispdetail2" },
                            [
                              _c("EditingItem", {
                                attrs: {
                                  type: _vm.getType(jisseki.mappingId),
                                  inputType: _vm.getInputType(
                                    jisseki.mappingId
                                  ),
                                  rules: _vm.rules(jisseki.mappingId),
                                  items: _vm.listDataItems(
                                    _vm.getCategory(jisseki.mappingId),
                                    jisseki.value
                                  ),
                                  min: _vm.getMin(jisseki.mappingId),
                                  max: _vm.getMax(jisseki.mappingId),
                                  step: _vm.getStep(jisseki.mappingId),
                                  bgcolor:
                                    _vm.getJisseki(jisseki.mappingId) ===
                                    _vm.JISSEKI_REQUIRED
                                      ? "pink lighten-5"
                                      : "",
                                  calcObject1Type:
                                    _vm.getType(jisseki.mappingId) ===
                                    _vm.TYPE_CALC
                                      ? _vm.getCalcObject1Type(
                                          jisseki.mappingId
                                        )
                                      : null
                                },
                                model: {
                                  value: jisseki.value,
                                  callback: function($$v) {
                                    _vm.$set(jisseki, "value", $$v)
                                  },
                                  expression: "jisseki.value"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "areamargin flexarea pt-2" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "make",
                      attrs: { outlined: "" },
                      on: {
                        click: function($event) {
                          return _vm.regist(false)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("WORD_REGIST")))]
                  ),
                  !this.tileInfo.isComplete
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "make2",
                          attrs: { outlined: "" },
                          on: {
                            click: function($event) {
                              return _vm.regist(true)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("WORD_REGIST_COMPLETE")))]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }