import Define from "../define.js";
import * as Util from "@/util/utils.js";

export const mixinUtil = {
  name: "mixinUtil",
  methods: {
    makeTextTileArea(date, area) {
      let text;
      if (date === Define.IS_DATE_FREE) {
        text = this.$t("WORD_CARD_HISTORY_POSITION_UNPLANNED");
      } else if (area === Define.IS_NO_SETTINGS) {
        text = this.$t("WORD_CARD_HISTORY_POSITION_MOVE", {date: date});
      } else {
        text = `[${date} - ${area}]`;
      }
      return text;
    },
    /**
     * ページタイトルとその説明オブジェクトを検索して返す
     * @param {Number} code - 検索対象コード
     * @returns {Object} - 検索結果
     */
    getPageTitleDescriptionObj(code) {
      let result = this.$t("PAGE_TITLE_DESCRIPTION").find(e => e.code === code);
      return Util.checkNotUndefined(result)
        ? result.contents
        : Define.PAGE_TITLE_DESCRIPTION_EMPTY_OBJECT;
    },
    /**
     * 引数で受け取ったエラーコードのリストからエラーログを生成する
     * @param {Array} checkResult エラーコードのリスト
     * @returns {Array} -エラーログのリスト
     */
    createErrorLogByImportFile(checkResult) {
      let index = 0;
      let list = [];
      let errmainfile = this.$t("MSG_ERR_MAIN_FILE");
      let errsubfile = this.$t("MSG_ERR_SUB_FILE");
      let errmainext = this.$t("MSG_ERR_MAIN_EXTENSION");
      let errsubext = this.$t("MSG_ERR_SUB_EXTENSION");
      let errmainrow = this.$t("MSG_ERR_MAIN_ROW");
      let errsubrow = this.$t("MSG_ERR_SUB_ROW");
      let errmainlength = this.$t("MSG_ERR_MAIN_LENGTH");
      let errsublength = this.$t("MSG_ERR_SUB_LENGTH");
      let errmaintype = this.$t("MSG_ERR_MAIN_TYPE");
      let errsubtype = this.$t("MSG_ERR_SUB_TYPE");
      let errmainabdate = this.$t("MSG_ERR_MAIN_ABSOLUTE_DATE");
      let errsubabdate = this.$t("MSG_ERR_SUB_ABSOLUTE_DATE");
      let errmainabplan = this.$t("MSG_ERR_MAIN_ABSOLUTE_PLAN");
      let errsubabplan = this.$t("MSG_ERR_SUB_ABSOLUTE_PLAN");
      let errsubabnot = this.$t("MSG_ERR_SUB_ABSOLUTE_NOT_EXIST_COLDATA");
      let errmainread = this.$t("MSG_ERR_MAIN_CANNOT_READ_FILE");
      let errsubread = this.$t("MSG_ERR_SUB_CANNOT_READ_FILE");
      let errmainparse = this.$t("MSG_ERR_MAIN_PARSE_CSV");
      let errsubparse = this.$t("MSG_ERR_SUB_PARSE_CSV");
      let errmainkey = this.$t("MSG_ERR_MAIN_KEY_EMPTY");
      let errsubkey = this.$t("MSG_ERR_SUB_KEY_EMPTY");
      let cellpos = this.$t("WORD_CELL_POSITION_SQUARE");
      let colpos = this.$t("WORD_COLUMN_POSITION");
      checkResult.forEach(function(value) {
        switch (value.code) {
          case Define.ERR_NO_FILE:
            list.push({
              id: index,
              message: errmainfile + ": " + errsubfile
            });
            break;
          case Define.ERR_EXTENSION:
            list.push({
              id: index,
              message: errmainext + ": " + errsubext
            });
            break;
          case Define.ERR_ROW_MAX:
            list.push({
              id: index,
              message: errmainrow + ": " + errsubrow
            });
            break;
          case Define.ERR_LENGTH_MAX:
            list.push({
              id: index,
              message:
                errmainlength +
                ": " +
                errsublength +
                cellpos +
                value.col +
                value.row +
                "]"
            });
            break;
          case Define.ERR_DATA_TYPE:
            list.push({
              id: index,
              message:
                errmaintype +
                ": " +
                errsubtype +
                cellpos +
                value.col +
                value.row +
                "]"
            });
            break;
          case Define.ERR_ABSOLUTE_DATE:
            list.push({
              id: index,
              message: `${errmainabdate}: ${errsubabdate}${cellpos}${value.col}${value.row}]`
            });
            break;
          case Define.ERR_ABSOLUTE_PLAN:
            list.push({
              id: index,
              message: `${errmainabplan}: ${errsubabplan}${cellpos}${value.col}${value.row}]`
            });
            break;
          case Define.ERR_ABSOLUTE_NOT_EXIST_COLDATA_DATE:
            list.push({
              id: index,
              message: `${errmainabdate}: ${errsubabnot}${colpos}${value.col}]`
            });
            break;
          case Define.ERR_ABSOLUTE_NOT_EXIST_COLDATA_PLAN:
            list.push({
              id: index,
              message: `${errmainabplan}: ${errsubabnot}${colpos}${value.col}]`
            });
            break;
          case Define.ERR_CANNOT_READ_FILE:
            list.push({
              id: index,
              message: `${errmainread}: ${errsubread}`
            });
            break;
          case Define.ERR_PARSE_CSV:
            list.push({
              id: index,
              message: `${errmainparse}: ${errsubparse}`
            });
            break;
          case Define.ERR_KEY_DATA_EMPTY:
            list.push({
              id: index,
              message:
                errmainkey +
                ": " +
                errsubkey +
                cellpos +
                value.col +
                value.row +
                "]"
            });
            break;
          default:
            break;
        }
        index++;
      });
      return list;
    }
  }
}