import rootState from "./index";
import BigNumber from "bignumber.js";
import Define from "@/define.js";
import SwalMsg from "@/swalMsg.js";
import Swal from "sweetalert2";
import moment from "moment";
import * as Util from "@/util/utils.js";
import camelcaseKeys from "camelcase-keys";
import Tile from "@/tile.js";

const state = {
  tileData: [],
  tileMoveHistoryData: [],
  tileDetailChangeHistoryData: [],
  tileDetailData: [],
  freeTileListTemplate: JSON.parse(JSON.stringify(Tile.FREE_TILELIST_TEMPLATE)),
  pageNo: 1,
  pageNum: 1,
  // カード詳細ダイアログ関連
  isOpenCardDetail: false,
  isOpenMobileCardDetail: false,
  cardDetailInfo: JSON.parse(JSON.stringify(Tile.CARD_DETAIL_TEMPLATE)),
  isAddCard: false,
  cardJissekiInfo: [],
  // 複数複製ダイアログ関連
  isOpenCardDetailMultipleCopy: false,
  copyMultipleCardFileList: [],
  inCompleteTileData: [],
  // カード検索ダイアログ関連
  isOpenCardSearch: false,
  // 一か月表示ダイアログ関連
  isOpenMonthView: false,
  tileLoadData: [],
  // カレンダー設定情報関連
  calendarSettingData: []
};

const getters = {
  tileMoveHistoryData: state => state.tileMoveHistoryData,
  tileDetailChangeHistoryData: state => state.tileDetailChangeHistoryData,
  /**
   * 計画ボード画面で使用するデータを返す
   * 他のメソッドでカードデータは整形する
   * @param {String} startDate - データを取得したい日付範囲の初めの日付
   * @param {String} endDate - データを取得したい日付範囲の終わりの日付
   * @param {Number} displayDays - 表示日付数
   * @returns {Object} result - 整形後のカードデータを含むオブジェクト
   */
  getTileData: (state, getters) => (startDate, endDate, displayDays) => {
    let result = {
      planTileList: getters.getPlanTileData(startDate, endDate, displayDays),
      freeTileList: getters.getFreeTileData,
      cardTempNum: getters.getTileTempNum,
      firstDate: startDate,
      seventhDate: endDate
    };
    return result;
  },
  getTileTempNum() {
    return rootState.state.settingData === null
      ? 0
      : rootState.state.settingData.templateNo;
  },
  /**
   * 計画済みエリアのカードデータを整形して返す
   * @param {String} startDate -取得したい範囲の初めの日付
   * @param {String} endDate - 取得したい日付の終わりの日付
   * @param {Number} displayDays - 表示日付数
   * @returns {Object} planTileList - 整形後の計画済みカードデータ
   */
  getPlanTileData: () => (startDate, endDate, displayDays) => {
    // アプリケーション設定の存在チェック
    let setting = rootState.state.settingData;
    if (!Util.isExistSetting(setting)) {
      //存在しない場合は、空のテンプレートを返す
      return Util.getPlanTileListTemplate(displayDays);
    }

    let planTileList = {
      tileList: []
    };

    // エリアデータを取得 (リスト)
    let areaData = Util.parseObject(setting.area);
    // エリアデータを表示順の昇順でソート
    if (areaData.length) {
      areaData.sort(function(a, b) {
        if (a.order < b.order) return -1;
        if (a.order > b.order) return 1;
        return 0;
      });
    }

    // マッピングデータを取得 (リスト)
    let mappingData = setting.mapping;

    // カレンダー設定情報を取得
    let calendarSetting = rootState.state.calendarSettingData;

    // カードに表示するデータのマッピングidを取得 (リスト)
    let templatePosList = mappingData.filter(e => e.pos !== "");
    let computeMappingdata = mappingData.find(e => e.isCompute === true);
    let computeMappingId = null;
    let computeFlg = true;
    if (computeMappingdata) {
      computeMappingId = computeMappingdata.mappingId;
    } else {
      computeFlg = !computeFlg;
    }
    let planTileData = [];
    planTileData = Util.parseList(
      rootState.state.tileData.filter(e => e.posCol !== null)
    );
    // 日付範囲指定のリストを作成する
    let dateList = [];
    let dateformat = Define.DATE_FORMAT_DAY_HYPHEN;
    let start = moment(startDate).format(dateformat);
    let end = moment(endDate).format(dateformat);
    for (
      let date = start;
      date <= end;
      date = moment(date)
        .add(1, "days")
        .format(dateformat)
    ) {
      dateList.push(date);
    }
    let displayNum = Util.getDiaplayNum(setting.templateNo);
    // エリアデータの先頭に未計画情報を追加
    areaData.unshift({
      areaId: null,
      name: "",
      max: 0
    });
    // カードデータ整形
    for (let i = 0; i < areaData.length; i++) {
      // エリアの表示順が未設定であれば成形対象外
      if (!Util.checkNotEmpty(areaData[i].order)) {
        continue;
      }
      let areaResult = {
        lineId: areaData[i].areaId,
        lineName: areaData[i].name,
        max: areaData[i].max,
        progressbarFlg: areaData[i].max === 0 ? false : computeFlg,
        tileList: []
      };
      let tileList = planTileData.filter(e => e.posRow == areaData[i].areaId);
      let calendarSettingByArea = calendarSetting.filter(e => e.posRow == areaData[i].areaId);
      for (let j = 0; j < dateList.length; j++) {
        let tileResult = {
          dateInfo: dateList[j],
          progressbarValue: 0,
          progressbarSum: 0,
          progressbarMax: 0,
          tileList: null
        };
        let tileFilterList = tileList.filter(e => e.posCol == dateList[j]);
        let calendarSettingByDate = calendarSettingByArea.filter(e => e.posCol == dateList[j]);
        let sumValue = 0;
        for (let k = 0; k < tileFilterList.length; k++) {
          // カード詳細情報の並び順をマッピング定義順にソートする
          tileFilterList[k].tileDetailData = Util.sortDetailData(
            tileFilterList[k].tileDetailData,
            mappingData
          );

          //カードに表示するデータのリストを作成してカード情報に追加する
          tileFilterList[k].displayData = Util.getDisplayTileList(
            tileFilterList[k].tileDetailData,
            displayNum,
            templatePosList
          );
          if (
            tileFilterList[k].tileDetailData.find(
              e => e.mappingId === computeMappingId
            ) != null &&
            areaData[i].areaId != null &&
            computeFlg
          ) {
            //負荷対象データの和を導出
            sumValue = BigNumber(sumValue).plus(
              Number(
                tileFilterList[k].tileDetailData.find(
                  e => e.mappingId === computeMappingId
                ).value
              )
            );
          }
        }
        // 表示順でソートする(昇順)
        tileResult.tileList = tileFilterList.sort(function(a, b) {
          if (a.order < b.order) return -1;
          if (a.order > b.order) return 1;
          return 0;
        });
        tileResult.progressbarSum = sumValue === 0 ? 0 : sumValue.toNumber();
        // カレンダー設定で負荷上限の設定があれば、カレンダー設定の負荷上限を使用
        if (calendarSettingByDate.length !== 0) {
          // プログレスバーに表示する%を計算
          tileResult.progressbarValue = Util.calcProgress(sumValue, parseInt(calendarSettingByDate[0].max));
          // プログレスバー詳細情報を作成
          tileResult.progressbarMax = parseInt(calendarSettingByDate[0].max);
        }
        // カレンダー設定がなかれば、対象計画エリアの負荷上限を使用
        else {
          // プログレスバーに表示する%を計算
          tileResult.progressbarValue = Util.calcProgress(sumValue, areaData[i].max);
          // プログレスバー詳細情報を作成
          tileResult.progressbarMax = areaData[i].max;
        }
        areaResult.tileList.push(tileResult);
      }
      planTileList.tileList.push(areaResult);
    }
    return planTileList;
  },
  /**
   * 未計画エリアのカードデータを整形して渡す
   * @returns {object} freeTileList - 整形後の未計画カードデータ
   */
  getFreeTileData: () => {
    let freeTileList = JSON.parse(JSON.stringify(Tile.FREE_TILELIST_TEMPLATE));
    if (rootState.state.settingData === null) {
      return state.freeTileListTemplate;
    }
    let mappingData = rootState.state.settingData.mapping;
    if (!mappingData) {
      return state.freeTileListTemplate;
    }
    if (mappingData.length === 0) {
      return state.freeTileListTemplate;
    }

    let displayNum = Util.getDiaplayNum(rootState.state.settingData.templateNo);
    let templatePosList = mappingData.filter(e => e.pos !== "");
    let freeTileData = [];
    freeTileData = Util.parseList(
      rootState.state.tileData.filter(e => e.posCol === null)
    );
    freeTileData.sort(function(a, b) {
      if (a.order < b.order) return -1;
      if (a.order > b.order) return 1;
      return 0;
    });
    // カードデータ整形
    for (let k = 0; k < freeTileData.length; k++) {
      // カード詳細情報の並び順をマッピング定義順にソートする
      freeTileData[k].tileDetailData = Util.sortDetailData(
        freeTileData[k].tileDetailData,
        mappingData
      );

      //カードに表示するデータのリストを作成してカード情報に追加する
      freeTileData[k].displayData = Util.getDisplayTileList(
        freeTileData[k].tileDetailData,
        displayNum,
        templatePosList
      );
      freeTileList.tileList.tileList[0].tileList.push(freeTileData[k]);
    }
    return freeTileList;
  },
  /**
   * 特定位置のカードリストを取得する
   * @param {String} areaId - 行ID
   * @param {String} dateId - 列ID（日付）
   * @returns {Array} tileAreaList
   */
  getMovedArea: () => (areaId, dateId) => {
    let tileAreaList = [];
    let tileData = Util.parseObject(rootState.state.tileData);
    tileAreaList = tileData.filter(
      e => e.posRow === areaId && e.posCol === dateId
    );
    // カードを表示順にソート(昇順)する
    // filter取得時点ではソートされていない
    if (tileAreaList.length) {
      tileAreaList.sort(function(a, b) {
        if (a.order < b.order) return -1;
        if (a.order > b.order) return 1;
        return 0;
      });
    }
    return tileAreaList;
  },
  /**
   * 未計画エリアのカードデータ数を取得する
   * @returns {Number} 未計画エリアのカードデータ数
   */
  getFreeTileNum: () => {
    let freeAreaTiles = rootState.state.tileData.filter(
      d => d.posCol === null && d.posRow === null
    );
    return freeAreaTiles.length;
  },
  pageNo: state => state.pageNo,
  pageNum: state => state.pageNum,
  isOpenCardDetail: state => state.isOpenCardDetail,
  isOpenMobileCardDetail: state => state.isOpenMobileCardDetail,
  cardDetailInfo: state => state.cardDetailInfo,
  isOpenCardDetailMultipleCopy: state => state.isOpenCardDetailMultipleCopy,
  copyMultipleCardFileList: state => state.copyMultipleCardFileList,
  isAddCard: state => state.isAddCard,
  isOpenCardSearch: state => state.isOpenCardSearch,
  isOpenMonthView: state => state.isOpenMonthView,
  isOpenDialog: state => {
    return state.isOpenCardDetail || state.isOpenCardDetailMultipleCopy || state.isOpenCardSearch || state.isOpenMonthView;
  },
  /**
   * storeから指定したタイルidの情報を１件取得
   * @param {String} - タイルid(uuid)
   * @returns {Object} - 取得結果※仮に存在しない場合はundifined
   */
  getOneTileData: () => id => {
    let tempTileData = Util.parseObject(rootState.state.tileData);
    let tileObj = tempTileData.find(e => e.tileId === id);
    return tileObj;
  },
  cardJissekiInfo: state => state.cardJissekiInfo,
  cardJissekiSum: state => mappingId => {
    let result = 0;
    for (const jisseki of state.cardJissekiInfo) {
      if (jisseki[mappingId]) {
        result = BigNumber(result).plus(Number(jisseki[mappingId]));
      }
    }
    return result;
  },
  inCompleteHeaders: () => cond => {
    let headers = [];
    let setting = rootState.state.settingData;
    if (!setting) {
      return [];
    }
    let displayPosList = Util.getDisplayPosList(setting.mapping);
    if (displayPosList.length) {
      displayPosList.sort(function(a, b) {
        if (a.pos < b.pos) return -1;
        if (a.pos > b.pos) return 1;
        return 0;
      });
    }
    displayPosList.forEach((item, index) => {
      headers.push({ text: item.mappingId, value: "detail00" + (index + 1) });
    });

    // カード検索条件で詳細項目のカテゴリを選択した場合、カード表示外の項目なら追加
    if(cond != null && cond.detailMappingId != "" && !headers.some(header => header.text === cond.detailMappingId)){
      headers.push({ text: cond.detailMappingId, value: "detailAdd"});
    }

    return headers;
  },
  inCompleteItems: (state) => cond => {
    let inCompleteItems = [];
    let TmpinCompleteTileData = state.inCompleteTileData;
    let setting = rootState.state.settingData;
    if (!setting) {
      return [];
    }
    let displayPosList = Util.getDisplayPosList(setting.mapping);
    let displayNum = Util.getDiaplayNum(setting.templateNo);
    for (const tile of TmpinCompleteTileData) {
      let displayDetail = Util.getDisplayTileList(tile.tileDetailData, displayNum, displayPosList);
      displayDetail = displayDetail.filter(d => d.mappingId !== "");

      // カード検索条件で詳細項目のカテゴリを選択した場合、カード表示外の項目なら追加
      let detailAdd = null;
      if(cond != null && cond.detailMappingId != "" && !displayDetail.some(d => d.mappingId === cond.detailMappingId)){
        detailAdd = tile.tileDetailData.filter(d => d.mappingId == cond.detailMappingId)[0];
      }

      inCompleteItems.push({
        tileId: tile.tileId,
        posCol: tile.posCol,
        posRow: tile.posRow,
        lineName: tile.posRow,
        order: tile.order,
        isComplete: tile.isComplete,
        color: tile.color,
        version: tile.version,
        isConfirmed: tile.isConfirmed,
        status: tile.status,
        detail001: displayDetail[0] ? displayDetail[0].value : null,
        detail002: displayDetail[1] ? displayDetail[1].value : null,
        detail003: displayDetail[2] ? displayDetail[2].value : null,
        detail004: displayDetail[3] ? displayDetail[3].value : null,
        detail005: displayDetail[4] ? displayDetail[4].value : null,
        detail006: displayDetail[5] ? displayDetail[5].value : null,
        detail007: displayDetail[6] ? displayDetail[6].value : null,
        detail008: displayDetail[7] ? displayDetail[7].value : null,
        detail009: displayDetail[8] ? displayDetail[8].value : null,
        detailAdd: detailAdd ? detailAdd.value : null,
        createdAt: tile.createdAt
      });
    }
    return inCompleteItems;
  },
  /**
   * 計画済みエリアのカード枚数/負荷情報を整形して返す
   * @param {String} startDate -取得したい範囲の初めの日付
   * @param {String} endDate - 取得したい日付の終わりの日付
   * @returns {Object} planTileList - 整形後のカード枚数/負荷情報
   */
  getTileLoadData: () => (startDate, endDate) => {
    let tileLoadData = state.tileLoadData;

    let setting = rootState.state.settingData;
    if (!Util.isExistSetting(setting)) { return []; }

    // マッピングデータを取得
    let mappingData = setting.mapping;
    let computeMappingdata = mappingData.find(e => e.isCompute === true);

    // カレンダー設定情報を取得
    let calendarSetting = state.calendarSettingData;

    // エリア配列
    let al = Util.parseObject(setting.area);
    if (al.length) {
      al.sort(function(a, b) {
        if (a.order < b.order) return -1;
        if (a.order > b.order) return 1;
        return 0;
      });
    }
    // エリア配列の先頭に未計画を追加
    al.unshift({ areaId: null, name: "", max: 0 });

    // 日付配列
    let dl = [];
    let df = Define.DATE_FORMAT_DAY_HYPHEN;
    let s = moment(startDate).format(df);
    let e = moment(endDate).format(df);
    for (let date = s; date <= e; date = moment(date).add(1, "days").format(df)) {
      dl.push(date);
    }

    // 結果データの整形
    let tileLoadList = [];
    for (let i = 0; i < al.length; i++) {
      // エリアの表示順が未設定であれば成形対象外
      if (!Util.checkNotEmpty(al[i].order)) { continue; }

      let tmpAreaList = {
        lineId: al[i].areaId,
        lineName: al[i].name,
        max: al[i].max,
        progressbarFlg: al[i].max !== 0 && computeMappingdata !== undefined,
        tileLoadList: []
      };

      let tmpTileLoadDataByArea = tileLoadData.filter(e => e.posRow == al[i].areaId);
      let calendarSettingByArea = calendarSetting.filter(e => e.posRow == al[i].areaId);
      for (let j = 0; j < dl.length; j++) {
        let tmpTileLoadDataByDate = tmpTileLoadDataByArea.filter(e => e.posCol == dl[j]);
        let tmpDateList = {
          dateInfo: dl[j],
          num: 0,
          sum: 0,
          progress: 0,
          calendarMax: null
        };
        tmpDateList.num = tmpTileLoadDataByDate.length;
        tmpDateList.sum = tmpTileLoadDataByDate.map(item => item.load).reduce((x, y) => x + y, 0);
        let calendarSettingByDate = calendarSettingByArea.filter(e => e.posCol == dl[j]);
        // カレンダー設定で対象枠に負荷上限の設定があれば、カレンダー設定の負荷上限を使用して負荷を計算する
        if (calendarSettingByDate.length !== 0) {
          tmpDateList.calendarMax = parseInt(calendarSettingByDate[0].max);
          tmpDateList.progress = Util.calcProgress(tmpDateList.sum, parseInt(calendarSettingByDate[0].max));
        }
        // カレンダー設定がなかれば、対象計画エリアの負荷上限を使用して負荷を計算する
        else {
          tmpDateList.progress = Util.calcProgress(tmpDateList.sum, al[i].max);
        }
        tmpAreaList.tileLoadList.push(tmpDateList);
      }
      tileLoadList.push(tmpAreaList);
    }
    return tileLoadList;
  }
};

const actions = {
  /**
   * [WS通信-送信] カード作成
   */
  createTileData(state, data) {
    const tileData = data.tileData;
    const socket = rootState.state.socket;
    if (!socket) {
      Swal.fire(SwalMsg.WS_NOT_CONNECT_ERR);
      return false;
    }
    socket.sendObj({
      type: Define.WS_CODE_CREATE_TILE,
      tile_data: tileData,
      work_space_id: state.rootState.auth.user.workSpaceId || null
    });
  },

  /**
   * [WS通信-送信] カード更新(カード移動)
   */
  moveUpdateTile(state, tileData) {
    const socket = rootState.state.socket;
    if (!socket) {
      Swal.fire(SwalMsg.WS_NOT_CONNECT_ERR);
      return false;
    }
    socket.sendObj({
      type: Define.WS_CODE_MOVE_TILE,
      tile_data: tileData,
      work_space_id: state.rootState.auth.user.workSpaceId || null
    });
  },

  /**
   * [WS通信-送信] カード更新(カード詳細)
   */
  detailUpdateTile(state, data) {
    const tileData = data.tileData;
    const socket = rootState.state.socket;
    if (!socket) {
      Swal.fire(SwalMsg.WS_NOT_CONNECT_ERR);
      return false;
    }
    socket.sendObj({
      type: Define.WS_CODE_TILE_DETAIL_UPDATE,
      tile_data: tileData,
      work_space_id: state.rootState.auth.user.workSpaceId || null
    });
  },

  /**
   * [WS通信-送信] カード削除
   */
  deleteTile(state, tileData) {
    const socket = rootState.state.socket;
    if (!socket) {
      Swal.fire(SwalMsg.WS_NOT_CONNECT_ERR);
      return false;
    }
    socket.sendObj({
      type: Define.WS_CODE_DELETE_TILE,
      tile_data: tileData,
      work_space_id: state.rootState.auth.user.workSpaceId || null
    });
  },

  /**
   * [WS通信-送信] ファイル取り込み
   */
  importCsvData(state, data) {
    const tileData = data.tileData;
    const isFixedPos= data.isFixedPos;
    const prosessingMethodSameDataExists = data.prosessingMethodSameDataExists;
    const updateCardConfirmedStatus = data.updateCardConfirmedStatus;

    const socket = rootState.state.socket;
    if (!socket) {
      Swal.fire(SwalMsg.WS_NOT_CONNECT_ERR);
      return false;
    }
    socket.sendObj({
      type: Define.WS_CODE_IMPORT_CSV,
      tile_data: tileData,
      work_space_id: state.rootState.auth.user.workSpaceId || null,
      isFixedPos: isFixedPos,
      prosessingMethodSameDataExists: prosessingMethodSameDataExists,
      updateCardConfirmedStatus: updateCardConfirmedStatus
    });
  },

  /**
   * [WS通信-送信] カード取得(日付変更)
   */
  getTileDataByChangeDate(state, date) {
    const socket = rootState.state.socket;
    if (!socket) {
      Swal.fire(SwalMsg.WS_NOT_CONNECT_ERR);
      return false;
    }
    socket.sendObj({
      type: Define.WS_CODE_CHANGE_DATE,
      date: date,
      work_space_id: state.rootState.auth.user.workSpaceId || null,
      days_diff: state.rootState.display.displayDays - 1 || 6
    });
  },

  /**
   * [WS通信-送信] カード履歴取得
   */
  getTileMoveHistoryData(state, tileId) {
    const socket = rootState.state.socket;
    if (!socket) {
      Swal.fire(SwalMsg.WS_NOT_CONNECT_ERR);
      return false;
    }
    socket.sendObj({
      type: Define.WS_CODE_GET_HISTORY_DATA,
      tileId: tileId,
      work_space_id: state.rootState.auth.user.workSpaceId || null
    });
  },

  /**
   * [WS通信-送信] カード詳細変更履歴取得
   */
  getTileDetailChangeHistoryData(state, moveHistoryId) {
    const socket = rootState.state.socket;
    if (!socket) {
      Swal.fire(SwalMsg.WS_NOT_CONNECT_ERR);
      return false;
    }
    socket.sendObj({
      type: Define.WS_CODE_GET_DETAIL_CHANGE_DATA,
      moveHistoryId: moveHistoryId,
      work_space_id: state.rootState.auth.user.workSpaceId || null
    });
  },

  /**
   * [WS通信-送信] カード詳細取得
   */
  getTileDetailData(state, tileId) {
    const socket = rootState.state.socket;
    if (!socket) {
      Swal.fire(SwalMsg.WS_NOT_CONNECT_ERR);
      return false;
    }
    socket.sendObj({
      type: Define.WS_CODE_GET_DETAIL_DATA,
      tileId: tileId,
      work_space_id: state.rootState.auth.user.workSpaceId || null
    });
  },

  /**
   * [WS通信-送信] カード実績取得
   */
  jissekiGet(state, tileId) {
    const socket = rootState.state.socket;
    if (!socket) {
      Swal.fire(SwalMsg.WS_NOT_CONNECT_ERR);
      return false;
    }
    socket.sendObj({
      type: Define.WS_CODE_JISSEKI_GET,
      tileId: tileId,
      work_space_id: state.rootState.auth.user.workSpaceId || null
    });
  },

  /**
   * [WS通信-送信] カード実績作成/更新
   */
  jissekiUpdate(state, data) {
    const tileData = data.tileData;
    const jissekiData = data.jissekiData;

    const socket = rootState.state.socket;
    if (!socket) {
      Swal.fire(SwalMsg.WS_NOT_CONNECT_ERR);
      return false;
    }
    socket.sendObj({
      type: Define.WS_CODE_JISSEKI_UPDATE,
      tile_data: tileData,
      jisseki_data: jissekiData,
      work_space_id: state.rootState.auth.user.workSpaceId || null
    });
  },

  /**
   * [WS通信-送信] カード実績削除
   */
  jissekiDelete(state, data) {
    const tileData = data.tileData;
    const groupId = data.groupId;

    const socket = rootState.state.socket;
    if (!socket) {
      Swal.fire(SwalMsg.WS_NOT_CONNECT_ERR);
      return false;
    }
    socket.sendObj({
      type: Define.WS_CODE_JISSEKI_DELETE,
      tile_data: tileData,
      group_id: groupId,
      work_space_id: state.rootState.auth.user.workSpaceId || null
    });
  },

  /**
   * [WS通信-送信] 過去30日分の未完了カード取得
   */
  serchCard(state, condition) {
    const socket = rootState.state.socket;
    if (!socket) {
      Swal.fire(SwalMsg.WS_NOT_CONNECT_ERR);
      return false;
    }
    socket.sendObj({
      type: Define.WS_CODE_GET_SEARCH_TILE_DATA,
      work_space_id: state.rootState.auth.user.workSpaceId || null,
      condition: condition
    });
  },
  /**
   * [WS通信-送信] カード枚数/負荷情報のリストを取得
   */
  getTileLoadData(state, condition) {
    const socket = rootState.state.socket;
    if (!socket) {
      Swal.fire(SwalMsg.WS_NOT_CONNECT_ERR);
      return false;
    }
    socket.sendObj({
      type: Define.WS_CODE_GET_TILE_LOAD_LIST,
      start_date: condition.startDate,
      end_date: condition.endDate,
      work_space_id: state.rootState.auth.user.workSpaceId || null
    });
  },
};

const mutations = {
  initState(state) {
    state.tileData = [];
    state.tileMoveHistoryData = [];
    state.tileDetailChangeHistoryData = [];
    state.tileDetailData = [];
    state.freeTileListTemplate = JSON.parse(JSON.stringify(Tile.FREE_TILELIST_TEMPLATE));
    state.pageNo = 1;
    state.pageNum = 1;
    state.isOpenCardDetail = false;
    state.isOpenMobileCardDetail = false;
    state.cardDetailInfo = JSON.parse(JSON.stringify(Tile.CARD_DETAIL_TEMPLATE));
    state.isOpenCardDetailMultipleCopy = false;
    state.copyMultipleCardFileList = [];
    state.isAddCard = false;
    state.isOpenCardSearch = false;
    state.isOpenMonthView = false;
    state.cardJissekiInfo = [];
    state.inCompleteTileData = [];
    state.tileLoadData = [];
    state.calendarSettingData = [];
  },
  setTileMoveHistory(state, { historyData }) {
    state.tileMoveHistoryData = camelcaseKeys(historyData, { deep: true });
  },
  setTileDetailChangeHistory(state, { detailData }) {
    state.tileDetailChangeHistoryData = camelcaseKeys(detailData, { deep: true }); 
  },
  setPageNo(state, val) {
    state.pageNo = val;
  },
  setPageNum(state, val) {
    state.pageNum = val;
  },
  setIsOpenCardDetail(state, val) {
    state.isOpenCardDetail = val;
  },
  setIsOpenMobileCardDetail(state, val) {
    state.isOpenMobileCardDetail = val;
  },
  setCardDetailInfo(state, val) {
    state.cardDetailInfo = val;
  },
  setIsOpenCardDetailMultipleCopy(state, val) {
    state.isOpenCardDetailMultipleCopy = val;
  },
  setcopyMultipleCardFileList(state, val) {
    state.copyMultipleCardFileList = val;
  },
  setIsAddCard(state, val) {
    state.isAddCard = val;
  },
  setIsOpenCardSearch(state, val) {
    state.isOpenCardSearch = val;
  },
  setIsOpenMonthView(state, val) {
    state.isOpenMonthView = val;
  },
  setTileDetail(state, { detailData }) {
    state.tileDetailData = camelcaseKeys(detailData, { deep: true });
  },
  setCardJissekiInfo(state, { jissekiData }) {
    let jissekis = camelcaseKeys(jissekiData, { deep: true });
    jissekis = jissekis.length > 0 ? Util.groupBy(jissekis, "groupId") : {};

    let items = [];
    for (const key in jissekis) {
      let item = {};
      item["groupId"] = key;
      let createdAts = jissekis[key].map(d => moment(d.createdAt));
      let latest = moment.max(createdAts);
      item["createdAt"] = latest.valueOf();
      item["createdAtStr"] = latest.format(Define.DATE_FORMAT_DAY_MIN);
      for (const jisseki of jissekis[key]) {
        item[jisseki.mappingId] = jisseki.value;
      }
      items.push(item);
    }
    state.cardJissekiInfo = items;
  },
  setInCompleteTileData(state, { dataObj }) {
    let tmpdataObj = camelcaseKeys(dataObj, { deep: true });
    state.inCompleteTileData = tmpdataObj.tileData;
  },
  setTileLoadData(state, { tileLoadData, calendarSettingData }) {
    state.tileLoadData = tileLoadData;
    state.calendarSettingData = camelcaseKeys(calendarSettingData, { deep: true });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
