var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "" },
      model: {
        value: _vm.isOpenCardDetailMultipleCopy,
        callback: function($$v) {
          _vm.isOpenCardDetailMultipleCopy = $$v
        },
        expression: "isOpenCardDetailMultipleCopy"
      }
    },
    [
      _c(
        "v-form",
        { ref: "form", staticClass: "overlay carddetailmultiplecopy" },
        [
          _c(
            "div",
            { staticClass: "overlayarea" },
            [
              _c(
                "v-card",
                {
                  staticClass: "cardsize",
                  attrs: { flat: "", color: "#f0f0f0" }
                },
                [
                  _c(
                    "div",
                    { staticClass: "text-right" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: { click: _vm.closeMultipleDetail }
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      )
                    ],
                    1
                  ),
                  _c("div", { staticClass: "subtitle" }, [
                    _vm._v(_vm._s(_vm.$t("PAGE_TITLE_CARD_MULTIPLE")))
                  ]),
                  _c(
                    "div",
                    { staticClass: "datatable" },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "datawrap areamargin",
                          attrs: { flat: "" }
                        },
                        [
                          _c("v-data-table", {
                            attrs: {
                              headers: _vm.headers,
                              items: _vm.items,
                              "hide-default-footer": ""
                            },
                            scopedSlots: _vm._u(
                              [
                                _vm._l(_vm.headers, function(data, id) {
                                  return {
                                    key: "header." + data.value,
                                    fn: function(ref) {
                                      var header = ref.header
                                      return [
                                        _vm._v(" " + _vm._s(header.text) + " "),
                                        _vm.isKeySettings(header.mappingId)
                                          ? _c(
                                              "v-icon",
                                              {
                                                key: id,
                                                style: { color: "red" },
                                                attrs: { small: "" }
                                              },
                                              [_vm._v(" * ")]
                                            )
                                          : _vm._e()
                                      ]
                                    }
                                  }
                                }),
                                _vm._l(_vm.headers, function(data, id) {
                                  return {
                                    key: "item." + data.value,
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c("EditingItem", {
                                          key: id,
                                          attrs: {
                                            type: _vm.getType(data.mappingId),
                                            inputType: _vm.getInputType(
                                              data.mappingId
                                            ),
                                            rules: _vm.rules(data.mappingId),
                                            items: _vm.listDataItems(
                                              _vm.getCategory(data.mappingId),
                                              item[data.value]
                                            ),
                                            editable:
                                              _vm.getType(data.mappingId) !==
                                              _vm.TYPE_CALC
                                          },
                                          model: {
                                            value: item[data.value],
                                            callback: function($$v) {
                                              _vm.$set(item, data.value, $$v)
                                            },
                                            expression: "item[data.value]"
                                          }
                                        })
                                      ]
                                    }
                                  }
                                }),
                                {
                                  key: "item.num",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [_vm._v(_vm._s(item.num))]
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "item.color",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c(
                                        "div",
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              dense: "",
                                              outlined: "",
                                              "hide-details": "auto",
                                              items: _vm.colors,
                                              "item-value": "color",
                                              "item-text": "label",
                                              rules: []
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "selection",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c("div", {
                                                        style: _vm.colorBoxlStyle(
                                                          item.color
                                                        )
                                                      }),
                                                      _c(
                                                        "span",
                                                        { staticClass: "pl-2" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.label)
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                },
                                                {
                                                  key: "item",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c("div", {
                                                        style: _vm.colorBoxlStyle(
                                                          item.color
                                                        )
                                                      }),
                                                      _c(
                                                        "span",
                                                        { staticClass: "pl-2" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.label)
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            ),
                                            model: {
                                              value: item.color,
                                              callback: function($$v) {
                                                _vm.$set(item, "color", $$v)
                                              },
                                              expression: "item.color"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "header.filecheck",
                                  fn: function(ref) {
                                    var header = ref.header
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "filecheck-header" },
                                        [
                                          _c(
                                            "span",
                                            { staticStyle: { flex: "6" } },
                                            [
                                              _vm._v(
                                                " " + _vm._s(header.text) + " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              staticStyle: { flex: "1" },
                                              attrs: {
                                                icon: "",
                                                "x-small": ""
                                              },
                                              on: { click: _vm.oneTimeCheck }
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v(
                                                  "mdi-checkbox-multiple-marked"
                                                )
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "mr-4",
                                              staticStyle: { flex: "1" },
                                              attrs: {
                                                icon: "",
                                                "x-small": ""
                                              },
                                              on: { click: _vm.oneTimeUncheck }
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v(
                                                  "mdi-checkbox-multiple-blank-outline"
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "item.filecheck",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "filecheck-item" },
                                        _vm._l(_vm.fileList, function(
                                          file,
                                          index
                                        ) {
                                          return _c(
                                            "div",
                                            {
                                              key: index,
                                              staticClass: "checkbox"
                                            },
                                            [
                                              file.name
                                                ? [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "checkbox-label"
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            "No." +
                                                              _vm._s(index + 1)
                                                          )
                                                        ])
                                                      ]
                                                    ),
                                                    _c("v-checkbox", {
                                                      attrs: { small: "" },
                                                      on: {
                                                        change: function(
                                                          $event
                                                        ) {
                                                          return _vm.checkbox(
                                                            item,
                                                            index
                                                          )
                                                        }
                                                      },
                                                      model: {
                                                        value:
                                                          item.filecheck[index],
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item.filecheck,
                                                            index,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.filecheck[index]"
                                                      }
                                                    })
                                                  ]
                                                : [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "checkbox-label"
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            "No." +
                                                              _vm._s(index + 1)
                                                          )
                                                        ])
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        staticClass:
                                                          "checkbox-inactive",
                                                        attrs: {
                                                          icon: "",
                                                          disabled: ""
                                                        }
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            "mdi-checkbox-blank-off-outline"
                                                          )
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  ]
                                            ],
                                            2
                                          )
                                        }),
                                        0
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "header.delete",
                                  fn: function() {
                                    return [_c("div", { staticClass: "mr-10" })]
                                  },
                                  proxy: true
                                },
                                {
                                  key: "item.delete",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _vm.items.length > 1
                                        ? _c(
                                            "v-icon",
                                            {
                                              attrs: { small: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.deleteRow(item)
                                                }
                                              }
                                            },
                                            [_vm._v(" mdi-close ")]
                                          )
                                        : _vm._e()
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          }),
                          _c(
                            "div",
                            { staticClass: "text-right addbtnarea" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "btn",
                                  attrs: { outlined: "" },
                                  on: { click: _vm.addRow }
                                },
                                [_vm._v(_vm._s(_vm.$t("WORD_ADD")))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "text-right createbtnarea" },
                    [
                      _c(
                        "v-btn",
                        {
                          directives: [
                            {
                              name: "shortkey",
                              rawName: "v-shortkey.once",
                              value: ["shift", "s"],
                              expression: "['shift', 's']",
                              modifiers: { once: true }
                            }
                          ],
                          staticClass: "btn",
                          attrs: { outlined: "" },
                          on: {
                            click: _vm.createCard,
                            shortkey: function($event) {
                              return _vm.keyCreate()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("WORD_CREATE")))]
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }