<template>
  <div>
    <v-dialog v-model="dialog" width="800px" persistent>
      <v-card width="800px" class="mx-auto">
        <v-card-title>{{ $t("PAGE_TITLE_USER_INFO_CHANGE") }}</v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-text-field
              v-model="username"
              placeholder=" "
              outlined
              :label="$t('WORD_USERNAME')"
              :rules="[required, max_length]"
              counter="256"
            >
            </v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn width="25%" class="info" @click="submit">{{ $t("WORD_CHANGE") }}</v-btn>
          <v-btn width="25%" class="" @click="closeDialog">{{ $t("WORD_CANCEL") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Define from "@/define.js";
import Swal from "sweetalert2";
import { mixinValidator } from "@/mixins/mixinValidator.js";
export default {
  name: "UserChangeDialog",
  props: {
    dialog: Boolean
  },
  mixins: [mixinValidator],
  data: function() {
    return {
      // 入力項目
      username: "",

      // 入力規則
      required: value => this.isValidRequired(value),
      max_length: value => this.isValidLength(value, null, Define.LENGTH_MAX_STRING)
    };
  },
  watch: {
    dialog: function() {
      if (this.$refs.form) this.$refs.form.reset();
    }
  },
  methods: {
    /**
     * 入力情報をAPIに伝搬する
     */
    submit() {
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch("auth/updateUserName", {
            username: this.username
          })
          .then(() => {
            this.closeDialog();
          });
      } else {
        Swal.fire({
          icon: "error",
          title: this.$t("MSG_ERR_MAIN_INPUT"),
          text: this.$t("MSG_ERR_SUB_INPUT")
        });
      }
    },
    /**
     * クローズイベントを親部品に通知する
     */
    closeDialog() {
      this.$refs.form.reset();
      this.$emit("close");
    }
  }
};
</script>

<style scoped></style>
