var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "600px", persistent: "" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        {
          staticClass: "mx-auto",
          attrs: { flat: "", color: "#f0f0f0", width: "600px" }
        },
        [
          _c(
            "div",
            { staticClass: "text-right" },
            [
              _c("v-icon", { on: { click: _vm.closeDialog } }, [
                _vm._v("mdi-close")
              ])
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c("v-form", { ref: "form" }, [
                _c(
                  "div",
                  { staticClass: "pl-8 pr-4" },
                  [
                    _c(
                      "v-row",
                      { staticClass: "pt-7", attrs: { align: "center" } },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "dispdetail1 font-weight-bold dispfont pl-3"
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("WORD_DATE")) + " ")]
                        ),
                        _c(
                          "div",
                          { staticClass: "dispdetail2 pr-5" },
                          [
                            _c("EditingItem", {
                              attrs: {
                                type: _vm.TYPE_DATE,
                                bgcolor: "#fff",
                                rules: [],
                                hideDetails: true
                              },
                              on: {
                                input: function($event) {
                                  return _vm.changeDate($event)
                                }
                              },
                              model: {
                                value: _vm.posCol,
                                callback: function($$v) {
                                  _vm.posCol = $$v
                                },
                                expression: "posCol"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "v-row",
                      { staticClass: "pt-6", attrs: { align: "center" } },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "dispdetail1 font-weight-bold dispfont pl-3"
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("WORD_LINENAME")) + " ")]
                        ),
                        _c(
                          "div",
                          { staticClass: "dispdetail2 pr-5" },
                          [
                            _c("v-select", {
                              attrs: {
                                dense: "",
                                outlined: "",
                                "hide-details": "",
                                "background-color": "#fff",
                                items: _vm.areaItems
                              },
                              model: {
                                value: _vm.posRow,
                                callback: function($$v) {
                                  _vm.posRow = $$v
                                },
                                expression: "posRow"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                { staticClass: "info ml-auto", on: { click: _vm.confirm } },
                [_vm._v(_vm._s(_vm.$t("WORD_MOVE_CARD")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }