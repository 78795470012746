var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      [
        _c("label", [
          _vm._v(_vm._s(_vm.$t("PAGE_SUBTITLE_INPUT_FILE_SETTING_HEADER")))
        ]),
        _c(
          "v-radio-group",
          {
            staticClass: "mt-1 ml-2",
            attrs: {
              disabled: _vm.userRole,
              row: "",
              dense: "",
              "hide-details": ""
            },
            model: {
              value: _vm.importSetting.header,
              callback: function($$v) {
                _vm.$set(_vm.importSetting, "header", $$v)
              },
              expression: "importSetting.header"
            }
          },
          [
            _c("v-radio", {
              attrs: {
                label: _vm.$t("WORD_INPUT_FILE_SETTING_HEADER_SET"),
                value: _vm.IMPORT_SETTING_HEADER_WITH
              }
            }),
            _c("v-radio", {
              attrs: {
                label: _vm.$t("WORD_INPUT_FILE_SETTING_HEADER_NOT"),
                value: _vm.IMPORT_SETTING_HEADER_WITHOUT
              }
            })
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "pt-5" },
      [
        _c("label", [
          _vm._v(_vm._s(_vm.$t("PAGE_SUBTITLE_INPUT_FILE_SETTING_QUOTATION")))
        ]),
        _c(
          "v-radio-group",
          {
            staticClass: "mt-1 ml-2",
            attrs: {
              disabled: _vm.userRole,
              row: "",
              dense: "",
              "hide-details": ""
            },
            model: {
              value: _vm.importSetting.character,
              callback: function($$v) {
                _vm.$set(_vm.importSetting, "character", $$v)
              },
              expression: "importSetting.character"
            }
          },
          [
            _c("v-radio", {
              attrs: {
                label: _vm.$t("WORD_INPUT_FILE_SETTING_QUOTATION_DOUBLE"),
                value: _vm.IMPORT_SETTING_CHARACTER_DOUBLE
              }
            }),
            _c("v-radio", {
              attrs: {
                label: _vm.$t("WORD_INPUT_FILE_SETTING_QUOTATION_SINGLE"),
                value: _vm.IMPORT_SETTING_CHARACTER_SINGLE
              }
            })
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "pt-5" },
      [
        _c("label", { staticClass: "display_inline" }, [
          _vm._v(_vm._s(_vm.$t("PAGE_SUBTITLE_INPUT_FILE_SETTING_POSITION")))
        ]),
        _c(
          "v-row",
          { attrs: { dense: "" } },
          [
            _c(
              "v-col",
              { staticClass: "mt-0 pt-0", attrs: { cols: "2" } },
              [
                _c(
                  "v-radio-group",
                  {
                    staticClass: "mt-1 ml-2",
                    attrs: {
                      disabled: _vm.userRole,
                      row: "",
                      dense: "",
                      "hide-details": ""
                    },
                    model: {
                      value: _vm.importSetting.location,
                      callback: function($$v) {
                        _vm.$set(_vm.importSetting, "location", $$v)
                      },
                      expression: "importSetting.location"
                    }
                  },
                  [
                    _c("v-radio", {
                      attrs: {
                        label: _vm.$t(
                          "WORD_INPUT_FILE_SETTING_POSTION_UNPLANNED"
                        ),
                        value: _vm.IMPORT_SETTING_LOCATION_NOPLAN
                      }
                    }),
                    _c("v-radio", {
                      attrs: {
                        label: _vm.$t("WORD_INPUT_FILE_SETTING_POSTION_ASSIGN"),
                        value: _vm.IMPORT_SETTING_LOCATION_ABSOLUTE
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _vm.hasKeySettings &&
            _vm.importSetting.prosessingMethodSameDataExists ==
              _vm.IMPORT_SETTING_SAME_KEY_UPDATE
              ? _c(
                  "v-col",
                  { staticClass: "mt-0 pt-0", attrs: { cols: "10" } },
                  [
                    _c("v-checkbox", {
                      staticClass: "mt-1",
                      attrs: {
                        label: _vm.$t(
                          "PAGE_SUBTITLE_INPUT_FILE_SETTING_FIXPOS"
                        ),
                        disabled: _vm.userRole,
                        "hide-details": ""
                      },
                      model: {
                        value: _vm.importSetting.isFixedPos,
                        callback: function($$v) {
                          _vm.$set(_vm.importSetting, "isFixedPos", $$v)
                        },
                        expression: "importSetting.isFixedPos"
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        ),
        _c("div", { staticClass: "d-flex flex-column" }, [
          _vm.importSetting.location == _vm.IMPORT_SETTING_LOCATION_ABSOLUTE
            ? _c("div", [
                _c("div", { staticClass: "d-flex flex-row pl-9" }, [
                  _c(
                    "div",
                    {
                      staticClass: "poslabel",
                      style: { width: "350px", "padding-top": "13px" }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t("PAGE_SUBTITLE_INPUT_FILE_SETTING_COL_DATE")
                        )
                      )
                    ]
                  ),
                  _c(
                    "div",
                    [
                      _c("v-select", {
                        staticClass: "pt-0 mt-0",
                        attrs: {
                          disabled: _vm.userRole,
                          items: _vm.listColNum,
                          "hide-details": ""
                        },
                        model: {
                          value: _vm.importSetting.absoluteCol,
                          callback: function($$v) {
                            _vm.$set(_vm.importSetting, "absoluteCol", $$v)
                          },
                          expression: "importSetting.absoluteCol"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "d-flex flex-row pl-9" }, [
                  _c(
                    "div",
                    {
                      staticClass: "poslabel",
                      style: { width: "350px", "padding-top": "13px" }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "PAGE_SUBTITLE_INPUT_FILE_SETTING_COL_PLANNED_AREA"
                          )
                        )
                      )
                    ]
                  ),
                  _c(
                    "div",
                    [
                      _c("v-select", {
                        staticClass: "pt-0 mt-0",
                        attrs: {
                          disabled: _vm.userRole,
                          items: _vm.listColNum,
                          "hide-details": ""
                        },
                        model: {
                          value: _vm.importSetting.absoluteRow,
                          callback: function($$v) {
                            _vm.$set(_vm.importSetting, "absoluteRow", $$v)
                          },
                          expression: "importSetting.absoluteRow"
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            : _vm._e()
        ])
      ],
      1
    ),
    _vm.hasKeySettings
      ? _c(
          "div",
          { staticClass: "pt-5" },
          [
            _c("label", [
              _vm._v(
                _vm._s(_vm.$t("PAGE_SUBTITLE_INPUT_FILE_SETTING_SAME_KEY"))
              )
            ]),
            _c(
              "v-radio-group",
              {
                staticClass: "mt-1 ml-2",
                attrs: {
                  disabled: _vm.userRole,
                  row: "",
                  dense: "",
                  "hide-details": ""
                },
                model: {
                  value: _vm.importSetting.prosessingMethodSameDataExists,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.importSetting,
                      "prosessingMethodSameDataExists",
                      $$v
                    )
                  },
                  expression: "importSetting.prosessingMethodSameDataExists"
                }
              },
              [
                _c("v-radio", {
                  attrs: {
                    label: _vm.$t("WORD_INPUT_FILE_SETTING_SAME_KEY_UPDATE"),
                    value: _vm.IMPORT_SETTING_SAME_KEY_UPDATE
                  }
                }),
                _c("v-radio", {
                  attrs: {
                    label: _vm.$t("WORD_INPUT_FILE_SETTING_SAME_KEY_ERROR"),
                    value: _vm.IMPORT_SETTING_SAME_KEY_ERROR
                  }
                }),
                _c("v-radio", {
                  attrs: {
                    label: _vm.$t("WORD_INPUT_FILE_SETTING_SAME_KEY_SKIP"),
                    value: _vm.IMPORT_SETTING_SAME_KEY_SKIP
                  }
                })
              ],
              1
            )
          ],
          1
        )
      : _vm._e(),
    _vm.option002 && _vm.isSelectedSameCardUpdate
      ? _c(
          "div",
          { staticClass: "pt-5" },
          [
            _c("label", [
              _vm._v(
                _vm._s(
                  _vm.$t("PAGE_SUBTITLE_INPUT_FILE_SETTING_UPDATE_CONFIRM")
                )
              )
            ]),
            _c(
              "v-radio-group",
              {
                staticClass: "mt-1 ml-2",
                attrs: {
                  disabled: _vm.userRole,
                  row: "",
                  dense: "",
                  "hide-details": ""
                },
                model: {
                  value: _vm.importSetting.updateCardConfirmedStatus,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.importSetting,
                      "updateCardConfirmedStatus",
                      $$v
                    )
                  },
                  expression: "importSetting.updateCardConfirmedStatus"
                }
              },
              [
                _c("v-radio", {
                  attrs: {
                    label: _vm.$t(
                      "WORD_INPUT_FILE_SETTING_UPDATE_CONFIRM_UNCONFIRM"
                    ),
                    value: _vm.IMPORT_SETTING_UPDATE_CONFIRM_UNCONFIRM
                  }
                }),
                _c("v-radio", {
                  attrs: {
                    label: _vm.$t(
                      "WORD_INPUT_FILE_SETTING_UPDATE_CONFIRM_KEEP"
                    ),
                    value: _vm.IMPORT_SETTING_UPDATE_CONFIRM_KEEP
                  }
                })
              ],
              1
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }