import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import SetInterval from "@/plugins/SetInterval";
import VueI18n from "vue-i18n";
Vue.use(SetInterval);
import uuid from "vue-uuid";
Vue.use(uuid);
import shortkey from "vue-shortkey";
Vue.use(shortkey, {prevent: ['input']})

Vue.config.productionTip = false;

// タイトル設定
document.title = process.env.VUE_APP_TITLE;

// axiosをプロトタイプに追加
Vue.prototype.$axios = axios;

// RestAPIのベースURL設定
axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;

Vue.use(VueI18n);
export const i18n = new VueI18n({
  locale: "ja",
  messages: {
    ja: require("./lang/ja.json"),
    en: require("./lang/en.json")
  }
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");
