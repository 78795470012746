var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pagestemplate" }, [
    _c("div", { staticClass: "mx-3 my-3" }, [
      _c(
        "div",
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _c("v-icon", { attrs: { large: "", left: "" } }, [
                    _vm._v("mdi-developer-board")
                  ]),
                  _c("span", { staticClass: "text-h6 font-weight-light" }, [
                    _vm._v(_vm._s(_vm.$t("PAGE_SUBTITLE_CARD_SETTING_BOARD")))
                  ])
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "justify-center" },
                [_c("SelectBoard")],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-2" },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _c("v-icon", { attrs: { large: "", left: "" } }, [
                    _vm._v("mdi-alpha-a-box-outline")
                  ]),
                  _c("span", { staticClass: "text-h6 font-weight-light" }, [
                    _vm._v(_vm._s(_vm.$t("PAGE_SUBTITLE_CARD_SETTING_LUNG")))
                  ])
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "justify-center" },
                [_c("SelectLunguage")],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-2" },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _c("v-icon", { attrs: { large: "", left: "" } }, [
                    _vm._v("mdi-format-size")
                  ]),
                  _c("span", { staticClass: "text-h6 font-weight-light" }, [
                    _vm._v(_vm._s(_vm.$t("PAGE_SUBTITLE_CARD_SETTING_FONT")))
                  ])
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "justify-center" },
                [_c("SelectFontSize")],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }