var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { outlined: _vm.outlined, tile: "" } },
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c("v-col", { attrs: { align: "left", cols: "2" } }, [
                _vm._v(" " + _vm._s(_vm.$t("WORD_TOTAL")) + " ")
              ]),
              _c("v-col", { attrs: { align: "right", cols: "10" } }, [
                _vm._v(" " + _vm._s(_vm.title) + " ")
              ])
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                { staticClass: "pb-0", attrs: { align: "left", cols: "2" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "alabel",
                      attrs: {
                        color: "cyan darken-1",
                        dark: "",
                        tile: "",
                        elevation: "1"
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("WORD_YOTEI")))]
                  )
                ],
                1
              ),
              _c(
                "v-col",
                {
                  ref: "column",
                  staticClass: "pb-0",
                  attrs: { align: "right", cols: "10" }
                },
                [
                  _c("label", { staticStyle: { "font-size": "22px" } }, [
                    _vm._v(_vm._s(_vm.displayYotei))
                  ])
                ]
              )
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                { staticClass: "pb-0", attrs: { align: "left", cols: "2" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "alabel",
                      attrs: {
                        color: "pink darken-1",
                        dark: "",
                        tile: "",
                        elevation: "1"
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("WORD_JISSEKI")))]
                  )
                ],
                1
              ),
              _c(
                "v-col",
                {
                  ref: "column",
                  staticClass: "pb-0",
                  attrs: { align: "right", cols: "10" }
                },
                [
                  _c("label", { staticStyle: { "font-size": "22px" } }, [
                    _vm._v(_vm._s(_vm.displayJisseki))
                  ])
                ]
              )
            ],
            1
          ),
          _c(
            "v-progress-linear",
            {
              staticClass: "progressbarliner",
              attrs: {
                height: "15",
                color: "success",
                "background-color": "grey"
              },
              model: {
                value: _vm.progress,
                callback: function($$v) {
                  _vm.progress = $$v
                },
                expression: "progress"
              }
            },
            [_vm._v(" " + _vm._s(_vm.progress) + "% ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }