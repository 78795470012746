import { render, staticRenderFns } from "./TenantRegistPage.vue?vue&type=template&id=2a0aef8a&scoped=true&"
import script from "./TenantRegistPage.vue?vue&type=script&lang=js&"
export * from "./TenantRegistPage.vue?vue&type=script&lang=js&"
import style0 from "./TenantRegistPage.vue?vue&type=style&index=0&id=2a0aef8a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a0aef8a",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCol,VForm,VImg,VLayout,VRow,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("C:\\Freely\\repo_nck_plan_board\\nck_plan_board_frontend\\vue\\code\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2a0aef8a')) {
      api.createRecord('2a0aef8a', component.options)
    } else {
      api.reload('2a0aef8a', component.options)
    }
    module.hot.accept("./TenantRegistPage.vue?vue&type=template&id=2a0aef8a&scoped=true&", function () {
      api.rerender('2a0aef8a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/pages/TenantRegistPage.vue"
export default component.exports