<template>
  <div class="api">
    <div v-if="!isValidRole" class="attention">
      <v-icon color="error">mdi-alert</v-icon>
      {{ $t("TEXT_APPLICATION_ROLE") }}
    </div>
    <div>
      <ApplicationSettingApiFromOuterToFreely />
      <ApplicationSettingApiFromFreelyToOuter />
    </div>
  </div>
</template>

<script>
import Define from "@/define.js";
import * as Util from "@/util/utils.js";
import ApplicationSettingApiFromOuterToFreely from "@/components/parts/ApplicationSettingApiFromOuterToFreely";
import ApplicationSettingApiFromFreelyToOuter from "@/components/parts/ApplicationSettingApiFromFreelyToOuter";
export default {
  components: {
    ApplicationSettingApiFromOuterToFreely,
    ApplicationSettingApiFromFreelyToOuter
  },
  computed: {
    isValidRole() {
      let user = this.$store.getters["auth/user"];
      return Util.getAuthority(Define.EVENT_APPLICATION_SETTING, user.role);
    }
  }
};
</script>

<style scoped>
.api {
  margin: 5px;
  width: 1820px;
  min-height: 735px;
}
.attention {
  color: #ff5252;
  margin: 0 12px 24px 12px;
}
</style>
