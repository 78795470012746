<template>
  <div>
    <div v-if="!isValidRole" class="attention">
      <v-icon color="error">mdi-alert</v-icon>
      {{ $t("TEXT_APPLICATION_ROLE") }}
    </div>
    <v-form dence ref="form" v-model="valid">
      <!-- 自動入力 -->
      <div class="dataarea" v-if="loadingFlg && !isExistSetting">
        <div>
          <div class="areatitle">
            {{ $t("PAGE_SUBTITLE_APPLICATON_SETTING_AUTO") }}
          </div>
          <div class="ml-4">
            <div class="my-1">
              <lavel class="lbl-auto">
                {{ $t("MSG_AUTO_SETTING_1") }}
                <br> 
                {{ $t("MSG_AUTO_SETTING_2") }}
              </lavel>
            </div>
            <v-btn class="success mx-1 mb-1" :disabled="!isValidRole" @click="setSeizoTanto()">
              <v-icon right dark class="mr-2">mdi-human</v-icon>
              &nbsp;{{ $t("WORD_SEIZOU_TANTO") }}
            </v-btn>
            <v-btn class="success mx-1 mb-1" :disabled="!isValidRole" @click="setSeizoKikai()">
              <v-icon right dark class="mr-2">mdi-seat-outline</v-icon>
              &nbsp;{{ $t("WORD_SEIZOU_KIKAI") }}
            </v-btn>
            <v-btn class="error mx-1 mb-1" :disabled="!isValidRole" @click="setInit()">
              &nbsp;{{ $t("WORD_SEIZOU_RESET") }}
            </v-btn>
          </div>
        </div>
      </div>
      <!-- テンプレート -->
      <div class="cardselect">
        <div class="areatitle">
          {{ $t("PAGE_SUBTITLE_APPLICATON_SETTING_TEMPLATE") }}
          <HelpButton :helpobject="tempalteHelpDialogData"></HelpButton>
        </div>
        <div class="flexarea">
          <v-spacer></v-spacer>
          <v-radio-group v-model="settingObj.tileTempNum" row :disabled="!isValidRole">
            <!-- CardPattern01 -->
            <v-radio name="0"></v-radio>
            <ApplicationSettingCardPattern :pattern="0" @select="setPattern" />
            <!-- CardPattern02 -->
            <v-radio name="1"></v-radio>
            <ApplicationSettingCardPattern :pattern="1" @select="setPattern" />
            <!-- CardPattern03 -->
            <v-radio name="2"></v-radio>
            <ApplicationSettingCardPattern :pattern="2" @select="setPattern" />
            <!-- CardPattern04 -->
            <v-radio name="3"></v-radio>
            <ApplicationSettingCardPattern :pattern="3" @select="setPattern" />
          </v-radio-group>
          <v-spacer></v-spacer>
        </div>
      </div>
      <!-- データマッピング -->
      <div class="dataarea">
        <div class="areatitle">
          {{ $t("PAGE_SUBTITLE_APPLICATON_SETTING_DATA_MAPPING") }}
          <HelpButton :helpobject="mappingHelpDialogData"></HelpButton>
          <span class="required">
          {{ requiredWord }}
          </span>
        </div>
        <v-data-table
          class="tablearea"
          :headers="mappingTableHeaders"
          item-key="id"
          :items="settingObj.mappingDataList"
          :footer-props="TABLE_FOOTER_SETTING_MIDDLE"
        >
          <template v-if="isValidRole" v-slot:top>
            <span class="red--text" :style="{ 'white-space': 'pre-wrap' }">
              {{ warningDeleteMapping }}
            </span>
          </template>
          <!-- データ名称 -->
          <template v-slot:[`item.name`]="{ item }">
            <v-text-field
              v-model="item.name"
              dense
              outlined
              hide-details="auto"
              :disabled="!isValidRole"
              :rules="[check_required, check_string, check_percent]"
            ></v-text-field>
          </template>
          <!-- データ形式 -->
          <template v-slot:[`item.type`]="{ item }">
            <v-select
              v-model="item.type"
              dense
              outlined
              hide-details
              :disabled="!isValidRole || item.state !== 'add'"
              :items="listTypeItems"
              @change="resetByType(item)"
            ></v-select>
          </template>
          <!-- データ入力形式 -->
          <template v-slot:[`item.inputType`]="{ item }">
            <div style="display: flex;">
              <v-select
                v-model="item.inputType"
                dense
                outlined
                hide-details
                :disabled="!isValidRole"
                :items="listInputTypeItems(item.type)"
              ></v-select>
              <!-- カテゴリ -->
              <v-select
                v-if="isDisplayCategory(item.type, item.inputType)"
                v-model="item.category"
                dense
                outlined
                hide-details="auto"
                :disabled="!isValidRole"
                :items="listCategoryItems"
                :rules="[check_required]"
                :style="'width: 250px'"
                :class="'ml-1'"
                :label="$t('WORD_APPLICATION_SETTING_CATEGORY')"
                @click="clickSelectCategory"
              ></v-select>
              <!-- 最小値 -->
              <v-text-field
                v-if="isDisplayNumParam(item.type, item.inputType)"
                v-model="item.min"
                dense
                outlined
                hide-details="auto"
                :disabled="!isValidRole"
                :rules="minRules(item.type)"
                :style="'width: 140px'"
                :class="'ml-1'"
                :label="$t('WORD_APPLICATION_SETTING_MINNUM')"
              ></v-text-field>
              <!-- 最大値 -->
              <v-text-field
                v-if="isDisplayNumParam(item.type, item.inputType)"
                v-model="item.max"
                dense
                outlined
                hide-details="auto"
                :disabled="!isValidRole"
                :rules="maxRules(item.type, item.min)"
                :style="'width: 140px'"
                :class="'ml-1'"
                :label="$t('WORD_APPLICATION_SETTING_MAXNUM')"
              ></v-text-field>
              <!-- ステップ -->
              <v-select
                v-if="isDisplayNumParam(item.type, item.inputType)"
                v-model="item.step"
                dense
                outlined
                hide-details="auto"
                :disabled="!isValidRole"
                :items="listStepItems(item.type)"
                :rules="[check_required]"
                :style="'width: 140px'"
                :class="'ml-1'"
                :label="$t('WORD_APPLICATION_SETTING_STEP')"
              ></v-select>
              <!-- 計算対象1 -->
              <v-select
                v-if="isDisplayCalc(item.type, item.inputType)"
                v-model="item.calcObject1"
                dense
                outlined
                hide-details="auto"
                :disabled="!isValidRole || item.state !== 'add'"
                :items="listCalcObject1Items"
                :rules="[check_required]"
                :style="'width: 175px'"
                :class="'ml-1'"
                :label="$t('WORD_CALC_OBJECT1')"
                @change="resetByCalcObjact1(item)"
              ></v-select>
              <!-- 四則演算子 -->
              <v-select
                v-if="isDisplayCalc(item.type, item.inputType)"
                v-model="item.shisokuEnzanshi"
                dense
                outlined
                hide-details="auto"
                :disabled="!isValidRole || item.state !== 'add'"
                :items="listShisokuEnzanshiItems(getTypeFromMappingId(item.calcObject1))"
                :rules="[check_required]"
                :style="'width: 120px'"
                :class="'ml-1'"
                :label="$t('WORD_SHISOKU_ENZANSHI')"
              ></v-select> 
              <!-- 計算対象2 -->
              <v-select
                v-if="isDisplayCalc(item.type, item.inputType)"
                v-model="item.calcObject2"
                dense
                outlined
                hide-details="auto"
                :disabled="!isValidRole || item.state !== 'add'"
                :items="listCalcObject2Items(getTypeFromMappingId(item.calcObject1))"
                :rules="[check_required]"
                :style="'width: 175px'"
                :class="'ml-1'"
                :label="$t('WORD_CALC_OBJECT2')"   
            ></v-select>
            </div>
          </template>
          <!-- 取込CSV対応列 -->
          <template v-slot:[`item.column`]="{ item }">
            <v-select
              v-model="item.column"
              dense
              outlined
              hide-details
              :disabled="!isValidRole || item.type === TYPE_CALC"
              :items="listColumnItems"
            ></v-select>
          </template>
          <!-- テンプレート対応場所 -->
          <template v-slot:[`item.pos`]="{ item }">
            <v-select
              v-model="item.pos"
              dense
              outlined
              hide-details
              :disabled="!isValidRole"
              :items="listPosItems"
            ></v-select>
          </template>
          <!-- 実績入力 -->
          <template v-slot:[`item.jisseki`]="{ item }">
            <v-select
              v-model="item.jisseki"
              dense
              outlined
              hide-details
              :disabled="!isValidRole"
              :items="listJissekiItems"
            ></v-select>
          </template>
          <!-- 実績集計方法 -->
          <template v-slot:[`item.jissekicalc`]="{ item }">
            <v-select
              v-model="item.jissekicalc"
              dense
              outlined
              hide-details
              :disabled="!isValidRole || !isEditableJissekiCalc(item.type, item.jisseki, getTypeFromMappingId(item.calcObject1))"
              :items="listJissekiCalcItems"
            ></v-select>
          </template>
          <!-- キー -->
          <template v-slot:[`item.key`]="{ item }">
            <v-checkbox v-model="item.key" small :disabled="!isValidRole || item.type === TYPE_CALC"></v-checkbox>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-icon small :disabled="!isValidRole" @click="deleteMappingRow(item)">
              mdi-close
            </v-icon>
          </template>
        </v-data-table>
        <div class="flexarea">
          <v-spacer></v-spacer>
          <v-btn :disabled="!isValidRole" class="info" @click="addMappingList">
            {{ $t("WORD_ADD") }}
          </v-btn>
        </div>
      </div>

      <!-- 負荷計算データ -->
      <div class="dataarea">
        <div class="areatitle">
          {{ $t("PAGE_SUBTITLE_APPLICATON_SETTING_LOAD_CALCULATE") }}
          <HelpButton :helpobject="calcHelpDialogData"></HelpButton>
        </div>
        <v-select
          v-model="settingObj.calcProgressData"
          class="selecter"
          dense
          outlined
          hide-details
          :disabled="!isValidRole"
          :items="listComputeItems"
          item-value="mappingId"
          item-text="name"
        ></v-select>
      </div>

      <!-- 計画エリア設定 -->
      <div class="dataarea">
        <div class="areatitle">
          {{ $t("PAGE_SUBTITLE_APPLICATON_SETTING_PLANNED_AREA") }}
          <HelpButton :helpobject="areaHelpDialogData"></HelpButton>
          <span class="required">
          {{ requiredWord }}
          </span>
        </div>
        <v-data-table
          class="tablearea"
          :headers="areaTableHeaders"
          :items="settingObj.planAreaList"
          :footer-props="TABLE_FOOTER_SETTING_MIDDLE"
        >
          <template v-if="isValidRole" v-slot:top>
            <span class="red--text" :style="{ 'white-space': 'pre-wrap' }">
              {{ warningDeleteArea }}
            </span>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <v-text-field
              v-model="item.name"
              dense
              outlined
              hide-details="auto"
              :disabled="!isValidRole"
              :rules="[check_required, check_string, check_percent]"
            ></v-text-field>
          </template>
          <template v-slot:[`item.max`]="{ item }">
            <v-text-field
              v-model="item.max"
              dense
              outlined
              hide-details="auto"
              :disabled="!isValidRole"
              :rules="[check_progress_max]"
            ></v-text-field>
          </template>
          <template v-slot:[`item.order`]="{ item }">
            <v-select
              v-model="item.order"
              dense
              outlined
              hide-details
              :disabled="!isValidRole"
              :items="listOrderItems"
            ></v-select>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-icon small :disabled="!isValidRole" @click="deleteAreaRow(item)">
              mdi-close
            </v-icon>
          </template>
        </v-data-table>
        <div class="flexarea">
          <v-spacer></v-spacer>
          <v-btn :disabled="!isValidRole" class="info" @click="addAreaList">
            {{ $t("WORD_ADD") }}
          </v-btn>
        </div>
      </div>

      <!-- ファイル取り込み -->
      <v-expansion-panels class="dataarea" multiple>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <div class="areatitle">
              {{ $t("PAGE_SUBTITLE_APPLICATON_SETTING_INPUT_FILE") }}
              <HelpButton :helpobject="fileHelpDialogData"></HelpButton>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
          <div class="dataarea">
            <InputFileSetting
            :inputFileSetting="settingObj.inputFileSetting"
            :userRole="!isValidRole"
            :mappingDataList="settingObj.mappingDataList"
            @input="settingObj.inputFileSetting = $event"
            ></InputFileSetting>
          </div>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <div class="areatitle">
              {{ $t("PAGE_SUBTITLE_APPLICATON_SETTING_COLOR") }}
              <HelpButton :helpobject="colorConditionsHelpDialogData"></HelpButton>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
          <!-- カード色指定条件 -->
          <ColorConditionSettings
            :color-condition-list="settingObj.colorConditionList"
            :mapping-data-list="listMapping"
            :user-role="!isValidRole"
          ></ColorConditionSettings>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <div class="areatitle">
              {{ $t("PAGE_SUBTITLE_APPLICATON_SETTING_DETAIL") }}
              <HelpButton :helpobject="detailHelpDialogData"></HelpButton>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
          <!-- 詳細設定 -->
          <div class="dataarea">
            <v-row dense align="center">
              <v-col cols="2">
                <label class="detail-title">{{ $t("PAGE_SUBTITLE_APPLICATON_SETTING_DETAIL_CARD_MOVE") }}</label>
              </v-col>
              <v-col cols="10">
                <v-radio-group v-model="settingObj.cardMoveJudge" row class="my-2" hide-details :disabled="!isValidRole">
                  <v-radio :label="$t('WORD_APPLICATION_SETTING_CARD_MOVE_OUTORDER')" :value="0"></v-radio>
                  <v-radio :label="$t('WORD_APPLICATION_SETTING_CARD_MOVE_INORDER')" :value="1"></v-radio>
                </v-radio-group>
              </v-col>                  
            </v-row>
            <v-row dense align="center">
              <v-col cols="2">
                <label class="detail-title">{{ $t("PAGE_SUBTITLE_APPLICATON_SETTING_DETAIL_LOAD_DISPLAY") }}</label>
              </v-col>
              <v-col cols="10">
                <v-radio-group v-model="settingObj.loadDisplayType" row class="my-2" hide-details :disabled="!isValidRole">
                  <v-radio :label="$t('WORD_APPLICATION_SETTING_LOAD_PERCENT')" :value="0"></v-radio>
                  <v-radio :label="$t('WORD_APPLICATION_SETTING_LOAD_PERCENT_DETAIL')" :value="1"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row dense align="center">
              <v-col cols="2">
                <label class="detail-title">{{ $t("PAGE_SUBTITLE_APPLICATON_SETTING_DETAIL_FILE_BADGE_DISPLAY") }}</label>
              </v-col>
              <v-col cols="10">
                <v-radio-group v-model="settingObj.fileDisplayType" row class="my-2" hide-details :disabled="!isValidRole">
                  <v-radio :label="$t('WORD_APPLICATION_SETTING_FILE_DISPLAY_NONE')" :value="0"></v-radio>
                  <v-radio :label="$t('WORD_APPLICATION_SETTING_FILE_DISPLAY_BADGE')" :value="1"></v-radio>
                  <v-radio :label="$t('WORD_APPLICATION_SETTING_FILE_DISPLAY_BADGE_NUMBER')" :value="2"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row dense align="center">
              <v-col cols="2">
                <label class="detail-title">{{ $t("PAGE_SUBTITLE_APPLICATON_SETTING_DETAIL_DATE_DISPLAY") }}</label>
              </v-col>
              <v-col cols="10">
                <v-radio-group v-model="settingObj.dateDisplayType" row class="my-2" hide-details :disabled="!isValidRole">
                  <v-radio :label="$t('WORD_APPLICATION_SETTING_DATE_YYYYMMDD')" :value="0"></v-radio>
                  <v-radio :label="$t('WORD_APPLICATION_SETTING_DATE_MMDD')" :value="1"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- 保存ボタン -->
      <div class="dataarea">
        <v-btn :disabled="!isValidRole" class="info savebtn" @click="settingSave">
          {{ $t("WORD_APPLICATION_SETTING_SAVE") }}
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("setting");
import Define from "@/define.js";
import * as Util from "@/util/utils.js";
import * as ControlUtil from "@/util/controlutils.js";
import Swal from "sweetalert2";
import HelpButton from "@/components/parts/HelpButton";
import ApplicationSettingCardPattern from "@/components/parts/ApplicationSettingCardPattern";
import InputFileSetting from "@/components/parts/InputFileSetting";
import ColorConditionSettings from "@/components/parts/ColorConditionSettings";
import { mixinUtil } from "@/mixins/mixinUtils.js";
import { mixinControl } from "@/mixins/mixinControl.js";
import { mixinValidator } from "@/mixins/mixinValidator.js";
import { mixinError } from "@/mixins/mixinError.js";
export default {
  components: {
    HelpButton,
    ApplicationSettingCardPattern,
    InputFileSetting,
    ColorConditionSettings
  },
  mixins: [mixinUtil, mixinControl, mixinValidator, mixinError],
  data() {
    return {
      initialized: false, // 初期化フラグ
      valid: true, // v-formのバリデート
      settingObj: {},

      // バリデーター
      check_required: value => this.isValidRequired(value, true),
      check_string: value => this.isValidString(value, true),
      check_int: value => this.isValidInt(value, true),
      check_float: value => this.isValidFloat(value, true),
      check_percent: value => this.isValidNotContainPercent(value),
      check_progress_max: value => this.checkProgressMax(value),

      // データ形式
      TYPE_STRING: Define.TYPE_STRING,
      TYPE_INTEGER: Define.TYPE_INTEGER,
      TYPE_FLOAT: Define.TYPE_FLOAT,
      TYPE_DATE: Define.TYPE_DATE,
      TYPE_CALC: Define.TYPE_CALC,

      // データ入力形式
      INPUT_TYPE_DEFAULT: Define.INPUT_TYPE_DEFAULT,
      INPUT_TYPE_LIST: Define.INPUT_TYPE_LIST,
      INPUT_TYPE_SPINNER: Define.INPUT_TYPE_SPINNER,
      INPUT_TYPE_SLIDER: Define.INPUT_TYPE_SLIDER,

      // 実績入力
      JISSEKI_NOTHING: Define.JISSEKI_NOTHING,
      JISSEKI_OPTIONAL: Define.JISSEKI_OPTIONAL,
      JISSEKI_REQUIRED: Define.JISSEKI_REQUIRED,
    };
  },
  computed: {
    isExistSetting() {
        return this.$store.getters["setting/isExistSetting"];
    },
    loadingFlg() {
      return this.$store.getters["isInitDataLoaded"];
    },
    isUpdateSetting() {
      return this.$store.getters["isUpdateSetting"];
    },
    isValidRole() {
      let user = this.$store.getters["auth/user"];
      return Util.getAuthority(Define.EVENT_APPLICATION_SETTING, user.role);
    },
    version() {
      return this.$store.getters["settingVersion"];
    },
    settingId() {
      return this.$store.getters["settingId"];
    },
    // ヘルプダイアログ関連
    tempalteHelpDialogData() { return this.$t("EXPLANATION_DIALOG.applicationSettingTemplateHelp"); },
    mappingHelpDialogData() { return this.$t("EXPLANATION_DIALOG.applicationSettingMappingHelp"); },
    calcHelpDialogData() { return this.$t("EXPLANATION_DIALOG.applicationSettingCalcHelp"); },
    areaHelpDialogData() { return this.$t("EXPLANATION_DIALOG.applicationSettingAreaHelp"); },
    fileHelpDialogData() { return this.$t("EXPLANATION_DIALOG.applicationSettingInputFileSettingHelp"); },
    colorConditionsHelpDialogData() { return this.$t("EXPLANATION_DIALOG.applicationSettingColorConditionsHelp"); },
    detailHelpDialogData() { return this.$t("EXPLANATION_DIALOG.applicationSettingDetailHelp"); },

    getSettingData() {
      return this.$store.getters["setting/getSettingData"];
    },
    // テーブル定義
    TABLE_FOOTER_SETTING_MIDDLE() {
      return this.$t("TABLE_FOOTER_SETTING_MIDDLE");
    },
    // 共通テキスト - 必須
    requiredWord() {
      return this.$t("MSG_SETTING_REQUIRED");
    },
    // データマッピング - 削除時メッセージ
    warningDeleteMapping() {
      return this.$t("MSG_WARNING_DELETE_DATAMAPPING");
    },
    // データマッピング - テーブルヘッダー
    mappingTableHeaders() {
      return this.$t("APP_SETTING_MAPPING_HEADER");
    },
    // データマッピング - リスト
    listMapping() {
      return this.settingObj.mappingDataList;
    },
    // データマッピング - データ形式のリスト
    listTypeItems() {
      return this.getMappingTableTypeItems();
    },
    // データマッピング - データ入力形式のリスト
    listInputTypeItems() {
      return type => {
        return this.getMappingTableInputTypeItems(type);
      };
    },
    // データマッピング - カテゴリのリスト
    listCategoryItems() {
      return this.$store.getters["info/getInfoCategoryItems"];
    },
    // データマッピング - ステップのリスト
    listStepItems() {
      return type => {
        return ControlUtil.getStepItems(type);
      };
    },
    // データマッピング - 最小値のルール
    minRules() {
      return type => {
        if (type === Define.TYPE_INTEGER) {
          return [this.check_required, this.check_int];
        } else if (type === Define.TYPE_FLOAT) {
          return [this.check_required, this.check_float];
        } else {
          return [this.check_required];
        }
      };
    },
    // データマッピング - 最大値のルール
    maxRules() {
      return (type, min) => {
        if (type === Define.TYPE_INTEGER) {
          return [this.check_required, this.check_int, value => this.isValidMaxValue(value, min, true)];
        } else if (type === Define.TYPE_FLOAT) {
          return [this.check_required, this.check_float, value => this.isValidMaxValue(value, min, true)];
        } else {
          return [this.check_required];
        }
      };
    },
    // データマッピング - 計算対象１のリスト
    listCalcObject1Items() {
      let targetType = [Define.TYPE_INTEGER, Define.TYPE_FLOAT, Define.TYPE_DATE];
      let mappings = (this.settingObj) ? this.settingObj.mappingDataList : [];
      let items = [];

      for (const item of mappings) {
        if (targetType.includes(item.type)){
          items.push({ text: item.name, value: item.mappingId });
        }
      }
      return items;
    },
    // データマッピング - 演算子のリスト
    listShisokuEnzanshiItems() {
      return (calcObject1Type) => {
        if (calcObject1Type === Define.TYPE_DATE) {
          return [
            { text: "+",  value: Define.CALC_PLUS },
            { text: "-",  value: Define.CALC_MINUS }, 
          ];
        }else{
          return [
            { text: "+",  value: Define.CALC_PLUS },
            { text: "-",  value: Define.CALC_MINUS }, 
            { text: "×",  value: Define.CALC_TIMES }, 
            { text: "÷",  value: Define.CALC_DIVIDED }, 
          ]
        }
      }
    },
    // データマッピング - 計算対象２のリスト
    listCalcObject2Items() {
      return (calcObject1Type) => {
        let targetTypeForDate = [Define.TYPE_INTEGER];
        let targetTypeFoeNum = [Define.TYPE_INTEGER, Define.TYPE_FLOAT];
        let mappings = (this.settingObj) ? this.settingObj.mappingDataList : [];
        let items = [];

        if (calcObject1Type === Define.TYPE_DATE){
          for (const item of mappings) {
            if (targetTypeForDate.includes(item.type)){
              items.push({ text: item.name, value: item.mappingId });
            }
          }
        }else{
          for (const item of mappings) {
            if (targetTypeFoeNum.includes(item.type)){
              items.push({ text: item.name, value: item.mappingId });
            }
          }
        }
        return items;
      }
    },
    // データマッピング - 取込CSV対応列のリスト
    listColumnItems() {
      return this.getMappingTableColumnItems();
    },
    // データマッピング - テンプレート対応場所のリスト
    listPosItems() {
      return this.getMappingTablePosItems(this.settingObj.tileTempNum);
    },
    // データマッピング - 実績入力
    listJissekiItems() {
      return this.getMappingTableJissekiItems();
    },
    // データマッピング - 実績集計方法
    listJissekiCalcItems() {
      return this.getMappingTableJissekiCalcItems();
    },
    // 負荷計算データ - 負荷計算データのリスト
    listComputeItems() {
      return this.getComputeItems(this.settingObj.mappingDataList);
    },
    // 計画エリア設定 - 削除時メッセージ
    warningDeleteArea() {
      return this.$t("MSG_WARNING_DELETE_PLANAREA");
    },
    // 計画エリア設定 - テーブルヘッダー
    areaTableHeaders() {
      return this.$t("APP_SETTING_AREA_HEADER");
    },
    // 計画エリア設定 - 表示順序のリスト
    listOrderItems() {
      return this.getPlanTableOrderItems(this.settingObj.planAreaList);
    },
    // 計算対象１のデータ形式
    getCalcObject1Type() {
      return mappingId => {
        let mappingList = this.settingObj ? this.settingObj.mappingDataList : null;
        if (!mappingId || !mappingList) {  return null; }

        const mapping = mappingList.find(item => item.mappingId == mappingId);
        if (mapping.type != Define.TYPE_CALC) { return null; }

        const calcObject1Mapping = mappingList.find(item => item.mappingId == mapping.calcObject1);
        return calcObject1Mapping.type;
        };
    }
  },
  watch: {
    loadingFlg: {
      immediate: true,
      handler: function(val) {
        if (val) { this.settingObj = Util.parseObject(this.getSettingData); }
      }
    },
    isUpdateSetting: {
      immediate: true,
      handler: function(val) {
        if (val) {
          if (this.initialized && this.settingObj.version !== this.version) {
            Swal.fire({
              icon: "info",
              title: this.$t("MSG_MAIN_RELOAD_SETTING"),
              text: this.$t("MSG_SUB_RELOAD_SETTING"),
              showConfirmButton: false,
              timer: 2000
            });
            this.settingObj = Util.parseObject(this.getSettingData);
          }
        }
      }
    },
    listMapping: {
      immediate: true,
      deep: true,
      handler: function() {
        this.$nextTick(() => {
          if (this.$refs.form) this.$refs.form.validate();
        });
      }
    },
    listPosItems: {
      immediate: true,
      handler: function() {
        if (Util.checkArrayLength(this.settingObj.mappingDataList) && Util.checkArrayLength(this.listPosItems)) {
          let length = this.listPosItems.length - 1;
          for (let i in this.settingObj.mappingDataList) {
            if (this.settingObj.mappingDataList[i].pos > length) { this.settingObj.mappingDataList[i].pos = ""; }
          }
        }
      }
    },
    listComputeItems: {
      immediate: true,
      handler: function(val) {
        if (Util.checkNotNull(val) && Util.checkNotUndefined(val)) {
          if (!val.some(item => item.mappingId === this.settingObj.calcProgressData)) { this.settingObj.calcProgressData = ""; }
        }
      }
    },
    listOrderItems: {
      immediate: true,
      handler: function() {
        if (Util.checkArrayLength(this.settingObj.planAreaList) && Util.checkArrayLength(this.listOrderItems)) {
          let length = this.listOrderItems.length - 1;
          for (let i in this.settingObj.planAreaList) {
            if (this.settingObj.planAreaList[i].order > length) { this.settingObj.planAreaList[i].order = ""; }
          }
        }
      }
    },
  },
  beforeMount() {
    this.initiaize();
  },
  methods: {
    ...mapActions(["createUpdateSetting"]),
    /**
     * 初期化処理
     */
    initiaize() {
      this.settingObj = Util.parseObject(this.getSettingData);
      this.initialized = true;
    },
    isDisplayCategory(type, inputType) {
      return ControlUtil.isDisplayCategory(type, inputType);
    },
    isDisplayNumParam(type, inputType) {
      return ControlUtil.isDisplayNumParam(type, inputType);
    },
    isEditableJissekiCalc(type, jisseki, calcObject1Type) {
      return ControlUtil.isEditableJissekiCalc(type, jisseki, calcObject1Type);
    },
    isDisplayCalc(type, inputType) {
      return ControlUtil.isDisplayCalc(type, inputType);
    },
    /**
     * マッピングIDからデータ形式を取得する
     */
    getTypeFromMappingId(mappingId) {
      let mappings = this.settingObj.mappingDataList;
      let mapping = mappings.filter(d => d.mappingId == mappingId);      
      return (mapping.length != 0) ? mapping[0].type : Define.TYPE_NOTHING;
    },
    /**
     * 順序の算出
     */
    getInitialListNum(settingList, maxNum, item) {
      // 戻り値numを定義
      let num = 0;
      let compareList = [];
      for (let i = 0; i < maxNum; i++) { compareList.push(i + 1); }
      // マッピングリスト中の値を読み取る配列
      let settingListGetItem = [];
      for (let j in settingList) { settingListGetItem.push(settingList[j][item]); }
      // 配列のアイテムを数字のみで洗い出し
      let settingListOnlyNum = settingListGetItem.filter(value => value !== "");
      // 配列中の数字を昇順で並び替え
      settingListOnlyNum.sort(function(a, b) { return a - b; });
      // 差分配列を作成
      let differenceList = compareList.filter(pos => settingListOnlyNum.indexOf(pos) == -1);
      // 戻り値を差分配列中の最小値に指定
      num = differenceList[0] ? differenceList[0] : "";
      return num;
    },
    /**
     * テンプレートを選択する
     */
    setPattern(num) {
      this.settingObj.tileTempNum = num;
    },
    /**
     * マッピングリストから負荷計算データの選択肢を取得する
     */
    getComputeItems(mappings) {
      let list = mappings;
      if (!list) { return []; }
      let items = [{ name: this.$t("WORD_NOTSET"), mappingId: "" }];
      return items.concat(
        list.filter(
          item => item.type === Define.TYPE_INTEGER || Util.isFloatConsideringCalc(item.type, this.getCalcObject1Type(item.mappingId))
        )
      );
    },
    /**
     * データマッピングの行追加
     */
    addMappingList() {
      if (Util.checkNotUndefined(this.settingObj.mappingDataList)) {
        if (this.settingObj.mappingDataList.length >= Define.APP_SETTING_ADD_LIMIT_MAPPING) {
          this.msgErrorAddMapping();
        } else {
          let maxNum = Util.getDiaplayNum(this.settingObj.tileTempNum);
          let addObj = {
            state: "add",
            column: "",
            isCompute: false,
            mappingId: this.$uuid.v4(),
            name: "",
            pos: this.getInitialListNum(this.settingObj.mappingDataList, maxNum, "pos"),
            type: Define.TYPE_STRING,
            inputType: Define.INPUT_TYPE_DEFAULT,
            category: "",
            min: "", max: "", step: "",
            calcObject1: "", shisokuEnzanshi: "", calcObject2: "",
            jisseki: Define.JISSEKI_NOTHING,
            jissekicalc: Define.JISSEKICALC_NOTHING,
            key: false
          };
          this.settingObj.mappingDataList.push(addObj);
        }
      }
    },
    /**
     * データマッピングの行追加エラー
     */
    msgErrorAddMapping() {
      Swal.fire({
        icon: "error",
        title: this.$t("MSG_ERR_MAIN_DATA_ADD"),
        text: this.$t("MSG_ERR_SUB_DATAMAPPING_ADD", { limit: Define.APP_SETTING_ADD_LIMIT_MAPPING }),
      });
    },
    /**
     * データマッピングの行削除
     */
    async deleteMappingRow(item) {
      if (!this.confirmCalcObjectType(item)) return;
      if (!await this.confirmDeleteRow(item, Define.CONFIRM_DELETE_DATAMAPPING)) return;
      const index = this.settingObj.mappingDataList.findIndex(elm => elm.mappingId == item.mappingId);
      this.settingObj.mappingDataList.splice(index, 1);
    },
    /**
     * 行削除時の確認ダイアログ表示
     */
    async confirmDeleteRow(item, messageId) {
      let ret = { isConfirmed: true };
      if (item.state != "add") {
        const messageObj = this._getErrorMessage(messageId);
        ret = await Swal.fire({
          title: messageObj.mainText,
          html: messageObj.subText,
          icon: "warning",
          input: "text",
          showCancelButton: true,
          width: "700px",
          inputValidator: value => {
            return new Promise(resolve => {
              if (value === item.name) {
                resolve();
              } else {
                resolve(this.$t("MSG_NOT_MATCH_VALUE"));
              }
            });
          }
        });
      }
      return ret.isConfirmed;
    },
    /**
     * データマッピング - データ形式を変更したときの連動処理
     */
    resetByType(item){
      if (item.type === Define.TYPE_CALC){
        item.column = "";
        item.jissekicalc = Define.JISSEKICALC_NOTHING;
        item.key = false;
      } else {
          item.calcObject1 = "";
          item.shisokuEnzanshi = "";
          item.calcObject2 = "";
      }
      item.inputType = Define.INPUT_TYPE_DEFAULT;
    },
    /**
     * データマッピング - カテゴリアイテムチェック
     */
    clickSelectCategory() {
      if (this.listCategoryItems.length === 0) {
        Swal.fire({
          title: this.$t("MSG_ERR_MAIN_INPUTTYPE_SETTINGS"),
          html: this.$t("MSG_ERR_SUB_CATEGORY_SETTINGS"),
          icon: "error"
        });
      }
    },
    /**
     * データマッピング - 計算対象１を変更したときの連動処理
     */
    resetByCalcObjact1(item){
      if(this.getTypeFromMappingId(item.calcObject1) === Define.TYPE_DATE ) {
        item.jissekicalc = Define.JISSEKICALC_NOTHING;
      }
    },
    /**
     * 計算対象の削除確認
     */
    confirmCalcObjectType(item) {
      let ret = { isConfirmed: true };
      let filterdRows = this.settingObj.mappingDataList.filter(d => d.calcObject1 == item.mappingId || d.calcObject2 == item.mappingId);
      if (filterdRows.length !== 0){
        const messageObj = this._getErrorMessage(Define.ERR_DEL_DATAMAPPING);
          ret = Swal.fire({
            title: messageObj.mainText,
            html: messageObj.subText,
            icon: "error",
          });
      }
      return ret.isConfirmed;
    },
    /**
     * 負荷計算データ - 負荷上限チェック
     */
    checkProgressMax(value) {
      let calcProgressDataType = this.getTypeFromMappingId(this.settingObj.calcProgressData);
      let calcObject1Type = this.getCalcObject1Type(this.settingObj.calcProgressData);
      return this.isValidProgressMax(value, calcProgressDataType, calcObject1Type);
    },
    /**
     * 計画エリア設定の行追加
     */
    addAreaList() {
      if (Util.checkNotUndefined(this.settingObj.planAreaList)) {
        if (this.settingObj.planAreaList.length >= Define.APP_SETTING_ADD_LIMIT_AREA) {
          this.addLimitAreaDialog();
        } else {
          let type = Util.searchCalcdata(
            this.settingObj.calcProgressData,
            this.settingObj.mappingDataList
          );
          let number = Define.NUMBER_RANGE_MIN_INTEGER;
          switch (type) {
            case Define.TYPE_INTEGER:
              number = Define.NUMBER_RANGE_MIN_INTEGER;
              break;
            case Define.TYPE_FLOAT:
              number = Define.NUMBER_RANGE_MIN_FLOAT.toFixed(1);
              break;
          }
          let maxNum = Define.APP_SETTING_ADD_LIMIT_AREA;
          let addObj = {
            state: "add",
            areaId: this.$uuid.v4(),
            max: number,
            name: "",
            order: this.getInitialListNum(this.settingObj.planAreaList, maxNum, "order")
          };
          this.settingObj.planAreaList.push(addObj);
        }
      }
    },
    /**
     * 計画エリア設定の行追加エラー
     */
    addLimitAreaDialog() {
      Swal.fire({
        icon: "error",
        title: this.$t("MSG_ERR_MAIN_DATA_ADD"),
        text: this.$t("MSG_ERR_SUB_PLANAREA_ADD", { limit: Define.APP_SETTING_ADD_LIMIT_AREA }),
      });
    },
    /**
     * 計画エリア設定の行削除
     */
    async deleteAreaRow(item) {
      if (!await this.confirmDeleteRow(item, Define.CONFIRM_DELETE_PLANAREA)) return;
      const index = this.settingObj.planAreaList.findIndex(elm => elm.areaId == item.areaId);
      this.settingObj.planAreaList.splice(index, 1);
    },
    /**
     * 保存処理
     */
    settingSave() {
      this.$refs.form.validate();
      // loadingダイアログ表示
      this.$store.commit("setIsLoading", true);
      let mappingList = this.settingObj.mappingDataList;
      let areaList = this.settingObj.planAreaList;
      let mappingResult = Util.checkSettingMappingList(mappingList);
      // エラー判定
      let areaResult = Util.checkSettingAreaList(
        areaList,
        this.settingObj.calcProgressData,
        mappingList
      );
      let messageObj = { mainText: "", subText: "" };
      if (
        mappingResult === Define.SUCCESS &&
        areaResult === Define.SUCCESS &&
        !this.valid
      ) {
        messageObj = this._getErrorMessage(Define.ERR_INPUT_ITEM_INVALID);
      } else {
        // エラーがあればメッセージをセットして表示
        messageObj = this.createErrorMessageBySettingSave(
          mappingResult,
          areaResult
        );
      }

      if (messageObj.mainText !== "" && messageObj.subText !== "") {
        // loadingダイアログ非表示
        this.$store.commit("setIsLoading", false);
        Swal.fire({
          title: messageObj.mainText,
          html: messageObj.subText,
          icon: "error",
          width: 550
        });
      } else {
        // 最新の設定Idセット(重複登録回避)
        this.settingObj.settingId = this.settingId;
        // 最新のバージョンセット(排他回避)
        this.settingObj.version = this.version;
        // データを整形してwebsocketで送信(action)
        let result = Util.createSettingObject(this.settingObj);
        // loadingダイアログ非表示(WebSocket通信に行ける場合はreceiveMessageで非表示する)
        this.createUpdateSetting(result);
        // 自分が更新したことを把握するためローカルに保持している設定値のバージョンを上げる
        this.settingObj.version++;
      }
    },
    /**
     * クイック設定 - 製造業向け(担当者)
     */
    setSeizoTanto() {
      let calcMappingId = this.$uuid.v4()
      this.settingObj.mappingDataList = [
        {state: "add", mappingId: this.$uuid.v4(), name: "指示番号", type: Define.TYPE_STRING, inputType: Define.INPUT_TYPE_DEFAULT, column: 1, pos: 1, jisseki: Define.JISSEKI_NOTHING, jissekicalc: Define.JISSEKICALC_NOTHING, key: false, isCompute: false, category: "", min: "", max: "", step: "", calcObject1: "", shisokuEnzanshi: "", calcObject2: ""},
        {state: "add", mappingId: this.$uuid.v4(), name: "品名", type: Define.TYPE_STRING, inputType: Define.INPUT_TYPE_DEFAULT, column: 2, pos: 2, jisseki: Define.JISSEKI_NOTHING, jissekicalc: Define.JISSEKICALC_NOTHING, key: false, isCompute: false, category: "", min: "", max: "", step: "", calcObject1: "", shisokuEnzanshi: "", calcObject2: ""},
        {state: "add", mappingId: calcMappingId, name: "数量", type: Define.TYPE_INTEGER, inputType: Define.INPUT_TYPE_DEFAULT, column: 3, pos: 3, jisseki: Define.JISSEKI_REQUIRED, jissekicalc: Define.JISSEKICALC_SUM, key: false, isCompute: false, category: "", min: "", max: "", step: "", calcObject1: "", shisokuEnzanshi: "", calcObject2: ""},
        {state: "add", mappingId: this.$uuid.v4(), name: "作業時間", type: Define.TYPE_FLOAT, inputType: Define.INPUT_TYPE_DEFAULT, column: 4, pos: 4, jisseki: Define.JISSEKI_REQUIRED, jissekicalc: Define.JISSEKICALC_SUM, key: false, isCompute: false, category: "", min: "", max: "", step: "", calcObject1: "", shisokuEnzanshi: "", calcObject2: ""},
        {state: "add", mappingId: this.$uuid.v4(), name: "担当者", type: Define.TYPE_STRING, inputType: Define.INPUT_TYPE_DEFAULT, column: 5, pos: 5, jisseki: Define.JISSEKI_REQUIRED, jissekicalc: Define.JISSEKICALC_NOTHING, key: false, isCompute: false, category: "", min: "", max: "", step: "", calcObject1: "", shisokuEnzanshi: "", calcObject2: ""},
        {state: "add", mappingId: this.$uuid.v4(), name: "納期", type: Define.TYPE_DATE, inputType: Define.INPUT_TYPE_DEFAULT, column: 6, pos: 6, jisseki: Define.JISSEKI_NOTHING, jissekicalc: Define.JISSEKICALC_NOTHING, key: false, isCompute: false, category: "", min: "", max: "", step: "", calcObject1: "", shisokuEnzanshi: "", calcObject2: ""}
      ];
      this.settingObj.planAreaList = [
        { state: "add", areaId: this.$uuid.v4(), name: "担当者A", max: 120, order: 1},
        { state: "add", areaId: this.$uuid.v4(), name: "担当者B", max: 80, order: 2},
        { state: "add", areaId: this.$uuid.v4(), name: "担当者C", max: 100, order: 3}
      ];
      this.settingObj.calcProgressData = calcMappingId;
    },
    /**
     * クイック設定 - 製造業向け(設備)
     */
    setSeizoKikai() {
      let calcMappingId = this.$uuid.v4()
      this.settingObj.mappingDataList = [
        {state: "add", mappingId: this.$uuid.v4(), name: "指示番号", type: Define.TYPE_STRING, inputType: Define.INPUT_TYPE_DEFAULT, column: 1, pos: 1, jisseki: Define.JISSEKI_NOTHING, jissekicalc: Define.JISSEKICALC_NOTHING, key: false, isCompute: false, category: "", min: "", max: "", step: "", calcObject1: "", shisokuEnzanshi: "", calcObject2: ""},
        {state: "add", mappingId: this.$uuid.v4(), name: "品名", type: Define.TYPE_STRING, inputType: Define.INPUT_TYPE_DEFAULT, column: 2, pos: 2, jisseki: Define.JISSEKI_NOTHING, jissekicalc: Define.JISSEKICALC_NOTHING, key: false, isCompute: false, category: "", min: "", max: "", step: "", calcObject1: "", shisokuEnzanshi: "", calcObject2: ""},
        {state: "add", mappingId: calcMappingId, name: "数量", type: Define.TYPE_INTEGER, inputType: Define.INPUT_TYPE_DEFAULT, column: 3, pos: 3, jisseki: Define.JISSEKI_REQUIRED, jissekicalc: Define.JISSEKICALC_SUM, key: false, isCompute: false, category: "", min: "", max: "", step: "", calcObject1: "", shisokuEnzanshi: "", calcObject2: ""},
        {state: "add", mappingId: this.$uuid.v4(), name: "作業時間", type: Define.TYPE_FLOAT, inputType: Define.INPUT_TYPE_DEFAULT, column: 4, pos: 4, jisseki: Define.JISSEKI_REQUIRED, jissekicalc: Define.JISSEKICALC_SUM, key: false, isCompute: false, category: "", min: "", max: "", step: "", calcObject1: "", shisokuEnzanshi: "", calcObject2: ""},
        {state: "add", mappingId: this.$uuid.v4(), name: "担当者", type: Define.TYPE_STRING, inputType: Define.INPUT_TYPE_DEFAULT, column: 5, pos: 5, jisseki: Define.JISSEKI_REQUIRED, jissekicalc: Define.JISSEKICALC_NOTHING, key: false, isCompute: false, category: "", min: "", max: "", step: "", calcObject1: "", shisokuEnzanshi: "", calcObject2: ""},
        {state: "add", mappingId: this.$uuid.v4(), name: "納期", type: Define.TYPE_DATE, inputType: Define.INPUT_TYPE_DEFAULT, column: 6, pos: 6, jisseki: Define.JISSEKI_NOTHING, jissekicalc: Define.JISSEKICALC_NOTHING, key: false, isCompute: false, category: "", min: "", max: "", step: "", calcObject1: "", shisokuEnzanshi: "", calcObject2: ""}
      ];
      this.settingObj.planAreaList = [
        { state: "add", areaId: this.$uuid.v4(), name: "設備A", max: 120, order: 1},
        { state: "add", areaId: this.$uuid.v4(), name: "設備B", max: 80, order: 2},
        { state: "add", areaId: this.$uuid.v4(), name: "設備C", max: 100, order: 3}
      ];
      this.settingObj.calcProgressData = calcMappingId;
    },
    /**
     * クイック設定 - リセット
     */
    setInit() {
      this.settingObj.mappingDataList = [];
      this.settingObj.planAreaList = [];
      this.settingObj.calcProgressData = "";
    },
  }
};
</script>

<style scoped>
.cardselect {
  margin: 5px;
  width: 1820px;
}
.dataarea {
  margin: 20px 5px 20px 5px;
  width: 1820px;
}
.tablearea {
  margin-left: 25px;
  margin-right: 25px;
  padding: 2px 0;
  white-space: pre-wrap;
}
.areatitle {
  border-left: solid 6px #1976d2ca;
  margin-bottom: 8px;
  padding-left: 8px;
}
.selecter {
  margin-left: 25px;
  width: 400px;
}
.savebtn {
  margin-left: 28%;
  width: 40%;
}
.attention {
  color: #ff5252;
  margin: 0 12px 24px 12px;
}
.detail-title {
  color: #666;
  font-size: 14px;
  font-weight: bold;
  margin-left: 10px;
}
.required {
  color: red;
  font-weight: bold;
}
.lbl-auto {
  color: #666;
  font-size: 14px;
}
</style>
