var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-tooltip",
        {
          attrs: {
            bottom: "",
            "open-delay": "300",
            color: "rgba(60,64,67,0.95)",
            transition: "fade"
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var small = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        attrs: {
                          outlined: _vm.displayFontSize == _vm.FONT_SIZE_SMALL
                        },
                        on: {
                          click: function($event) {
                            return _vm.setDisplayFontSize(_vm.FONT_SIZE_SMALL)
                          }
                        }
                      },
                      small
                    ),
                    [_vm._v(_vm._s(_vm.$t("WORD_SMALL")))]
                  )
                ]
              }
            }
          ])
        },
        [_c("span", [_vm._v(_vm._s(_vm.$t("TOOLTIP_SMALL")))])]
      ),
      _c(
        "v-tooltip",
        {
          attrs: {
            bottom: "",
            "open-delay": "300",
            color: "rgba(60,64,67,0.95)",
            transition: "fade"
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var mediium = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        attrs: {
                          outlined: _vm.displayFontSize == _vm.FONT_SIZE_MEDIUM
                        },
                        on: {
                          click: function($event) {
                            return _vm.setDisplayFontSize(_vm.FONT_SIZE_MEDIUM)
                          }
                        }
                      },
                      mediium
                    ),
                    [_vm._v(_vm._s(_vm.$t("WORD_MEDIUM")))]
                  )
                ]
              }
            }
          ])
        },
        [_c("span", [_vm._v(_vm._s(_vm.$t("TOOLTIP_MEDIUM")))])]
      ),
      _c(
        "v-tooltip",
        {
          attrs: {
            bottom: "",
            "open-delay": "300",
            color: "rgba(60,64,67,0.95)",
            transition: "fade"
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var large = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        attrs: {
                          outlined: _vm.displayFontSize == _vm.FONT_SIZE_LARGE
                        },
                        on: {
                          click: function($event) {
                            return _vm.setDisplayFontSize(_vm.FONT_SIZE_LARGE)
                          }
                        }
                      },
                      large
                    ),
                    [_vm._v(_vm._s(_vm.$t("WORD_LARGE")))]
                  )
                ]
              }
            }
          ])
        },
        [_c("span", [_vm._v(_vm._s(_vm.$t("TOOLTIP_LARGE")))])]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }