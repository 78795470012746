<template>
  <div class="datepick">
    <!-- 「＜」ボタン -->
    <v-tooltip bottom open-delay="300" color="rgba(60,64,67,0.95)" transition="fade">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon elevation="2" v-bind="attrs" v-on="on" @click="lastMonth">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("TOOLTIP_LAST_MONTH") }}</span>
    </v-tooltip>
    <v-spacer></v-spacer>
    <!-- 年月表示 -->
    <div class="days">{{ display }}</div>
    <v-spacer></v-spacer>
    <!-- 「＞」ボタン -->
    <v-tooltip bottom open-delay="300" color="rgba(60,64,67,0.95)" transition="fade">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon elevation="2" v-bind="attrs" v-on="on" @click="nextMonth">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("TOOLTIP_NEXT_MONTH") }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import Define from "@/define.js";
import moment from "moment";
export default {
  data() {
    return {
      selectedDate: moment(),
      formatYYYY_M: Define.DATE_FORMAT_MONTH_SLASH_CHECK
    };
  },
  computed: {
    display() {
      return moment(this.selectedDate).format(this.formatYYYY_M);
    }
  },
  watch: {
    /**
     * 月の変更時に変更後の値を親部品に返す
     */
    selectedDate: {
      immediate: true,
      handler: function() {
        this.$emit("setDate", this.selectedDate);
      }
    }
  },
  methods: {
    /**
     * 当月を設定する
     */
    initMonth() {
      this.selectedDate = moment();
    },
    /**
     * 前月を設定する
     */
    lastMonth() {
      this.selectedDate = moment(this.selectedDate).subtract(1, "M");
    },
    /**
     * 翌月を設定する
     */
    nextMonth() {
      this.selectedDate = moment(this.selectedDate).add(1, "M");
    }
  }
};
</script>

<style scoped>
.datepick {
  width: 200px;
  padding-top: 2px;
  display: flex;
}
.v-btn {
  background-color: #fff;
}
.days {
  margin: 8px;
}
</style>
