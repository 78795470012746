<template>
  <v-dialog v-model="isOpenCardDetailMultipleCopy" persistent>
    <v-form ref="form" class="overlay carddetailmultiplecopy">
      <div class="overlayarea">
        <!-- カード表示範囲 -->
        <v-card flat color="#f0f0f0" class="cardsize">
          <!-- ダイアログの✕ボタン -->
          <div class="text-right">
            <v-btn class="" icon @click="closeMultipleDetail">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <!-- サブタイトル -->
          <div class="subtitle">{{ $t("PAGE_TITLE_CARD_MULTIPLE") }}</div>
          <!-- データテーブル -->
          <div class="datatable">
            <!-- カードデータ -->
            <v-card flat class="datawrap areamargin">
              <v-data-table
                :headers="headers"
                :items="items"
                hide-default-footer
              >
                <!-- カードデータ(ヘッダー) -->
                <template
                  v-for="(data, id) in headers"
                  v-slot:[`header.${data.value}`]="{ header }"
                >
                  {{ header.text }}
                  <v-icon
                    v-if="isKeySettings(header.mappingId)"
                    :key="id"
                    small
                    :style="{ color: 'red' }"
                  >
                    *
                  </v-icon>
                </template>
                <!-- カードデータ(コンテンツ) -->
                <template
                  v-for="(data, id) in headers"
                  v-slot:[`item.${data.value}`]="{ item }"
                >
                  <EditingItem
                    :key="id"
                    v-model="item[data.value]"
                    :type="getType(data.mappingId)"
                    :inputType="getInputType(data.mappingId)"
                    :rules="rules(data.mappingId)"
                    :items="listDataItems(getCategory(data.mappingId), item[data.value])"
                    :editable="getType(data.mappingId) !== TYPE_CALC"
                  ></EditingItem>
                </template>
                <!-- 複数複製カード番号 -->
                <template v-slot:[`item.num`]="{ item }">
                  <div style="text-align:center">{{ item.num }}</div>
                </template>
                <!-- カード色設定 -->
                <template v-slot:[`item.color`]="{ item }">
                  <div>
                    <v-select
                      v-model="item.color"
                      dense
                      outlined
                      hide-details="auto"
                      :items="colors"
                      item-value="color"
                      item-text="label"
                      :rules="[]"
                    >
                      <template v-slot:selection="{ item }">
                        <div :style="colorBoxlStyle(item.color)"></div>
                        <span class="pl-2">{{ item.label }}</span>
                      </template>
                      <template v-slot:item="{ item }">
                        <div :style="colorBoxlStyle(item.color)"></div>
                        <span class="pl-2">{{ item.label }}</span>
                      </template>
                    </v-select>
                  </div>
                </template>
                <!-- カード補助情報(ヘッダー) -->
                <template v-slot:[`header.filecheck`]="{ header }">
                  <div class="filecheck-header">
                    <span style="flex:6">
                      {{ header.text }}
                    </span>
                    <!-- 一括チェックボタン -->
                    <v-btn style="flex:1" icon x-small @click="oneTimeCheck">
                      <v-icon>mdi-checkbox-multiple-marked</v-icon>
                    </v-btn>
                    <!-- 一括チェック外しボタン -->
                    <v-btn style="flex:1" class="mr-4" icon x-small @click="oneTimeUncheck">
                      <v-icon>mdi-checkbox-multiple-blank-outline</v-icon>
                    </v-btn>
                  </div>
                </template>
                <!-- カード補助情報(コンテンツ) -->
                <template v-slot:[`item.filecheck`]="{ item }">
                  <div class="filecheck-item">
                    <div
                      v-for="(file, index) in fileList"
                      :key="index"
                      class="checkbox"
                    >
                      <!-- 親カードの補助情報が存在であれば、チェックボックスが活性にする -->
                      <template v-if="file.name">
                        <div class="checkbox-label">
                          <span>No.{{ index + 1 }}</span>
                        </div>
                        <v-checkbox
                          v-model="item.filecheck[index]"
                          small
                          @change="checkbox(item, index)"
                        >
                        </v-checkbox>
                      </template>
                      <!-- 親カードの補助情報が存在しなければ、チェックボックスが非活性にする -->
                      <template v-else>
                        <div class="checkbox-label">
                          <span>No.{{ index + 1 }}</span>
                        </div>
                        <v-btn class="checkbox-inactive" icon disabled>
                          <v-icon>mdi-checkbox-blank-off-outline</v-icon>
                        </v-btn>
                      </template>
                    </div>
                  </div>
                </template>
                <!-- 行削除の✕ボタン(ヘッダー) -->
                <template v-slot:[`header.delete`]>
                  <div class="mr-10"></div>
                </template>
                <!-- 行削除の✕ボタン -->
                <template v-slot:[`item.delete`]="{ item }">
                  <v-icon v-if="items.length > 1" small @click="deleteRow(item)">
                    mdi-close
                  </v-icon>
                </template>
              </v-data-table>
              <!-- 行追加ボタン -->
              <div class="text-right addbtnarea">
                <v-btn outlined class="btn" @click="addRow">{{ $t("WORD_ADD") }}</v-btn>
              </div>
            </v-card>
          </div>
          <!-- 作成ボタン -->
          <div class="text-right createbtnarea">
            <v-btn outlined class="btn" @click="createCard" v-shortkey.once="['shift', 's']" @shortkey="keyCreate()">{{ $t("WORD_CREATE") }}</v-btn>
          </div>
        </v-card>
      </div>
    </v-form>
  </v-dialog>
</template>

<script>
import Define from "@/define.js";
import * as Util from "@/util/utils.js";
import Swal from "sweetalert2";
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("tile");
const { mapGetters } = createNamespacedHelpers("setting");
import EditingItem from "@/components/parts/EditingItem";
import { mixinControl } from "@/mixins/mixinControl.js";
import { mixinError } from "@/mixins/mixinError.js";
export default {
  components: {
    EditingItem
  },
  mixins: [mixinControl, mixinError],
  data() {
    return {
      tempTileInfo: {},
      headers: [],
      items: [],
      itemData: {},
      fileCheckList: [false, false, false, false],

      TYPE_CALC: Define.TYPE_CALC
    };
  },
  computed: {
    ...mapGetters(["getMappingList"]),
    isOpenCardDetailMultipleCopy() {
      return this.$store.getters["tile/isOpenCardDetailMultipleCopy"];
    },
    cardDetailInfo() {
      return this.$store.getters["tile/cardDetailInfo"];
    },
    colors() {
      return this.getCardColors();
    },
    fileList() {
      return this.$store.getters["tile/copyMultipleCardFileList"];
    },
    getType() {
      return id => {
        return this.$store.getters["setting/getMappingType"](id);
      };
    },
    hasKeySettings() {
      return Util.hasKeySettings(this.getMappingList);
    },
    isKeySettings() {
      return mappingId => {
        return Util.isKeySettings(this.getMappingList, mappingId);
      };
    },
    rules() {
      return mappingId => {
        if (Util.isKeySettings(this.getMappingList, mappingId)) {
          return [
            value => Util.checkNotEmpty(value) || this.$t("V_ERR_MSG_KEY_IS_EMPTY")
          ];
        } else {
          return [];
        }
      };
    },
    selectedWorkspaceId() {
      return this.$store.getters["auth/selectedWorkSpaceId"];
    },
    getInputType() {
      return id => {
        let inputType = this.$store.getters["setting/getMappingInputType"](id);
        // カード詳細画面では、「標準入力」及び「リスト選択入力」以外は見た目に統一感がなくなるので「標準入力」に戻す
        if (
          inputType !== Define.INPUT_TYPE_DEFAULT &&
          inputType !== Define.INPUT_TYPE_LIST
        ) {
          inputType = Define.INPUT_TYPE_DEFAULT;
        }
        return inputType;
      };
    },
    listDataItems() {
      return (category, value) => {
        let items = this.$store.getters["info/getInfoDataItems"](category);
        // 設定値が現在選択中のカテゴリに存在しない場合はリストの先頭に追加する
        if (value !== "" && !items.some(d => d.text === value)) {
          items.unshift({ text: value, value: value });
        }
        return items;
      };
    },
    getCategory() {
      return id => {
        return this.$store.getters["setting/getMappingCategory"](id);
      };
    }
  },
  watch: {
    isOpenCardDetailMultipleCopy: {
      immediate: true,
      handler: function(value) {
        if (value) {
          // カード情報取得
          this.tempTileInfo = Util.parseObject(this.cardDetailInfo);
          // ヘッダーとコンテンツの情報を作成
          let headerData = [];
          // 複製カードの順番数
          this.itemData.num = 1;
          // データテーブルの二行目からfor文でカードデータを埋める
          for (let i = 0; i < this.tempTileInfo.tileDetailData.length; i++) {
            // ヘッダー部
            headerData[i] = {
              mappingId: this.tempTileInfo.tileDetailData[i].mappingId,
              text: this.mappingName(
                this.tempTileInfo.tileDetailData[i].mappingId
              ),
              value: `data${i}`,
              sortable: false
            };
            // ヘッダーの幅をデータ形式により、動的に設定する(文字列:240px, 数字:160px, 日付:250px)
            let dataType = this.getType(this.tempTileInfo.tileDetailData[i].mappingId);
            dataType == 0
              ? (headerData[i].width = "240px")
              : dataType == 1 || dataType == 2
              ? (headerData[i].width = "160px")
              : (headerData[i].width = "250px");
            // コンテンツ部
            if (
              this.isKeySettings(this.tempTileInfo.tileDetailData[i].mappingId)
            ) {
              // 重複キーである列のデータデフォルト値を未設定にする
              this.itemData[[`data${i}`]] = "";
            } else {
              // 重複キーではない列に、親カードの情報をもとにデフォルト値設定する
              this.itemData[[`data${i}`]] = this.tempTileInfo.tileDetailData[i].value;
            }
          }
          // カード色設定
          let colorHeader = {
            mappingId: headerData.length,
            text: this.$t("PAGE_SUBTITLE_CARD_COLOR"),
            value: "color",
            sortable: false,
            width: "240px"
          };
          headerData.push(colorHeader);
          this.itemData[["color"]] = this.tempTileInfo.color;
          // カード補助情報
          let fileHeader = {
            mappingId: headerData.length,
            text: this.$t("PAGE_TITLE_CARD_ASSIST"),
            value: "filecheck",
            sortable: false,
            width: "320px"
          };
          headerData.push(fileHeader);
          // 親カード補助情報の有/無による、チェックボックスの初期値をtrue/falseに設定する
          for (let i = 0; i < this.fileList.length; i++) {
            if (this.fileList[i].name) {
              this.fileCheckList[i] = true;
            }
          }
          this.itemData[["filecheck"]] = JSON.parse(
            JSON.stringify(this.fileCheckList)
          );
          // 親カードの補助情報を複製カードに移行する
          this.itemData[["filelist"]] = JSON.parse(
            JSON.stringify(this.fileList)
          );
          // ✕ボタン
          let deletHeader = {
            mappingId: headerData.length,
            text: "",
            value: "delete",
            sortable: false,
            width: "40px"
          };
          headerData.push(deletHeader);
          // 生成したヘッダーとコンテンツをそれぞれdata()に渡す
          this.headers = headerData;
          this.items = [];
          this.items[0] = JSON.parse(JSON.stringify(this.itemData));
        } else {
          this.tempTileInfo = [];
        }
      }
    }
  },
  methods: {
    ...mapActions(["createTileData"]),
    /**
     * マッピングIDからデータ名称文字列を返す
     * @param {String} id - マッピングID
     * @returns {String} - データ名称
     */
    mappingName(id) {
      if (!id) return ""; // 描画のタイミング（初期化時）を考慮
      return this.$store.getters["setting/getMappingName"](id);
    },
    colorBoxlStyle(color) {
      return [Define.STYLE_COLOR_BOX, { "background-color": color }];
    },
    /**
     * 複製カードの枚数を増やす処理、及び、追加するカードの情報生成
     */
    addRow() {
      if (this.items.length > 9) {
        this.showErrorMsg(Define.ERR_COPY_OVER_10);
        return;
      } else {
        // 追加カードのデータ生成
        let copyItem = JSON.parse(JSON.stringify(this.itemData));
        // 追加カードの番号設定
        copyItem.num = this.items.length + 1;
        // データテーブルのコンテンツの末尾に追加カードデータを追加する
        this.items.push(copyItem);
      }
    },
    /**
     * 複製するカードの枚数を減らす
     */
    deleteRow(item) {
      const index = this.items.findIndex(elm => elm.num == item.num);
      this.items.splice(index, 1);
    },
    /**
     * カード作成イベント
     */
    async createCard() {
      // データテーブル内に入力ルール違反のデータがあればエラー表示
      if (!this.$refs.form.validate()) {
        this.showErrorMsg(Define.ERR_INPUT_ITEM_INVALID);
        return;
      }
      let freeAreaTileNum = this.$store.getters["tile/getFreeTileNum"];
      if (freeAreaTileNum + this.items.length > Define.FREEAREA_MAX_TILENUM) {
        Swal.fire({
          title: this.$t("MSG_ERR_MAIN_TILE_COPY"),
          html: this.$t("MSG_ERR_SUB_TILE_COPY"),
          icon: "error"
        });
        return;
      }
      // 重複キーが設定してあるかつ、ダイアログ内に内容の同じカードがある場合、エラー表示
      if (this.hasKeySettings && this.checkSameKeyTileInDataTable()) {
        this.showErrorMsg(Define.ERR_VIOLATE_UNIQUE_KEY);
        return;
      } else if (await this.checkSameKeyTile()) {
        // データテーブル内のカードと既存しているカードが重複キールールに違反する場合、エラー表示
        this.showErrorMsg(Define.ERR_VIOLATE_UNIQUE_KEY);
        return;
      } else {
        // データテーブル内に全てのカードデータを持つ配列
        const tileDataList = [];
        // データテーブルの行ごとのデータを用いて、カード情報を作成する
        for (let num = 0; num < this.items.length; num++) {
          // カード作成するため、データテーブルのヘッダーとコンテンツから
          // mappingIdとvalueをtileDetailDataにまとめる
          const tileDetailData = [];
          for (let i = 0; i < this.headers.length - 3; i++) {
            let detail = {};
            detail.mappingId = JSON.parse(
              JSON.stringify(this.headers[i].mappingId)
            );
            detail.value = JSON.parse(
              JSON.stringify(this.items[num][[this.headers[i].value]])
            );
            tileDetailData.push(detail);
          }
          // カード補助情報を持つ配列
          const fileDataList = [];
          this.items[num].filelist.forEach(element => {
            if (element.isEdited && element.source) {
              fileDataList.push(element);
            }
          });
          let result = {
            pos_col: null,
            pos_row: null,
            is_complete: false,
            color: this.items[num].color,
            order: String(freeAreaTileNum + 1),
            is_confirmed: false,
            status: 0,
            tile_detail_data: [],
            file_data_list: fileDataList,
            tile_move_history_data: {
              history_type: Define.HISTORY_TYPE_MOVED,
              pos_col_before: null,
              pos_col_after: null,
              pos_row_before: null,
              pos_row_after: null
            }
          };
          if (!Util.checkArrayLength(this.tempTileInfo.tileDetailData)) {
            this.showErrorMsg(Define.ERR_MAP_NO_SETTINGS);
          } else {
            for (let i in tileDetailData) {
              let dataType = this.$store.getters["setting/getMappingType"](
                tileDetailData[i].mappingId
              );
              let value =
                dataType === Define.TYPE_FLOAT &&
                Util.checkNotEmpty(tileDetailData[i].value)
                  ? String(Util.computeRoundNum(
                      tileDetailData[i].value,
                      Define.ROUND_NUM
                    ))
                  : tileDetailData[i].value;
              let get = {
                mapping_id: tileDetailData[i].mappingId,
                value: value
              };
              result.tile_detail_data.push(get);
            }
            let pageNum = Math.ceil(
              (freeAreaTileNum + 1) / Define.PLANAREA_MAX_PAGE_NUM
            );
            freeAreaTileNum++;
            this.$store.commit("tile/setPageNo", pageNum);
          }
          // カードごとのデータ情報を配列tileDataListにまとめる
          tileDataList.push(result);
        }
        // テーブル内のカードデータを確認した上、エラーがなければ、カード作成処理に移す
        this.createTileData({ tileData: tileDataList });
      }
      this.closeMultipleDetail();
    },
    /**
     * 重複キーであるカードデータのみitemsから取り出す
     */
    keyValueLists() {
      const tileDetailDataList = [];
      this.items.forEach(item => {
        // 一個のカードの重複キーであるデータ配列
        const tileDetailData = [];
        for (let i = 0; i < this.tempTileInfo.tileDetailData.length; i++) {
          if (
            this.isKeySettings(this.tempTileInfo.tileDetailData[i].mappingId)
          ) {
            // 重複キーである列のデータを配列keySetArrayに入れる
            let detail = {};
            detail.mappingId = JSON.parse(
              JSON.stringify(this.tempTileInfo.tileDetailData[i].mappingId)
            );
            detail.value = JSON.parse(JSON.stringify(item[`data${i}`]));
            tileDetailData.push(detail);
          }
        }
        // カードの重複キーであるデータの配列を一つずつ文字列に変換してからまとめる
        tileDetailDataList.push(tileDetailData);
      });
      return tileDetailDataList;
    },
    async getTileDataByKey(keyValueLists) {
      const workSpaceId = this.selectedWorkspaceId;
      const data = {
        work_space_id: workSpaceId,
        key_value_lists: keyValueLists
      };
      return await Util.axiosPostReq(
        Define.API_GET_MULTIPLE_TILE_DATE_BY_KEY,
        data,
        false
      );
    },
    /**
     * データテーブル内のカードと既存しているカードが重複キールールに違反か確認
     */
    async checkSameKeyTile() {
      let keyValueLists = this.keyValueLists();
      const response = await this.getTileDataByKey(keyValueLists);
      // データが空じゃない場合（つまり同一キーのデータが存在）
      return Object.keys(response.data).length > 0;
    },
    /**
     * データテーブル内に内容の同じカードが存在しているか確認
     */
    checkSameKeyTileInDataTable() {
      let result = false;
      // データテーブル内に、全カードの重複キーであるデータ配列を持つ配列
      let keySetArrayList = [];
      this.items.forEach(item => {
        // 一個のカードの重複キーであるデータ配列
        let keySetArray = [];
        for (let i = 0; i < this.tempTileInfo.tileDetailData.length; i++) {
          if (
            this.isKeySettings(this.tempTileInfo.tileDetailData[i].mappingId)
          ) {
            // 重複キーである列のデータを配列keySetArrayに入れる
            keySetArray.push(item[`data${i}`]);
          }
        }
        // カードの重複キーであるデータの配列を一つずつ文字列に変換してからまとめる
        keySetArrayList.push(JSON.stringify(keySetArray));
      });
      if (this.hasDuplicateElement(keySetArrayList)) {
        result = true;
      }
      return result;
    },
    /**
     * 配列中に重複要素があるか確認
     * @param {Array} array - マッピングID
     * @returns {Boolean} - 確認結果
     */
    hasDuplicateElement(array) {
      let result = false;
      // array中の重複要素を外し、重複要素なしのarrayNoDuplicateを作成する
      let arrayNoDuplicate = array.filter((el, index) => {
        return array.indexOf(el) === index;
      });
      if (arrayNoDuplicate.length !== array.length) {
        result = true;
      }
      return result;
    },
    showErrorMsg(code) {
      if (code === Define.ERR_MAP_NO_SETTINGS) {
        Swal.fire({
          html: this.$t("MSG_SETTING_MAP_DATA"),
          icon: "error"
        });
      } else {
        let msg = this.createErrorMessageBySettingSave(0, code);
        Swal.fire({
          title: msg.mainText,
          text: msg.subText,
          icon: "error"
        });
      }
    },
    closeMultipleDetail() {
      this.$store.commit("tile/setIsOpenCardDetailMultipleCopy", false);
    },
    /**
     * 活性のチェックボックスにチェックを入れた場合、親カードの補助情報を複製する
     * チェックを外した場合、親カードの補助情報を複製しない
     */
    checkbox(item, index) {
      let num = item.num;
      let filecheck = item.filecheck;
      if (!filecheck[index]) {
        this.items[num - 1].filelist[index].name = "";
        this.items[num - 1].filelist[index].source = "";
      } else {
        this.items[num - 1].filelist[index] = JSON.parse(
          JSON.stringify(this.fileList[index])
        );
      }
    },
    oneTimeCheck() {
      this.items.forEach(item => {
        item.filecheck = JSON.parse(JSON.stringify(this.fileCheckList));
        item.filelist = JSON.parse(JSON.stringify(this.fileList));
      });
    },
    oneTimeUncheck() {
      this.items.forEach(item => {
        item.filecheck = [false, false, false, false];
        item.filelist.forEach(file => {
          file.name = "";
          file.source = "";
        });
      });
    },
    /**
     * [ショートカットキー shift+s]作成
     */
    keyCreate() {
      if(this.isOpenCardDetailMultipleCopy){
        this.createCard();
      }
    },
  }
};
</script>

<style scoped>
.overlay {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
.overlayarea {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.cardsize {
  margin-top: 20px;
  width: 1700px;
  max-height: 930px;
  display: flex;
  flex-direction: column;
}
.datawrap {
  border: solid 1px #aaa;
}
.areamargin {
  margin: 0 10px 10px 10px;
}
.subtitle {
  border-left: solid 6px #1976d2ca;
  color: #333;
  padding-left: 4px;
  margin: 0 4px 8px 15px;
}
.datatable {
  width: 99%;
  max-height: 800px;
  padding: 0 15px 0 15px;
  right: 0;
}
.btn {
  margin-right: 4px;
  color: #fff;
  background-color: #2196f3;
  border-color: #2196f3;
}
.addbtnarea {
  padding: 8px;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}
.createbtnarea {
  margin-top: auto;
  margin-bottom: 15px;
  margin-right: 15px;
}
.filecheck-header {
  display: flex;
  align-items: center;
}
.filecheck-item {
  margin-right: -8px;
  display: flex;
}
.checkbox {
  height: 66px;
  margin-right: 8px;
  display: flex;
  flex: 1;
}
.checkbox-inactive {
  margin-top: auto;
  margin-bottom: 16px;
  margin-left: -5px;
}
.checkbox-label {
  margin-top: auto;
  margin-bottom: 24px;
  margin-right: 2px;
}
</style>
