import Vue from "vue";
import VueRouter from "vue-router";
import TopPage from "@/components/pages/TopPage.vue";
import PlanBoard from "@/components/pages/PlanBoardPage.vue";
import Today from "@/components/pages/TodayPage.vue";
import InputFile from "@/components/pages/InputFilePage.vue";
import OutputFile from "@/components/pages/OutputFilePage.vue";
import ApplicationSetting from "@/components/pages/ApplicationSettingPage.vue";
import Management from "@/components/pages/ManagementPage.vue";
import MobileJisseki from "@/components/mobile/pages/MobileJisseki.vue";
import MobileSetting from "@/components/mobile/pages/MobileSetting.vue";
import Login from "@/components/pages/LoginPage.vue";
import ResetMail from "@/components/pages/PasswordResetMailPage.vue";
import Reset from "@/components/pages/PasswordResetPage.vue";
import Activate from "@/components/pages/ActivatePage.vue";
import TenantRegist from "@/components/pages/TenantRegistPage.vue";
import TenantRegistConfirm from "@/components/pages/TenantRegistConfirmPage.vue";
import ShowImage from "@/components/pages/ShowImage.vue";
import store from "../store";
import WebSocketMiddleware from "./websocket";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: TopPage,
    meta: {
      name: "TopPagee",
      requiresAuth: true
    },
    redirect: {
      path: "/planboard"
    },
    children: [
      {
        path: "/planboard",
        meta: {
          name: "PlanBoard",
          requiresAuth: true,
          ws: route => route.path
        },
        component: PlanBoard
      },
      {
        path: "/today",
        meta: {
          name: "Today",
          requiresAuth: true,
          ws: route => route.path
        },
        component: Today
      },
      {
        path: "/inputfile",
        meta: {
          name: "InputFile",
          requiresAuth: true,
          ws: route => route.path
        },
        component: InputFile
      },
      {
        path: "/outputfile",
        meta: {
          name: "OutputFile",
          requiresAuth: true,
          ws: route => route.path
        },
        component: OutputFile
      },
      {
        path: "/applicationsetting",
        meta: {
          name: "ApplicationSetting",
          requiresAuth: true,
          ws: route => route.path
        },
        component: ApplicationSetting
      },
      {
        path: "/management",
        meta: {
          name: "Management",
          requiresAuth: true,
          ws: route => route.path
        },
        component: Management
      },
      {
        path: "/mobilejisseki",
        meta: {
          name: "MobileJisseki",
          requiresAuth: true,
          ws: route => route.path
        },
        component: MobileJisseki
      },
      {
        path: "/mobilesetting",
        meta: {
          name: "MobileSetting",
          requiresAuth: true,
          ws: route => route.path
        },
        component: MobileSetting
      }
    ]
  },
  {
    path: "/login",
    meta: {
      name: "Login",
      requiresAuth: false
    },
    component: Login
  },
  {
    path: "/reset",
    meta: {
      name: "Reset",
      requiresAuth: false
    },
    component: Reset
  },
  {
    path: "/resetmail",
    meta: {
      name: "ResetMail",
      requiresAuth: false
    },
    component: ResetMail
  },
  {
    path: "/activate",
    meta: {
      name: "Activate",
      requiresAuth: false
    },
    component: Activate
  },
  {
    path: "/tenantregist",
    meta: {
      name: "TenantRegist",
      requiresAuth: false
    },
    component: TenantRegist
  },
  {
    path: "/tenantregistconfirm",
    meta: {
      name: "TenantRegistConfirm",
      requiresAuth: false
    },
    component: TenantRegistConfirm
  },
  {
    path: "/show-image/:tileId/:index/",
    meta: {
      name: "show-image",
      requiresAuth: true
    },
    component: ShowImage
  }
];

const router = new VueRouter({
  routes
});

// ブラウザバック禁止
window.history.pushState(null, null, window.location.href);
window.addEventListener('popstate', () => {
  if(store.getters["tile/isOpenMobileCardDetail"]){
    store.commit("tile/setIsOpenMobileCardDetail", false);
  }
  history.go(1);
});

// 全てのルートでログインチェックを行い、ログインしていない場合はログイン画面へ飛ばす
router.beforeEach((to, from, next) => {

  // 認証不要なページはチェック無しで遷移する
  if (!to.matched.some(record => record.meta.requiresAuth)) {
    next();
    return;
  }

  // 認証必要なページは認証済みであれば遷移する
  // 認証済みでなければログインページ
  if (store.getters["auth/isLoggedIn"]) {
    next();
    return;
  } else {
    next("/login");
    return;
  }
});

WebSocketMiddleware(router);
export default router;
