<template>
  <div class="line-flame">
    <!-- 計画エリア名 -->
    <div class="linecolor">
      <v-tooltip
        left
        open-delay="300"
        color="rgba(60,64,67,0.95)"
        transition="fade"
      >
        <template v-slot:activator="{ on: disp }">
          <div class="linename" v-on="disp">
            {{ orthopedyName(tileLoadListByArea.lineName) }}
          </div>
        </template>
        <div>
          <div v-for="(tooltipText, index) in tooltipTextArray" :key="index">
            {{ tooltipText }}
          </div>
        </div>
      </v-tooltip>
    </div>
    <!-- カード枚数/負荷表示 -->
    <MonthViewListProgressbar 
      v-for="tileLoadListByDate in tileLoadListByArea.tileLoadList"
      :tileLoadListByDate="tileLoadListByDate"
      :lineId="tileLoadListByArea.lineId"
      :progressbarFlg="tileLoadListByArea.progressbarFlg"
      :max="tileLoadListByArea.max"
      :key="tileLoadListByDate.dateInfo"
      @changeDate="changeDate($event)"
      @close="closeDialog()"
    />
  </div>
</template>

<script>
import * as Util from "@/util/utils.js";
import Define from "@/define.js";
import MonthViewListProgressbar from "@/components/parts/MonthViewListProgressbar.vue";
export default {
  components: {
    MonthViewListProgressbar
  },
  props: {
    tileLoadListByArea: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    tooltipTextArray() {
      return Util.splitStringBySpace(this.tileLoadListByArea.lineName);
    }
  },
  methods: {
    /**
     * 行タイトルを表示文字数上限で切り、それ以降を「...」とする
     * @param {String} getText - 行タイトル
     * @returns {String} - 整形後行タイトル
     */
    orthopedyName(getText) {
      let maxLineName = this.tileLoadListByArea.progressbarFlg ? Define.MAX_MONTH_VIEW_LINE_NAME : Define.MAX_MONTH_VIEW_LINE_NAME_NO_LOAD;
      if(getText === this.$t("WORD_NOTSET")) {maxLineName = Define.MAX_MONTH_VIEW_LINE_NAME_NOT_SET;}
      return Util.checkNotUndefined(getText)
        ? Util.orthopedyText(getText, maxLineName)
        : "";
    },
    /**
     * 日付変更を親部品に通知する
     */
    changeDate(date) {
      this.$emit("changeDate", date);
    },
    /**
     * クローズイベントを親部品に通知する
     */
    closeDialog() {
      this.$emit("close");
    }
  }
};
</script>

<style scoped>
.line-flame {
  display: flex;
  height: auto;
  margin-top: 3px;
  margin-bottom: 3px;
  background-color: #fff;
}
.linename {
  white-space: normal;
  padding: 2px;
  min-width: 100px;
  width: 100px;
  height: calc(100% - 21px);
  color: #fff;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}
.linecolor {
  background-color: #1976d2ca;
}
</style>
