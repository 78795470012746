<template>
  <v-select
    v-model="selectedLanguage"
    dense
    outlined
    hide-details
    :items="languages"
  ></v-select>
</template>

<script>
export default {
  computed: {
    selectedLanguage: {
      set(value) {
        this.$i18n.locale = value;
        this.$store.dispatch("global/setSelectedLanguage", value);
      },
      get() {
        return this.$store.getters["global/selectedLanguage"];
      }
    },
    languages() {
      return [
        { text: "日本語", value: "ja" },
        { text: "English", value: "en" }
      ];
    }
  }
};
</script>