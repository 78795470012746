<template>
  <!-- カード色指定条件 -->
  <div :style="dataAreaStyle">
    <v-data-table
      :style="tableAreaStyle"
      :headers="colorConditionsHeaders"
      item-key="id"
      :items="colorConditionList"
      hide-default-footer
    >
      <!-- データ名称 -->
      <template v-slot:[`item.mappingId`]="{ item }">
        <v-select
          v-model="item.mappingId"
          dense
          outlined
          hide-details="auto"
          :disabled="userRole"
          :items="mappingDataList"
          item-text="name"
          item-value="mappingId"
          :rules="[check_required]"
        ></v-select>
      </template>
      <!-- 条件 -->
      <template v-slot:[`item.condition`]="{ item }">
        <v-select
          v-model="item.operator"
          dense
          outlined
          hide-details="auto"
          :disabled="userRole"
          :items="conditions"
          item-value="conditionId"
          item-text="label"
          :rules="[check_required]"
        ></v-select>
      </template>
      <!-- 比較値 -->
      <template v-slot:[`item.value`]="{ item }">
        <ColorConditionSettingsEditingItem
          v-model="item.value"
          :type="getType(item.mappingId)"
          :calcObject1Type="getCalcObject1Type(item.mappingId)"
          :editable="!userRole"
          :rules="[]"
        ></ColorConditionSettingsEditingItem>
      </template>
      <!-- 色 -->
      <template v-slot:[`item.color`]="{ item }">
        <v-select
          v-model="item.color"
          dense
          outlined
          hide-details="auto"
          :disabled="userRole"
          :items="colors"
          item-value="color"
          item-text="label"
          :rules="[check_required]"
        >
          <template v-slot:selection="{ item }">
            <div :style="colorBoxlStyle(item.color)"></div>
            <span class="pl-2">{{ item.label }}</span>
          </template>
          <template v-slot:item="{ item }">
            <div :style="colorBoxlStyle(item.color)"></div>
            <span class="pl-2">{{ item.label }}</span>
          </template>
        </v-select>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-icon
          small
          :disabled="userRole"
          @click="deleteColorConditionRow(item)"
        >
          mdi-close
        </v-icon>
      </template>
    </v-data-table>
    <div class="flexarea">
      <v-spacer></v-spacer>
      <v-btn :disabled="userRole" class="info" @click="addColorConditionList">
        {{ $t("WORD_ADD") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import * as Util from "@/util/utils.js";
import Define from "@/define.js";
import ColorConditionSettingsEditingItem from "@/components/parts/ColorConditionSettingsEditingItem";
import { mixinValidator } from "@/mixins/mixinValidator.js";
export default {
  name: "ColorConditionSettings",
  components: {
    ColorConditionSettingsEditingItem
  },
  mixins: [mixinValidator],
  props: {
    colorConditionList: { type: Array, default: () => [], require: true },
    mappingDataList: { type: Array, default: () => [], require: true },
    userRole: { type: Boolean, default: true, require: true }
  },
  data() {
    return {
      conditions: Define.conditions,
      dataAreaStyle: Define.STYLE_CARD_COLOR_DATA_AREA,
      areaTitleStyle: Define.STYLE_AREA_TITLE,
      tableAreaStyle: Define.STYLE_TABLE_AREA,
      saveBtnStyle: Define.STYLE_BAGE_BTN,

      check_required: value => this.isValidRequired(value)
    };
  },
  computed: {
    getType() {
      return id => {
        const mappingData = this.mappingDataList.find(
          item => item.mappingId === id
        );
        return mappingData ? mappingData.type : Define.TYPE_NOTHING;
      };
    },
    getCalcObject1Type() {
      return mappingId => {
        return this.$store.getters["setting/getCalcObject1Type"](mappingId);
      };
    },
    colorConditionsHeaders() {
      return [
        {
          text: this.$t("TABLE_COLOR_CONDITION_DATA_NAME"),
          sortable: false,
          value: "mappingId",
          width: "30%"
        },
        {
          text: this.$t("TABLE_COLOR_CONDITION_CONDITION"),
          sortable: false,
          value: "condition",
          width: "10%"
        },
        {
          text: this.$t("TABLE_COLOR_CONDITION_VALUE"),
          sortable: false,
          value: "value",
          width: "35%"
        },
        {
          text: this.$t("TABLE_COLOR_CONDITION_COLOR"),
          sortable: false,
          value: "color",
          width: "20%"
        },
        {
          text: "",
          sortable: false,
          value: "action"
        }
      ];
    },
    colors() {
      return [
        this.$t("COLOR_DEFAULT"),
        this.$t("COLOR_RED"),
        this.$t("COLOR_ORANGE"),
        this.$t("COLOR_YELLOW"),
        this.$t("COLOR_GREEN"),
        this.$t("COLOR_PURPLE"),
        this.$t("COLOR_BLUE"),
        this.$t("COLOR_PINK"),
        this.$t("COLOR_LIME"),
        this.$t("COLOR_CYAN"),
        this.$t("COLOR_WHITE")
      ];
    }
  },
  watch: {
    mappingDataList: {
      immediate: true,
      deep: true,
      handler: function(list) {
        this.colorConditionList.forEach(element => {
          if (!list.some(col => col.mappingId == element.mappingId)) {
            element.mappingId = "";
          }
        });
      }
    }
  },
  beforeMount() {
    this.initiaize();
  },
  methods: {
    initiaize() {},
    colorBoxlStyle(color) {
      return [Define.STYLE_COLOR_BOX, { "background-color": color }];
    },
    deleteColorConditionRow(item) {
      // 新規追加した行の場合、複数あった際に区別できないため「index」を設けている。
      //  ←追加する際に振っている
      // 削除した際に振り直しをしておく
      this.colorConditionList.splice(item.index, 1);
      for (let i = 0; i < this.colorConditionList.length; i++) {
        this.colorConditionList[i].index = i;
      }
    },
    addColorConditionList() {
      if (
        Util.checkNotUndefined(this.colorConditionList) &&
        this.colorConditionList.length >=
          Define.APP_SETTING_ADD_LIMIT_COLOR_CONDITIONS
      ) {
        this.addLimitColorConditionsDialog();
        return;
      }
      this.colorConditionList.push({
        index: this.colorConditionList.length,
        mappingId: "",
        operator: "",
        value: "",
        color: ""
      });
    },
    addLimitColorConditionsDialog() {
      Swal.fire({
        icon: "error",
        title: this.$t("MSG_ERR_MAIN_DATA_ADD"),
        text: this.$t("MSG_ERR_SUB_COLOR_ADD", { limit: Define.APP_SETTING_ADD_LIMIT_COLOR_CONDITIONS }),
      });
    }
  }
};
</script>
