// 定義
export default Object.freeze({
  PLAN_TILELIST_TEMPLATE_7: {
    tileList: [
      {
        lineId: null,
        lineName: "未設定",
        max: 0,
        progressbarFlg: false,
        tileList: [
          // eslint-disable-next-line prettier/prettier
          { dateInfo: "0", progressbarValue: 0, tileList: [ { tileId: null, displayData: [], tileDetailData: [] } ] },
          // eslint-disable-next-line prettier/prettier
          { dateInfo: "1", progressbarValue: 0, tileList: [ { tileId: null, displayData: [], tileDetailData: [] } ] },
          // eslint-disable-next-line prettier/prettier
          { dateInfo: "2", progressbarValue: 0, tileList: [ { tileId: null, displayData: [], tileDetailData: [] } ] },
          // eslint-disable-next-line prettier/prettier
          { dateInfo: "3", progressbarValue: 0, tileList: [ { tileId: null, displayData: [], tileDetailData: [] } ] },
          // eslint-disable-next-line prettier/prettier
          { dateInfo: "4", progressbarValue: 0, tileList: [ { tileId: null, displayData: [], tileDetailData: [] } ] },
          // eslint-disable-next-line prettier/prettier
          { dateInfo: "5", progressbarValue: 0, tileList: [ { tileId: null, displayData: [], tileDetailData: [] } ] },
          // eslint-disable-next-line prettier/prettier
          { dateInfo: "6", progressbarValue: 0, tileList: [ { tileId: null, displayData: [], tileDetailData: [] } ] }
        ]
      }
    ]
  },
  PLAN_TILELIST_TEMPLATE_10: {
    tileList: [
      {
        lineId: null,
        lineName: "未設定",
        max: 0,
        progressbarFlg: false,
        tileList: [
          // eslint-disable-next-line prettier/prettier
          { dateInfo: "0", progressbarValue: 0, tileList: [ { tileId: null, displayData: [], tileDetailData: [] } ] },
          // eslint-disable-next-line prettier/prettier
          { dateInfo: "1", progressbarValue: 0, tileList: [ { tileId: null, displayData: [], tileDetailData: [] } ] },
          // eslint-disable-next-line prettier/prettier
          { dateInfo: "2", progressbarValue: 0, tileList: [ { tileId: null, displayData: [], tileDetailData: [] } ] },
          // eslint-disable-next-line prettier/prettier
          { dateInfo: "3", progressbarValue: 0, tileList: [ { tileId: null, displayData: [], tileDetailData: [] } ] },
          // eslint-disable-next-line prettier/prettier
          { dateInfo: "4", progressbarValue: 0, tileList: [ { tileId: null, displayData: [], tileDetailData: [] } ] },
          // eslint-disable-next-line prettier/prettier
          { dateInfo: "5", progressbarValue: 0, tileList: [ { tileId: null, displayData: [], tileDetailData: [] } ] },
          // eslint-disable-next-line prettier/prettier
          { dateInfo: "6", progressbarValue: 0, tileList: [ { tileId: null, displayData: [], tileDetailData: [] } ] },
          // eslint-disable-next-line prettier/prettier
          { dateInfo: "7", progressbarValue: 0, tileList: [ { tileId: null, displayData: [], tileDetailData: [] } ] },
          // eslint-disable-next-line prettier/prettier
          { dateInfo: "8", progressbarValue: 0, tileList: [ { tileId: null, displayData: [], tileDetailData: [] } ] },
          // eslint-disable-next-line prettier/prettier
          { dateInfo: "9", progressbarValue: 0, tileList: [ { tileId: null, displayData: [], tileDetailData: [] } ] },
        ]
      }
    ]
  },
  FREE_TILELIST_TEMPLATE: {
    tileList: {
      lineId: null,
      lineName: null,
      max: 0,
      progressbarFlg: false,
      tileList: [
        // eslint-disable-next-line prettier/prettier
        { dateInfo: null, progressbarValue: 0, tileList: [ { tileId: null, displayData: [], tileDetailData: [] } ] }
      ]
    }
  },
  CARD_DETAIL_TEMPLATE: {
    tileId: "",
    isComplete: false,
    order: 0,
    posCol: "",
    posRow: "",
    isConfirmed: false,
    status: 0,
    displayData: [],
    tileDetailData: []
  }
});
