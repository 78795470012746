<template>
  <div v-if="items.length > 0">
    <v-card flat class="jisseki-table">
      <v-data-table
        :headers="headers"
        :items="items"
        dense
        hide-default-footer
      >
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import Define from "@/define.js";
import * as Util from "@/util/utils.js";
export default {
  data() {
    return {
      headers: []
    };
  },
  computed: {
    isOpenMobileCardDetail() {
      return this.$store.getters["tile/isOpenMobileCardDetail"];
    },
    tileInfo() {
      return this.$store.getters["tile/cardDetailInfo"];
    },
    jissekiMappings() {
      return this.$store.getters["setting/getJissekiMappings"];
    },
    items() {
      return this.$store.getters["tile/cardJissekiInfo"];
    },
    authCardAction() {
      let user = this.$store.getters["auth/user"];
      let role = this.$store.getters["role/roleByWorkspaceId"](this.selectedWorkspaceId, user.role);
      return Util.getAuthority(Define.EVENT_CARD_ACTION, role);
    },
    selectedWorkspaceId() {
      return this.$store.getters["auth/selectedWorkSpaceId"];
    },
    isUpdatingJisseki() {
      return this.$store.getters["isUpdatingJisseki"];
    },
    isDeletingJisseki() {
      return this.$store.getters["isDeletingJisseki"];
    },
    TABLE_FOOTER_SETTING_SHORT() {
      return this.$t("TABLE_FOOTER_SETTING_SHORT");
    }
  },
  watch: {
    isOpenMobileCardDetail: {
      immediate: true,
      handler: function() {
        if (this.isOpenMobileCardDetail) {
          this.createHeader(this.jissekiMappings);
        }
      }
    }
  },
  methods: {
    createHeader(jissekiMappings) {
      this.headers = [];

      // 登録日時
      this.headers.push({
        text: this.$t("WORD_REGISTERED_DATE"),
        value: "createdAtStr"
      });

      // マッピングから生成するヘッダー
      for (const jissekiMapping of jissekiMappings) {
        this.headers.push({
          text: jissekiMapping.name,
          value: jissekiMapping.mappingId
        });
      }
    }
  }
};
</script>

<style scoped>
.jisseki-table {
  border: solid 1px #aaa;
}
</style>
