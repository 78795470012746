<template>
  <div>
    <v-dialog v-model="dialog" width="800px" persistent>
      <v-card width="800px" class="mx-auto">
        <v-card-title>{{ $t("PAGE_TITLE_PASSWORD_CHANGE") }}</v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-text-field
              v-model="oldPassword"
              :placeholder="$t('PLACEHOLDER_PASSWORD')"
              outlined
              :label="$t('WORD_OLD_PASSWORD')"
              :rules="[required, password_max_length, password_format]"
              counter="128"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
            >
            </v-text-field>
            <v-text-field
              v-model="newPassword"
              :placeholder="$t('PLACEHOLDER_PASSWORD')"
              outlined
              :label="$t('WORD_NEW_PASSWORD')"
              :rules="[required, password_max_length, password_format]"
              counter="128"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
            >
            </v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn width="25%" class="info" @click="submit">{{ $t("WORD_CHANGE") }}</v-btn>
          <v-btn width="25%" class="" @click="closeDialog">{{ $t("WORD_CANCEL") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as Util from "@/util/utils.js";
import Swal from "sweetalert2";
import Define from "@/define.js";
import { mixinValidator } from "@/mixins/mixinValidator.js";
export default {
  name: "PasswordChangeDialog",
  props: {
    dialog: Boolean
  },
  mixins: [mixinValidator],
  data: function() {
    return {
      // 入力項目
      oldPassword: "",
      newPassword: "",

      // 入力規則
      required: value => this.isValidRequired(value),
      password_max_length: value => this.isValidLength(value, null, Define.LENGTH_MAX_PASSWORD),
      password_format: value => this.isValidPasswordLFormat(value),

      // パスワード表示
      showPassword: false
    };
  },
  watch: {
    dialog: function() {
      if (this.$refs.form) this.$refs.form.reset();
    }
  },
  methods: {
    /**
     * 入力情報をAPIに伝搬する
     */
    submit() {
      if (this.$refs.form.validate()) {
        // loadingダイアログ表示
        this.$store.commit("setIsLoading", true);
        Util.axiosPostReq(Define.API_AUTH_PASSWORD_CHANGE, {
          new_password1: this.newPassword,
          new_password2: this.newPassword,
          old_password: this.oldPassword
        })
          .then(response => {
            // console.log(response);
            console.log(response.status); // ステータスコードのみ表示(秘匿情報は外部に出さない)
            // loadingダイアログ非表示
            this.$store.commit("setIsLoading", false);
            this.closeDialog();
            Swal.fire({
              icon: "success",
              title: this.$t("MSG_SUCCESS_MAIN_PASSWORD_CHANGE"),
              text: this.$t("MSG_SUCCESS_SUB_PASSWORD_CHANGE")
            });
          })
          .catch(error => {
            console.log(error.message);
            // loadingダイアログ非表示
            this.$store.commit("setIsLoading", false);
            this.closeDialog();
            Swal.fire({
              icon: "warning",
              title: this.$t("MSG_ERR_MAIN_PASSWORD_CHANGE"),
              text: this.$t("MSG_ERR_SUB_PASSWORD_CHANGE")
            });
          });
      } else {
        Swal.fire({
          icon: "error",
          title: this.$t("MSG_ERR_INPUT_ITEM_INVALID"),
          text: this.$t("MSG_ERR_SUB_INPUT_ITEM_INVALID")
        });
      }
    },
    /**
     * クローズイベントを親部品に通知する
     */
    closeDialog() {
      this.$refs.form.reset();
      this.$emit("close");
    }
  }
};
</script>

<style scoped></style>
