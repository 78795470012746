var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.cardClass }, [
    _c(
      "div",
      { style: _vm.styles },
      _vm._l(_vm.cardData, function(row, index) {
        return _c(
          "div",
          {
            key: index,
            class:
              index == _vm.cardData.length - 1
                ? "rows"
                : "rows base_holizontal_line"
          },
          _vm._l(row, function(col, index) {
            return _c(
              "v-tooltip",
              {
                key: index,
                attrs: {
                  bottom: "",
                  "open-delay": "300",
                  color: "rgba(60,64,67,0.95)",
                  transition: "fade",
                  disabled: !_vm.mappingName(col.display)
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function(ref) {
                        var disp = ref.on
                        return [
                          _c(
                            "div",
                            _vm._g(
                              {
                                class: col.class,
                                style: _vm.getStyleFontSize(
                                  _vm.DISPLAY_TYPE_CARD,
                                  _vm.displayFontSize
                                )
                              },
                              _vm.isModePc ? disp : undefined
                            ),
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.displayDataValue(col.display, true)
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              },
              [
                _c("span", [
                  _vm._v("【" + _vm._s(_vm.mappingName(col.display)) + "】"),
                  _c("br"),
                  _vm._v(_vm._s(_vm.displayDataValue(col.display, false)))
                ])
              ]
            )
          }),
          1
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }