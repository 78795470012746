import Swal from "sweetalert2";
import * as Util from "@/util/utils.js";
import Define from "@/define.js";

const state = {
  user: [],
  selectedUser: {},
  isUserLoading: false
};
const getters = {
  user: state => state.user,
  manager: state => state.user.filter(d => d.role === 0),
  selectedUser: state => state.selectedUser,
  isUserLoading: state => state.isUserLoading
};
const mutations = {
  initState(state) {
    state.user = [];
    state.selectedUser = {};
    state.isUserLoading = false;
  },
  setUser(state, user) {
    state.user = user;
  },
  setSelectedUser(state, selectedUser) {
    state.selectedUser = selectedUser;
  },
  setIsUserLoading(state, isUserLoading) {
    state.isUserLoading = isUserLoading;
  }
};
const actions = {
  getUser({ commit }) {
    commit("setIsUserLoading", true);
    Util.axiosGetReq(Define.API_USER_BY_TENANT_LIST)
      .then(response => {
        // console.log(response);
        console.log(response.status); // ステータスコードのみ表示(秘匿情報は外部に出さない)
        commit("setIsUserLoading", false);
        // ユーザー情報を設定する
        commit("setUser", response.data);
      })
      .catch(error => {
        console.log(error.message);
        commit("setIsUserLoading", false);
        Swal.fire({
          icon: "warning",
          title: "Server Error",
          text: "Please refresh the page."
        });
      });
  },
  createUser({ commit, dispatch }, param) {
    commit("setIsUserLoading", true);
    Util.axiosPostReq(Define.API_AUTH_REG, param)
      .then(response => {
        // console.log(response);
        console.log(response.status); // ステータスコードのみ表示(秘匿情報は外部に出さない)
        commit("setIsUserLoading", false);
        // 最新のユーザー情報を取得する
        dispatch("getUser", {});
      })
      .catch(error => {
        console.log(error.message);
        commit("setIsUserLoading", false);
        Swal.fire({
          icon: "warning",
          title: "Update Error",
          text: "Please try again."
        });
      });
  },
  updateUser({ commit, dispatch }, param) {
    commit("setIsUserLoading", true);
    Util.axiosPatchReq(
      `${Define.API_USER_BY_TENANT_UPDATE}${param.user_id}/`,
      param.param
    )
      .then(response => {
        // console.log(response);
        console.log(response.status); // ステータスコードのみ表示(秘匿情報は外部に出さない)
        commit("setIsUserLoading", false);
        // 最新のユーザー情報を取得する
        dispatch("getUser", {});
      })
      .catch(error => {
        console.log(error.message);
        commit("setIsUserLoading", false);
        Swal.fire({
          icon: "warning",
          title: "Update Error",
          text: "Please try again."
        });
      });
  },
  deleteUser({ commit, dispatch }, userInfo) {
    commit("setIsUserLoading", true);
    Util.axiosDeleteReq(Define.API_USER_BY_TENANT_DEL + `${userInfo.userId}/`)
      .then(response => {
        // console.log(response);
        console.log(response.status); // ステータスコードのみ表示(秘匿情報は外部に出さない)
        commit("setIsUserLoading", false);
        // 最新のユーザー情報を取得する
        dispatch("getUser", {});
      })
      .catch(error => {
        console.log(error.message);
        commit("setIsUserLoading", false);
        Swal.fire({
          icon: "warning",
          title: "Update Error",
          text: "Please try again."
        });
      });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
