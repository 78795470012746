<template>
  <v-dialog v-model="dialog" width="600px" persistent>
    <v-card flat color="#f0f0f0" width="600px" class="mx-auto">
      <div class="text-right">
        <v-icon @click="closeDialog">mdi-close</v-icon>
      </div>
      <v-card-text>
        <v-form ref="form">
          <div class="pl-8 pr-4">
            <v-row align="center" class="pt-7">
              <span class="dispdetail1 font-weight-bold dispfont pl-3" >
                {{ $t("WORD_DATE") }}
              </span>
              <div class="dispdetail2 pr-5">
                <EditingItem
                  v-model="posCol"
                  :type="TYPE_DATE"
                  :bgcolor="'#fff'"
                  :rules="[]"
                  :hideDetails="true"
                  @input="changeDate($event)"
                ></EditingItem>
              </div>
            </v-row>
            <v-row align="center" class="pt-6">
              <span class="dispdetail1 font-weight-bold dispfont pl-3" >
                {{ $t("WORD_LINENAME") }}
              </span>
              <div class="dispdetail2 pr-5">
                <v-select
                  v-model="posRow"
                  dense
                  outlined
                  hide-details
                  background-color="#fff"
                  :items="areaItems"
                ></v-select>
              </div>
            </v-row>            
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn class="info ml-auto" @click="confirm">{{ $t("WORD_MOVE_CARD") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Define from "@/define.js";
import Swal from "sweetalert2";
import * as Util from "@/util/utils.js";
import EditingItem from "@/components/parts/EditingItem";
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("tile");
export default {
  name: "CardSearchDialog",
  components: {
    EditingItem
  },
  props: {
    dialog: Boolean,
    selected: Array
  },
  data() {
    return {
      posCol: "",
      posRow: "",

      TYPE_DATE: Define.TYPE_DATE,
    };
  },
  computed: {
    freeTileNum() {
      return this.$store.getters["tile/getFreeTileNum"];
    },
    option002() {
      let option = this.$store.getters["option/getOption"];
      return option.option002;
    },
    displayFontSize() {
      return this.$store.getters["display/displayFontSize"];
    },
    areaItems() {
      let items = [
        { text: "", value: "" }
      ];
      let areas = this.$store.getters["setting/getAreaList"];
      if(areas){
        areas = areas.filter(d => d.order != "");
        if (areas.length) {
          areas.sort(function(a, b) {
            if (a.order < b.order) return -1;
            if (a.order > b.order) return 1;
            return 0;
          });
        }
        for (let area of areas) {
          items.push({ text: area.name, value: area.areaId });
        }
      }
      return items;
    }
  },
  watch: {
    dialog: function(value) {
      if (value) {
        this.posCol = "";
        this.posRow = "";
      }
    }
  },
  methods: {
    ...mapActions([
      "detailUpdateTile"
    ]),
    /**
     * 日付が未設定に変更されると、エリア名も未設定にする
     */
    changeDate(value) {
      if (value == "") {
        this.posRow = "";
      }
    },
    /**
     * チェックしたカードを指定位置に移動する
     */
    confirm() {
      Swal.fire({
        icon: "question",
        html: this.$t("MSG_SEARCH_MOVE_CARD"),
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          let moveTiles = [];
          // カード位置情報の整形
          const posColAfter = Util.convertDateFormat(this.posCol, Define.DATE_FORMAT_DAY_HYPHEN);
          const posRowAfter = this.posRow && this.posCol ? this.posRow : null;

          // 選択したカードから、完了状態のカード、移動前の位置＝移動先のカードを除外する
          let filterdSelected = this.selected.filter
          (
            d => d.isComplete === false
            && (d.posCol !== posColAfter || d.posRow !== posRowAfter)
          );
          // カードを選択していないであれば、エラーメッセージを表示する
          if (filterdSelected.length === 0) {
            Swal.fire(this.$t("MSG_ERR_TARGET_NOT_EXIST"));
            return;
          }
          // 50枚以上のカードを移動しようとすると、エラーメッセージを表示する
          if (filterdSelected.length > Define.CARD_OPERATION_MAX_NUM) {
            Swal.fire(this.$t("MSG_ERR_CARD_OPERATION_MAX_NUM"));
            return;
          }

          // 移動先＝未計画エリア、300件以上のカードを置こうとした場合、警告を表示して移動をさせない
          if (posColAfter == null && posRowAfter == null) {
            let freeAreaSum = this.freeTileNum + filterdSelected.length;
            if (freeAreaSum > Define.FREEAREA_MAX_TILENUM) {
              Swal.fire({
                html: this.$t("MSG_ERR_OVER_ITEMS_FREE_AREA"),
                icon: "error"
              });
              return;
            }
          }

          // カード移動をロック
          this.$store.commit("setting/switchLoading");

          for (let i = 0; i < filterdSelected.length; i++) {
            let is_confirmed_before = filterdSelected[i].isConfirmed
            let moveTile = {
              tile_id: filterdSelected[i].tileId,
              pos_col: posColAfter,
              pos_row: posRowAfter,
              is_complete: filterdSelected[i].isComplete,
              is_confirmed: Util.getNextCardIsConfirmedByMove(filterdSelected[i].isConfirmed, this.option002, true),
              status: Util.getNextCardStatusByMove(filterdSelected[i].status, this.option002, true),
              color: filterdSelected[i].color,
              version: filterdSelected[i].version,
              tile_move_history_data: {
                history_type: Define.HISTORY_TYPE_MOVED,
                pos_col_before: filterdSelected[i].posCol,
                pos_col_after: posColAfter,
                pos_row_id_before: filterdSelected[i].posRow,
                pos_row_id_after: posRowAfter,
                pos_row_before: this.$store.getters["setting/getAreaName"](filterdSelected[i].posRow),
                pos_row_after: this.$store.getters["setting/getAreaName"](posRowAfter),
              }
            }
            if (is_confirmed_before != moveTile.is_confirmed) {
              moveTile.tile_confirmed_history_data = {
                history_type: Define.HISTORY_TYPE_CONFIRMED,
                flg_type: Define.FLG_TYPE_CONFIRMED,
                flg_before: is_confirmed_before,
                flg_after: moveTile.is_confirmed
              }
            }
            moveTiles.push(moveTile);
          }
          this.detailUpdateTile({ tileData: moveTiles });
          // カード移動ロックの解除
          this.$store.commit("setting/switchLoading");
          this.$emit("close");
        }
      });
    },
    closeDialog() {
      this.$emit("close");
    }
  }
};
</script>

<style scoped>
.text-right {
  position: relative;
  top: 10px;
  right: 10px;
}
.dispdetail1 {
  width: 45%;
  height: 100%;
}
.dispdetail2 {
  width: 55%;
  height: 100%;
}
.dispfont {
  color: #333;
}
</style>
