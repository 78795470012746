import * as Util from "@/util/utils.js";
import Define from "@/define.js";

const state = {
  myRoles: [],
  selectedUserRoles: []
};
const getters = {
  myRoles: state => state.myRoles,
  selectedUserRoles: state => state.selectedUserRoles,
  roleByWorkspaceId: () => (workSpaceId, userRole) => {
    let role = state.myRoles.filter(role => role.workSpaceId === workSpaceId);
    return role.length > 0 ? role[0].role : userRole;
  }
};
const mutations = {
  initState(state) {
    state.myRoles = [];
    state.selectedUserRoles = [];
  },
  setSelectedUserRoles(state, selectedUserRoles) {
    state.selectedUserRoles = selectedUserRoles;
  }
};
const actions = {
  getRole({commit}, userId) {
    const query = `user_id=${userId}`;
    Util.axiosGetReq(`${Define.API_ROLE}?${query}`)
      .then(response => {
        commit("setSelectedUserRoles", response.data);
      })
      .catch(error => {
        console.log(error.message);
      });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
