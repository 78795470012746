<template>
  <div>
    <v-card outlined>
      <v-list>
        <v-list-item v-for="detail in tileInfo.tileDetailData" :key="detail.mappingId" three-line>
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold">{{ mappingName(detail.mappingId) }}</v-list-item-title>
            <v-list-item-subtitle class="text-right">{{ detail.value }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
    <v-select
      v-model="tileInfo.color"
      dense
      outlined
      hide-details="auto"
      :items="colors"
      item-value="color"
      item-text="label"
      :rules="[]"
      :label = "colorTitle"
      @change="updateCard"
      class="mt-2"
      :disabled="tileInfo.isComplete" 
    >
      <template v-slot:selection="{ item }">
        <div :style="colorBoxlStyle(item.color)"></div>
        <span class="pl-2">{{ item.label }}</span>
      </template>
      <template v-slot:item="{ item }">
        <div :style="colorBoxlStyle(item.color)"></div>
        <span class="pl-2">{{ item.label }}</span>
      </template>
    </v-select>
  </div>
</template>

<script>
import Define from "@/define.js";
import { mixinControl } from "@/mixins/mixinControl.js";
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("tile");
export default {
  mixins: [mixinControl],
  computed: {
    tileInfo() {
      return this.$store.getters["tile/cardDetailInfo"];
    },
    colors() {
      return this.getCardColors();
    },
    colorTitle() {
      return this.$t("PAGE_SUBTITLE_CARD_COLOR");
    }
  },
  methods: {
    ...mapActions([
      "detailUpdateTile",
    ]),
    mappingName(id) {
      if (!id) return "";
      return this.$store.getters["setting/getMappingName"](id);
    },
    colorBoxlStyle(color) {
      return [Define.STYLE_COLOR_BOX, { "background-color": color }];
    },
    updateCard(){
      // カード情報
      const result = {
        tile_id: this.tileInfo.tileId,
        pos_col: this.tileInfo.posCol,
        pos_row: this.tileInfo.posRow,
        order: this.tileInfo.order,
        is_complete: this.tileInfo.isComplete,
        color: this.tileInfo.color,
        version: this.tileInfo.version,
        is_confirmed: this.tileInfo.isConfirmed,
        status: this.tileInfo.status
      };
      this.detailUpdateTile({ tileData: [result] });
      this.close();
    },
    close(){
      this.$emit("close");
    }
  }
};
</script>

<style scoped>

</style>
