<template>
  <v-dialog v-model="dialog" persistent>
    <v-form ref="form" class="overlay">
      <div class="overlayarea">
        <v-card flat color="#f0f0f0" class="cardsize">
          <div class="text-right">
            <v-icon @click="closeDialog">mdi-close</v-icon>
          </div>
          <div class="subtitle">
            {{ $t("PAGE_TITLE_MONTH_VIEW") }}
            <HelpButton :helpobject="monthHelpDialogData"></HelpButton>
          </div>
          <div class="area-flame">
            <!-- DatePickMonth -->
            <DatePickMonth ref="datePickMonth" class="mt-2" @setDate="setHeader($event)"/>
            <!-- ヘッダー部 -->
            <div class="days-headerarea mt-2">
              <div v-for="(day, index) in days" :key="index" :class="(day.isToday) ? 'days-header-today' : 'days-header'">
                {{ day.day }}
              </div>
            </div>
            <!-- データ部 -->
            <div class="list-scroll">
              <MonthViewLineListProgressbar 
                v-for="tileLoadListByArea in tileLoadList"
                :tileLoadListByArea="tileLoadListByArea"
                :key="tileLoadListByArea.lineId"
                @changeDate="changeDate($event)"
                @close="closeDialog()"
              />
            </div>
          </div>
        </v-card>
      </div>
    </v-form>
  </v-dialog>
</template>

<script>
import Define from "@/define.js";
import moment from "moment";
import DatePickMonth from "@/components/parts/DatePickMonth.vue";
import MonthViewLineListProgressbar from "@/components/parts/MonthViewLineListProgressbar.vue";
import HelpButton from "@/components/parts/HelpButton";
export default {
  name: "MonthViewDialog",
  components: {
    DatePickMonth,
    MonthViewLineListProgressbar,
    HelpButton
  },
  data() {
    return {
      target: null
    };
  },
  computed: {
    dialog() {
      return this.$store.getters["tile/isOpenMonthView"];
    },
    tileLoadList () {
      let tileLoadList = this.$store.getters["tile/getTileLoadData"] (this.startDate.format(Define.DATE_FORMAT_DAY_HYPHEN), this.endDate.format(Define.DATE_FORMAT_DAY_HYPHEN));
      if (tileLoadList.length > 0) {
        tileLoadList[0].lineName = this.$t("WORD_NOTSET");
      }
      return tileLoadList;
    },
    startDate () {
      return (this.target !== null) ? this.target.startOf('month').clone() : moment();
    },
    endDate () {
      return (this.target !== null) ? this.target.endOf('month').clone() : moment();
    },
    days () {
      let result = [];
      let today = moment();
      if(this.target !== null){
        for (let date = this.startDate; date <= this.endDate; date = moment(date).add(1, "days")) {
          result.push({ day: date.format("D"), isToday: date.isSame(today, 'day') });
        }
      }
      return result;
    },
    monthHelpDialogData() {
      return this.$t("EXPLANATION_DIALOG.monthHelp");
    }
  },
  watch: {
    dialog: function(isOpen) {
      if(isOpen){
        let locale = this.$i18n.locale;
        moment.locale(locale);
        this.target = moment();
        if(this.$refs.datePickMonth){
          this.$refs.datePickMonth.initMonth()
        }
      }
    },
    target: function() {
      let condition = {
        startDate: this.startDate.format(Define.DATE_FORMAT_DAY_SLASH_CHECK),
        endDate: this.endDate.format(Define.DATE_FORMAT_DAY_SLASH_CHECK)
      };
      this.$store.dispatch("tile/getTileLoadData", condition);
    }
  },
  methods: {
    /**
     * DatePickMonth.vueで取得した日付を設定
     * @param {Date} date - 取得した日付
     */
    setHeader(date) {
      this.target = date;
    },
    /**
     * 日付変更を親部品に通知する
     */
    changeDate(date) {
      this.$emit("changeDate", date);
    },
    /**
     * クローズイベントを親部品に通知する
     */
    closeDialog() {
      this.$store.commit("tile/setIsOpenMonthView", false);
    }
  }
};
</script>

<style scoped>
.overlay {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
.overlayarea {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.cardsize {
  margin-top: 20px;
  width: 1700px;
  max-height: 900px;
}
.text-right {
  position: relative;
  top: 10px;
  right: 10px;
}
.subtitle {
  font-size: 120%;
  border-left: solid 6px #1976d2ca;
  color: #333;
  padding: 0 10px;
  margin-left: 6px;
}
.area-flame {
  margin-left: auto;
  margin-right: auto;
  width: 96%;
}
.days-headerarea {
  height: 30px;
  padding-left: 100px;
  padding-right: 16px;
  display: flex;
  background-color: #1976d2f7;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.days-header {
  text-align: center;
  margin-left: -1px;
  padding: 3px;
  width: 160px;
  color: #fff;
  font-weight: bold;
  border-right: solid 1px #bbb;
  border-left: solid 1px #bbb;
}
.days-header-today {
  text-align: center;
  margin-left: -1px;
  padding: 3px;
  width: 160px;
  color: #fff;
  font-weight: bold;
  border-right: solid 1px #bbb;
  border-left: solid 1px #bbb;
  cursor: pointer;
  background-color: #ffa726;
}
.list-scroll {
  max-height: 720px;
  margin-bottom: -3px;
  background-color: #ededed;
  overflow-x: hidden;
  overflow-y: scroll;
}
</style>
