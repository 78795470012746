var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "mb-4" }, [
      _c("div", [_vm._v(_vm._s(_vm.$t("WORD_MULTILINGUAL_API_COMMON")))]),
      _c(
        "div",
        { staticClass: "d-flex flex-row align-baseline mb-4" },
        [
          _c(
            "div",
            { staticClass: "front-div" },
            [
              _c("v-select", {
                staticClass: "ml-5",
                attrs: {
                  placeholder: " ",
                  dense: "",
                  "hide-details": "auto",
                  items: _vm.renkeisakiItems,
                  label: _vm.$t("WORD_MULTILINGUAL_API_DESTINATION"),
                  disabled: !_vm.isValidRole
                },
                on: {
                  change: function($event) {
                    return _vm.cbChangeRenkeisaki($event)
                  }
                },
                model: {
                  value: _vm.item.renkeisakiId,
                  callback: function($$v) {
                    _vm.$set(_vm.item, "renkeisakiId", $$v)
                  },
                  expression: "item.renkeisakiId"
                }
              })
            ],
            1
          ),
          _c("v-checkbox", {
            attrs: {
              disabled:
                !_vm.isValidRole ||
                _vm.item.renkeisakiId != _vm.WEBHOOK_RENKEISAKI_ID_KINTONE,
              label: _vm.$t("WORD_MULTILINGUAL_API_SYNCHRONIZATION"),
              "hide-details": ""
            },
            model: {
              value: _vm.item.sync,
              callback: function($$v) {
                _vm.$set(_vm.item, "sync", $$v)
              },
              expression: "item.sync"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex flex-row align-baseline" },
        [
          _c(
            "div",
            { staticClass: "front-div" },
            [
              _c("v-select", {
                staticClass: "ml-5",
                attrs: {
                  placeholder: " ",
                  dense: "",
                  "hide-details": "auto",
                  items: _vm.methodItems,
                  label: _vm.$t("WORD_MULTILINGUAL_API_METHOD"),
                  disabled: !_vm.isValidRole
                },
                model: {
                  value: _vm.item.method,
                  callback: function($$v) {
                    _vm.$set(_vm.item, "method", $$v)
                  },
                  expression: "item.method"
                }
              })
            ],
            1
          ),
          _c("v-text-field", {
            attrs: {
              placeholder: " ",
              dense: "",
              "hide-details": "auto",
              label: "URL",
              disabled: !_vm.isValidRole,
              rules: [_vm.required, _vm.https]
            },
            model: {
              value: _vm.item.url,
              callback: function($$v) {
                _vm.$set(_vm.item, "url", $$v)
              },
              expression: "item.url"
            }
          })
        ],
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "mb-4" },
      [
        _c(
          "div",
          { staticClass: "d-flex flex-row align-baseline" },
          [
            _c("div", { staticClass: "mb-4" }, [
              _vm._v(_vm._s(_vm.$t("WORD_MULTILINGUAL_API_HEADER")))
            ]),
            _vm.item.header.length < _vm.UPPER_LIMIT_HEADER_NUM
              ? _c(
                  "v-btn",
                  {
                    staticClass: "ma-2",
                    attrs: {
                      disabled: !_vm.isValidRole,
                      color: "info",
                      rounded: "",
                      small: "",
                      text: ""
                    },
                    on: {
                      click: function($event) {
                        return _vm.addRow()
                      }
                    }
                  },
                  [
                    _c(
                      "v-icon",
                      { attrs: { small: "", disabled: !_vm.isValidRole } },
                      [_vm._v("mdi-plus")]
                    ),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("WORD_MULTILINGUAL_API_HEADER_ADDITION")
                        ) +
                        " "
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        ),
        _vm._l(_vm.item.header, function(header, i) {
          return _c(
            "div",
            { key: i, staticClass: "d-flex flex-row align-baseline mb-4" },
            [
              _c(
                "div",
                { staticClass: "front-div" },
                [
                  _c("v-text-field", {
                    staticClass: "ml-5",
                    attrs: {
                      placeholder: " ",
                      dense: "",
                      "hide-details": "auto",
                      label: _vm.$t("WORD_MULTILINGUAL_API_HEADER_NAME"),
                      readonly: _vm.isContentTypeJson(
                        header.name,
                        header.value
                      ),
                      disabled: !_vm.isValidRole,
                      rules: [_vm.required]
                    },
                    model: {
                      value: header.name,
                      callback: function($$v) {
                        _vm.$set(header, "name", $$v)
                      },
                      expression: "header.name"
                    }
                  })
                ],
                1
              ),
              _c("v-text-field", {
                attrs: {
                  placeholder: " ",
                  dense: "",
                  "hide-details": "auto",
                  label: _vm.$t("WORD_MULTILINGUAL_API_VALUE"),
                  readonly: _vm.isContentTypeJson(header.name, header.value),
                  disabled: !_vm.isValidRole,
                  rules: [_vm.required]
                },
                model: {
                  value: header.value,
                  callback: function($$v) {
                    _vm.$set(header, "value", $$v)
                  },
                  expression: "header.value"
                }
              }),
              _c(
                "v-icon",
                {
                  attrs: {
                    small: "",
                    disabled:
                      !_vm.isValidRole ||
                      _vm.isContentTypeJson(header.name, header.value)
                  },
                  on: {
                    click: function($event) {
                      return _vm.deleteRow(i)
                    }
                  }
                },
                [_vm._v("mdi-close")]
              )
            ],
            1
          )
        })
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "mb-4" },
      [
        _c("div", { staticClass: "mb-4" }, [
          _vm._v(_vm._s(_vm.$t("WORD_MULTILINGUAL_API_BODY")))
        ]),
        _c("v-textarea", {
          staticClass: "ml-5",
          attrs: {
            placeholder: " ",
            dense: "",
            outlined: "",
            "hide-details": "auto",
            label: _vm.$t("WORD_MULTILINGUAL_API_DATA_FORMAT"),
            disabled: !_vm.isValidRole,
            rules: [_vm.required, _vm.jsonFormat]
          },
          model: {
            value: _vm.item.body,
            callback: function($$v) {
              _vm.$set(_vm.item, "body", $$v)
            },
            expression: "item.body"
          }
        })
      ],
      1
    ),
    _c("div", { staticClass: "mb-4" }, [
      _c("div", { staticClass: "mb-4" }, [
        _vm._v(_vm._s(_vm.$t("WORD_MULTILINGUAL_API_TEST_EXECUTION")))
      ]),
      _c(
        "div",
        { staticClass: "d-flex flex-row align-baseline" },
        [
          _c("v-textarea", {
            staticClass: "ml-5",
            attrs: {
              placeholder: " ",
              dense: "",
              outlined: "",
              "hide-details": "auto",
              label: _vm.$t("WORD_MULTILINGUAL_API_EXECUTION_RESULTS")
            },
            model: {
              value: _vm.testResult,
              callback: function($$v) {
                _vm.testResult = $$v
              },
              expression: "testResult"
            }
          }),
          _c(
            "v-btn",
            {
              attrs: { color: "info", disabled: !_vm.isValidRole },
              on: {
                click: function($event) {
                  return _vm.execTest()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("WORD_MULTILINGUAL_API_EXECUTION")))]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }