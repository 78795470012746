var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "areatitle mb-5" }, [
      _vm._v(
        " " +
          _vm._s(
            _vm.$t(
              "PAGE_SUBTITLE_APPLICATON_SETTING_MULTILINGUAL_API_FROM_FREELY_TO_OUTER"
            )
          ) +
          " "
      )
    ]),
    _c(
      "div",
      { staticClass: "ml-5" },
      [
        _c(
          "v-form",
          { ref: "form" },
          [
            _c("ApiSettingEvent", {
              attrs: {
                eventId: _vm.WEBHOOK_EVENT_ID_JISSEKI_REGIST,
                items: _vm.jissekiRegistItems
              }
            })
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "dataarea" },
      [
        _c(
          "v-btn",
          {
            staticClass: "info savebtn",
            attrs: { disabled: !_vm.isValidRole },
            on: {
              click: function($event) {
                return _vm.save()
              }
            }
          },
          [_vm._v(" " + _vm._s(_vm.$t("WORD_APPLICATION_SETTING_SAVE")) + " ")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }