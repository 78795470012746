var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "1000px", persistent: "" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "mx-auto", attrs: { width: "1000px" } },
            [
              _c(
                "v-row",
                { staticClass: "mx-2" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-card-title", [
                        _vm._v(_vm._s(_vm.$t("PAGE_TITLE_USER_ROLE_CHANGE")))
                      ]),
                      _c("v-card-text", [
                        _vm._v(
                          _vm._s(_vm.$t("TEXT_USER_CHANGE_NAME")) +
                            _vm._s(_vm.selectedUser.username)
                        )
                      ]),
                      _c("v-card-text", [
                        _vm._v(
                          _vm._s(_vm.$t("TEXT_USER_CHANGE_ROLE")) +
                            _vm._s(_vm.getRoleName(_vm.selectedUser.role))
                        )
                      ]),
                      _c(
                        "v-radio-group",
                        {
                          staticClass: "ml-3",
                          attrs: { row: "" },
                          model: {
                            value: _vm.role,
                            callback: function($$v) {
                              _vm.role = $$v
                            },
                            expression: "role"
                          }
                        },
                        [
                          _c("v-radio", {
                            attrs: {
                              label: _vm.$t("WORD_ROLENAME")[0],
                              value: "0"
                            }
                          }),
                          _c("v-radio", {
                            attrs: {
                              disabled: _vm.disabled,
                              label: _vm.$t("WORD_ROLENAME")[1],
                              value: "1"
                            }
                          }),
                          _c("v-radio", {
                            attrs: {
                              disabled: _vm.disabled,
                              label: _vm.$t("WORD_ROLENAME")[2],
                              value: "2"
                            }
                          }),
                          _c("v-radio", {
                            attrs: {
                              disabled: _vm.disabled,
                              label: _vm.$t("WORD_ROLENAME")[3],
                              value: "3"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.workspaces.length > 0
                    ? _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "v-card",
                            { attrs: { outlined: "" } },
                            [
                              _c("v-card-title", [
                                _vm._v(_vm._s(_vm.$t("TEXT_WORKSPACE")))
                              ]),
                              _c(
                                "v-card-text",
                                _vm._l(_vm.wsRoles, function(wsRole, index) {
                                  return _c(
                                    "div",
                                    { key: index },
                                    [
                                      _c("label", [
                                        _vm._v(_vm._s(wsRole.name))
                                      ]),
                                      _c(
                                        "v-radio-group",
                                        {
                                          staticClass: "mt-0",
                                          attrs: { row: "" },
                                          model: {
                                            value: _vm.wsRoles[index].role,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.wsRoles[index],
                                                "role",
                                                $$v
                                              )
                                            },
                                            expression: "wsRoles[index].role"
                                          }
                                        },
                                        [
                                          _c("v-radio", {
                                            attrs: {
                                              label: _vm.$t("WORD_ROLENAME")[0],
                                              value: "0"
                                            }
                                          }),
                                          _c("v-radio", {
                                            attrs: {
                                              label: _vm.$t("WORD_ROLENAME")[1],
                                              value: "1"
                                            }
                                          }),
                                          _c("v-radio", {
                                            attrs: {
                                              label: _vm.$t("WORD_ROLENAME")[2],
                                              value: "2"
                                            }
                                          }),
                                          _c("v-radio", {
                                            attrs: {
                                              label: _vm.$t("WORD_ROLENAME")[3],
                                              value: "3"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "info",
                      attrs: { width: "25%" },
                      on: { click: _vm.submit }
                    },
                    [_vm._v(_vm._s(_vm.$t("WORD_CHANGE")))]
                  ),
                  _c(
                    "v-btn",
                    { attrs: { width: "25%" }, on: { click: _vm.closeDialog } },
                    [_vm._v(_vm._s(_vm.$t("WORD_CANCEL")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }