import Define from "../define.js";
import * as Util from "../util/utils.js";
import * as Validator from "@/util/validator.js";

export const mixinValidator = {
  name: "mixinValidator",
  methods: {
    /**
     * バリデーター - * - 入力必須
     * @param {String} value - 入力文字列
     * @param {String} isShortMsg - 短縮文字にするかどうか
     * @returns {Boolean || String} - 結果
     */
    isValidRequired(value, isShortMsg = false) {
      // eslint-disable-next-line prettier/prettier
      let msg = isShortMsg ? this.$t("V_ERR_MSG_VALID_REQUIRE_SHORT") : this.$t("V_ERR_MSG_VALID_REQUIRE_LONG");
      return !!value || msg;
    },
    /**
     * バリデーター - 文字数
     * @param {String} value - 入力文字列
     * @param {Number} minLength - 入力文字最小数、未使用時はnull
     * @param {Number} maxLength - 入力文字最大数、未使用時はnull
     * @param {String} isShortMsg - 短縮文字にするかどうか
     * @returns {Boolean || String} - 結果
     */
    isValidLength(value, minLength, maxLength, isShortMsg = false) {
      // eslint-disable-next-line prettier/prettier
      let msgMax = isShortMsg ? this.$t("V_ERR_MSG_VALID_LENGTH_MAX_SHORT", {maxLength: maxLength}) : this.$t("V_ERR_MSG_VALID_LENGTH_MAX_LONG", {maxLength: maxLength});
      // eslint-disable-next-line prettier/prettier
      let msgMin = isShortMsg ? this.$t("V_ERR_MSG_VALID_LENGTH_MIN_SHORT", {minLength: minLength}) : this.$t("V_ERR_MSG_VALID_LENGTH_MIN_LONG", {minLength: minLength});
      // eslint-disable-next-line prettier/prettier
      let msgMinMax = isShortMsg ? this.$t("V_ERR_MSG_VALID_LENGTH_MINMAX_SHORT", {minLength: minLength}, {maxLength: maxLength}) : this.$t("V_ERR_MSG_VALID_LENGTH_MINMAX_LONG", {minLength: minLength}, {maxLength: maxLength});
      // eslint-disable-next-line prettier/prettier
      let msgEqual = isShortMsg ? this.$t("V_ERR_MSG_VALID_LENGTH_EQUAL_SHORT", {maxLength: maxLength}) : this.$t("V_ERR_MSG_VALID_LENGTH_EQUAL_LONG", {maxLength: maxLength});

      if (value === undefined || value === null) {
        return true;
      } else {
        if (!minLength) {
          return value.length <= maxLength || msgMax;
        } else if (!maxLength) {
          return value.length >= minLength || msgMin;
        } else {
          return (
            (minLength <= value.length && value.length <= maxLength) ||
            (minLength !== maxLength ? msgMinMax : msgEqual)
          );
        }
      }
    },

    /**
     * バリデーター - データ型 - 文字列
     * @param {String} value - 入力文字列
     * @param {String} isShortMsg - 短縮文字にするかどうか
     * @returns {Boolean || String} - 結果
     */
    isValidString(value, isShortMsg = false) {
      return this.isValidLength(value, null, Define.LENGTH_MAX_STRING, isShortMsg);
    },

    /**
     * バリデーター - データ型 - 数値(小数点なし)
     * @param {String} value - 入力文字列
     * @param {String} isShortMsg - 短縮文字にするかどうか
     * @returns {Boolean || String} - 結果
     */
    isValidInt(value, isShortMsg = false) {
      // eslint-disable-next-line prettier/prettier
      let msg = isShortMsg ? this.$t("MSG_ERR_FORMAT_INT_SHORT") : this.$t("MSG_ERR_FORMAT_INT");
      // eslint-disable-next-line prettier/prettier
      let msgRange = isShortMsg ? this.$t("MSG_ERR_SUB_NUMBER_RANGE_SHORT") : this.$t("MSG_ERR_SUB_NUMBER_RANGE");
      if (!Util.checkIntNum(value) && Util.checkNotEmpty(value)) {
        return msg;
      } else {
        return Util.checkIntValue(value) === Define.SUCCESS ? true : msgRange;
      }
    },

    /**
     * バリデーター - データ型 - 数値(小数点あり)
     * @param {String} value - 入力文字列
     * @param {String} isShortMsg - 短縮文字にするかどうか
     * @returns {Boolean || String} - 結果
     */
    isValidFloat(value, isShortMsg = false) {
      // eslint-disable-next-line prettier/prettier
      let msg = isShortMsg ? this.$t("MSG_ERR_FORMAT_FLOAT_SHORT") : this.$t("MSG_ERR_FORMAT_FLOAT");
      // eslint-disable-next-line prettier/prettier
      let msgRange = isShortMsg ? this.$t("MSG_ERR_SUB_NUMBER_RANGE_SHORT") : this.$t("MSG_ERR_SUB_NUMBER_RANGE");
      if (!Util.checkFloatNum(value) && Util.checkNotEmpty(value)) {
        return msg;
      } else {
        return Util.checkFloatValue(value) === Define.SUCCESS ? true : msgRange;
      }
    },

    /**
     * バリデーター - データ型 - 数値(日付)
     * @param {String} value - 入力文字列
     * @param {String} isShortMsg - 短縮文字にするかどうか
     * @returns {Boolean || String} - 結果
     */
    isValidDate(value, isShortMsg = false) {
      // eslint-disable-next-line prettier/prettier
      let msg = isShortMsg ? this.$t("MSG_ERR_FORMAT_DATE_SHORT") : this.$t("MSG_ERR_FORMAT_DATE");
      if (!Util.checkDateType(value) && Util.checkNotEmpty(value)) {
        return msg;
      } else {
        return true;
      }
    },

    /**
     * バリデーター - データ型 - 数値(時間)
     * @param {String} value - 入力文字列
     * @param {String} isShortMsg - 短縮文字にするかどうか
     * @returns {Boolean || String} - 結果
     */
    isValidTime(value, isShortMsg = false) {
      // eslint-disable-next-line prettier/prettier
      let msg = isShortMsg ? this.$t("MSG_ERR_FORMAT_TIME_SHORT") : this.$t("MSG_ERR_FORMAT_TIME");
      if (!Util.checkTimeType(value) && Util.checkNotEmpty(value)) {
        return msg;
      } else {
        return true;
      }
    },
    /**
     * バリデーター - 最大値
     * @param {String} value - 入力文字列
     * @param {String} min - 最小値
     * @param {String} isShortMsg - 短縮文字にするかどうか
     * @returns {Boolean || String} - 結果
     */
    isValidMaxValue(value, min, isShortMsg = false) {
      if (value === null || value === undefined || value === "") return true;
      if (min === null || min === undefined || min === "") return true;
      // eslint-disable-next-line prettier/prettier
      let msg = isShortMsg ? this.$t("MSG_MIN_MAX_INVALID_SHORT"): this.$t("MSG_MIN_MAX_INVALID");
      let maxValue = Number(value);
      let minValue = Number(min);
      return maxValue > minValue || msg;
    },
    isValidFIleSize(value) {
      const { format } = require("util");
      return (
        !value ||
        value.size < Define.FILE_SIZE_MAX ||
        format(
          this.$t("V_ERR_MSG_MAX_FILE_SIZE", {size: Util.convertSizeLabel(Define.FILE_SIZE_MAX)})
        )
      );
    },
    /**
     * バリデーター - Email - 形式
     * @param {String} value - 入力文字列
     * @returns {Boolean || String} - 結果
     */
    isValidEmailFormat(value) {
      if (value === undefined || value === null) {
        return true;
      } else {
        const regexp = /^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        return regexp.test(value) || this.$t("V_ERR_MSG_VALID_EMAIL_FORMAT");
      }
    },
    /**
     * バリデーター - パスワード - 形式
     * @param {String} value - 入力文字列
     * @returns {Boolean || String} - 結果
     */
    isValidPasswordLFormat(value) {
      if (value === undefined || value === null) {
        return true;
      } else {
        const regexp = /^(?=.*?[a-z])(?=.*?\d)[a-z\d!-/:-@[-`{-~]{8,}$/i;
        return regexp.test(value) || this.$t("V_ERR_MSG_VALID_PASSWORD_FORMAT");
      }
    },

    /**
     * バリデーター - JSON - 形式
     * @param {String} value - 入力文字列
     * @returns {Boolean || String} - 結果
     */
    isValidJsonFormat(value) {
      try {
        JSON.parse(value);
        return true;
      } catch {
        return this.$t("V_ERR_MSG_VALID_JSON_FORMAT");
      }
    },

    /**
     * バリデーター - % - 符号存在チェック
     * @param {String} value - 入力文字列
     * @returns {Boolean || String} - 結果
     */
    isValidNotContainPercent(value) {
      if (value === undefined || value === null) {
        return true;
      } else {
        const regexp = /\s*%\s*/;
        return !regexp.test(value) || this.$t("V_ERR_MSG_VALID_CONTAIN_PERCENT");
      }
    },

    /**
     * バリデーター - URL(https始まり) - 形式
     * @param {String} value - 入力文字列
     * @returns {Boolean || String} - 結果
     */
    isValidUrl(value) {
      return Validator.isValidUrl(value) || this.$t("V_ERR_MSG_VALID_CONTAIN_HTTPS");
    },

    /**
     * バリデーター - 負荷上限 - 形式
     * @param {String} value - 入力文字列
     * @param {Number} calcProgressDataType - 負荷計算データ形式
     * @param {Number} calcObject1Type - 計算項目の計算対象1データ形式
     * @returns {Boolean || String} - 結果
     */
    isValidProgressMax(value, calcProgressDataType, calcObject1Type) {
      if (calcProgressDataType) {
        const result = value === 0 ? true : this.isValidRequired(value);
        if (result !== true) return result;
      }
      if (calcProgressDataType == Define.TYPE_INTEGER) {
        if (!Util.checkIntNum(value)) {
          return this.$t("MSG_ERR_FORMAT_INT");
        } else {
          return Util.isValidNumberRange(
            value,
            Define.NUMBER_RANGE_MIN_INTEGER,
            Define.NUMBER_RANGE_MAX_INTEGER
          )
            ? true
            : this.$t("MSG_ERR_SUB_NUMBER_RANGE");
        }
      }
      else if (Util.isFloatConsideringCalc(calcProgressDataType, calcObject1Type)) {
        if (!Util.checkFloatNum(value)) {
          return this.$t("MSG_ERR_FORMAT_FLOAT");
        } else {
          return Util.isValidNumberRange(
            value,
            Define.NUMBER_RANGE_MIN_FLOAT,
            Define.NUMBER_RANGE_MAX_FLOAT
          )
            ? true
            : this.$t("MSG_ERR_SUB_NUMBER_RANGE");
        }
      }
      else {
        return true;
      }
    }
  }
}