var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pagestemplate" }, [
    _c("div", { staticClass: "management" }, [
      _c(
        "div",
        [
          _c("PageTitle", {
            attrs: { titles: _vm.pagetitle, descriptions: _vm.pagedescription }
          }),
          _c("div", { staticClass: "tablearea" }, [_c("UserTable")], 1),
          _c("div", { staticClass: "tablearea" }, [_c("WorkSpaceTable")], 1)
        ],
        1
      ),
      _c("div", { staticClass: "planarea" }, [
        _c("div", { staticClass: "areatitle" }, [
          _vm._v(_vm._s(_vm.$t("WORD_MANAGEMENT_PLAN_CHANGE")))
        ]),
        _c(
          "div",
          { staticClass: "acbtnarea" },
          [
            _c(
              "v-btn",
              {
                staticClass: "abtn",
                attrs: {
                  width: "800px",
                  height: "60px",
                  text: "",
                  href: _vm.agreementUrl,
                  target: "_blank"
                }
              },
              [_c("h3", [_vm._v(_vm._s(_vm.$t("WORD_MANAGEMENT_CONTACT")))])]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "acbtnarea" },
          [
            _c(
              "v-btn",
              {
                staticClass: "cbtn",
                attrs: {
                  width: "800px",
                  height: "60px",
                  text: "",
                  href: _vm.contactUrl,
                  target: "_blank"
                }
              },
              [_c("h3", [_vm._v(_vm._s(_vm.$t("WORD_MANAGEMENT_QUIT")))])]
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }