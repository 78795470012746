<template>
  <div>
    <!-- 実績入力 -->
    <div>
      <CardDetailJissekiInput />
    </div>
    <!-- 実績サマリ -->
    <div v-if="getIsValidJissekiCalc">
      <CardDetailJissekiSummary />
    </div>
    <!-- 実績テーブル -->
    <div>
      <CardDetailJissekiTable />
    </div>
  </div>
</template>

<script>
import CardDetailJissekiInput from "@/components/parts/CardDetailJissekiInput";
import CardDetailJissekiSummary from "@/components/parts/CardDetailJissekiSummary";
import CardDetailJissekiTable from "@/components/parts/CardDetailJissekiTable";
export default {
  components: {
    CardDetailJissekiInput,
    CardDetailJissekiSummary,
    CardDetailJissekiTable
  },

  computed: {
    openDetailTileId() {
      return this.$store.getters["openDetailTileId"];
    },
    isUpdatingJisseki() {
      return this.$store.getters["isUpdatingJisseki"];
    },
    isDeletingJisseki() {
      return this.$store.getters["isDeletingJisseki"];
    },
    isOpenCardDetail() {
      return this.$store.getters["tile/isOpenCardDetail"];
    },
    getIsValidJissekiCalc() {
      return this.$store.getters["setting/getIsValidJissekiCalc"];
    }
  },

  watch: {
    isOpenCardDetail: {
      immediate: true,
      handler: function() {
        if (this.isOpenCardDetail) {
          // タイル実績を取得しセット
          this.$store.dispatch("tile/jissekiGet", this.openDetailTileId);
        }
      }
    },
    isUpdatingJisseki: {
      immediate: true,
      handler: function() {
        if (!this.isUpdatingJisseki) {
          // タイル実績を取得しセット
          this.$store.dispatch("tile/jissekiGet", this.openDetailTileId);
        }
      }
    },
    isDeletingJisseki: {
      immediate: true,
      handler: function() {
        if (!this.isDeletingJisseki) {
          // タイル実績を取得しセット
          this.$store.dispatch("tile/jissekiGet", this.openDetailTileId);
        }
      }
    }
  }
};
</script>

<style scoped></style>
