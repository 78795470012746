<template>
  <v-tooltip right>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="ml-5"
        text
        small
        v-bind="attrs"
        fab
        @click="helpOpen"
        v-on="on"
      >
        <v-icon color="grey darken-2">
          mdi-help-circle-outline
        </v-icon>
      </v-btn>
    </template>
    <span>{{ $t("WORD_HELP") }}</span>
    <HelpDialog ref="helpdialog" :dialogdata="helpobject"></HelpDialog>
  </v-tooltip>
</template>

<script>
import HelpDialog from "@/components/parts/InputExplanationDialog";
export default {
  components: {
    HelpDialog
  },
  props: {
    helpobject: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    helpOpen() {
      this.$refs.helpdialog.helpOpen();
    }
  }
};
</script>
