var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.pattern === _vm.pattern01
        ? _c(
            "v-card",
            {
              staticClass: "cardsumple",
              attrs: { flat: "" },
              on: {
                click: function($event) {
                  return _vm.selectCard(_vm.pattern01)
                }
              }
            },
            [
              _c("div", { staticClass: "cardheight" }, [
                _c("div", { staticClass: "item1" }, [_vm._v("①")])
              ]),
              _c("div", { staticClass: "holizontal_line" }),
              _c("div", { staticClass: "cardheight flexarea" }, [
                _c("div", { staticClass: "item2" }, [_vm._v("②")]),
                _c("div", { staticClass: "vertical_line" }),
                _c("div", { staticClass: "item2" }, [_vm._v("③")])
              ]),
              _c("div", { staticClass: "holizontal_line" }),
              _c("div", { staticClass: "cardheight flexarea" }, [
                _c("div", { staticClass: "item3" }, [_vm._v("④")]),
                _c("div", { staticClass: "vertical_line" }),
                _c("div", { staticClass: "item3" }, [_vm._v("⑤")]),
                _c("div", { staticClass: "vertical_line" }),
                _c("div", { staticClass: "item3" }, [_vm._v("⑥")])
              ])
            ]
          )
        : _vm._e(),
      _vm.pattern === _vm.pattern02
        ? _c(
            "v-card",
            {
              staticClass: "cardsumple",
              attrs: { flat: "" },
              on: {
                click: function($event) {
                  return _vm.selectCard(_vm.pattern02)
                }
              }
            },
            [
              _c("div", { staticClass: "cardheight flexarea" }, [
                _c("div", { staticClass: "item2" }, [_vm._v("①")]),
                _c("div", { staticClass: "vertical_line" }),
                _c("div", { staticClass: "item2" }, [_vm._v("②")])
              ]),
              _c("div", { staticClass: "holizontal_line" }),
              _c("div", { staticClass: "cardheight flexarea" }, [
                _c("div", { staticClass: "item2" }, [_vm._v("③")]),
                _c("div", { staticClass: "vertical_line" }),
                _c("div", { staticClass: "item2" }, [_vm._v("④")])
              ]),
              _c("div", { staticClass: "holizontal_line" }),
              _c("div", { staticClass: "cardheight flexarea" }, [
                _c("div", { staticClass: "item2" }, [_vm._v("⑤")]),
                _c("div", { staticClass: "vertical_line" }),
                _c("div", { staticClass: "item2" }, [_vm._v("⑥")])
              ])
            ]
          )
        : _vm._e(),
      _vm.pattern === _vm.pattern03
        ? _c(
            "v-card",
            {
              staticClass: "cardsumple",
              attrs: { flat: "" },
              on: {
                click: function($event) {
                  return _vm.selectCard(_vm.pattern03)
                }
              }
            },
            [
              _c("div", { staticClass: "cardheight flexarea" }, [
                _c("div", { staticClass: "item3" }, [_vm._v("①")]),
                _c("div", { staticClass: "vertical_line" }),
                _c("div", { staticClass: "item3" }, [_vm._v("②")]),
                _c("div", { staticClass: "vertical_line" }),
                _c("div", { staticClass: "item3" }, [_vm._v("③")])
              ]),
              _c("div", { staticClass: "holizontal_line" }),
              _c("div", { staticClass: "cardheight flexarea" }, [
                _c("div", { staticClass: "item3" }, [_vm._v("④")]),
                _c("div", { staticClass: "vertical_line" }),
                _c("div", { staticClass: "item3" }, [_vm._v("⑤")]),
                _c("div", { staticClass: "vertical_line" }),
                _c("div", { staticClass: "item3" }, [_vm._v("⑥")])
              ]),
              _c("div", { staticClass: "holizontal_line" }),
              _c("div", { staticClass: "cardheight flexarea" }, [
                _c("div", { staticClass: "item3" }, [_vm._v("⑦")]),
                _c("div", { staticClass: "vertical_line" }),
                _c("div", { staticClass: "item3" }, [_vm._v("⑧")]),
                _c("div", { staticClass: "vertical_line" }),
                _c("div", { staticClass: "item3" }, [_vm._v("⑨")])
              ])
            ]
          )
        : _vm._e(),
      _vm.pattern === _vm.pattern04
        ? _c(
            "v-card",
            {
              staticClass: "cardsumple",
              attrs: { flat: "" },
              on: {
                click: function($event) {
                  return _vm.selectCard(_vm.pattern04)
                }
              }
            },
            [
              _c("div", { staticClass: "cardheight" }, [
                _c("div", { staticClass: "item1" }, [_vm._v("①")])
              ]),
              _c("div", { staticClass: "holizontal_line" }),
              _c("div", { staticClass: "cardheight flexarea" }, [
                _c("div", { staticClass: "item1" }, [_vm._v("②")])
              ]),
              _c("div", { staticClass: "holizontal_line" }),
              _c("div", { staticClass: "cardheight flexarea" }, [
                _c("div", { staticClass: "item1" }, [_vm._v("③")])
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }