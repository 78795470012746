<template>
  <div :class="cellFrameClass">
    <!-- カード枚数 -->
    <div class="disp-num">
      <div class="mt-2">
        <label class="disp-num-label" @click="moveToTargetDate()">{{tileLoadListByDate.num}}</label>
      </div>
    </div>
    <!-- 負荷情報 -->
    <div v-if="isDisplayProgressbar" class="progressbar white_back">
      <Progressbar
        :progress="tileLoadListByDate.progress"
        :sum="tileLoadListByDate.sum"
        :max="progressMax"
        :isMonthView="true"
      ></Progressbar>
    </div>
  </div>
</template>

<script>
import Define from "@/define.js";
import moment from "moment";
import Progressbar from "@/components/parts/Progressbar.vue";
export default {
  components: {
    Progressbar
  },
  props: {
    tileLoadListByDate: {
      type: Object,
      default: () => {}
    },
    lineId: {
      type: String,
      default: ""
    },
    max: {
      type: Number,
      default: 0
    },
    progressbarFlg: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    /**
     * 対象日の曜日
     */
    days () {
      return moment(this.tileLoadListByDate.dateInfo).day();
    },
    /**
     * 対象月の日数
     */
    daysInMonth () {
      return moment(this.tileLoadListByDate.dateInfo).daysInMonth();
    },
    /**
     * セルのクラスを取得する
     * @returns {String} - セルのクラス
     */
    cellFrameClass () {
      var cellFrameClass = "cell-flame";
      switch(this.daysInMonth){
        case 28:
        cellFrameClass += " cell-flame-width-28";
        break;
        case 29:
        cellFrameClass += " cell-flame-width-29";
        break;
        case 30:
        cellFrameClass += " cell-flame-width-30";
        break;
        case 31:
        cellFrameClass += " cell-flame-width-31";
        break;
        default:
          break;
      }
      switch(this.days){
        case Define.DAYS_SUNDAY:
        cellFrameClass += " sunday";
        break;
        case Define.DAYS_SATURDAY:
        cellFrameClass += " saturday";
        break;
        default:
          break;
      }
      return cellFrameClass;
    },
    /**
     * カレンダー設定で負荷上限を0に設定時、負荷バーを表示しない
     * カレンダー設定で負荷上限が0でない時、負荷バーを表示する(計画エリアの負荷上限=0であても)
     */
    isDisplayProgressbar () {
      let calendarMax = this.tileLoadListByDate.calendarMax;
      return calendarMax == 0 ? false : (calendarMax ? true : this.progressbarFlg);
    },
    /**
     * カレンダー設定で負荷上限の設定があれば、カレンダー設定の負荷上限を使用
     * カレンダー設定がなかれば、対象計画エリアの負荷上限を使用
     */
    progressMax () {
      // カレンダー設定の負荷上限
      let calendarMax = this.tileLoadListByDate.calendarMax;
      return calendarMax ? calendarMax : this.max;
    }
  },
  methods: {
    /**
     * 指定日に移動する
     */
    moveToTargetDate() {
      this.$emit("changeDate", this.tileLoadListByDate.dateInfo);
      this.$emit("close");
    }
  }
};
</script>

<style scoped>
.cell-flame {
  border: solid 1px #bbb;
  border-left: 0;
}
.cell-flame-width-28 {
  width: 54.8px;
}
.cell-flame-width-29 {
  width: 53.2px;
}
.cell-flame-width-30 {
  width: 51px;
}
.cell-flame-width-31 {
  width: 50px;
}
.disp-num {
  font-size: 20px;
  text-align: center;
  height: 38px;
}
.disp-num-label {
  cursor: pointer;
}
.progressbar {
  text-align: center;
  height: 20px;
}
.white_back {
  background-color: #fff;
}
</style>
