<template>
  <div v-if="isInitDataLoaded" class="pagestemplate">

    <!-- カード詳細ダイアログ -->
    <MobileCardDetail />

    <!-- 日付選択 -->
    <div :class="headerCss">
      <DatePick class="headertext mx-auto my-auto" ref="datePick" @setDate="setTarget($event)"></DatePick>
    </div>

    <!-- ライン毎 -->
    <div class="listdiv">
      <v-list outlined class="listarea" :color="bgColor" v-for="line in lines" :key="line.lineId">
      <v-list-group class="mb-1" :value="line.tileList[0].tileList.length > 0">
        <template v-slot:activator>
          <v-list-item-title>{{ line.lineName }}</v-list-item-title>
          <v-spacer></v-spacer>
          <!-- 未完了カード -->
          <v-chip small color="info" >
            {{ line.tileList[0].tileList.filter(d => d.isComplete == false).length }}
          </v-chip>
          <!-- 完了カード -->
          <v-chip small color="grey" text-color="white" class="ml-1">
            {{ line.tileList[0].tileList.filter(d => d.isComplete == true).length }}
          </v-chip>
        </template>
        <!-- カード毎 -->
        <v-list-item v-for="tile in line.tileList[0].tileList" :key="tile.tileId">
          <CardBase :tileinfo="tile" class="mt-2 mx-auto"/>
        </v-list-item>
      </v-list-group>
    </v-list>
    </div>

  </div>
</template>

<script>
import Define from "@/define.js";
import moment from "moment";
import DatePick from "@/components/parts/DatePick.vue";
import CardBase from "@/components/parts/CardPattern/CardBase.vue";
import MobileCardDetail from "@/components/mobile/parts/MobileCardDetail.vue";
export default {
  components: {
    DatePick,
    CardBase,
    MobileCardDetail
  },
  data() {
    return {
      targetDay: null,
      formatHyphen: Define.DATE_FORMAT_DAY_HYPHEN,
    };
  },
  computed: {
    isInitDataLoaded() {
      return this.$store.getters["isInitDataLoaded"];
    },
    lines() {
      let result = {};
      if (this.targetDay != null) {
        result = this.$store.getters["tile/getPlanTileData"](this.targetDay, this.targetDay, 1);
        result = result.tileList.filter(d => d.lineId != null);
      }
      return result;
    },
    headerCss() {
      let today = moment().format(this.formatHyphen);
      return (this.targetDay == today) ? "headerarea istoday":"headerarea";
    },
    days() {
      return moment(this.targetDay).day();
    },
    bgColor(){
      let color = undefined;
      switch(this.days){
        case Define.DAYS_SUNDAY:
          color = "rgba(248, 187, 208, 0.2)";
          break;
        case Define.DAYS_SATURDAY:
          color = "rgba(187, 222, 251, 0.2)";
          break;
        default:
          break;
      }
      return color;
    }
  },
  methods: {
    setTarget(date){
      this.targetDay = date;
    }
  }
};
</script>

<style>
.v-list-group__header__append-icon {
  min-width: 24px !important;
}
.listdiv {
  height: calc(100vh - 90px);
  overflow-y: auto;
}
.listarea {
  padding: 0px;
}
.headerarea {
  height: 40px;
  display: flex;
  background-color: #1976d2f7;
}
.istoday {
  background-color: #ffa726;
}
.headertext {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}
</style>
