<template>
  <div class="upper">
    <v-dialog v-model="loadingDialog" width="24%" persistent>
      <div class="loadingdialog">
        <v-card class="pa-4">
          <div class="text-center">
            <v-progress-circular
              :size="70"
              :width="7"
              color="info"
              indeterminate
            ></v-progress-circular>
            <div class="mt-6 text-center">
              <p>{{ $t("WORD_LOADING") }}</p>
            </div>
          </div>
        </v-card>
      </div>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    loadingDialog: false
  }),
  methods: {
    loadingOpen() {
      //呼び出し元でこのメソッド呼び出し(ダイアログOPEN)
      this.loadingDialog = true;
    },
    loadingClose() {
      //呼び出し元でこのメソッド呼び出し(ダイアログCLOSE)
      this.loadingDialog = false;
    }
  }
};
</script>
<style>
.upper {
  z-index: 10;
}
.border_left {
  border-left: solid 8px #1976d2ca;
  padding-left: 8px;
}
</style>
