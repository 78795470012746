var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c(
        "v-card",
        {
          staticClass: "mx-auto",
          style: _vm.thmbnailBoxStyle,
          on: { dblclick: _vm.openContent }
        },
        [
          _vm.isLoading
            ? _c("div", { staticClass: "pt-10 pl-10" }, [
                _vm._v(_vm._s(_vm.MSG_LOADING_FILE))
              ])
            : _vm._e(),
          _vm.isImageOrPdf(_vm.parameter.name)
            ? _c("v-img", {
                attrs: {
                  src: _vm.imageSource,
                  "max-height": _vm.isModePc ? _vm.imageHeight - 2 : undefined,
                  "max-width": _vm.isModePc ? _vm.imageWidth - 2 : undefined,
                  contain: _vm.isModePc
                }
              })
            : _vm._e(),
          _vm.canOpenContent
            ? _c(
                "v-tooltip",
                {
                  attrs: { right: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _vm.disp == true
                              ? _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "mt-7",
                                        attrs: {
                                          absolute: "",
                                          fab: "",
                                          dark: "",
                                          small: "",
                                          right: "",
                                          top: "",
                                          color: "primary"
                                        },
                                        on: { click: _vm.openContent }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c("v-icon", { attrs: { dark: "" } }, [
                                      _vm._v("mdi-open-in-new")
                                    ])
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    1036103910
                  )
                },
                [_c("span", [_vm._v(_vm._s(_vm.MSG_TOOLTIP_OPEN_CONTENT))])]
              )
            : _vm._e(),
          _vm.parameter.name
            ? _c(
                "v-tooltip",
                {
                  attrs: { right: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _vm.disp == true
                              ? _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        ref: "download",
                                        staticClass: "mb-7",
                                        attrs: {
                                          absolute: "",
                                          fab: "",
                                          dark: "",
                                          small: "",
                                          right: "",
                                          bottom: "",
                                          color: "primary"
                                        },
                                        on: { click: _vm.downloadContent }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c("v-icon", { attrs: { dark: "" } }, [
                                      _vm._v("mdi-download")
                                    ])
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    2084625564
                  )
                },
                [_c("span", [_vm._v(_vm._s(_vm.MSG_TOOLTIP_DOWNLOAD_FILE))])]
              )
            : _vm._e()
        ],
        1
      ),
      _vm.disp == true
        ? _c("v-file-input", {
            key: _vm.restKey,
            ref: "input",
            staticClass: "mr-4 my-0 py-0",
            attrs: {
              "prepend-icon": "mdi-file-document-outline",
              placeholder: _vm.label,
              clearable: "",
              disabled: !_vm.editable,
              "hide-details": "auto"
            },
            on: { change: _vm.onChange, "click:clear": _vm.onDelete },
            model: {
              value: _vm.file,
              callback: function($$v) {
                _vm.file = $$v
              },
              expression: "file"
            }
          })
        : _c("label", { staticClass: "file-label" }, [
            _vm._v(_vm._s(_vm.file ? _vm.file.name : ""))
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }