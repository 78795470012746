<template>
  <div class="tenantregist">
    <v-card
      outlined
      tile
      class="mx-auto mb-2"
      width="650px"
      color="rgb(0, 0, 0, 0)"
    >
      <v-layout class="justify-center">
        <v-img
          :src="require('@/assets/Freely_squair.png')"
          max-height="150"
          max-width="150"
        />
      </v-layout>

      <v-card-text>
        <v-form ref="registform">
          <v-row dense>
            <v-col>
              <v-text-field v-model="company" placeholder=" " outlined label="会社名" :rules="[required, max_length]">
                <template v-slot:label>会社名<span style="color: red"> *</span></template>
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field v-model="department" placeholder=" " outlined label="部署名" :rules="[required, max_length]" class="ml-2">
                <template v-slot:label>部署名<span style="color: red"> *</span></template>
              </v-text-field>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <v-text-field v-model="name" placeholder=" " outlined label="担当者名" :rules="[required, max_length]">
                <template v-slot:label>担当者名<span style="color: red"> *</span></template>
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field v-model="phone" placeholder=" " outlined label="電話番号(ハイフンあり)" :rules="[required, max_length, phone_format]" class="ml-2">
                <template v-slot:label>電話番号(ハイフンあり)<span style="color: red"> *</span></template>
              </v-text-field>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <v-text-field v-model="email" placeholder=" " outlined label="メールアドレス" :rules="[required, email_max_length, email_format]" height="20px">
                <template v-slot:label>メールアドレス<span style="color: red"> *</span></template>
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field v-model="password" placeholder=" " outlined label="パスワード" :rules="[required, password_max_length, password_format]" type="password" class="ml-2">
                <template v-slot:label>パスワード<span style="color: red"> *</span></template>
              </v-text-field>
            </v-col>
          </v-row>

          <v-card-actions class="justify-center mb-5">
            <v-btn
              id="btn_tenant_regist"
              height="50"
              class="templatebtn"
              color="success"
              @click="submit"
              >無料お試しプランに登録する</v-btn
            >
          </v-card-actions>
          ～ 無料お試しプラン ～
          <div class="planborder mt-1 pl-2">
            <div class="policy">お申込みから30日間の期間限定で10ユーザまで無料利用可能なプランとなっております。</div>
            <div class="policy">利用期間と利用人数に制限を設けさせていただきますが機能の制限はございません。</div>
            <div class="policy">有料プランをご利用いただく前に操作感などをご確認いただけます。</div>
          </div>
          <div class="spolicy mt-3">
            ※「無料お試しプランに登録する」ボタンから申し込むことで、
            <a href="https://kaizen-navi.biz/freely/policies" target="_blank">
              Freelyの利用規約
            </a>
            と
            <a href="https://kaizen-navi.biz/privacy-policy" target="_blank">
              プライバシーポリシー
            </a>
            に同意したものといたします。
          </div>
          <div class="spolicy">
            ※無料お試しプラン終了後、自動的に課金されることはありません。
          </div>
          <div class="spolicy">
            ※無料お試しプラン終了後も継続利用いただく場合は、
            <a href="https://kaizen-navi.biz/contact-start" target="_blank">
              カイゼンナビの「お問合せ」
            </a>
            からお申込みください。
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import * as Util from "@/util/utils.js";
import * as Validator from "@/util/validator.js";
import Swal from "sweetalert2";
import Define from "@/define.js";
export default {
  name: "TenantRegist",
  data: function() {
    return {
      // 入力項目
      company: "",
      department: "",
      name: "",
      email: "",
      phone: "",
      password: "",
      purpose: "",

      // 入力規則
      required: value => Validator.isValidRequired(value),
      max_length: value =>
        Validator.isValidLength(value, null, Define.LENGTH_MAX_STRING),
      email_max_length: value =>
        Validator.isValidLength(value, null, Define.LENGTH_MAX_EMAIL),
      email_format: value => Validator.isValidEmailFormat(value),
      password_max_length: value =>
        Validator.isValidLength(value, null, Define.LENGTH_MAX_PASSWORD),
      password_format: value => Validator.isValidPasswordLFormat(value),
      phone_format: value => Validator.isValidPhoneFormat(value)
    };
  },
  methods: {
    /**
     * 入力情報をAPIに伝搬する
     */
    submit() {
      let query = this.$router.history.current.query;
      let lead = "lead" in query ? query.lead : "";
      if (this.$refs.registform.validate()) {
        var tid = this.$uuid.v4();
        var tenant = {
          tenant_id: tid,
          company: this.company,
          department: this.department,
          email: this.email,
          phone: this.phone,
          name: this.name,
          plan: 0,
          purpose: this.purpose,
          lead: lead
        };
        var user = {
          tenant_id: tid,
          email: this.email,
          password: this.password,
          role: 0
        };
        // 管理者ユーザー登録を先に実行する
        // loadingダイアログ表示
        this.$store.commit("setIsLoading", true);
        Util.axiosPostReq(Define.API_AUTH_REG, user)
          .then(response => {
            // console.log(response);
            console.log(response.status); // ステータスコードのみ表示(秘匿情報は外部に出さない)
            Util.axiosPostReq(Define.API_TENANT, tenant)
              .then(response => {
                // console.log(response);
                console.log(response.status); // ステータスコードのみ表示(秘匿情報は外部に出さない)
                // loadingダイアログ非表示
                this.$store.commit("setIsLoading", false);
                this.$router.push("/tenantregistconfirm");
              })
              .catch(error => {
                console.log(error.message);
                // loadingダイアログ非表示
                this.$store.commit("setIsLoading", false);
                Swal.fire({
                  icon: "error",
                  title: "無料お試しプラン登録失敗",
                  text: "テナント情報の登録に失敗しました。"
                });
              });
          })
          .catch(error => {
            console.log(error.message);
            // loadingダイアログ非表示
            this.$store.commit("setIsLoading", false);
            Swal.fire({
              icon: "error",
              title: "無料お試しプラン登録失敗",
              text: "ユーザー情報の登録に失敗しました。"
            });
          });
      } else {
        Swal.fire({
          icon: "error",
          title: "入力エラー",
          text: "項目ごとに正しい情報を入力してください。"
        });
      }
    }
  }
};
</script>

<style scoped>
.tenantregist {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #333;
  height: 100%;
  width: 100%;
}
.planborder {
  border: 1px solid #999;
  border-radius: 5px;
}
.logo {
  text-align: center;
  margin-top: 30px;
}
.templatetenantregistlogo {
  width: 500px;
  height: 80px;
}
.templatetenantregistcard {
  width: 500px;
  height: 250px;
}
.policy {
  font-size: 12px;
  margin: 2px 0;
  color: 333;
}
.spolicy {
  font-size: 9.5px;
  margin: 1px 0;
  color: 333;
}
.purpose {
  font-size: 12px;
  color: gray;
}
.container {
  display: flex;
  padding-left: 200px;
  padding-right: 200px;
}
</style>
