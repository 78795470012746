import Define from "../define.js";

/**
 * ステップの選択肢を取得する(マッピングテーブル)
 * @returns - ステップの選択肢
 */
export function getStepItems(type) {
  let items;
  switch (type) {
    case Define.TYPE_INTEGER:
      items = [
        // eslint-disable-next-line prettier/prettier
        { text: Define.SCALE_1,     value: Define.SCALE_1 },
      ];
      break;
    case Define.TYPE_FLOAT:
      items = [
        // eslint-disable-next-line prettier/prettier
        { text: Define.SCALE_1,     value: Define.SCALE_1 },
        // eslint-disable-next-line prettier/prettier
        { text: Define.SCALE_01,    value: Define.SCALE_01 },
        // eslint-disable-next-line prettier/prettier
        { text: Define.SCALE_001,   value: Define.SCALE_001 },
        // eslint-disable-next-line prettier/prettier
        { text: Define.SCALE_0001,  value: Define.SCALE_0001 }
      ];
      break;
    default:
      items = [];
      break;
  }
  return items;
}

/**
 * カテゴリを表示するかどうか(マッピングテーブル)
 * @param {Number} type データ形式
 * @param {Number} inputType データ入力形式
 * @returns - true:表示, false:非表示
 */
export function isDisplayCategory(type, inputType) {
  return type === Define.TYPE_STRING && inputType === Define.INPUT_TYPE_LIST;
}

/**
 * 最小値/最大値/ステップを表示するかどうか(マッピングテーブル)
 * @param {Number} type データ形式
 * @param {Number} inputType データ入力形式
 * @returns - true:表示, false:非表示
 */
export function isDisplayNumParam(type, inputType) {
  return (
    (type === Define.TYPE_INTEGER || type === Define.TYPE_FLOAT) &&
    (inputType === Define.INPUT_TYPE_SPINNER ||
      inputType === Define.INPUT_TYPE_SLIDER)
  );
}

/**
 * 実績集計方法が編集可能かどうか(マッピングテーブル)
 * @param {Number} type データ形式
 * @param {Number} jisseki 実績入力
 * @param {Number} calcObject1Type 計算対象1のデータ形式
 * @returns - true:編集可能, false:編集不可能
 */
export function isEditableJissekiCalc(type, jisseki, calcObject1Type) {
  let targetType = [Define.TYPE_INTEGER, Define.TYPE_FLOAT, Define.TYPE_CALC];
  let targetJisseki = [Define.JISSEKI_OPTIONAL, Define.JISSEKI_REQUIRED]; 
  let targetCalcType = [Define.TYPE_NOTHING, Define.TYPE_INTEGER, Define.TYPE_FLOAT];

  return targetType.includes(type) && targetJisseki.includes(jisseki) && targetCalcType.includes(calcObject1Type);
}

/**
 * 計算対象1/四則演算子/計算対象2を表示するかどうか(マッピングテーブル)
 * @param {Number} type データ形式
 * @param {Number} inputType データ入力形式
 * @returns - true:編集可能, false:編集不可能
 */
export function isDisplayCalc(type, inputType) {
  return type === Define.TYPE_CALC && inputType ===  Define.INPUT_TYPE_DEFAULT
}