<template>
  <div class="planarea">
    <v-row class="searcharea">

      <div class="d-area">
        <!-- 日付選択 -->
        <DatePickWeek class="ml-4" ref="datePick" :keydate="keydate" @setDate="setHeader($event)"></DatePickWeek>

        <!-- キーワード検索 -->
        <SearchBox class="ml-2" :lineid="planAreaCode" @getCombo="setCombo"></SearchBox>


        <!-- カード検索 -->
        <v-tooltip bottom open-delay="300" color="rgba(60,64,67,0.95)" transition="fade">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon class="round-btn ml-2" elevation="2" v-bind="attrs" v-on="on" @click="openCardSearchDialog()">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("TOOLTIP_CARD_SEARCH") }}</span>
        </v-tooltip>
        <CardSearchDialog
          :dialog="isOpenCardSearchDialog"
          @changeDate="changeDate($event)"
        />

        <!-- 一か月表示 -->
        <v-tooltip bottom open-delay="300" color="rgba(60,64,67,0.95)" transition="fade">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon class="round-btn ml-1" elevation="2" v-bind="attrs" v-on="on" @click="openMonthViewDialog()">
              <v-icon>mdi-calendar-month</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("TOOLTIP_MONTH_VIEW") }}</span>
        </v-tooltip>
        <MonthViewDialog
          :dialog="isOpenMonthViewDialog"
          @changeDate="changeDate($event)"
        />

        <!-- カード履歴 -->
        <v-tooltip bottom open-delay="300" color="rgba(60,64,67,0.95)" transition="fade">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon class="round-btn ml-1" elevation="2" v-bind="attrs" v-on="on" @click="openHistoryDialog()">
              <v-icon>mdi-history</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("PAGE_TITLE_CARD_HISTORY") }}</span>
        </v-tooltip>
        <CardHistoryDialog
          :dialog="isOpenHistoryDialog"
          @changeDate="changeDate($event)"
        />

        <!-- 列数選択 -->
        <div style="width: 100px;">
          <v-select
            v-model="displayDays"
            class="round-btn ml-2"
            dense
            :label="$t('WORD_COLNUM')"
            outlined
            hide-details
            :items="colNumItems"
          ></v-select>
        </div>
      </div>

      <!-- カード移動 -->
      <v-col v-if="!loading">
        <v-switch
          v-model="swEnabled"
          class="swEnabled"
          :label="$t('WORD_CARD_MOVE')"
          color="#00b842"
          :disabled="tenant.plan === planTrial && tenant.trialEndFlg || !authCardAction"
        ></v-switch>
      </v-col>
      <v-col v-if="loading">
        <div class="text-center">
          <v-progress-circular
            :size="32"
            :width="4"
            color="#00b842"
            indeterminate
            class="mt-1"
          ></v-progress-circular>
        </div>
      </v-col>

    </v-row>
    <div class="headerarea" :style="areaWidthHeader">
      <div
        v-for="index in displayDays"
        :key="index"
        :class="
          isColOpens[index - 1]
            ? getClassOpen(days(index - 1).value)
            : getClassClose(days(index - 1).value)
        "
        @click="changeColOpen(days(index - 1).value)"
      >
        {{ days(index - 1).header }}
      </div>
    </div>
    <div class="scroll" :style="areaWidth">
      <LineListProgressbar
        v-for="tilelist in plantilelist.tileList"
        :key="tilelist.lineId"
        :enabled="enabled"
        :cardtempnum="cardtempnum"
        :arealist="tilelist"
        :keyword="keyword"
        :is-col-opens="isColOpens"
      ></LineListProgressbar>
    </div>
  </div>
</template>

<script>
import LineListProgressbar from "@/components/parts/LineListProgressbar.vue";
import SearchBox from "@/components/parts/SearchBox.vue";
import DatePickWeek from "@/components/parts/DatePickWeek.vue";
import CardSearchDialog from "@/components/parts/CardSearchDialog.vue";
import MonthViewDialog from "@/components/parts/MonthViewDialog.vue";
import CardHistoryDialog from "@/components/parts/CardHistoryDialog.vue";
import Define from "@/define.js";
import moment from "moment";
import * as Util from "@/util/utils.js";
import Vue from "vue";
import Swal from "sweetalert2";
export default {
  components: {
    LineListProgressbar,
    SearchBox,
    DatePickWeek,
    CardSearchDialog,
    MonthViewDialog,
    CardHistoryDialog
  },
  props: {
    enabled: {
      type: Boolean,
      default: true
    },
    plantilelist: {
      type: Object,
      default: () => ({
        tileList: []
      })
    },
    cardtempnum: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      planAreaCode: Define.PLAN_AREA_CODE,
      planTrial: Define.PLAN_TRIAL,
      firstdate: moment(),
      keydate: "",
      keyword: { value: "", mappingId: "" },
      isColOpens: [],
      isOpenCardSearchDialog: false,
      isOpenMonthViewDialog: false,
      isOpenHistoryDialog: false,
      colNumItems: [
        { text: "1", value: Define.DISP_DAYS_1 },
        { text: "2", value: Define.DISP_DAYS_2 },
        { text: "3", value: Define.DISP_DAYS_3 },
        { text: "7", value: Define.DISP_DAYS_7 },
        { text: "10", value: Define.DISP_DAYS_10 }
      ],
      isFirst: true,
      formatHyphen: Define.DATE_FORMAT_DAY_HYPHEN,
    };
  },
  computed: {
    days() {
      return dayIndex => {
        return this.setDate(dayIndex);
      };
    },
    swEnabled: {
      get() {
        let user = this.$store.getters["auth/user"];
        if (user.role === Define.USER_ROLE_PRACTITIONER) {
          return false;
        } else {
          return this.$store.getters["setting/getEnabled"];
        }
      },
      set() {
        this.$store.commit("setting/switchEnabled");
      }
    },
    tenant() {
      return this.$store.getters["tenant/getTenantData"];
    },
    loading: {
      get() {
        return this.$store.getters["setting/loading"];
      }
    },
    selectedWorkspaceId() {
      return this.$store.getters["auth/selectedWorkSpaceId"];
    },
    displayCol() {
      return this.$store.getters["display/displayCol"];
    },
    areaWidthHeader() {
      return {
        width: Util.getAreaWidth(this.isColOpens, true, this.displayDays)
      };
    },
    areaWidth() {
      return {
        width: Util.getAreaWidth(this.isColOpens, false, this.displayDays)
      };
    },
    displayDays: {
      set(value) {
        this.$store.commit("display/setDisplayDays", value);

        let today = moment().format(this.formatHyphen);
        let mToday = moment(today);
        let mStart = moment(this.startDate);
        let mEnd = moment(this.endDate);
        let isToday = false;

        if(value == Define.DISP_DAYS_1 || value == Define.DISP_DAYS_2 || value == Define.DISP_DAYS_3){
          // 初期表示は本日を基準日とする
          if(this.isFirst){
            this.changeDateForSelect(today);
          // セレクトボックスでの変更時
          }else{
            // 本日が表示範囲内の場合、本日を基準日とする
            if(mToday.isBetween(mStart, mEnd, null, '[]')) {
              this.changeDateForSelect(today);
              isToday = true;
            // 本日が表示範囲外の場合、表示範囲の開始日を基準日とする
            }else{
              this.changeDateForSelect(this.displayFirstDay);
            }
          }
        }else{
          this.changeDateForSelect(this.displayFirstDay);
        }
        this.isFirst = false;

        // 10日表示の場合、未計画の折畳状態を閉に変更する
        if(value == Define.DISP_DAYS_10 && this.isColFreeAreaOpen){
          let value = { boardId: this.selectedWorkspaceId, dateId: null };
          this.$store.commit("display/changeColOpen", value);
        // 10日表示以外の場合、未計画の折畳状態を開に変更する
        }else if(value != Define.DISP_DAYS_10 && !this.isColFreeAreaOpen){
          let value = { boardId: this.selectedWorkspaceId, dateId: null };
          this.$store.commit("display/changeColOpen", value);
        }

        this.$store.dispatch("initialData", {
          workSpaceId: this.selectedWorkspaceId,
          daysDiff: value - 1,
          displayFirstDay: isToday ? today : this.displayFirstDay
        });

        this.setIsColOpens();
      },
      get() {
        return this.$store.getters["display/displayDays"];
      }
    },
    authCardAction() {
      let user = this.$store.getters["auth/user"];
      let role = this.$store.getters["role/roleByWorkspaceId"](this.selectedWorkspaceId, user.role);
      return Util.getAuthority(Define.EVENT_CARD_ACTION, role);
    },
    isExistSetting() {
      return this.$store.getters["setting/isExistSetting"];
    },
    displayFirstDay() {
      return this.$store.getters["display/displayFirstDay"];
    },
    isColFreeAreaOpen(){
      return Util.getIsOpen(this.displayCol, this.selectedWorkspaceId, null)
    },
    startDate() {
      return this.$store.getters["startDate"];
    },
    endDate() {
      return this.$store.getters["endDate"];
    },
  },
  watch: {
    selectedWorkspaceId: {
      handler: function() {
        this.setIsColOpens();
      }
    },
    displayCol: {
      immediate: true,
      deep: true,
      handler: function() {
        this.setIsColOpens();
      }
    }
  },
  mounted() {
    this.displayDays = this.$store.getters["display/displayDays"];
  },
  methods: {
    /**
     * コンボボックスで選択されたキーワードとそのマッピングID、日付を代入
     * @param {Object} keyword - キーワード、マッピングID、日付
     */
    setCombo(keyword) {
      this.keyword = {
        value: keyword.value,
        mappingId: keyword.mappingId
      };
      this.keydate = keyword.posCol;
    },
    /**
     * DatePick.vueで取得した日付を列ヘッダの変数に代入、emitでイベント発火
     * @param {Date} date - 取得した日付
     */
    setHeader(date) {
      this.firstdate = date;
      this.setIsColOpens();
      this.$emit("catchDate", this.firstdate);
    },
    /**
     * DatePick.vueに外部からの日付変更を通知する
     * @param {Date} date - 取得した日付
     */
    changeDate(date) {
      this.$refs.datePick.setSelectDate(date)
    },
    /**
     * DatePick.vueに外部からの日付変更を通知する
     * @param {Date} date - 取得した日付
     */
    changeDateForSelect(date) {
      this.$refs.datePick.setSelectDateForSelect(date)
    },
    /**
     * 列ヘッダに表示する文字列と比較用データを含むオブジェクトを返す
     * @param {Number} num - numの分だけfirstdateから＋される。そのままfirstdateを表示する場合はnum=0
     * @returns {Object} result - header: 画面表示文字列, value: isToday比較用
     */
    setDate(num) {
      let header = Define.DATE_FORMAT_WEEK;
      let value = Define.DATE_FORMAT_DAY_HYPHEN;
      let result = { header: "", value: "" };
      let locale = this.$i18n.locale;
      moment.locale(locale);
      if (num === Define.DAY_FIRST) {
        result.header = moment(this.firstdate).format(header);
        result.value = moment(this.firstdate).format(value);
      } else {
        result.header = moment(this.firstdate)
          .add(num, "days")
          .format(header);
        result.value = moment(this.firstdate)
          .add(num, "days")
          .format(value);
      }
      return result;
    },
    /**
     * 非折畳状態のクラスを取得する
     * @param {Date} date - 比較対象の日付
     * @returns {String} - 当てるcss名
     */
    getClassOpen(date) {
      let css = "";
      if(this.displayDays === Define.DISP_DAYS_1) {
        css += "headeropen1";
      }else if(this.displayDays === Define.DISP_DAYS_2) {
        css += "headeropen2";
      }else if(this.displayDays === Define.DISP_DAYS_3) {
        css += "headeropen3";
      }else {
        css += "headeropen7_10";
      }

      let format = Define.DATE_FORMAT_DAY_HYPHEN;
      let today = moment().format(format);
      if(date === today) {
        css += " istoday";
      }
      return css;
    },
    /**
     * 折畳状態のクラスを取得する
     * @param {Date} date - 比較対象の日付
     * @returns {String} - 当てるcss名
     */
    getClassClose(date) {
      let format = Define.DATE_FORMAT_DAY_HYPHEN;
      let headerClose = Define.CSS_DAY_CLOSE_HEADER;
      let todayHeaderClose = Define.CSS_IS_TODAY_DAY_CLOSE_HEADER;
      let today = moment().format(format);
      return date === today ? todayHeaderClose : headerClose;
    },
    /**
     * 対象日付の折畳状態を変更する
     * @param {Date} date - 対象日付
     */
    changeColOpen(date) {
      let value = {
        boardId: this.selectedWorkspaceId,
        dateId: date
      };
      this.$store.commit("display/changeColOpen", value);
    },
    /**
     * 表示日付の折畳状態を設定する
     */
    setIsColOpens() {
      this.isColOpens = [];
      for (let i = 0; i < this.displayDays; i++) {
        Vue.set(this.isColOpens, i, Util.getIsOpen(this.displayCol, this.selectedWorkspaceId, this.days(i).value));
      }
    },
    /**
     * カード検索ダイアログを開く
     */
    openCardSearchDialog() {
      if(!this.isExistSetting){
        Swal.fire({
          html: this.$t("MSG_REQUIRED_SETTING"),
          icon: "error"
        });
        return;
      }
      this.$store.commit("tile/setIsOpenCardSearch", true);
    },
    /**
     * 一か月表示ダイアログを開く
     */
    openMonthViewDialog() {
      if(!this.isExistSetting){
        Swal.fire({
          html: this.$t("MSG_REQUIRED_SETTING"),
          icon: "error"
        });
        return;
      }
      this.$store.commit("tile/setIsOpenMonthView", true);
    },
    /**
     * カード履歴ダイアログを開く
     */
    openHistoryDialog() {
      if(!this.isExistSetting){
        Swal.fire({
          html: this.$t("MSG_REQUIRED_SETTING"),
          icon: "error"
        });
        return;
      }
      this.$store.commit("tile/setIsOpenHistory", true);
    }
  }
};
</script>

<style scoped>
.planarea {
  margin: 5px 2px 5px 1px;
}
.searcharea {
  min-width: 1350px;
  max-width: 100%;
  height: 64px;
}
.d-area {
  display: flex;
  margin-top: 12px;
}
.col {
  max-height: 100%;
}
.swEnabled {
  margin-top: 4px;
}
.scroll {
  max-height: 82vh;
  margin-bottom: -3px;
  background-color: #ededed;
  overflow-x: hidden;
  overflow-y: scroll;
}
.headeropen1 {
  text-align: center;
  margin-left: -1px;
  padding: 3px;
  width: 1260px;
  color: #fff;
  font-weight: bold;
  border-right: solid 1px #bbb;
  border-left: solid 1px #bbb;
  cursor: pointer;
}
.headeropen2 {
  text-align: center;
  margin-left: -1px;
  padding: 3px;
  width: 630px;
  color: #fff;
  font-weight: bold;
  border-right: solid 1px #bbb;
  border-left: solid 1px #bbb;
  cursor: pointer;
}
.headeropen3 {
  text-align: center;
  margin-left: -1px;
  padding: 3px;
  width: 420px;
  color: #fff;
  font-weight: bold;
  border-right: solid 1px #bbb;
  border-left: solid 1px #bbb;
  cursor: pointer;
}
.headeropen7_10 {
  text-align: center;
  margin-left: -1px;
  padding: 3px;
  width: 180px;
  color: #fff;
  font-weight: bold;
  border-right: solid 1px #bbb;
  border-left: solid 1px #bbb;
  cursor: pointer;
}
.headerclose {
  text-align: center;
  margin-left: -1px;
  padding: 3px;
  width: 65px;
  color: #fff;
  font-weight: bold;
  border-right: solid 1px #bbb;
  border-left: solid 1px #bbb;
  cursor: pointer;
}
.headerarea {
  height: 30px;
  padding-left: 80px;
  display: flex;
  background-color: #1976d2f7;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.istoday {
  background-color: #ffa726;
}
.round-btn {
  background-color: #fff;
}
</style>
