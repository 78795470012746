<template>
  <div class="searchbox">
    <div style="width: 180px;">
      <v-select
        v-model="select"
        color="teal"
        background-color="#fff"
        dense
        outlined
        :label="$t('WORD_CATEGORY')"
        class="dropdown"
        :items="dropdown"
        item-text="name"
        item-value="mapping_id"
        return-object
        @change="getId"
      ></v-select>
    </div>
    <div style="width: 10px;"></div>
    <div style="width: 350px;">
      <v-combobox
        v-if="lineid !== 0"
        v-model="comboModel"
        color="teal"
        background-color="#fff"
        dense
        placeholder=" "
        outlined
        :label="searchBoxText"
        class="input"
        clearable
        :items="autocomplete"
        item-text="display"
        item-value="display"
        return-object
        :search-input.sync="search"
      ></v-combobox>
      <v-text-field
        v-else
        v-model="text"
        color="teal"
        background-color="#fff"
        dense
        placeholder=" "
        outlined
        :label="$t('WORD_KEYWORD')"
        class="input"
        clearable
        @input="getKeyword"
      >
      </v-text-field>
    </div>
  </div>
</template>

<script>
import * as Util from "@/util/utils.js";
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("setting");
export default {
  props: {
    lineid: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      select: {},
      text: "",
      comboModel: { value: null, pos_col: null, display: "" },
      autocompleteNum: 0,
      search: null
    };
  },
  computed: {
    dropdown: {
      get() {
        let tempList = [];
        let mappingList = this.$store.getters["setting/getMappingList"];
        if (this.checkUndefNull(mappingList)) {
          tempList = mappingList.filter(e => e.pos !== null && e.pos !== "");
        }
        return tempList;
      }
    },
    autocomplete: {
      get() {
        return this.$store.getters.getAutocomplete;
      }
    },
    searchBoxText: {
      get() {
        return this.$t("WORD_KEYWORD_PLANAREA");
      }
    }
  },
  watch: {
    dropdown: {
      immediate: true,
      handler: function() {
        this.select = this.dropdown[0];
      }
    },
    select: {
      handler: function() {
        if (this.checkUndefNull(this.select)) {
          let keys = {
            mappingId: this.select.mappingId,
            value: ""
          };
          this.text = "";
          this.comboModel = "";
          // 未計画のときはws通信を行わない
          // 余計なクエリが発行されるのを防ぐため
          if (this.lineid !== 0) {
            this.getAutocomplete(keys);
          }
        }
      }
    },
    autocomplete: {
      immediate: true,
      handler: function(val) {
        if (!Util.checkArrayLength(val)) {
          this.autocompleteNum = 0;
        } else {
          this.autocompleteNum = val.length;
        }
      }
    },
    comboModel: {
      immediate: true,
      handler: function() {
        let comboObj;
        if (
          this.checkUndefNull(this.select) &&
          this.checkUndefNull(this.comboModel)
        ) {
          comboObj = {
            mappingId: this.select.mappingId,
            value: this.comboModel.value,
            posCol: this.comboModel.pos_col
          };
        } else {
          comboObj = {
            mappingId: "",
            value: null,
            posCol: null
          };
        }
        this.$emit("getCombo", comboObj);
      }
    },
    search: {
      handler: function(val) {
        let keys = {
          mappingId: "",
          value: ""
        };
        if (this.checkUndefNull(this.select)) {
          keys.mappingId = this.select.mappingId;
        }
        if (this.checkUndefNull(val)) {
          let index = val.indexOf("  [");
          keys.value = index !== -1 ? val.slice(0, index) : val;
        }
        if (this.lineid !== 0 && this.checkUndefNull(val)) {
          this.getAutocomplete(keys);
        }
      }
    }
  },
  methods: {
    ...mapActions(["getAutocomplete"]),
    /**
     * 引数のundefined, nullチェック
     * @param item - チェック対象
     * @returns {Boolean} - 判定結果
     */
    checkUndefNull(item) {
      return Util.checkNotUndefined(item) && Util.checkNotNull(item)
        ? true
        : false;
    },
    /**
     * 引数オブジェクトをselectに代入
     * @param obj - 代入するオブジェクト
     */
    getId(obj) {
      this.select = obj;
    },
    /**
     * 検索用オブジェクトを生成して他コンポーネントに渡す
     */
    getKeyword() {
      let keyObj = {
        mappingId: this.select.mappingId,
        value: this.text
      };
      this.$emit("getKey", keyObj);
    }
  }
};
</script>

<style scoped>
.searchbox {
  width: 530px;
  height: 40px;
  display: flex;
}
.dropdown {
  max-width: 180px;
}
.input {
  width: auto;
}
</style>
