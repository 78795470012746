var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "800px", persistent: "" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "mx-auto", attrs: { width: "800px" } },
            [
              _c("v-card-title", [
                _vm._v(_vm._s(_vm.$t("PAGE_TITLE_USER_DELETE")))
              ]),
              _c("v-card-text", [
                _vm._v(_vm._s(_vm.$t("TEXT_USER_DELETE_ATTENSION")))
              ]),
              _c("v-card-text", [
                _vm._v(
                  _vm._s(_vm.$t("TEXT_USER_CHANGE_NAME")) +
                    _vm._s(_vm.selectedUser.username)
                )
              ]),
              _c("v-card-text", [
                _vm._v(
                  _vm._s(_vm.$t("TEXT_USER_CHANGE_MAILADDRESS")) +
                    _vm._s(_vm.selectedUser.email)
                )
              ]),
              _c("div", { staticClass: "area" }),
              _c(
                "v-card-actions",
                { staticClass: "justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "error",
                      attrs: { width: "25%" },
                      on: { click: _vm.submit }
                    },
                    [_vm._v(_vm._s(_vm.$t("WORD_DELETE")))]
                  ),
                  _c(
                    "v-btn",
                    { attrs: { width: "25%" }, on: { click: _vm.closeDialog } },
                    [_vm._v(_vm._s(_vm.$t("WORD_CANCEL")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }