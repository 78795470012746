<template>
  <div>
    <v-dialog v-model="dialog" width="800px" persistent>
      <v-card width="800px" class="mx-auto">
        <v-card-title>{{ $t("PAGE_TITLE_WORKSPACE_CHANGE") }}</v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-text-field
              v-model="name"
              placeholder=" "
              outlined
              :label="$t('WORD_MANAGEMENT_WORKSPACE_NAME')"
              :rules="[required, max_length, check_percent]"
              counter="50"
            >
            </v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn width="25%" class="info" @click="submit">{{ $t("WORD_CHANGE") }}</v-btn>
          <v-btn width="25%" class="" @click="closeDialog">{{ $t("WORD_CANCEL") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Define from "@/define.js";
import { mixinValidator } from "@/mixins/mixinValidator.js";
export default {
  name: "WorkspaceChangeDialog",
  props: {
    dialog: Boolean
  },
  mixins: [mixinValidator],
  data: function() {
    return {
      // 入力項目
      name: "",

      // 入力規則
      required: value => this.isValidRequired(value),
      max_length: value => this.isValidLength(value, null, Define.LENGTH_MAX_WORKSPACE),
      check_percent: value => this.isValidNotContainPercent(value)
    };
  },
  computed: {
    selectedWorkspace() {
      return this.$store.getters["workspace/selectedWorkspace"];
    },
    workspaces() {
      return this.$store.getters["workspace/workspaces"];
    }
  },
  watch: {
    dialog: function() {
      if (this.$refs.form) this.$refs.form.reset();
    }
  },
  methods: {
    /**
     * 入力情報をAPIに伝搬する
     */
    submit() {
      // ボード名の重複チェック
      if(this.workspaces.some(workspace => workspace.name === this.name)){
        Swal.fire({
          icon: "error",
          title: this.$t("MSG_ERR_MAIN_DUPLICATE"),
          text: this.$t("MSG_ERR_SUB_DUPLICATE_WORKSPACE")
        });
        return;
      }
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch(`workspace/updateWorkspace`, {
            work_space_id: this.selectedWorkspace.workSpaceId,
            param: { name: this.name }
          })
          .then(() => {
            this.closeDialog();
          });
      } else {
        Swal.fire({
          icon: "error",
          title: this.$t("MSG_ERR_MAIN_INPUT"),
          text: this.$t("MSG_ERR_SUB_INPUT")
        });
      }
    },
    /**
     * クローズイベントを親部品に通知する
     */
    closeDialog() {
      this.$refs.form.reset();
      this.$emit("close");
    }
  }
};
</script>

<style scoped></style>
