import Vue from "vue";
import Vuex from "vuex";
import camelcaseKeys from "camelcase-keys";
import createPersistedState from "vuex-persistedstate";
import Define from "@/define.js";
import SwalMsg from "@/swalMsg.js";
import moment from "moment";
import * as Util from "@/util/utils.js";
import SecureLS from "secure-ls";
var ls = new SecureLS({ encodingType: "aes" });

Vue.use(Vuex);

// モジュール
import moduleAuth from "./moduleAuth";
import moduleTenant from "./moduleTenant";
import moduleSetting from "./moduleSetting";
import moduleTile from "./moduleTile";
import moduleUser from "./moduleUser";
import moduleWorkSpace from "./moduleWorkSpace";
import moduleDisplay from "./moduleDisplay";
import moduleInfo from "./moduleInfo";
import moduleOption from "./moduleOption";
import moduleGlobal from "./moduleGlobal";
import moduleApiSetting from "./moduleApiSetting";
import moduleCalendarSetting from "./moduleCalendarSetting";
import moduleRole from "./moduleRole";
import Swal from "sweetalert2";

const state = {
  socket: null,
  data: {},

  tenantData: {},
  settingData: {},
  tileData: [],

  // 日付範囲(最初)
  startDate: null,
  // 日付範囲(最後)
  endDate: null,
  autocompleteList: [],
  // 今開いているカード詳細のカードid,
  openDetailTileId: null,
  // 今動かしているカードのカードID
  movingTileId: null,
  // 設定データのversion
  settingVersion: 0,
  // IsLoadind
  isLoading: false,
  // 初期値読み込みフラグ
  isInitDataLoaded: false,
  // アプリケーション設定値配信フラグ
  isUpdateSetting: false,
  // アプリケーション設定画面での参照用settingId(初期設定時に二重で登録されることを防ぐため)
  settingId: null,
  tileIdListByImport: [],
  deleteButtonDisplayFlg: false,
  category: [],
  isUpdatingJisseki: false,
  isDeletingJisseki: false,
  option: {},
  notifyFlgUpdate: false,
  notifyFlgDelete: false,

  msgSw: false,
  msgCode: null,
  apiSetting: [],
  // カレンダー設定情報関連
  calendarSettingData: [],

  mode: "0",
};

const getters = {
  getAutocomplete(state) {
    let comp = [];
    let list = state.autocompleteList;
    if (list.length !== 0) {
      for (let i in list) {
        let str =
          list[i].value +
          "  [ " +
          moment(list[i].pos_col).format("YYYY/MM/DD") +
          " ]";
        let get = {
          value: list[i].value,
          pos_col: list[i].pos_col,
          display: str
        };
        comp.push(get);
      }
    }
    return comp;
  },
  getTenantData(state) {
    return state.tenantData;
  },
  socket: state => state.socket,
  settingVersion: state => state.settingVersion,
  isLoading: state => state.isLoading,
  isInitDataLoaded: state => state.isInitDataLoaded,
  isUpdateSetting: state => state.isUpdateSetting,
  settingId: state => state.settingId,
  tileIdListByImport: state => state.tileIdListByImport,
  deleteButtonDisplayFlg(state) {
    return state.tileIdListByImport.length !== 0 ? true : false;
  },
  startDate: state => state.startDate,
  endDate: state => state.endDate,
  openDetailTileId: state => state.openDetailTileId,
  isUpdatingJisseki: state => state.isUpdatingJisseki,
  isDeletingJisseki: state => state.isDeletingJisseki,
  notifyFlgUpdate: state => state.notifyFlgUpdate,
  notifyFlgDelete: state => state.notifyFlgDelete,
  msgSw: state => state.msgSw,
  msgCode: state => state.msgCode,
  mode: state => state.mode,
  isModePc(state) {
    return state.mode == "0";
  },
};

const mutations = {
  initState(state) {
    state.socket = null;
    state.data = {};
    state.tenantData = {};
    state.settingData = {};
    state.tileData = [];
    state.startDate = null;
    state.endDate = null;
    state.autocompleteList = [];
    state.openDetailTileId = null;
    state.movingTileId = null;
    state.settingVersion = 0;
    state.isLoading = false;
    state.isInitDataLoaded = false;
    state.isUpdateSetting = false;
    state.settingId = null;
    state.category = [];
    state.isUpdatingJisseki = false;
    state.isDeletingJisseki = false;
    state.option = {};
    state.notifyFlgUpdate = false;
    state.notifyFlgDelete = false;
    state.msgSw = false;
    state.msgCode = null;
    state.apiSetting = [];
    state.calendarSettingData = [];

    // modeは自動入力用なので初期化しない
    // state.mode = "0";
  },
  SOCKET_ONOPEN(state, event) {
    console.log("SOCKET_ONOPEN");
    state.socket = event.target;
    if (!state.socket) {
      Swal.fire(SwalMsg.WS_NOT_CONNECT_ERR);
      return false;
    }

    /**
     * [WS通信-送信] 初期データ取得
     */
    state.socket.sendObj({
      type: Define.WS_CODE_GET_INITIAL_DATA,
      work_space_id: state.auth.user.workSpaceId || null,
      days_diff: state.display.displayDays - 1 || Define.DISP_DAYS_7 - 1
    });
  },
  SOCKET_ONCLOSE(state) {
    console.log("SOCKET_ONCLOSE");
    // 接続終了にStoreのwebsocketオブジェクトを解除する
    state.socket = null;
  },
  SOCKET_ONERROR() {
    console.log("SOCKET_ONERROR");
  },
  SOCKET_ONMESSAGE() {
    console.log("SOCKET_ONMESSAGE");
  },
  SOCKET_RECONNECT() {
    console.log("SOCKET_RECONNECT");
  },
  SOCKET_RECONNECT_ERROR() {
    console.log("SOCKET_RECONNECT_ERROR");
    Swal.fire(SwalMsg.WS_NOT_CONNECT_ERR);
  },
  setData(state, { dataObj }) {
    let tmpdataObj = camelcaseKeys(dataObj, { deep: true });
    state.tenantData = tmpdataObj.tenantData;
    state.settingData = tmpdataObj.settingData;
    state.category = tmpdataObj.info;
    state.tileData = tmpdataObj.tileData;
    state.option = tmpdataObj.option;
    state.apiSetting = camelcaseKeys(dataObj.api_setting);
    state.calendarSettingData = tmpdataObj.calendarSettingData;
    state.role.myRoles = tmpdataObj.roles;
    for(let setting of state.apiSetting){
        setting.header = (setting.header) ? Object.keys(setting.header).map(function(key) {
          return {name: key, value: setting.header[key]};
        }) : [];
      setting.body = JSON.stringify(setting.body, null, "\t");
    }
    // 最新の設定Id、バージョンを設定
    if (state.settingData) {
      state.settingVersion = state.settingData.version;
      state.settingId = state.settingData.settingId;
    } else {
      state.settingVersion = 0;
      state.settingId = null;
    }
    // データの更新を監視している画面に変更を通知させるため
    state.isInitDataLoaded = false;
    Vue.nextTick(function() {
      state.isInitDataLoaded = true;
    });
    //Loadingを閉じる
    state.isLoading = false;
  },
  closeLoadingDialog(state) {
    //Loadingを閉じる
    state.isLoading = false;
  },
  receiveMessage(state, resultObj) {
    state.data = resultObj;
    if (resultObj.result === Define.ERR_SURVER) {
      console.log("receiveMessage() server error");
      // サーバーエラー
      Swal.fire({
        icon: "error",
        title: "Server Error"
      });
    } else if (resultObj.result === Define.ERR_EXCLUSION) {
      // 排他エラー
      Swal.fire({
        icon: "error",
        title: "Update Error",
        text: "Please reload the page"
      });
    } else if (resultObj.result === Define.ERR_VIOLATE_UNIQUE_KEY) {
      // ユニークキー制約違反
      Swal.fire({
        icon: "error",
        title: "Key Duplication Error"
      });
    } else if (resultObj.result === Define.ERR_IMPORT_CSV_VIOLATE_UNIQUE_KEY) {
      // CSV取り込み時のユニークキー制約違反
      Swal.fire({
        icon: "error",
        title: "Key Duplication Error"
      });
    } else if (resultObj.result === Define.ERR_IMPORT_CSV_VIOLATE_MAX_FREE_AREA_OVRE) {
      // 未計画の上限を超えた場合
      Swal.fire({
        icon: "error",
        title: "More than 300 unplanned area card cannot be imported."
      });
    } else {
      // メッセージ検索し表示する
      // 表示処理はTopPage.vueでmsgSwをwatchしておこなう
      if (resultObj.code !== 1 && resultObj.code !== 4) {
        state.msgSw = !state.msgSw;
        state.msgCode = resultObj.code;
      }
    }
  },
  updateSetting(state, { settingObj, workSpaceId }) {
    // ボードIDが異なる更新は無視する
    if (workSpaceId != state.auth.user.workSpaceId) {
      return;
    }
    state.settingData = camelcaseKeys(settingObj, { deep: true });
    // 最新の設定Id,バージョンを設定
    state.settingVersion = state.settingData.version;
    state.settingId = state.settingData.settingId;

    // データの更新を監視している画面に変更を通知させるため
    state.isUpdateSetting = false;
    Vue.nextTick(function() {
      state.isUpdateSetting = true;
    });
  },
  updateOption(state, { optionObj, workSpaceId }) {
    // ボードIDが異なる更新は無視する
    if (workSpaceId != state.auth.user.workSpaceId) {
      return;
    }
    state.option = camelcaseKeys(optionObj, { deep: true });
  },
  updateTileChangeDate(state, { tileObj, calendarSettingData }) {
    state.tileData = camelcaseKeys(tileObj, { deep: true });
    state.calendarSettingData = camelcaseKeys(calendarSettingData, { deep: true });
  },
  updateTileSaveSetting(state, { tileObj, workSpaceId }) {
    // ボードIDが異なる更新は無視する
    if (workSpaceId != state.auth.user.workSpaceId) {
      return;
    }
    state.tileData = camelcaseKeys(tileObj, { deep: true });
  },
  updateDate(state, content) {
    // 日付更新時storeの日付更新
    state.startDate = content.startDate;
    state.endDate = content.endDate;
  },
  updateTile(state, { tileObj, workSpaceId }) {
    // バックエンドからカード更新リクエストを受け取った場合の処理

    // ボードIDが異なる更新は無視する
    if (workSpaceId != state.auth.user.workSpaceId) {
      return;
    }

    // ハードコピー
    let tempTileData = Util.parseObject(state.tileData);
    let tileList = camelcaseKeys(tileObj.send_list, { deep: true });
    let index;
    let deleteIndex;
    tileList.forEach(function(value) {
      index = null;
      deleteIndex = null;
      if (value.posCol === null) {
        index = tempTileData.findIndex(({ tileId }) => tileId === value.tileId);
      } else if (
        Util.checkPlanAreaTileIndex(
          value.posCol,
          state.startDate,
          state.endDate
        )
      ) {
        // カードが計画エリアに作成(更新)かつ今表示されている日付の範囲内に作成(更新)の場合
        index = tempTileData.findIndex(({ tileId }) => tileId === value.tileId);
      } else {
        // カードが計画エリアに作成(更新)かつ今表示されている日付の範囲外に作成(更新)の場合
        // ケースとしては他ユーザーが未計画エリアから今表示している範囲外に移動した場合
        // 他ユーザーが表示範囲外の計画エリアから表示範囲外の計画エリアに移動させた場合
        deleteIndex = tempTileData.findIndex(
          ({ tileId }) => tileId === value.tileId
        );
      }
      if (deleteIndex !== null && deleteIndex !== -1) {
        tempTileData.splice(deleteIndex, 1);
      } else if (index !== null && index !== -1) {
        tempTileData.splice(index, 1, value);
      } else if (index === -1) {
        tempTileData.push(value);
      }
      //deleteindexが-1のときは削除する要素が存在しないため処理なし
      if (value.tileId === state.openDetailTileId) {
        if (state.isUpdatingJisseki) {
          state.tile.cardDetailInfo = value;
          state.isUpdatingJisseki = false;
        } else if (state.isDeletingJisseki) {
          state.tile.cardDetailInfo = value;
          state.isDeletingJisseki = false;
        } else {
          Swal.fire({
            text: "Changed by others.",
            icon: "warning"
          });
          // カード詳細ダイアログを閉じる
          document.getElementById("closebtn_" + value.tileId).click();
          state.openDetailTileId = null;
        }
      }
      // 移動中のカードが更新された場合警告を出す
      if (value.tileId === state.movingTileId) {
        Swal.fire({
          text: "Changed by others.",
          icon: "warning"
        });
        state.movingTileId = null;
      }
      // タイルに紐づくデータ数が存在しなければ、0に設定する
      if (!value.fileDataNum) {
        value.fileDataNum = 0;
      }
    });
    state.tileData = tempTileData;
  },
  deleteTile(state, { tileObj, workSpaceId }) {
    // バックエンドからカード削除リクエストを受け取った場合の処理

    // ボードIDが異なる更新は無視する
    if (workSpaceId != state.auth.user.workSpaceId) {
      return;
    }

    // ハードコピー
    let tempTileData = Util.parseObject(state.tileData);
    let tileList = camelcaseKeys(tileObj.send_list, { deep: true });
    let index = null;
    tileList.forEach(function(value) {
      if (value.posCol === null) {
        index = tempTileData.findIndex(({ tileId }) => tileId === value.tileId);
      } else if (
        Util.checkPlanAreaTileIndex(
          value.posCol,
          state.startDate,
          state.endDate
        )
      ) {
        // カードが計画エリアに作成かつ今表示されている日付の範囲内に作成の場合
        index = tempTileData.findIndex(({ tileId }) => tileId === value.tileId);
      }
      if (index !== null && index !== -1) {
        tempTileData.splice(index, 1);
      }

      // 削除カードが存在したエリアのカード表示順を再設定
      let target = [];
      // 削除カードが存在したエリア内のカード配列を作成する
      for (let tile of tempTileData) {
        if (tile.posCol === value.posCol && tile.posRow === value.posRow) {
          target.push(tile);
        }
      }
      // カード配列を表示順でソートする
      if (target.length) {
        target.sort(function(a, b) {
          if (a.order < b.order) return -1;
          if (a.order > b.order) return 1;
          return 0;
        });
      }
      let order = 1;
      // 表示順を振り直す
      for (let tile of target) {
        Vue.set(tile, "order", order);
        order++;
      }

      // カード詳細ダイアログ表示中カードが削除された場合警告を出す
      if (value.tileId === state.openDetailTileId) {
        Swal.fire({
          text: "Changed by others.",
          icon: "warning"
        });
        // カード詳細ダイアログを閉じる
        document.getElementById("closebtn_" + value.tileId).click();
        state.openDetailTileId = null;
      }

      // 移動中カードが削除された場合警告を出す
      if (value.tileId === state.movingTileId) {
        Swal.fire({
          text: "Changed by others.",
          icon: "warning"
        });
        state.movingTileId = null;
      }
    });
    state.tileData = tempTileData;
    // カード削除の完了通知(各部品でwatchして完了を検知する)
    state.notifyFlgDelete = !state.notifyFlgDelete;
  },
  setAutoComplete(state, { autocomplete }) {
    state.autocompleteList = autocomplete.data;
  },
  updateDateData: function(state, content) {
    state.startDate = content.startDate;
    state.endDate = content.endDate;
  },
  setOpenDetailTileId: function(state, tileId) {
    state.openDetailTileId = tileId;
  },
  removeOpenDetailTileId: function(state) {
    state.openDetailTileId = null;
  },
  setMovingTileId: function(state, tileId) {
    state.movingTileId = tileId;
  },
  removeMovingTileId: function(state) {
    state.movingTileId = null;
  },
  setIsLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  updateMovedTile(state, tileObj) {
    //websocketにデータ送信前に動かしたタイル情報を更新する
    let tempTileData = Util.parseObject(state.tileData);
    let index = tempTileData.findIndex(
      ({ tileId }) => tileId === tileObj.tileId
    );
    if (index >= 0) {
      tempTileData.splice(index, 1, tileObj);
    }
    state.tileData = tempTileData;
  },
  setIsLoadingAsync(state, isLoading) {
    state.isLoading = isLoading;
  },
  updateInfo(state, { info, workSpaceId }) {
    // ボードIDが異なる更新は無視する
    if (workSpaceId != state.auth.user.workSpaceId) {
      return;
    }
    state.category = camelcaseKeys(info, { deep: true });
  },
  setIsUpdatingJisseki(state, isUpdatingJisseki) {
    state.isUpdatingJisseki = isUpdatingJisseki;
  },
  setIsDeletingJisseki(state, isDeletingJisseki) {
    state.isDeletingJisseki = isDeletingJisseki;
  },
  setNotifyFlgUpdate(state, { workSpaceId }) {
    // ボードIDが異なる更新は無視する
    if (workSpaceId != state.auth.user.workSpaceId) {
      return;
    }

    // カード更新の完了通知(各部品でwatchして完了を検知する)
    state.notifyFlgUpdate = !state.notifyFlgUpdate;
  },
  setNotifyFlgDelete(state, { workSpaceId }) {
    // ボードIDが異なる更新は無視する
    if (workSpaceId != state.auth.user.workSpaceId) {
      return;
    }

    // カード更新の完了通知(各部品でwatchして完了を検知する)
    state.notifyFlgDelete = !state.notifyFlgDelete;
  },
  updateApiSetting(state, { apiSetting, workSpaceId }) {
    // ボードIDが異なる更新は無視する
    if (workSpaceId != state.auth.user.workSpaceId) {
      return;
    }
    state.apiSetting = camelcaseKeys(apiSetting);
    for(let setting of state.apiSetting){
      setting.header = Object.keys(setting.header).map(function(key) {
        return {name: key, value: setting.header[key]};
      });
      setting.body = JSON.stringify(setting.body, null, "\t");
    }
  },
  setMode(state, mode) {
    state.mode = mode;
  },
};

const actions = {
  initAllState({ commit }) {
    commit("initState");
    commit("auth/initState");
    commit("tenant/initState");
    commit("setting/initState");
    commit("tile/initState");
    commit("user/initState");
    commit("workspace/initState");
    commit("display/initState");
    commit("info/initState");
    commit("option/initState");
    commit("global/initState");
    commit("calendarSetting/initState")
    commit("role/initState")
  },
  updateDateData({ commit }, content) {
    commit("updateDateData", content);
  },
  setOpenDetailTileId({ commit }, tileId) {
    commit("setOpenDetailTileId", tileId);
  },
  removeOpenDetailTileId({ commit }) {
    commit("removeOpenDetailTileId");
  },
  setMovingTileId({ commit }, tileId) {
    commit("setMovingTileId", tileId);
  },
  removeMovingTileId({ commit }) {
    commit("removeMovingTileId");
  },
  updateMovedTile({ commit }, tileObj) {
    commit("updateMovedTile", tileObj);
  },
  async setIsLoadingAsync({ commit }, isLoading) {
    await commit("setIsLoadingAsync", isLoading);
    return;
  },

  /**
   * [WS通信-送信] 初期データ取得
   */
  initialData({ state }, param) {
    const socket = state.socket;
    if (!socket) {
      Swal.fire(SwalMsg.WS_NOT_CONNECT_ERR);
      return false;
    }
    socket.sendObj({
      type: Define.WS_CODE_GET_INITIAL_DATA,
      work_space_id: param.workSpaceId,
      days_diff: param.daysDiff,
      display_first_day: param.displayFirstDay
    });
  }
};
const modules = {
  auth: moduleAuth,
  tenant: moduleTenant,
  setting: moduleSetting,
  tile: moduleTile,
  user: moduleUser,
  workspace: moduleWorkSpace,
  display: moduleDisplay,
  info: moduleInfo,
  option: moduleOption,
  global: moduleGlobal,
  apiSetting: moduleApiSetting,
  calendarSetting: moduleCalendarSetting,
  role: moduleRole
};

const plugins = [
  createPersistedState({
    key: "Keikaku",
    storage: {
      getItem: key => ls.get(key),
      setItem: (key, value) => ls.set(key, value),
      removeItem: key => ls.remove(key)
    },
    paths: [
      "mode",
      "auth.token",
      "auth.user",
      "auth.isLoggedIn",
      "auth.email",
      "setting.importSetting",
      "tileIdListByImport",
      "display.displayRow",
      "display.displayCol",
      "display.displayFontSize",
      "display.displayDays",
      "global.language"
    ]
  })
];

const store = new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules,
  plugins
});

export default store;
