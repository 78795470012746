<template>
  <div>
    <v-card outlined>
      <v-list>
        <v-list-item v-for="detail in cardDetailInfo.tileDetailData" :key="detail.mappingId" three-line>
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold">{{ mappingName(detail.mappingId) }}</v-list-item-title>
            <v-list-item-subtitle class="text-right">{{ detail.value }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </div>
</template>

<script>
export default {
  computed: {
    cardDetailInfo() {
      return this.$store.getters["tile/cardDetailInfo"];
    },
  },
  methods: {
    mappingName(id) {
      if (!id) return "";
      return this.$store.getters["setting/getMappingName"](id);
    }
  }
};
</script>

<style scoped>

</style>
