<template>
  <div>
    <v-row dense v-for="item in jissekiCalcMappings" :key="item.mappingId">
      <v-col>
        <CardDetailJissekiSummaryCard
          :title="item.name"
          :yotei="yotei(item.mappingId)"
          :jisseki="cardJissekiSum(item.mappingId)"
          :outlined="true"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CardDetailJissekiSummaryCard from "@/components/parts/CardDetailJissekiSummaryCard";
export default {
  components: {
    CardDetailJissekiSummaryCard
  },
  computed: {
    jissekiCalcMappings() {
      return this.$store.getters["setting/getJissekiCalcMappings"];
    },
    cardJissekiSum() {
      return mappingId => {
        return Number(this.$store.getters["tile/cardJissekiSum"](mappingId));
      };
    },
    yotei() {
      return mappingId => {
        let card = this.$store.getters["tile/cardDetailInfo"];
        let cardDetail = card.tileDetailData.filter(d => d.mappingId == mappingId);
        if(cardDetail.length == 0 || cardDetail.value == ""){ return 0;}
        return Number(cardDetail[0].value);
      }
  },
  }
};
</script>

<style scoped>

</style>
