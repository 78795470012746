<template>
  <div class="pagestemplate">

    <div class="mx-3 my-3">

      <!-- 言語設定 -->
      <div class="mt-2">
        <v-card>
          <v-card-title>
            <v-icon large left>mdi-alpha-a-box-outline</v-icon>
            <span class="text-h6 font-weight-light">{{ $t("PAGE_SUBTITLE_CARD_SETTING_LUNG") }}</span>
          </v-card-title>
          <v-card-actions class="justify-center">
            <SelectLunguage />
          </v-card-actions>
        </v-card>
      </div>

      <!-- 文字サイズ設定 -->
      <div class="mt-2">
        <v-card>
          <v-card-title>
            <v-icon large left>mdi-format-size</v-icon>
            <span class="text-h6 font-weight-light">{{ $t("PAGE_SUBTITLE_CARD_SETTING_FONT") }}</span>
          </v-card-title>
          <v-card-actions class="justify-center">
            <SelectFontSize />
          </v-card-actions>
        </v-card>
      </div>

    </div> 
  </div>
</template>

<script>
import SelectLunguage from "@/components/parts/SelectLunguage";
import SelectFontSize from "@/components/parts/SelectFontSize";
export default {
  components: {
    SelectLunguage,
    SelectFontSize
  }
};
</script>

<style scoped>

</style>
