var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tooltip",
    {
      attrs: { right: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "ml-5",
                      attrs: { text: "", small: "", fab: "" },
                      on: { click: _vm.helpOpen }
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c("v-icon", { attrs: { color: "grey darken-2" } }, [
                    _vm._v(" mdi-help-circle-outline ")
                  ])
                ],
                1
              )
            ]
          }
        }
      ])
    },
    [
      _c("span", [_vm._v(_vm._s(_vm.$t("WORD_HELP")))]),
      _c("HelpDialog", {
        ref: "helpdialog",
        attrs: { dialogdata: _vm.helpobject }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }