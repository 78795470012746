import Swal from "sweetalert2";
import * as Util from "@/util/utils.js";
import Define from "@/define.js";

const state = {
  workspaces: [],
  selectedWorkspace: {},
  isWorkSpaceLoading: false,
  isFirst: true
};
const getters = {
  workspaces(state) {
    // 名前の昇順にソート
    return state.workspaces.sort(function(a, b) {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
  },
  selectedWorkspace: state => state.selectedWorkspace,
  workspacesSelectList(state) {
    let list = [{ text: "", value: null }];
    state.workspaces.forEach(function(workspace) {
      list.push({ text: workspace.name, value: workspace.workSpaceId });
    });
    return list;
  },
  isWorkSpaceLoading: state => state.isWorkSpaceLoading,
  isFirst: state => state.isFirst
};
const mutations = {
  initState(state) {
    state.workspaces = [];
    state.selectedWorkspace = {};
    state.isWorkSpaceLoading = false;
    state.isFirst = true;
  },
  setWorkspaces(state, workspaces) {
    state.workspaces = workspaces;
  },
  setSelectedWorkspace(state, selectedWorkspace) {
    state.selectedWorkspace = selectedWorkspace;
  },
  setIsWorkSpaceLoading(state, isWorkSpaceLoading) {
    state.isWorkSpaceLoading = isWorkSpaceLoading;
  },
  setIsFirst(state, isFirst) {
    state.isFirst = isFirst;
  }
};
const actions = {
  getWorkspace({ commit, rootState }) {
    let workSpaceId = rootState.auth.user.workSpaceId;
    commit("setIsWorkSpaceLoading", true);
    Util.axiosGetReq(Define.API_WORKSPACELIST)
      .then(response => {
        // console.log(response);
        console.log(response.status); // ステータスコードのみ表示(秘匿情報は外部に出さない)
        commit("setIsWorkSpaceLoading", false);
        // ボード情報を設定する
        commit("setWorkspaces", response.data);

        // 初回だけボードの存在確認を行う
        if (state.isFirst) {
          if (Util.isDeletedWorkSpace(workSpaceId, state.workspaces)) {
            Swal.fire({
              icon: "error",
              title: "Not Exist Error",
              text: "Please select other board."
            });
          }
          commit("setIsFirst", false);
        }
      })
      .catch(error => {
        console.log(error.message);
        commit("setIsWorkSpaceLoading", false);
      });
  },
  createWorkspace({ commit, dispatch }, param) {
    commit("setIsWorkSpaceLoading", true);
    Util.axiosPostReq(Define.API_WORKSPACE, param)
      .then(response => {
        // console.log(response);
        console.log(response.status); // ステータスコードのみ表示(秘匿情報は外部に出さない)
        commit("setIsWorkSpaceLoading", false);
        // 最新のボード情報を取得する
        dispatch("getWorkspace", {});
      })
      .catch(error => {
        console.log(error.message);
        commit("setIsWorkSpaceLoading", false);
        // 最新のボード情報を取得する
        dispatch("getWorkspace", {});
        Swal.fire({
          icon: "warning",
          title: "Update Error",
          text: "Please try again."
        });
      });
  },
  updateWorkspace({ commit, dispatch }, param) {
    commit("setIsWorkSpaceLoading", true);
    Util.axiosPatchReq(
      `${Define.API_WORKSPACEUPDATE}${param.work_space_id}/`,
      param.param
    )
      .then(response => {
        // console.log(response);
        console.log(response.status); // ステータスコードのみ表示(秘匿情報は外部に出さない)
        commit("setIsWorkSpaceLoading", false);
        // 最新のボード情報を取得する
        dispatch("getWorkspace", {});
      })
      .catch(error => {
        console.log(error.message);
        commit("setIsWorkSpaceLoading", false);
        Swal.fire({
          icon: "warning",
          title: "Update Error",
          text: "Please try again."
        });
      });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
