<template>
  <div>
    <v-dialog v-model="dialog" width="800px" persistent>
      <v-card width="800px" class="mx-auto">
        <v-card-title>{{ $t("PAGE_TITLE_USER_CREATE") }}</v-card-title>
        <div class="area">
          <v-card-text>
            <v-form ref="form">
              <v-text-field
                v-model="email"
                placeholder=" "
                outlined
                :label="$t('WORD_MAILADDRESS')"
                :rules="[required, email_max_length, email_format]"
                counter="254"
              />
              <v-text-field
                v-model="password"
                :placeholder="$t('PLACEHOLDER_PASSWORD')"
                outlined
                :label="$t('WORD_PASSWORD')"
                :rules="[required, password_max_length, password_format]"
                counter="128"
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
              />
            </v-form>
            <v-radio-group v-model="role" row>
              <v-radio :label="$t('WORD_ROLENAME')[0]" value="0"></v-radio>
              <v-radio :label="$t('WORD_ROLENAME')[1]" value="1"></v-radio>
              <v-radio :label="$t('WORD_ROLENAME')[2]" value="2"></v-radio>
            </v-radio-group>
          </v-card-text>
        </div>
        <v-card-actions class="justify-center">
          <v-btn width="25%" class="info" @click="submit">{{ $t("WORD_ADD") }}</v-btn>
          <v-btn width="25%" class="" @click="closeDialog">{{ $t("WORD_CANCEL") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Define from "@/define.js";
import Swal from "sweetalert2";
import { mixinValidator } from "@/mixins/mixinValidator.js";
export default {
  name: "UserCreateDialog",
  props: {
    dialog: Boolean,
    data: {
      type: Object,
      default: () => ({})
    }
  },
  mixins: [mixinValidator],
  data: function() {
    return {
      // 入力項目
      email: "",
      password: "",
      role: "1",

      // 入力規則
      required: value => this.isValidRequired(value),
      email_max_length: value => this.isValidLength(value, null, Define.LENGTH_MAX_EMAIL),
      email_format: value => this.isValidEmailFormat(value),
      password_max_length: value => this.isValidLength(value, null, Define.LENGTH_MAX_PASSWORD),
      password_format: value => this.isValidPasswordLFormat(value),
      // パスワード表示
      showPassword: false
    };
  },
  computed: {
    user() {
      return this.$store.getters["auth/user"];
    }
  },
  watch: {
    dialog: function() {
      if (this.$refs.form) this.$refs.form.reset();
    }
  },
  methods: {
    /**
     * 入力情報をAPIに伝搬する
     */
    submit() {
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch("user/createUser", {
            email: this.email,
            password: this.password,
            role: this.role,
            tenant_id: this.user.tenantId
          })
          .then(() => {
            this.closeDialog();
          });
      } else {
        Swal.fire({
          icon: "error",
          title: this.$t("MSG_ERR_MAIN_INPUT"),
          text: this.$t("MSG_ERR_SUB_INPUT")
        });
      }
    },
    /**
     * クローズイベントを親部品に通知する
     */
    closeDialog() {
      this.$refs.form.reset();
      this.$emit("close");
    }
  }
};
</script>

<style scoped>
.before {
  width: 400px;
}
.after {
  width: 400px;
}
.area {
  display: flex;
}
</style>
