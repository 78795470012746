<template>
  <div class="areamargin">
    <v-expansion-panels v-model="isOpen">

      <!-- カード詳細情報 -->
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="subtitle">{{ $t("PAGE_TITLE_CARD_DETAIL") }}</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <MobileCardDetailInfoDetail @close="close" />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- カード補助情報 -->
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="subtitle">{{ $t("PAGE_TITLE_CARD_ASSIST") }}</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <MobileCardDetailInfoFile />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- ボタンエリア -->
      <v-btn v-if="!this.tileInfo.isComplete" block class="make" @click="completeCard" outlined>{{ $t("WORD_UPDATE_COMPLETE") }}</v-btn>

    </v-expansion-panels>
  </div>
</template>

<script>
import Define from "@/define.js";
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("tile");
import MobileCardDetailInfoDetail from "@/components/mobile/parts/MobileCardDetailInfoDetail.vue";
import MobileCardDetailInfoFile from "@/components/mobile/parts/MobileCardDetailInfoFile.vue";
export default {
  components: {
    MobileCardDetailInfoDetail,
    MobileCardDetailInfoFile
  },
  data() {
    return {
      isOpen: 0
    };
  },
  computed: {
    isOpenMobileCardDetail() {
      return this.$store.getters["tile/isOpenMobileCardDetail"];
    },
    tileInfo() {
      return this.$store.getters["tile/cardDetailInfo"];
    },
  },
  watch: {
    isOpenMobileCardDetail: {
      immediate: true,
      handler: function() {
        if (this.isOpenMobileCardDetail) {
          this.isOpen = 0;
        }
      }
    },
  },
  methods: {
    ...mapActions([
      "detailUpdateTile",
    ]),
    completeCard(){
      // カード情報
      const result = {
        tile_id: this.tileInfo.tileId,
        pos_col: this.tileInfo.posCol,
        pos_row: this.tileInfo.posRow,
        order: this.tileInfo.order,
        is_complete: true,
        color: this.tileInfo.color,
        version: this.tileInfo.version,
        is_confirmed: this.tileInfo.isConfirmed,
        status: this.tileInfo.status
      };
      // カード履歴(完了)
      if (!this.tileInfo.isComplete) {
        result.tile_complete_history_data = {
          history_type: Define.HISTORY_TYPE_COMPLETE,
          flg_type: Define.FLG_TYPE_COMPLETE,
          flg_before: false,
          flg_after: true
        };
      }
      this.detailUpdateTile({ tileData: [result] });
      this.close();
    },
    close(){
      this.$emit("close");
    }

  }
};
</script>

<style scoped>
.areamargin {
  margin: 10px;
}
.subtitle {
  border-left: solid 4px #1976d2ca;
  color: #333;
  padding-left: 4px;
}
.make {
  margin: 10px;
  color: #fff;
  background-color: #575757;
  border-color: #575757;
}
</style>
