<template>
  <div class="usertable">
    <div class="boxContainer">
      <div class="areatitle">{{ $t("PAGE_SUBTITLE_MANAGEMENT_WORKSPACE_TABLE") }}</div>
      <div v-if="isWorkSpaceLoading">
        <v-progress-circular
          :size="20"
          :width="3"
          color="#00b842"
          indeterminate
          class="ml-2"
        ></v-progress-circular>
      </div>
    </div>
    <v-card width="100%" height="100%" class="mx-auto my-auto scroll">
      <WorkSpaceCreateDialog
        :dialog="isWorkSpaceCreate"
        @close="callbackWorkSpaceCreateDialogClose"
      >
      </WorkSpaceCreateDialog>
      <WorkSpaceChangeDialog
        :dialog="isWorkSpaceChange"
        @close="callbackWorkSpaceChangeDialogClose"
      />
      <WorkSpaceDeleteDialog
        :dialog="isWorkSpaceDelete"
        @close="callbackWorkSpaceDeleteDialogClose"
      />
      <v-card-text style="color:#ff5252">
        {{ $t("TEXT_WORKSPACE_NUM_LIMIT", { workspacenum: tenant.workSpaceNum}) }}
      </v-card-text>
      <v-data-table
        :headers="workSpaceTableHeaders"
        :items="workspaces"
        :footer-props="TABLE_FOOTER_SETTING_MIDDLE"
      >
        <template v-slot:[`item.createdAt`]="{ item }">
          <span>{{ new Date(item.createdAt).toLocaleString() }}</span>
        </template>
        <template v-slot:[`item.update`]="{ item }">
          <v-icon :disabled="isWorkSpaceLoading" small @click="changeRow(item)">
            mdi-pen
          </v-icon>
        </template>
        <template v-slot:[`item.delete`]="{ item }">
          <v-icon :disabled="isWorkSpaceLoading" small @click="deleteRow(item)">
            mdi-close
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <div class="flexarea btnarea">
      <v-spacer></v-spacer>
      <v-btn
        :disabled="isWorkSpaceLoading"
        small
        color="info"
        @click="isLimitOverWorkSpace"
      >
        {{ $t("WORD_MANAGEMENT_WORKSPACE_ADD") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import WorkSpaceCreateDialog from "@/components/parts/ManageWorkSpace/WorkSpaceCreateDialog.vue";
import WorkSpaceChangeDialog from "@/components/parts/ManageWorkSpace/WorkSpaceChangeDialog.vue";
import WorkSpaceDeleteDialog from "@/components/parts/ManageWorkSpace/WorkSpaceDeleteDialog.vue";
export default {
  name: "WorkSpaceTable",
  components: {
    WorkSpaceCreateDialog,
    WorkSpaceChangeDialog,
    WorkSpaceDeleteDialog
  },
  data() {
    return {
      isWorkSpaceCreate: false,
      isWorkSpaceChange: false,
      isWorkSpaceDelete: false
    };
  },
  computed: {
    workspaces() {
      return this.$store.getters["workspace/workspaces"];
    },
    isWorkSpaceLoading() {
      return this.$store.getters["workspace/isWorkSpaceLoading"];
    },
    tenant() {
      return this.$store.getters["tenant/getTenantData"];
    },
    workSpaceTableHeaders() {
      return this.$t("MANAGE_WORKSPACE_HEADER");
    },
    TABLE_FOOTER_SETTING_MIDDLE() {
      // テーブル定義
      return this.$t("TABLE_FOOTER_SETTING_MIDDLE");
    }
  },
  methods: {
    /**
     * 「ボード作成ダイアログ」を閉じる
     */
    callbackWorkSpaceCreateDialogClose() {
      this.isWorkSpaceCreate = false;
    },
    /**
     * 「ボード作成ダイアログ」を開く
     */
    isLimitOverWorkSpace() {
      let workspaces = this.$store.getters["workspace/workspaces"];
      let workSpaceNum = this.tenant.workSpaceNum ? this.tenant.workSpaceNum : 0;
      if (workspaces.length >= workSpaceNum) {
        Swal.fire({
          title: this.$t("MSG_ERR_MAIN_OVER_WORKSPACE_MAX_NUM"),
          text: this.$t("MSG_ERR_SUB_OVER_WORKSPACE_PAID_MAX_NUM"),
          icon: "error"
        });
      } else {
        this.isWorkSpaceCreate = true;
      }
    },
    /**
     * 「ボード変更ダイアログ」を閉じる
     */
    callbackWorkSpaceChangeDialogClose() {
      this.isWorkSpaceChange = false;
    },
    /**
     * 「ボード変更ダイアログ」を開く
     * @param {Object} selectedWorkspace - 選択行のボード
     */
    changeRow(selectedWorkspace) {
      this.$store.commit("workspace/setSelectedWorkspace", selectedWorkspace);
      this.isWorkSpaceChange = true;
    },
    /**
     * 「ボード削除ダイアログ」を閉じる
     */
    callbackWorkSpaceDeleteDialogClose() {
      this.isWorkSpaceDelete = false;
    },
    /**
     * 「ボード削除ダイアログ」を開く
     * @param {Object} selectedWorkspace - 選択行のボード
     */
    deleteRow(selectedWorkspace) {
      this.$store.commit("workspace/setSelectedWorkspace", selectedWorkspace);
      this.isWorkSpaceDelete = true;
    }
  }
};
</script>

<style scoped>
.usertable {
  height: 100%;
}
.scroll {
  max-height: 82vh;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
}
.areatitle {
  border-left: solid 6px #1976d2ca;
  margin-bottom: 8px;
  padding-left: 8px;
}
.user_name {
  width: 473px;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
}
.btnarea {
  margin-top: 10px;
}
.boxContainer {
  display: flex;
}
</style>
