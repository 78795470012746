<template>
  <div class="list">
    <draggable
      :id="posid"
      v-model="filteredPagenationList"
      class="draggablearea flexarea"
      group="kanban"
      animation="200"
      :disabled="!enabled"
      @start="onStart"
      @end="onEnd"
    >
      <CardBase v-for="tile in filteredPagenationList" :key="tile.tileId" class="item" :tileinfo="tile" />
    </draggable>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import CardBase from "@/components/parts/CardPattern/CardBase.vue";
import Define from "@/define.js";
import * as Util from "@/util/utils.js";
import Swal from "sweetalert2";
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("tile");
export default {
  components: {
    draggable: draggable,
    CardBase
  },
  props: {
    lineid: {
      type: String,
      default: ""
    },
    dateid: {
      type: String,
      default: ""
    },
    enabled: {
      type: Boolean,
      default: true
    },
    tileinfo: {
      type: Array,
      default: () => [
        {
          tileId: "",
          isComplete: false,
          order: 0,
          posCol: "",
          posRow: "",
          displayData: [
            {
              value: "",
              mappingId: ""
            }
          ],
          tileDetailData: []
        }
      ]
    },
    cardtempnum: {
      type: Number,
      default: 0
    },
    keyword: {
      type: Object,
      default: () => ({
        value: "",
        mappingId: ""
      })
    },
    isfreearea: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // posid: draggableのidにバインド
      posid: `${this.lineid}${Define.SEPARATE_DRAGGABLE_ID}${this.dateid}`
    };
  },
  computed: {
    filteredPagenationList: {
      get() {
        let filteredList = this.getFilteredList(this.tileinfo, false);
        if (
          this.isfreearea &&
          filteredList.length > Define.PLANAREA_MAX_PAGE_NUM
        ) {
          let list = this.pagenateList(filteredList, this.pageNo);
          return list;
        } else {
          return filteredList;
        }
      },
      set() {
        // setter処理はonEndイベントで処理
        // setter枠を設けない場合、computeのsetterがないエラーに引っかかるため枠のみ作成
      }
    },
    /**
     * カードリストを検索キーワードを用いてフィルタリングする
     * ロジックでも使用したいため、methodに処理を切り分け
     * @returns {Array} getList - フィルター後のリスト
     */
    filteredList() {
      return this.getFilteredList(this.tileinfo, false);
    },
    /**
     * ページネーションで選択中のページNO.
     * @returns {Namber} pageNo
     */
    pageNo() {
      return this.$store.getters["tile/pageNo"];
    },
    /**
     * ページ数(1ページ100件で算出)
     * @returns {Namber} pageNum
     */
    pageNum: {
      get() {
        return this.$store.getters["tile/pageNum"];
      },
      set(val) {
        this.$store.commit("tile/setPageNum", val);
      }
    },
    getOption002() {
      let option = this.$store.getters["option/getOption"];
      return option.option002;
    },
    cardMoveJudge() {
      return this.$store.getters["setting/getCardMoveJudge"];
    }
  },
  watch: {
    filteredList: {
      immediate: true,
      handler: function(val) {
        if (this.isfreearea) {
          if (!Util.checkArrayLength(val)) {
            this.pageNum = 1;
          } else {
            this.pageNum = Math.ceil(val.length / Define.PLANAREA_MAX_PAGE_NUM);
          }
        }
      }
    }
  },
  methods: {
    /**
     * カードリストを検索キーワードを用いてフィルタリングする
     * 第一引数のフラグをtrueで実行する際にはある特定のエリアの情報のみ取得する
     * @param {Boolean} filterFlg - 検索フラグ(true:特定のエリアのみ,false:全て対象)
     * @param {String} posRow - 特定したい行位置
     * @param {String} posCol - 特定したい列位置
     * @returns {Array} - フィルター後のリスト
     */
    getFilteredList(tileInfo, filterFlg, posRow = null, posCol = null) {
      let list = tileInfo;
      if (!Util.checkArrayLength(list)) {
        return list;
      }
      let getList = [];
      if (this.valueNotEmptyNull(this.keyword)) {
        if (filterFlg) {
          list = list.filter(e => e.posRow === posRow && e.posCol === posCol);
        }
        for (let i in list) {
          if (!Util.checkArrayLength(list[i].displayData)) {
            continue;
          }
          for (let j in list[i].displayData) {
            if (
              !Util.checkNotUndefined(list[i].displayData[j].mappingId) ||
              !Util.checkNotUndefined(list[i].displayData[j].value)
            ) {
              break;
            }
            if (this.includingKeyword(list[i].displayData[j], this.keyword)) {
              getList.push(list[i]);
              break;
            }
          }
        }
      } else {
        if (filterFlg) {
          list = list.filter(e => e.posRow === posRow && e.posCol === posCol);
        }
        getList = list;
      }
      return getList;
    },
    ...mapActions(["moveUpdateTile"]),
    /**
     * オブジェクトの"value"がnull, 空文字でないことを判断
     * @param {Object} item - "value"要素を含むオブジェクト
     * @returns {Boolean} 判定結果
     */
    valueNotEmptyNull(item) {
      return Util.checkNotEmpty(item.value) &&
        Util.checkNotNull(item.value) &&
        Util.checkNotUndefined(item.value)
        ? true
        : false;
    },
    /**
     * 対象の"mappingId"と"value"が、ともにkeywordと等しい場合trueを返す
     * @param {Object} data - 比較対象
     * @param {Object} keyword - 検索キーワード
     * @returns {Boolean} 判定結果
     */
    includingKeyword(data, keyword) {
      return Util.comparisonId(data.mappingId, keyword.mappingId) &&
        Util.checkIncludingKeyword(data.value, keyword.value)
        ? true
        : false;
    },
    /**
     * カード移動イベント(始点)
     * @param {Object} e - ddraggableアイテムオブジェクト
     */
    onStart: function(e) {
      // 掴んでるカード情報： e.item._underlying_vm_
      // 移動中のカードIDを記憶
      this.$store.dispatch("setMovingTileId", e.item._underlying_vm_.tileId);
    },
    /**
     * カード移動イベント(終点)
     * @param {Object} e - draggableアイテムオブジェクト
     */
    onEnd: function(e) {
      // カード移動をロック
      this.$store.commit("setting/switchLoading");
      // 記憶したカードIDを解放
      this.$store.dispatch("removeMovingTileId");

      let result = [];

      // カード情報(始点)
      let cardInfoStart = Util.parseObject(e.item.__vue__.$props.tileinfo);

      // カード位置(始点/終点)
      let posStart = this.convertId(e.from.id);
      posStart.order = cardInfoStart.order;
      let posEnd = this.convertId(e.to.id);
      posEnd.order = e.newDraggableIndex + 1;

      let isAreaMoved = Util.isAreaMoved(posStart, posEnd);
      let isCardMoved = Util.isCardMoved(this.cardMoveJudge, posStart, posEnd);

      // 更新用のカードオブジェクト
      let cardObjForUpd = this.getCardObjForUpd(
        posStart,
        posEnd,
        cardInfoStart,
        isCardMoved
      );

      // カードリスト(終点)
      let cardListEnd = this.makeSplicedList(posEnd, cardObjForUpd.tile_id);

      // 移動先が未計画エリアの場合の処理
      if (posEnd.col === Define.IS_DATE_FREE) {
        // 300件以上のカードを置こうとした場合、警告を表示して移動をさせない
        if (cardListEnd.length >= Define.FREEAREA_MAX_TILENUM) {
          Swal.fire({
            html: this.$t("MSG_ERR_OVER_ITEMS_FREE_AREA"),
            icon: "error"
          });
          // カード移動ロックの解除
          this.$store.commit("setting/switchLoading");
          return 0;
        }
        // ページネーションを考慮したindexの設定
        posEnd.order += (this.pageNo - 1) * Define.PLANAREA_MAX_PAGE_NUM;
      }
      // 移動前位置のリスト
      // 対象カードを別エリアから移動させたとき
      if (isAreaMoved) {
        let cardListStart = this.makeSplicedList(posStart, cardInfoStart.tileId);
        if (Util.checkArrayLength(cardListStart)) {
          for (let i = 0; i < cardListStart.length; i++) {
            let order = i + 1;
            let getTileData = Util.makeOtherTileData(cardListStart[i], order);
            result.push(getTileData);
          }
        }
      }
      // 動かしたタイルデータのstoreを更新
      let movedTileObj = this.$store.getters["tile/getOneTileData"](
        cardObjForUpd.tile_id
      );
      if (Util.checkNotUndefined(movedTileObj)) {
        //情報を更新したobjectをdispatch
        movedTileObj.posRow = cardObjForUpd.pos_row;
        movedTileObj.posCol = cardObjForUpd.pos_col;
        movedTileObj.order = cardObjForUpd.order;
        this.$store.dispatch("updateMovedTile", movedTileObj);
      }
      let filterList = this.getFilteredList(
        Util.parseObject(cardListEnd),
        true,
        posEnd.row,
        posEnd.col
      );
      if (isAreaMoved) {
        filterList.splice(posEnd.order - 1, 0, cardObjForUpd);
      } else {
        let originalFilterList = Util.parseObject(filterList);
        let originalNotFilterList = Util.parseObject(cardListEnd);
        let deleteFilterList = Util.spliceId(
          originalFilterList,
          cardInfoStart.tileId
        );
        let deleteNotFilterList = Util.spliceId(
          originalNotFilterList,
          cardInfoStart.tileId
        );
        filterList = Util.parseObject(deleteFilterList);
        cardListEnd = Util.parseObject(deleteNotFilterList);
        filterList.splice(posEnd.order - 1, 0, cardObjForUpd);
      }
      if (filterList.length !== 1) {
        posEnd.order -= 1;
        if (posEnd.order === 0) {
          //検索後のリストで先頭に移動した
          //前の分岐でリストの要素数は2以上であるので一つ次の要素は必ず存在
          //検索前のカードのひとつ上に表示
          let data = filterList[1];
          let tileIndex = cardListEnd.findIndex(e => e.tileId === data.tileId);
          cardListEnd.splice(tileIndex, 0, cardObjForUpd);
        } else if (posEnd.order === filterList.length - 1) {
          //検索後のリストで末尾に移動した
          //前の分岐でリストの要素数は2以上であるので一つ前の要素は必ず存在
          let data = filterList[posEnd.order - 1];
          let tileIndex = cardListEnd.findIndex(e => e.tileId === data.tileId);
          cardListEnd.splice(tileIndex + 1, 0, cardObjForUpd);
        } else {
          //検索後のリストで複数枚の間に移動した
          //移動後のカードの一つ前のカードの次に配置
          let data = filterList[posEnd.order - 1];
          let tileIndex = cardListEnd.findIndex(e => e.tileId === data.tileId);
          cardListEnd.splice(tileIndex + 1, 0, cardObjForUpd);
        }
      } else {
        cardListEnd.push(cardObjForUpd);
      }
      for (let i = 0; i < cardListEnd.length; i++) {
        if (!cardListEnd[i].tile_id) {
          let getTileData = Util.makeOtherTileData(cardListEnd[i], i + 1);
          result.push(getTileData);
        } else {
          cardListEnd[i].order = i + 1;
          result.push(cardListEnd[i]);
        }
      }
      this.moveUpdateTile(result);
    },
    /**
     * 更新用のカード情報オブジェクトを取得する
     * @param {Object} posStart - 掴んでいたカードの移動前位置の行ID、列ID
     * @param {Object} posEnd - 掴んでいたカードの移動後位置の行ID、列ID
     * @param {Object} cardInfoStart - 掴んでいたカード情報オブジェクト
     * @param {Boolean} isCardMoved - 位置が移動していればtrue、並び順を変えただけだとfalse
     * @returns {Object} movedTile - 移動履歴更新用オブジェクト
     */
    getCardObjForUpd(posStart, posEnd, cardInfoStart, isCardMoved) {
      let movedTile = {
        order: posEnd.order,
        pos_col: posEnd.col,
        pos_row: posEnd.row,
        tile_id: cardInfoStart.tileId,
        is_complete: cardInfoStart.isComplete,
        version: cardInfoStart.version,
        is_confirmed: Util.getNextCardIsConfirmedByMove(cardInfoStart.isConfirmed, this.getOption002, isCardMoved),
        status: Util.getNextCardStatusByMove(cardInfoStart.status, this.getOption002, isCardMoved)
      }
      if (isCardMoved) {
        movedTile.tile_move_history_data = {
          history_type: Define.HISTORY_TYPE_MOVED,
          pos_col_before: posStart.col,
          pos_col_after: posEnd.col,
          pos_row_before: this.$store.getters["setting/getAreaName"](
            posStart.row
          ),
          pos_row_after: this.$store.getters["setting/getAreaName"](posEnd.row),
          tile_id: cardInfoStart.tileId
        };
      }
      if (cardInfoStart.isConfirmed != movedTile.is_confirmed) {
        movedTile.tile_confirmed_history_data = {
          history_type: Define.HISTORY_TYPE_CONFIRMED,
          flg_type: Define.FLG_TYPE_CONFIRMED,
          flg_before: cardInfoStart.isConfirmed,
          flg_after: movedTile.is_confirmed
        };
      }
      return movedTile;
    },
    /**
     * 指定位置のカードリストを取得し、掴んでいたカードが含まれる場合、それを除外したリストを返す
     * @param {String} areaId - 移動前、または後の位置の行ID、列ID
     * @param {String} movedTileId - 掴んでいたカードのID
     * @returns {Array}} makeList - 処理後カードリスト
     */
    makeSplicedList(areaId, movedTileId) {
      let makeList = this.$store.getters["tile/getMovedArea"](
        areaId.row,
        areaId.col
      );
      makeList = Util.spliceId(makeList, movedTileId);
      return makeList;
    },
    /**
     * idを行ID、列IDに分割して、オブジェクトとして返す
     * @param {String} id - draggableオブジェクト(endイベントで拾ってるオブジェクト)に格納されるID
     * @returns {Object} area - 行ID、列ID
     */
    convertId(id) {
      let separaterId = Define.SEPARATE_DRAGGABLE_ID;
      let area = { row: "", col: "" };
      let splitArray = Util.splitTextByKeyword(id, separaterId);
      area.row = Util.checkTextIsNull(splitArray[0]);
      area.col = Util.checkTextIsNull(splitArray[1]);
      return area;
    },
    /**
     * 配列を指定要素数で分割する
     * @param {Array} array - 配列
     * @param {String} number - 指定要素数
     * @returns {Array[]} - 分割配列
     */
    sliceByNumber(array, number) {
      const length = Math.ceil(array.length / number);
      return new Array(length)
        .fill()
        .map((_, i) => array.slice(i * number, (i + 1) * number));
    },
    pagenateList(filteredList, pageNo) {
      let result = [];
      let start = (pageNo - 1) * Define.PLANAREA_MAX_PAGE_NUM + 1;
      let end = pageNo * Define.PLANAREA_MAX_PAGE_NUM;
      result = filteredList.filter(e => e.order >= start && e.order <= end);
      return result;
    }
  }
};
</script>

<style scoped>
.list {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: calc(100% - 20px);
  width: 100%;
}
.draggablearea {
  height: 100%;
  width: 100%;
  padding: 2px;
}
.item:hover {
  cursor: grab;
}
.item:active {
  cursor: grabbing;
}
</style>
