<template>
  <div>
    <v-dialog v-model="dialog" width="1000px" persistent>
      <v-card width="1000px" class="mx-auto">
        <v-row class="mx-2">
          <v-col cols="6">
            <v-card-title>{{ $t("PAGE_TITLE_USER_ROLE_CHANGE") }}</v-card-title>
            <v-card-text>{{ $t("TEXT_USER_CHANGE_NAME") }}{{ selectedUser.username }}</v-card-text>
            <v-card-text>{{ $t("TEXT_USER_CHANGE_ROLE") }}{{ getRoleName(selectedUser.role) }}</v-card-text>
            <v-radio-group class="ml-3" v-model="role" row>
              <v-radio :label="$t('WORD_ROLENAME')[0]" value="0"></v-radio>
              <v-radio :disabled="disabled" :label="$t('WORD_ROLENAME')[1]" value="1"></v-radio>
              <v-radio :disabled="disabled" :label="$t('WORD_ROLENAME')[2]" value="2"></v-radio>
              <v-radio :disabled="disabled" :label="$t('WORD_ROLENAME')[3]" value="3"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col v-if="workspaces.length > 0" cols="6">
            <v-card outlined> 
              <v-card-title>{{ $t("TEXT_WORKSPACE") }}</v-card-title>
              <v-card-text>
                <div v-for="(wsRole, index) in wsRoles" :key="index">
                  <label>{{ wsRole.name }}</label>
                  <v-radio-group class="mt-0" v-model="wsRoles[index].role" row>
                    <v-radio :label="$t('WORD_ROLENAME')[0]" value="0"></v-radio>
                    <v-radio :label="$t('WORD_ROLENAME')[1]" value="1"></v-radio>
                    <v-radio :label="$t('WORD_ROLENAME')[2]" value="2"></v-radio>
                    <v-radio :label="$t('WORD_ROLENAME')[3]" value="3"></v-radio>
                  </v-radio-group>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-card-actions class="justify-center">
          <v-btn width="25%" class="info" @click="submit">{{ $t("WORD_CHANGE") }}</v-btn>
          <v-btn width="25%" class="" @click="closeDialog">{{ $t("WORD_CANCEL") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "UserTableChangeDialog",
  props: {
    dialog: Boolean
  },
  data: function() {
    return {
      // 入力項目
      role: "0",
      wsRoles: [],
    };
  },
  computed: {
    selectedUser() {
      return this.$store.getters["user/selectedUser"];
    },
    user() {
      return this.$store.getters["auth/user"];
    },
    workspaces() {
      return this.$store.getters["workspace/workspaces"];
    },
    selectedUserRoles() {
      return this.$store.getters["role/selectedUserRoles"];
    },
    disabled() {
      return this.isSelf(this.selectedUser, this.user);
    }
  },
  watch: {
    dialog: function(value) {
      if (value) {
        this.role = String(this.selectedUser.role);
        this.$store.dispatch("role/getRole", this.selectedUser.userId);
      }else{
        this.role = "0";
        this.wsRoles = [];
      }
    },
    selectedUserRoles: function(value) {
      this.wsRoles = [];
      for(var workspace of this.workspaces){
        let index = value.findIndex(role => role.workSpaceId === workspace.workSpaceId) 
          if(index != -1){
            this.wsRoles.push({name: workspace.name, workspaceId: workspace.workSpaceId, role: String(value[index].role)});
          }else{
            this.wsRoles.push({name: workspace.name, workspaceId: workspace.workSpaceId, role: String(this.role)});
          }
      }
    }
  },
  methods: {
    /**
     * ユーザーロール名を定義する
     * @param {String} userRole - ユーザーのロール
     */
    getRoleName(userRole) {
      return this.$t("WORD_ROLENAME")[userRole];
    },
    /**
     * 入力情報をAPIに伝搬する
     */
    submit() {
      this.$store
        .dispatch("user/updateUser", { user_id: this.selectedUser.userId, param: { role: this.role }, wsRoles: this.wsRoles })
        .then(() => {
          this.closeDialog();
        });
    },
    /**
     * クローズイベントを親部品に通知する
     */
    closeDialog() {
      this.$emit("close");
    },
    /**
     * 選択行のユーザーが自身かどうか判定する
     * @param {Object} selectedUser - 選択行のユーザー
     * @param {Object} user - 自身
     * @return {Object} - 判定結果
     */
    isSelf(selectedUser, user) {
      return selectedUser.userId === user.userId;
    }
  }
};
</script>

<style scoped></style>
