var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pagestemplate" }, [
    _c(
      "div",
      { staticClass: "importfilepage" },
      [
        _c("PageTitle", {
          attrs: { titles: _vm.pagetitle, descriptions: _vm.pagedescription }
        }),
        _c(
          "div",
          [
            _c(
              "v-stepper",
              {
                staticClass: "stepper_width",
                model: {
                  value: _vm.stepperPageNum,
                  callback: function($$v) {
                    _vm.stepperPageNum = $$v
                  },
                  expression: "stepperPageNum"
                }
              },
              [
                _c(
                  "v-stepper-header",
                  { staticClass: "header_color" },
                  [
                    _c(
                      "v-stepper-step",
                      {
                        attrs: {
                          complete: _vm.stepperPageNum > 1,
                          step: "1",
                          color: "info"
                        }
                      },
                      [
                        _c("span", {}, [
                          _vm._v(_vm._s(_vm.$t("TABNAME_INPUT_FILE_SELECT")))
                        ])
                      ]
                    ),
                    _c("v-divider", {}),
                    _c(
                      "v-stepper-step",
                      {
                        attrs: {
                          complete: _vm.stepperPageNum > 2,
                          step: "2",
                          color: "info"
                        }
                      },
                      [
                        _c("span", {}, [
                          _vm._v(_vm._s(_vm.$t("TABNAME_INPUT_FILE_SETTING")))
                        ])
                      ]
                    ),
                    _c("v-divider", {}),
                    _c(
                      "v-stepper-step",
                      { attrs: { step: "3", color: "info" } },
                      [
                        _c("span", {}, [
                          _vm._v(_vm._s(_vm.$t("TABNAME_INPUT_FILE_START")))
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "v-stepper-items",
                  { staticClass: "content mt-2" },
                  [
                    _c("v-stepper-content", { attrs: { step: "1" } }, [
                      _c("div", { staticClass: "text-center contents_area" }, [
                        _c("div", { staticClass: "text_area" }, [
                          _c(
                            "div",
                            { staticClass: "button_center mt-12" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    "x-large": "",
                                    width: "40vw",
                                    color: "success",
                                    dark: "",
                                    disabled: !_vm.authFileInput
                                  },
                                  on: { click: _vm.fileUpload }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-2",
                                      attrs: { right: "", dark: "" }
                                    },
                                    [_vm._v(" mdi-file-document-outline")]
                                  ),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("WORD_INPUT_FILE_SELECT_FILE")
                                      ) +
                                      " "
                                  )
                                ],
                                1
                              ),
                              _c("div", [
                                _c("p", { staticClass: "mt-3 mb-0" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("WORD_INPUT_FILE_SELECTED_FILE")
                                      ) +
                                      " " +
                                      _vm._s(
                                        _vm.fileName || _vm.fileNameNotSet
                                      ) +
                                      " "
                                  )
                                ]),
                                _c(
                                  "p",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.alertFlg,
                                        expression: "alertFlg"
                                      }
                                    ],
                                    staticClass: "my-0"
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "mt-1 alert_text_color" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "MSG_INPUT_FILE_SELECT_ALERT"
                                            )
                                          )
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c("div", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.alertFlg,
                                      expression: "!alertFlg"
                                    }
                                  ],
                                  staticClass: "pt-6"
                                })
                              ]),
                              _c(
                                "div",
                                { staticClass: "text-left mt-4" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "border_left mb-3" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("WORD_INPUT_FILE_ATTENTION")
                                        )
                                      )
                                    ]
                                  ),
                                  _vm._l(_vm.attentions, function(
                                    attention,
                                    index
                                  ) {
                                    return _c("ul", { key: index }, [
                                      _c(
                                        "li",
                                        { staticClass: "linefeed mb-1" },
                                        [_vm._v(_vm._s(attention))]
                                      )
                                    ])
                                  })
                                ],
                                2
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: false,
                                    expression: "false"
                                  }
                                ],
                                ref: "inputUpload",
                                attrs: {
                                  id: "file_input_expense",
                                  name: "file_input_expense",
                                  type: "file",
                                  accept: ".csv"
                                },
                                on: { change: _vm.fileChange }
                              })
                            ],
                            1
                          )
                        ]),
                        _c(
                          "div",
                          { staticClass: "button_area text-right" },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: { color: "info" },
                                on: { click: _vm.fileCheck }
                              },
                              [_vm._v(_vm._s(_vm.$t("WORD_NEXT")))]
                            )
                          ],
                          1
                        )
                      ])
                    ]),
                    _c("v-stepper-content", { attrs: { step: "2" } }, [
                      _c(
                        "div",
                        { staticClass: "contents_area" },
                        [
                          _c(
                            "div",
                            { staticClass: "areatitle" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("PAGE_TITLE_INPUT_FILE_SETTING")
                                  ) +
                                  " "
                              ),
                              _c("HelpButton", {
                                attrs: { helpobject: _vm.fileHelpDialogData }
                              })
                            ],
                            1
                          ),
                          _c("InputFileSetting", {
                            attrs: {
                              inputFileSetting: _vm.InputFileSetting,
                              userRole: !_vm.authFileInput,
                              mappingDataList: _vm.mappingList
                            },
                            on: {
                              input: function($event) {
                                _vm.importSetting = $event
                              }
                            }
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "d-flex justify-space-between mt-4"
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  on: {
                                    click: function($event) {
                                      _vm.stepperPageNum = 1
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("WORD_BACK")))]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "info" },
                                  on: { click: _vm.next }
                                },
                                [_vm._v(_vm._s(_vm.$t("WORD_NEXT")))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]),
                    _c("v-stepper-content", { attrs: { step: "3" } }, [
                      _c("div", { staticClass: "text-center contents_area" }, [
                        _c(
                          "div",
                          { staticClass: "text_area" },
                          [
                            _c(
                              "div",
                              { staticClass: "d-flex justify-space-between" },
                              [
                                _c("p", { staticClass: "border_left" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("TEXT_INPUT_FILE_SETTING_CHECK")
                                    )
                                  )
                                ])
                              ]
                            ),
                            _c("v-divider", { staticClass: "mt-1" }),
                            _c(
                              "div",
                              { staticClass: "d-flex justify-center" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-center setting_result_area confirm_area mt-1"
                                  },
                                  [
                                    _c(
                                      "v-container",
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass: "border_left text-left"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "PAGE_TITLE_INPUT_FILE_SETTING_CHECK"
                                                )
                                              )
                                            )
                                          ]
                                        ),
                                        _c(
                                          "v-row",
                                          { attrs: { justify: "center" } },
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "5" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "PAGE_SUBTITLE_INPUT_FILE_SETTING_FILE_NAME"
                                                    )
                                                  )
                                                )
                                              ]
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "4" } },
                                              [_vm._v(_vm._s(_vm.fileName))]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          { attrs: { justify: "center" } },
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "5" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "PAGE_SUBTITLE_INPUT_FILE_SETTING_HEADER"
                                                    )
                                                  )
                                                )
                                              ]
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "4" } },
                                              [
                                                _vm.importSetting.header ==
                                                _vm.IMPORT_SETTING_HEADER_WITH
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "WORD_INPUT_FILE_SETTING_HEADER_SET"
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  : _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "WORD_INPUT_FILE_SETTING_HEADER_NOT"
                                                          )
                                                        )
                                                      )
                                                    ])
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          { attrs: { justify: "center" } },
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "5" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "PAGE_SUBTITLE_INPUT_FILE_SETTING_QUOTATION"
                                                    )
                                                  )
                                                )
                                              ]
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "4" } },
                                              [
                                                _vm.importSetting.character ==
                                                _vm.IMPORT_SETTING_CHARACTER_DOUBLE
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "WORD_INPUT_FILE_SETTING_QUOTATION_DOUBLE"
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  : _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "WORD_INPUT_FILE_SETTING_QUOTATION_SINGLE"
                                                          )
                                                        )
                                                      )
                                                    ])
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          { attrs: { justify: "center" } },
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "5" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "PAGE_SUBTITLE_INPUT_FILE_SETTING_POSITION"
                                                    )
                                                  )
                                                )
                                              ]
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "4" } },
                                              [
                                                _vm.importSetting.location ==
                                                _vm.IMPORT_SETTING_LOCATION_NOPLAN
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "WORD_INPUT_FILE_SETTING_POSTION_UNPLANNED"
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  : _vm.importSetting
                                                      .location ==
                                                    _vm.IMPORT_SETTING_LOCATION_ABSOLUTE
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "WORD_INPUT_FILE_SETTING_POSTION_ASSIGN"
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  : _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "WORD_INPUT_FILE_SETTING_POSTION_RELATIVE"
                                                          )
                                                        )
                                                      )
                                                    ]),
                                                _vm.importSetting.isFixedPos ==
                                                _vm.IMPORT_SETTING_FIXED_POS
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "WORD_INPUT_FILE_SETTING_ISFIXEDPOS"
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  : _vm._e()
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _vm.importSetting.location ==
                                        _vm.IMPORT_SETTING_LOCATION_ABSOLUTE
                                          ? _c(
                                              "v-row",
                                              { attrs: { justify: "center" } },
                                              [
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "5" } },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "PAGE_SUBTITLE_INPUT_FILE_SETTING_COL_DATE"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "4" } },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.importSetting
                                                          .absoluteCol
                                                      )
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.importSetting.location ==
                                        _vm.IMPORT_SETTING_LOCATION_ABSOLUTE
                                          ? _c(
                                              "v-row",
                                              { attrs: { justify: "center" } },
                                              [
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "5" } },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "PAGE_SUBTITLE_INPUT_FILE_SETTING_COL_PLANNED_AREA"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "4" } },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.importSetting
                                                          .absoluteRow
                                                      )
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.importSetting.location ==
                                        _vm.IMPORT_SETTING_LOCATION_RELATIVE
                                          ? _c(
                                              "v-row",
                                              { attrs: { justify: "center" } },
                                              [
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "5" } },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "PAGE_SUBTITLE_INPUT_FILE_SETTING_REFERENCE_DATE"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "4" } },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.importSetting
                                                          .referenceDate
                                                      )
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.importSetting.location ==
                                        _vm.IMPORT_SETTING_LOCATION_RELATIVE
                                          ? _c(
                                              "v-row",
                                              { attrs: { justify: "center" } },
                                              [
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "5" } },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "PAGE_SUBTITLE_INPUT_FILE_SETTING_RELATIVE_COL_NAME"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "4" } },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.relativeColName
                                                      )
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.importSetting.location ==
                                        _vm.IMPORT_SETTING_LOCATION_RELATIVE
                                          ? _c(
                                              "v-row",
                                              { attrs: { justify: "center" } },
                                              [
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "5" } },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "PAGE_SUBTITLE_INPUT_FILE_SETTING_RELATIVE_ROW_NAME"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "4" } },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.relativeRowName
                                                      )
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.hasKeySettings
                                          ? _c(
                                              "v-row",
                                              { attrs: { justify: "center" } },
                                              [
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "5" } },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "PAGE_SUBTITLE_INPUT_FILE_SETTING_SAME_KEY"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "4" } },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.prosessingMethodSameDataExistsName
                                                        )
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.option002 &&
                                        _vm.isSelectedSameCardUpdate
                                          ? _c(
                                              "v-row",
                                              { attrs: { justify: "center" } },
                                              [
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "5" } },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "PAGE_SUBTITLE_INPUT_FILE_SETTING_UPDATE_CONFIRM"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "4" } },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getUpdateCardConfirmedStatusName
                                                      )
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "log_area text-center mx-auto mt-4"
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "text-center" },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass: "border_left text-left"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "PAGE_SUBTITLE_INPUT_FILE_SETTING_ERROR_DETAIL"
                                                )
                                              )
                                            )
                                          ]
                                        ),
                                        _c(
                                          "v-card",
                                          {
                                            staticClass:
                                              "text-left scroll pa-2",
                                            attrs: {
                                              width: "100%",
                                              height: "38vh"
                                            }
                                          },
                                          _vm._l(_vm.resultLog, function(item) {
                                            return _c("div", { key: item.id }, [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "alert_text_color"
                                                },
                                                [_vm._v(_vm._s(item.message))]
                                              )
                                            ])
                                          }),
                                          0
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "mt-6" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      "x-large": "",
                                      width: "56%",
                                      color: "info"
                                    },
                                    on: { click: _vm.fileImportConfirm }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("WORD_INPUT_FILE_START")
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "button_area text-left" },
                          [
                            _c(
                              "v-btn",
                              {
                                on: {
                                  click: function($event) {
                                    _vm.stepperPageNum = 2
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("WORD_BACK")))]
                            )
                          ],
                          1
                        )
                      ])
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }