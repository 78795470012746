<template>
  <div class="pagestemplate">
    <div class="management">
      <div>
        <PageTitle
          :titles="pagetitle"
          :descriptions="pagedescription"
        ></PageTitle>
        <div class="tablearea"><UserTable /></div>
        <div class="tablearea"><WorkSpaceTable /></div>
      </div>
      <div class="planarea">
        <div class="areatitle">{{ $t("WORD_MANAGEMENT_PLAN_CHANGE") }}</div>
        <div class="acbtnarea">
          <v-btn
            class="abtn"
            width="800px"
            height="60px"
            text
            :href="agreementUrl"
            target="_blank"
          >
            <h3>{{ $t("WORD_MANAGEMENT_CONTACT") }}</h3>
          </v-btn>
        </div>
        <div class="acbtnarea">
          <v-btn
            class="cbtn"
            width="800px"
            height="60px"
            text
            :href="contactUrl"
            target="_blank"
          >
            <h3>{{ $t("WORD_MANAGEMENT_QUIT") }}</h3>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/parts/PageTitle";
import UserTable from "@/components/parts/ManageUser/UserTable.vue";
import WorkSpaceTable from "@/components/parts/ManageWorkSpace/WorkSpaceTable.vue";
import Define from "@/define.js";
import { mixinUtil } from "@/mixins/mixinUtils.js";
export default {
  components: {
    PageTitle,
    UserTable,
    WorkSpaceTable
  },
  mixins: [mixinUtil],
  data() {
    return {
      agreementUrl: Define.URL_AGREEMENT,
      contactUrl: Define.URL_CONTACT
    };
  },
  computed: {
    pagetitle() {
      let pageObject = this.getPageTitleDescriptionObj(
        Define.PAGE_CODE_MANAGEMENT
      );
      return pageObject.title;
    },
    pagedescription() {
      let pageObject = this.getPageTitleDescriptionObj(
        Define.PAGE_CODE_MANAGEMENT
      );
      return pageObject.description;
    }
  }
};
</script>

<style scoped>
.management {
  margin: 20px;
  padding-left: 20px;
  height: calc(100% - 40px);
  width: calc(100% - 40px);
  background-color: #fff;
  border: solid 1px #aaa;
  overflow: auto;
  white-space: nowrap;
}
.tablearea {
  padding: 10px;
  height: 80%;
}
.areatitle {
  border-left: solid 6px #1976d2ca;
  margin-top: 8px;
  margin-bottom: 28px;
  padding-left: 8px;
}
.planarea {
  padding: 10px;
  height: 20%;
}
.acbtnarea {
  margin-top: 10px;
  text-align: center;
}
.abtn {
  background-color: #ef6c00;
  color: #ffffff;
}
.cbtn {
  background-color: #575757;
  color: #ffffff;
}
</style>
