import Define from "@/define.js";
import * as Util from "@/util/utils.js";

export const mixinControl = {
  name: "mixinControl",
  methods: {
    getCardColors() {
      let items = [
        { label: this.$t("WORD_NOTSET"), color: "" },
        this.$t("COLOR_DEFAULT"),
        this.$t("COLOR_RED"),
        this.$t("COLOR_ORANGE"),
        this.$t("COLOR_YELLOW"),
        this.$t("COLOR_GREEN"),
        this.$t("COLOR_PURPLE"),
        this.$t("COLOR_BLUE"),
        this.$t("COLOR_PINK"),
        this.$t("COLOR_LIME"),
        this.$t("COLOR_CYAN"),
        this.$t("COLOR_WHITE")
      ];
      return items;
    },
    /**
     * データ形式の選択肢を取得する(マッピングテーブル)
     * @returns -  データ形式の選択肢
     */
    getMappingTableTypeItems() {
      let items = [
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("WORD_STRING"),  value: Define.TYPE_STRING },
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("WORD_INTEGER"), value: Define.TYPE_INTEGER },
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("WORD_FLOAT"),   value: Define.TYPE_FLOAT },
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("WORD_DATE"),    value: Define.TYPE_DATE },
        //  eslint-disable-next-line prettier/prettier
        { text: this.$t("WORD_TIME"),    value: Define.TYPE_TIME },
        //  eslint-disable-next-line prettier/prettier
        { text: this.$t("WORD_CALC"),    value: Define.TYPE_CALC }
      ];
      return items;
    },
    /**
     * データ入力形式の選択肢を取得する(マッピングテーブル)
     * @returns - データ入力形式の選択肢
     */
    getMappingTableInputTypeItems(type) {
      let items;
      switch (type) {
        case Define.TYPE_STRING:
          items = [
            // eslint-disable-next-line prettier/prettier
            { text: this.$t("INPUT_TEXT_TYPE_DEFAULT"), value: Define.INPUT_TYPE_DEFAULT },
            // eslint-disable-next-line prettier/prettier
            { text: this.$t("INPUT_TEXT_TYPE_LIST"),    value: Define.INPUT_TYPE_LIST }
          ];
          break;
        case Define.TYPE_INTEGER:
          items = [
            // eslint-disable-next-line prettier/prettier
            { text: this.$t("INPUT_TEXT_TYPE_DEFAULT"), value: Define.INPUT_TYPE_DEFAULT },
            // eslint-disable-next-line prettier/prettier
            { text: this.$t("INPUT_TEXT_TYPE_SPINNER"),  value: Define.INPUT_TYPE_SPINNER },
            // eslint-disable-next-line prettier/prettier
            { text: this.$t("INPUT_TEXT_TYPE_SLIDER"),  value: Define.INPUT_TYPE_SLIDER }
          ];
          break;
        case Define.TYPE_FLOAT:
          items = [
            // eslint-disable-next-line prettier/prettier
            { text: this.$t("INPUT_TEXT_TYPE_DEFAULT"), value: Define.INPUT_TYPE_DEFAULT },
            // eslint-disable-next-line prettier/prettier
            { text: this.$t("INPUT_TEXT_TYPE_SPINNER"), value: Define.INPUT_TYPE_SPINNER },
            // eslint-disable-next-line prettier/prettier
            { text: this.$t("INPUT_TEXT_TYPE_SLIDER"),  value: Define.INPUT_TYPE_SLIDER }
          ];
          break;
        case Define.TYPE_DATE:
          // eslint-disable-next-line prettier/prettier
          items = [{ text: this.$t("INPUT_TEXT_TYPE_DEFAULT"), value: Define.INPUT_TYPE_DEFAULT }];
          break;
        default:
          items = [];
          break;
        case Define.TYPE_TIME:
          // eslint-disable-next-line prettier/prettier
          items = [{ text: this.$t("INPUT_TEXT_TYPE_DEFAULT"), value: Define.INPUT_TYPE_DEFAULT }];
          break;
        case Define.TYPE_CALC:
          // eslint-disable-next-line prettier/prettier
           items = [{ text: this.$t("INPUT_TEXT_TYPE_DEFAULT"), value: Define.INPUT_TYPE_DEFAULT }];
           break;
      }
      return items;
    },
    /**
     * 取込CSV対応列の選択肢を取得する(マッピングテーブル)
     * @returns -  取込CSV対応列の選択肢
     */
    getMappingTableColumnItems() {
      let items = [{ text: this.$t("MAPPING_NOTSET"), value: "" }];
      for (let i = 1; i <= Define.CSV_COL_LIMIT; i++) {
        items.push({ text: i, value: i });
      }
      return items;
    },
    /**
     * テンプレート対応場所の選択肢を取得する(マッピングテーブル)
     * @returns -  テンプレート対応場所の選択肢
     */
    getMappingTablePosItems(tileTempNum) {
      let num = Util.getDiaplayNum(tileTempNum);
      let items = [{ text: this.$t("MAPPING_NOTSET"), value: "" }];
      for (let i = 1; i <= num; i++) {
        items.push({ text: i, value: i });
      }
      return items;
    },
    /**
     * 実績入力の選択肢を取得する(マッピングテーブル)
     * @returns - 実績入力の選択肢
     */
    getMappingTableJissekiItems() {
      let items = [
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("WORD_NOTHING"),  value: Define.JISSEKI_NOTHING },
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("JISSEKI_TEXT_OPTIONAL"), value: Define.JISSEKI_OPTIONAL },
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("JISSEKI_TEXT_REQUIRED"), value: Define.JISSEKI_REQUIRED }
      ];
      return items;
    },
    /**
     * 実績集計方法の選択肢を取得する(マッピングテーブル)
     * @returns - 実績集計方法の選択肢
     */
    getMappingTableJissekiCalcItems() {
      let items = [
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("WORD_NOTHING"), value: Define.JISSEKICALC_NOTHING },
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("JISSEKICALC_SUM"), value: Define.JISSEKICALC_SUM }
      ];
      return items;
    },
    /**
     * 表示順序の選択肢を取得する(計画エリアテーブル)
     * @returns - 表示順序の選択肢
     */
    getPlanTableOrderItems(planAreaList) {
      let notDispObj = { text: this.$t("WORD_HIDDEN"), value: "" };
      let items = [];
      items.push(notDispObj);
      let planList = Util.parseObject(planAreaList);
      if (Util.checkNotUndefined(planList)) {
        if (Util.checkArrayLength(planList)) {
          for (let i = 1; i < planList.length + 1; i++) {
            items.push({
              text: i,
              value: i
            });
          }
        }
      }
      return items;
    },
    /**
     * 連携先の選択肢を取得する(API連係機能)
     * @returns - 連携先の選択肢
     */
    getRenkeisaki() {
      return [
        { text: this.$t("WEBHOOK_RENKEISAKI_NAME_KINTONE"),    value: Define.WEBHOOK_RENKEISAKI_ID_KINTONE },
        { text: this.$t("WEBHOOK_RENKEISAKI_NAME_HANYOU"),    value: Define.WEBHOOK_RENKEISAKI_ID_HANYOU }
      ]
    }
  }
}