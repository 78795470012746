import { render, staticRenderFns } from "./PasswordResetPage.vue?vue&type=template&id=bd21bed0&scoped=true&"
import script from "./PasswordResetPage.vue?vue&type=script&lang=js&"
export * from "./PasswordResetPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd21bed0",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCard,VCardActions,VCardSubtitle,VCardText,VForm,VImg,VLayout,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("C:\\Freely\\repo_nck_plan_board\\nck_plan_board_frontend\\vue\\code\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('bd21bed0')) {
      api.createRecord('bd21bed0', component.options)
    } else {
      api.reload('bd21bed0', component.options)
    }
    module.hot.accept("./PasswordResetPage.vue?vue&type=template&id=bd21bed0&scoped=true&", function () {
      api.rerender('bd21bed0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/pages/PasswordResetPage.vue"
export default component.exports