<template>
  <div class="templatepage">
    <v-card
      outlined
      tile
      class="templatecard mx-auto mt-16"
      color="rgb(0, 0, 0, 0)"
    >
      <div class="templatetitle">
        <v-layout class="justify-center">
          <v-img
            :src="require('@/assets/Freely_squair.png')"
            max-height="250"
            max-width="250"
          />
        </v-layout>
      </div>
      <v-card-text>
        <v-form ref="form">
          <v-card-subtitle>
            登録しているメールアドレスを入力してください。
          </v-card-subtitle>
          <v-text-field
            v-model="email"
            placeholder=" "
            outlined
            label="メールアドレス"
            :rules="[required, email_max_length, email_format]"
            counter="254"
          />
          <v-card-actions class="justify-center">
            <v-btn
              height="50"
              class="templatebtn"
              color="success"
              @click="submitEmail"
              >パスワード初期化用メール送信</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn text color="blue" @click="toLogin">ログイン画面に戻る</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import * as Util from "@/util/utils.js";
import * as Validator from "@/util/validator.js";
import Define from "@/define.js";
export default {
  name: "PasswordResetMail",
  data() {
    return {
      email: null,

      // 入力規則
      required: value => Validator.isValidRequired(value),
      email_max_length: value => Validator.isValidLength(value, null, Define.LENGTH_MAX_EMAIL),
      email_format: value => Validator.isValidEmailFormat(value)
    };
  },
  methods: {
    /**
     * 入力情報をAPIに伝搬する
     */
    submitEmail() {
      if (this.$refs.form.validate()) {
        // loadingダイアログ表示
        this.$store.commit("setIsLoading", true);
        Util.axiosPostReq(Define.API_AUTH_PASSRESET, {
          email: this.email
        })
          .then(response => {
            // console.log(response);
            console.log(response.status); // ステータスコードのみ表示(秘匿情報は外部に出さない)
            // loadingダイアログ非表示
            this.$store.commit("setIsLoading", false);
            Swal.fire({
              icon: "success",
              title: "メール送信成功",
              text: "パスワード初期化用のメールを送信しました。"
            });
          })
          .catch(error => {
            console.log(error.message);
            // loadingダイアログ非表示
            this.$store.commit("setIsLoading", false);
            Swal.fire({
              icon: "warning",
              title: "メール送信失敗",
              text: "有効なメールアドレスを入力してください。"
            });
          });
      } else {
        Swal.fire({
          icon: "error",
          title: "入力エラー",
          text: "正しい情報を入力してください。"
        });
      }
    },
    /**
     * login画面に遷移する
     */
    toLogin() {
      this.$router.push("/login");
    }
  }
};
</script>

<style scoped></style>
