<template>
  <v-dialog v-model="helpDialog" width="48%" persistent>
    <div class="inputhelpdialog">
      <v-card class="pa-4">
        <v-card-title>
          <div class="border_main_title">
            {{ dialogdata.mainTitle }}
          </div>
        </v-card-title>
        <div v-if="dialogdata.subTitle !== null">
          <v-card-text>
            <div class="text_color linefeed">{{ dialogdata.subTitle }}</div>
          </v-card-text>
        </div>
        <div v-if="dialogdata.subDisplayFlg">
          <v-card-text
            v-for="sub in dialogdata.sub"
            :key="sub.subTitle"
            class="mt-6 left_margin"
          >
            <div v-if="sub.subTitle !== null">
              <v-row class="my-0">
                <v-col cols="9">
                  <div class="border_sub_title text_color">
                    {{ sub.subTitle }}
                  </div>
                </v-col>
                <v-col cols="9" class="sub_text text_color">{{
                  sub.subText
                }}</v-col>
              </v-row>
              <div v-if="sub.displayType === 'text'">
                <v-container
                  v-for="detail in sub.details"
                  :key="detail.detailTitle"
                  class="py-0"
                >
                  <v-row class="my-0">
                    <v-col cols="2" class="text_color">{{
                      detail.detailTitle
                    }}</v-col>
                    <v-col cols="9" class="sub_text text_color">{{
                      detail.detailText
                    }}</v-col>
                  </v-row>
                </v-container>
              </div>
            </div>
            <div v-else class="border_sub_title text_color mb-3">
              {{ $t("HELP_TEXT_CATEGORY_AND_EXPLAIN") }}
            </div>
            <table v-if="sub.displayType === 'table'" border="1" width="95%">
              <tr bgcolor="#f0f0f0">
                <th>{{ $t("HELP_TEXT_CATEGORY") }}</th>
                <th>{{ $t("HELP_TEXT_EXPLAIN") }}</th>
              </tr>
              <tr
                v-for="detail in sub.details"
                :key="detail.detailTitle"
                class="py-0"
              >
                <td width="18%" class="table_text text_color">
                  {{ detail.detailTitle }}
                </td>
                <td class="table_text text_color linefeed">
                  {{ detail.detailText }}
                </td>
              </tr>
            </table>
          </v-card-text>
        </div>
        <v-card-actions>
          <v-row justify="end">
            <v-btn class="mr-2" @click="helpClose">{{ $t("WORD_CLOSE") }}</v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialogdata: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    helpDialog: false
  }),
  methods: {
    helpOpen() {
      this.helpDialog = true;
    },
    helpClose() {
      this.helpDialog = false;
    }
  }
};
</script>
<style scoped>
.border_main_title {
  border-left: solid 10px #1976d2ca;
  padding-left: 4px;
}
.border_sub_title {
  border-left: solid 6px #1976d2ca;
  padding-left: 8px;
}
.sub_text {
  white-space: pre-wrap;
}
.left_margin {
  margin-left: 8px;
}
.table_text {
  padding-left: 2px;
}
table {
  margin: 0 6px 0 14px;
  border-collapse: collapse;
}
.text_color {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #333;
}
.linefeed {
  white-space: pre-line;
}
</style>
