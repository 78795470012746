<template>
  <div class="datepick">
    <!-- 今日 -->
    <v-btn class="date_btn" small text elevation="2" @click="todayBtn" v-shortkey.once="['shift', 't']" @shortkey="keyToday()">{{ $t("WORD_TODAY") }}</v-btn>
    <!-- 前週 -->
    <v-tooltip bottom open-delay="300" color="rgba(60,64,67,0.95)" transition="fade">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="date_btn" small icon elevation="2" v-bind="attrs" v-on="on" @click="mSevenDay">
          <v-icon>mdi-chevron-double-left</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("TOOLTIP_LAST_WEEK") }}</span>
    </v-tooltip>
    <!-- 前日 -->
    <v-tooltip bottom open-delay="300" color="rgba(60,64,67,0.95)" transition="fade">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="date_btn" small icon elevation="2" v-bind="attrs" v-on="on" @click="mOneDay">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("TOOLTIP_LAST_DAY") }}</span>
    </v-tooltip>
    <!-- 期間 -->
    <div class="days">{{ startdate }} ~ {{ enddate }}</div>
    <!-- 翌日 -->
    <v-tooltip bottom open-delay="300" color="rgba(60,64,67,0.95)" transition="fade">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="date_btn" small icon elevation="2" v-bind="attrs" v-on="on" @click="pOneDay">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("TOOLTIP_NEXT_DAY") }}</span>
    </v-tooltip>
    <!-- 翌週 -->
    <v-tooltip bottom open-delay="300" color="rgba(60,64,67,0.95)" transition="fade">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="date_btn" small icon elevation="2" v-bind="attrs" v-on="on" @click="pSevenDay">
          <v-icon>mdi-chevron-double-right</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("TOOLTIP_NEXT_WEEK") }}</span>
    </v-tooltip>
    <!-- カレンダー -->
    <v-menu v-model="menu" offset-y :close-on-content-click="false">
      <template v-slot:activator="{ on }">
        <v-btn class="date_btn" small text elevation="2" v-on="on">
          <v-icon color="grey darken-2">mdi-calendar</v-icon>
        </v-btn>
      </template>
      <v-date-picker
        v-model="datePicker"
        :locale="locale"
        :day-format="date => new Date(date).getDate()"
        @click="menu = false"
      />
    </v-menu>
  </div>
</template>

<script>
import Define from "@/define.js";
import * as Util from "@/util/utils.js";
import moment from "moment";
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("tile");
export default {
  props: {
    keydate: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      selectDate: moment()
        .startOf("isoWeek")
        .format(Define.DATE_FORMAT_DAY_HYPHEN),
      menu: false,
      formatHyphen: Define.DATE_FORMAT_DAY_HYPHEN,
      formatSlash: Define.DATE_FORMAT_DAY_SLASH
    };
  },
  computed: {
    datePicker: {
      get() {
        return moment(this.selectDate).format(this.formatHyphen);
      },
      set(val) {
        this.menu = false;
        this.selectDate = val;
        this.updateTileData();
      }
    },
    startdate: {
      get() {
        return moment(this.selectDate).format(this.formatSlash);
      }
    },
    enddate: {
      get() {
        return moment(this.selectDate)
          .add(this.displayDaysDiff, "days")
          .format(this.formatSlash);
      }
    },
    displayDaysDiff() {
      return this.$store.getters["display/displayDaysDiff"];
    },
    locale() {
      return this.$i18n.locale;
    },
    isOpenDialog() {
      return this.$store.getters["tile/isOpenDialog"];
    },
  },
  watch: {
    selectDate: {
      immediate: true,
      handler: function() {
        let content = {
          startDate: this.startdate,
          endDate: this.enddate
        };
        let stateStartDate = this.$store.getters["startDate"];
        let stateEndDate = this.$store.getters["endDate"];
        if (
          Util.isDifferentDates(
            stateStartDate,
            stateEndDate,
            this.startdate,
            this.enddate
          )
        ) {
          this.$store.dispatch("updateDateData", content);
          this.updateTileData();
        }
        this.$store.dispatch("updateDateData", content);
        this.$store.commit("display/setDisplayFirstDay", this.selectDate);
        this.$emit("setDate", this.selectDate);
      }
    },
    keydate: {
      immediate: true,
      handler: function() {
        if (
          Util.checkNotNull(this.keydate) &&
          Util.checkNotEmpty(this.keydate)
        ) {
          let str = String(this.keydate);
          this.selectDate = moment(str).format(this.formatHyphen);
          this.updateTileData();
        }
      }
    }
  },
  methods: {
    ...mapActions(["getTileDataByChangeDate"]),
    todayBtn() {
      this.selectDate = moment().format(this.formatHyphen);
    },
    mSevenDay() {
      this.selectDate = moment(this.selectDate)
        .subtract(7, "days")
        .format(this.formatHyphen);
    },
    mOneDay() {
      this.selectDate = moment(this.selectDate)
        .subtract(1, "days")
        .format(this.formatHyphen);
    },
    pOneDay() {
      this.selectDate = moment(this.selectDate)
        .add(1, "days")
        .format(this.formatHyphen);
    },
    pSevenDay() {
      this.selectDate = moment(this.selectDate)
        .add(7, "days")
        .format(this.formatHyphen);
    },
    // 日付変更時にカードデータを再取得する
    updateTileData() {
      // loadingダイアログ表示
      this.$store.commit("setIsLoading", true);
      this.getTileDataByChangeDate(this.selectDate);
    },
    setSelectDate(date) {
      this.selectDate = date;
    },
    /**
     * [ショートカットキー shift+t]今日
     */
    keyToday() {
      if(!this.isOpenDialog){
        this.menu = false;
        this.todayBtn();
      }
    }
  }
};
</script>

<style scoped>
.datepick {
  padding-top: 2px;
  display: flex;
}
.v-btn {
  background-color: #fff;
}
.days {
  margin: 8px;
}
.date_btn {
  margin: 5px 2px 0px 2px;
}
</style>
