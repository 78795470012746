import rootState from "./index";
import Define from "@/define.js";
import SwalMsg from "@/swalMsg.js";
import Swal from "sweetalert2";

const state = {
  defaultOptionObject: {
    optionId: null,
    option001: false, // 未完了カード検知機能
    option002: false, // 変更通知機能
    option003: false, // API連係機能
    option004: false,
    option005: false,
    option006: false,
    option007: false,
    option008: false,
    option009: false,
    option010: false,
    option011: false,
    option012: false,
    option013: false,
    option014: false,
    option015: false,
    version: 0
  }
};

const getters = {
  /**
   * オプションを取得する
   * @returns {option} オプション
   */
  getOption() {
    let option = rootState.state.option;
    if (!option) {
      return state.defaultOptionObject;
    }
    return option;
  }
};
const mutations = {
  initState() {
    state.defaultOptionObject = {
      optionId: null,
      option001: false,
      option002: false,
      option003: false,
      option004: false,
      option005: false,
      option006: false,
      option007: false,
      option008: false,
      option009: false,
      option010: false,
      option011: false,
      option012: false,
      option013: false,
      option014: false,
      option015: false,
      version: 0
    };
  }
};
const actions = {
  /**
   * [WS通信-送信] オプション作成/更新
   */
  createUpdateOption(state, option) {
    const socket = rootState.state.socket;
    if (!socket) {
      Swal.fire(SwalMsg.WS_NOT_CONNECT_ERR);
      return false;
    }
    socket.sendObj({
      type: Define.WS_CODE_SAVE_OPTION,
      option_data: option,
      work_space_id: state.rootState.auth.user.workSpaceId || null
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
