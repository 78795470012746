<template>
  <v-input :value="innerValue" hide-details>
    <v-text-field
      v-if="inputType === INPUT_TYPE_DEFAULT"
      ref="inputField"
      :key="resetKey"
      v-model="innerValue"
      :disabled="!editable"
      dense
      outlined
      :hide-details="hideDetails"
      :rules="innerRules(type, calcObject1Type)"
      :background-color="bgcolor"
    >
      <template v-if="type === TYPE_DATE || type === TYPE_TIME || (type === TYPE_CALC && calcObject1Type === TYPE_DATE)" v-slot:append>
        <v-menu v-if="type === TYPE_DATE || (type === TYPE_CALC && calcObject1Type === TYPE_DATE)" v-model="menu" offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on }">
            <v-btn
              x-small
              height="24px"
              width="36px"
              outlined
              color="#616161"
              @click="innerValue = getToday()"
              :disabled="!editable"
            >
              {{ $t("WORD_TODAY") }}
            </v-btn>
            <v-icon :color="iconColor" style="margin-bottom: 2px;" v-on="on">
              mdi-calendar
            </v-icon>
          </template>
          <v-date-picker
            v-model="datePicker"
            :locale="locale"
            :day-format="date => new Date(date).getDate()"
            @click="menu = false"
            @change="innerValue = changeFormat(datePicker)"
            :disabled="!editable"
          />
        </v-menu>
        <v-menu v-if="type === TYPE_TIME" v-model="menu" offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on }">
            <v-icon :color="iconColor" style="margin-bottom: 2px;" v-on="on">
              mdi-clock-outline
            </v-icon>
          </template>
          <v-time-picker
            v-if="menu"
            v-model="timePicker"
            format="24hr"
            @change="innerValue = changeFormatTime(timePicker)"
            :disabled="!editable"
          />
        </v-menu>
      </template>
    </v-text-field>
    <v-select
      v-if="inputType === INPUT_TYPE_LIST"
      v-model="innerValue"
      dense
      outlined
      hide-details="auto"
      :rules="innerRules(type, calcObject1Type)"
      :items="items"
      :background-color="bgcolor"
      :disabled="!editable"
    ></v-select>
    <SpinnerButtonInput
      v-if="inputType === INPUT_TYPE_SPINNER"
      :min="Number(min)"
      :max="Number(max)"
      :step="Number(step)"
      :value="innerValue"
      :rules="innerRules(type, calcObject1Type)"
      :bgcolor="bgcolor"
      @input="innerValue = String($event)"
    />
    <SliderInput
      v-if="inputType === INPUT_TYPE_SLIDER"
      :min="Number(min)"
      :max="Number(max)"
      :step="Number(step)"
      :value="innerValue"
      :rules="innerRules(type, calcObject1Type)"
      :bgcolor="bgcolor"
      @input="innerValue = String($event)"
    />
  </v-input>
</template>

<script>
import moment from "moment";
import Define from "@/define.js";
import * as Util from "@/util/utils.js";
import SpinnerButtonInput from "@/components/parts/SpinnerButtonInput.vue";
import SliderInput from "@/components/parts/SliderInput.vue";
import { mixinValidator } from "@/mixins/mixinValidator.js";
export default {
  name: "EditingItem",
  components: {
    SpinnerButtonInput,
    SliderInput
  },
  mixins: [mixinValidator],
  props: {
    value: { type: String, default: "", require: true },
    type: { type: Number, default: Define.TYPE_STRING, require: true },
    inputType: { type: Number, default: Define.INPUT_TYPE_DEFAULT, require: false },
    editable: { type: Boolean, default: true, require: true },
    rules: { type: Array, default: () => [], require: false },
    // INPUT_TYPE_LIST専用 - セレクトボックスの選択肢
    items: { type: Array, default: () => [], require: false },
    // INPUT_TYPE_SPINNER/INPUT_TYPE_SLIDER専用 - 最小値
    min: { type: String, default: "", require: false },
    // INPUT_TYPE_SPINNER/INPUT_TYPE_SLIDER専用 - 最大値
    max: { type: String, default: "", require: false },
    // INPUT_TYPE_SPINNER/INPUT_TYPE_SLIDER専用 - ステップ
    step: { type: String, default: "", require: false },
    bgcolor: { type: String, default: "", require: false },
    hideDetails: { type: [String, Boolean], default: "auto", require: false },
    calcObject1Type: { type: Number, default: null, require: false }
  },
  data() {
    return {
      resetKey: 0,
      TYPE_STRING: Define.TYPE_STRING,
      TYPE_INTEGER: Define.TYPE_INTEGER,
      TYPE_FLOAT: Define.TYPE_FLOAT,
      TYPE_DATE: Define.TYPE_DATE,
      TYPE_TIME: Define.TYPE_TIME,
      TYPE_CALC: Define.TYPE_CALC,
      INPUT_TYPE_DEFAULT: Define.INPUT_TYPE_DEFAULT,
      INPUT_TYPE_LIST: Define.INPUT_TYPE_LIST,
      INPUT_TYPE_SPINNER: Define.INPUT_TYPE_SPINNER,
      INPUT_TYPE_SLIDER: Define.INPUT_TYPE_SLIDER,
      menu: false,
      datePicker: moment().format(Define.DATE_FORMAT_DAY_HYPHEN),
      timePicker: "",
      isInitialize: false
    };
  },
  computed: {
    innerValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
        // カード詳細情報が変更されたとき、CardDetail.vueに情報が変更されたことを伝える
        // (カード詳細ダイアログの初期表示時を除く)
        if (!this.isInitialize) {
          this.$emit("update", true);
        }
        this.isInitialize = false;
      }
    },
    innerRules() {
      return (type, calcObject1Type) => {
        switch (type) {
          case Define.TYPE_STRING:
            return [...this.rules, this.checkString];
          case Define.TYPE_INTEGER:
            return [...this.rules, this.checkInt];
          case Define.TYPE_FLOAT:
            return [...this.rules, this.checkFloat];
          case Define.TYPE_DATE:
            return [...this.rules, this.checkDate];
          case Define.TYPE_TIME:
            return [...this.rules, this.checkTime];
          case Define.TYPE_CALC:
            if (calcObject1Type == Define.TYPE_DATE) {
              return [...this.rules, this.checkDate];
            }else if (calcObject1Type == Define.TYPE_INTEGER || calcObject1Type == Define.TYPE_FLOAT){
              return [...this.rules, this.checkFloat];
            }else{
              return [...this.rules];
            }
          default:
            return [...this.rules];
        }
      };
    },
    iconColor() {
      return this.editable ? "grey darken-2" : "grey lighten-2";
    },
    locale() {
      return this.$i18n.locale;
    }
  },
  watch: {
    type: function() {
      this.refresh();
      this.validate();
    }
  },
  beforeMount() {
    this.isInitialize = true;
    this.innerValue = this.value;
  },
  methods: {
    refresh() {
      // 強制的にリフレッシュ ←「key」を更新するとレンダリングが発生する
      this.resetKey++;
    },
    validate() {
      this.$nextTick(() => {
        // validate()は走るけどvalid=falseの際にこのままではエラーメッセージが表示されないため
        // フォーカス in-out を行って強制的に（エラーの場合は）メッセージを表示させる
        if (this.$refs.inputField) {
          this.$refs.inputField.focus();
          this.$nextTick(() => this.$refs.inputField.blur());
        }
      });
    },
    checkString(value) {
      return this.isValidString(value);
    },
    checkInt(value) {
      return this.isValidInt(value);
    },
    checkFloat(value) {
      return this.isValidFloat(value);
    },
    checkDate(value) {
      return this.isValidDate(value);
    },
    checkTime(value) {
      return this.isValidTime(value);
    },
    changeFormat(date) {
      this.menu = false;
      this.datePicker = moment().format(Define.DATE_FORMAT_DAY_HYPHEN);
      return Util.checkDateType(date)
        ? moment(date).format(Define.DATE_FORMAT_DAY_SLASH)
        : "";
    },
    changeFormatTime(time) {
      this.menu = false;
      this.timePicker = "";
      return Util.checkTimeType(time) ? time : "";
    },
    getToday() {
      return moment().format(Define.DATE_FORMAT_DAY_SLASH);
    }
  }
};
</script>

<style scoped></style>
