<template>
  <div class="tenantregistconfirm">
    <v-alert type="success">
      無料お試しプランの登録が成功しました
    </v-alert>
    <v-card
      outlined
      tile
      class="templatecard mx-auto mt-8"
      color="rgb(0, 0, 0, 0)"
    >
      <div class="infobox">
        <div><v-icon> mdi-domain </v-icon>サービスご利用までのステップ</div>
        <br />
        <div>
          <ol>
            <li>アカウントを有効化する</li>
            <br />
            <p>
              入力いただいたメールアドレス宛にアカウントを有効化するためのURLを送信いたしました。URLをクリックし、アカウントを有効化してください。
            </p>
            <br />
            <li>サービスにログインする</li>
            <br />
            <p>
              アカウントの有効化が完了したら、サービスにログインしてご利用を開始してください。
            </p>
          </ol>
        </div>
      </div>
    </v-card>
    <!-- <div class="logo">
      <img :src="require('@/assets/NCK.png')" width="102" height="34" />
    </div> -->
  </div>
</template>

<script>
export default {
  name: "App",

  components: {},

  data: () => ({
    //
  })
};
</script>

<style scoped>
.tenantregistconfirm {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #333;
  height: 100%;
  width: 100%;
}
.logo {
  text-align: center;
  margin-top: 10px;
}
.infobox {
  margin: 5px;
}
</style>
