<template>
  <v-dialog v-model="isOpenMobileCardDetail" fullscreen hide-overlay transition="v-dialog-transition">
    <v-card>

      <!-- ヘッダー -->
      <v-toolbar dark color="grey darken-3">
        <v-spacer></v-spacer>
        <v-btn :id="'closebtn_' + cardDetailInfo.tileId" icon dark @click="closeMobileCardDetail()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <!-- タブ -->
      <v-tabs v-model="tab" ref="tabs" color="info">
        <v-tab>{{ $t("TABNAME_CARD_INFO") }}</v-tab>
        <v-tab v-if="isValidJisseki">{{ $t("TABNAME_CARD_JISSEKI") }}</v-tab>
      </v-tabs>
      <div class="tabdiv">
        <v-tabs-items v-model="tab">
          <!-- カード情報 -->
          <v-tab-item>
            <MobileCardDetailInfo @close="closeMobileCardDetail()" />
          </v-tab-item>
          <!-- 実績情報 -->
          <v-tab-item>
            <MobileCardDetailJissekiInfo />
          </v-tab-item>
        </v-tabs-items>
      </div>

      
  </v-card>
  </v-dialog>
</template>

<script>
import MobileCardDetailInfo from "@/components/mobile/parts/MobileCardDetailInfo.vue";
import MobileCardDetailJissekiInfo from "@/components/mobile/parts/MobileCardDetailJissekiInfo.vue";
export default {
  components: {
    MobileCardDetailInfo,
    MobileCardDetailJissekiInfo
  },
  data() {
    return {
      tab: 0
    };
  },
  computed: {
    isOpenMobileCardDetail() {
      return this.$store.getters["tile/isOpenMobileCardDetail"];
    },
    cardDetailInfo() {
      return this.$store.getters["tile/cardDetailInfo"];
    },
    isUpdatingJisseki() {
      return this.$store.getters["isUpdatingJisseki"];
    },
    isDeletingJisseki() {
      return this.$store.getters["isDeletingJisseki"];
    },
    openDetailTileId() {
      return this.$store.getters["openDetailTileId"];
    },
    isValidJisseki() {
      return this.$store.getters["setting/getIsValidJisseki"];
    }
  },
  watch: {
    isOpenMobileCardDetail: {
      immediate: true,
      handler: function() {
        if (this.isOpenMobileCardDetail) {
          // タイル実績を取得しセット
          this.$store.dispatch("tile/jissekiGet", this.openDetailTileId);
          // タブリセット
          this.tab = 0;
        }
      }
    },
    isUpdatingJisseki: {
      immediate: true,
      handler: function() {
        if (!this.isUpdatingJisseki) {
          // タイル実績を取得しセット
          this.$store.dispatch("tile/jissekiGet", this.openDetailTileId);
        }
      }
    },
    isDeletingJisseki: {
      immediate: true,
      handler: function() {
        if (!this.isDeletingJisseki) {
          // タイル実績を取得しセット
          this.$store.dispatch("tile/jissekiGet", this.openDetailTileId);
        }
      }
    }
  },
  methods: {
    /**
     * ダイアログを閉じる
     */
    closeMobileCardDetail() {
      this.$store.commit("tile/setIsOpenMobileCardDetail", false);
      this.$store.dispatch("removeOpenDetailTileId");
    },
  },
};
</script>

<style scoped>
.tabdiv {
  margin-top: 5px;
  height: calc(100vh - 110px);
  overflow-y: auto;
}
</style>
