<template>
  <div>
    <v-tooltip v-if="isMonthView" bottom open-delay="300" color="rgba(60,64,67,0.95)" transition="fade">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <v-progress-linear
            v-model="progressData"
            height="20"
            :color="berColor"
            background-color="grey darken-1"
            class="progressbarliner"
            
          >
            {{ calcProgress() }}
          </v-progress-linear>
        </div>
      </template>
      <span>{{ sum }} / {{ max }}</span>
    </v-tooltip>
    <v-progress-linear
      v-else
      v-model="progressData"
      height="20"
      :color="berColor"
      background-color="grey darken-1"
      class="progressbarliner"
      
    >
      {{ calcProgress() }}
    </v-progress-linear>
  </div>
</template>

<script>
import Define from "@/define.js";
export default {
  props: {
    progress: {
      type: Number,
      default: 0
    },
    sum: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 0
    },
    isMonthView: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isNoSettings: Define.IS_NO_SETTINGS
    };
  },
  computed: {
    progressData: {
      get() {
        return this.progress;
      }
    },
    /**
     * 負荷の色にバインド、負荷値を比較して色を変更する
     * @param {型} 引数名 - 引数の説明
     * @returns {型} 戻り値名 - 戻り値の説明
     */
    berColor() {
      let color;
      if (this.progressData < Define.PROGRESS_LINE_ORANGE) {
        color = Define.PROGRESS_COLOR_GREEN;
      } else if (this.progressData < Define.PROGRESS_LINE_RED) {
        color = Define.PROGRESS_COLOR_ORANGE;
      } else {
        color = Define.PROGRESS_COLOR_RED;
      }
      return color;
    },
    loadDisplayType(){
      return this.$store.getters["setting/getLoadDisplayType"];
    }
  },
  methods: { 	
    calcProgress() {

      let result = this.progressData.toString() + "%"
      if(this.loadDisplayType == Define.LOAD_DISPLAY_PERCENT_DETAIL && !this.isMonthView){
        result += ` (${this.sum}/${this.max})`;
      }
      return result;
    }
  }
};
</script>

<style scoped>
.progressbarliner {
  color: #fff;
  border-top: solid 1px #bbb;
  pointer-events: none;
}
</style>
