import rootState from "./index";
import axios from "axios";
import Swal from "sweetalert2";
import router from "../router";
import * as Util from "@/util/utils.js";
import Define from "@/define.js";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    // ログイン状態
    isLoggedIn: false,
    // ユーザー情報
    user: {},
    // トークン
    token: "",
    // IsLoginLoadind
    isLoginLoading: false,
    // メールアドレス(自動入力用)
    email: "",
  },
  getters: {
    isLoggedIn: state => state.isLoggedIn,
    user: state => state.user,
    token: state => state.token,
    isLoginLoading: state => state.isLoginLoading,
    selectedWorkSpaceId: state => {
      if (state.user && state.user.workSpaceId) {
        return state.user.workSpaceId;
      } else {
        return null;
      }
    },
    // メールアドレス(自動入力用)を取得する
    email: state => state.email,
  },
  mutations: {
    initState(state) {
      state.isLoggedIn = false;
      state.user = {};
      state.token = "";
      state.isLoginLoading = false;

      // emailは自動入力用なので初期化しない
      // state.email = "";
    },
    loggedIn(state, token) {
      state.isLoggedIn = true;
      axios.defaults.headers.common["Authorization"] = `JWT ${token}`;
      state.token = token;
    },
    loggedOut() {
      // Authorizationヘッダー削除
      delete axios.defaults.headers.common["Authorization"];
      // webscoket切断
      Vue.prototype.$disconnect();
    },
    setUser(state, user) {
      state.user = user;
    },
    setToken(state, newToken) {
      state.token = newToken;
      axios.defaults.headers.common["Authorization"] = `JWT ${newToken}`;
    },
    setIsLoginLoading(state, isLoginLoading) {
      state.isLoginLoading = isLoginLoading;
    },
    /**
     * メールアドレス(自動入力用)を設定する
     * @param {Object} state - state
     * @param {Boolean} email - email
     */
    setEmail(state, email) {
      state.email = email;
    },
  },
  actions: {
    login({ commit }, loginInfo) {
      commit("setIsLoginLoading", true);
      Util.axiosPostReq(Define.API_AUTH_LOGIN, {
        email: loginInfo.email,
        password: loginInfo.password
      })
        .then(response => {
          // console.log(response);
          console.log(response.status); // ステータスコードのみ表示(秘匿情報は外部に出さない)
          commit("setIsLoginLoading", false);
          // モードを設定する
          commit("setMode", loginInfo.mode, { root: true });
          // ログイン状態を設定する
          commit("loggedIn", response.data.token);
          // ユーザー情報を設定する
          commit("setUser", response.data.user);
          // 自動入力用にメールアドレスを保存
          commit("setEmail", loginInfo.email);

          // 計画ボードページに遷移する
          if(rootState.state.mode == "0"){
            router.push("/");
          }else{
            router.push("/mobilejisseki");
          }
          
        })
        .catch(error => {
          console.log(error.message);
          commit("setIsLoginLoading", false);
          let res = error.response;
          if (res && res.data) {
            // アカウント有効化済ユーザーかどうか
            if (Util.isActivatedUser(res.data, res.status)) {
              Swal.fire({
                icon: "warning",
                title: "ログインエラー",
                text: "アカウントを有効化してください。"
              });
              // ログイン情報が正しいかどうか
            } else if (Util.isCorrectLoginInfo(res.data, res.status)) {
              Swal.fire({
                icon: "warning",
                title: "ログインエラー",
                text: "メールアドレスまたはパスワードが間違っています。"
              });
              // その他(異常系)
            } else {
              Swal.fire({
                icon: "warning",
                title: "ログインエラー",
                text: "ログインに失敗しました"
              });
            }
            // その他(異常系)
          } else {
            Swal.fire({
              icon: "warning",
              title: "ログインエラー",
              text: "ログインに失敗しました"
            });
          }
        });
    },
    logout({ commit, dispatch }) {
      commit("setIsLoading", true, { root: true });
      Util.axiosPostReq(Define.API_AUTH_LOGOUT, {})
        .then(response => {
          // console.log(response);
          console.log(response.status); // ステータスコードのみ表示(秘匿情報は外部に出さない)
          commit("setIsLoading", false, { root: true });
          // ログアウト状態を設定する
          commit("loggedOut");
          dispatch("initAllState", null, { root: true });
          // ログインページに遷移する
          router.push("/login");
        })
        .catch(error => {
          console.log(error.message);
          commit("setIsLoading", false, { root: true });
          // ログアウト状態を設定する
          commit("loggedOut");
          dispatch("initAllState", null, { root: true });
          // ログインページに遷移する
          router.push("/login");
        });
    },
    updateUserName({ commit }, userInfo) {
      commit("setIsLoading", true, { root: true });
      Util.axiosPatchReq(Define.API_AUTH_USER, {
        username: userInfo.username
      })
        .then(response => {
          // console.log(response);
          console.log(response.status); // ステータスコードのみ表示(秘匿情報は外部に出さない)
          commit("setIsLoading", false, { root: true });
          // ユーザー情報を設定する
          commit("setUser", response.data);
        })
        .catch(error => {
          console.log(error.message);
          commit("setIsLoading", false, { root: true });
          Swal.fire({
            icon: "warning",
            title: "Update Error",
            text: "Please try again."
          });
        });
    },
    updateUser({ commit }, param) {
      Util.axiosPatchReq(
        `${Define.API_USER_BY_TENANT_UPDATE}${param.user_id}/`,
        param.param
      )
        .then(response => {
          // console.log(response);
          console.log(response.status); // ステータスコードのみ表示(秘匿情報は外部に出さない)
          // ユーザー情報を設定する
          commit("setUser", response.data);
        })
        .catch(error => {
          console.log(error.message);
        });
    },
    refreshToken({ commit }, oldToken) {
      Util.axiosPostReq(Define.API_TOKEN_REFRESH, {
        token: oldToken
      })
        .then(response => {
          // console.log(response);
          console.log(response.status); // ステータスコードのみ表示(秘匿情報は外部に出さない)
          // 新しいトークンを設定する
          commit("setToken", response.data.token);
        })
        .catch(error => {
          console.log(error.message);
        });
    }
  }
};
