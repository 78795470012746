<template>
  <div>
    <!-- CardPattern01 -->
    <v-card v-if="pattern === pattern01" flat class="cardsumple" @click="selectCard(pattern01)">
      <div class="cardheight">
        <div class="item1">①</div>
      </div>
      <div class="holizontal_line"></div>
      <div class="cardheight flexarea">
        <div class="item2">②</div>
        <div class="vertical_line"></div>
        <div class="item2">③</div>
      </div>
      <div class="holizontal_line"></div>
      <div class="cardheight flexarea">
        <div class="item3">④</div>
        <div class="vertical_line"></div>
        <div class="item3">⑤</div>
        <div class="vertical_line"></div>
        <div class="item3">⑥</div>
      </div>
    </v-card>
    <!-- CardPattern02 -->
    <v-card v-if="pattern === pattern02" flat class="cardsumple" @click="selectCard(pattern02)">
      <div class="cardheight flexarea">
        <div class="item2">①</div>
        <div class="vertical_line"></div>
        <div class="item2">②</div>
      </div>
      <div class="holizontal_line"></div>
      <div class="cardheight flexarea">
        <div class="item2">③</div>
        <div class="vertical_line"></div>
        <div class="item2">④</div>
      </div>
      <div class="holizontal_line"></div>
      <div class="cardheight flexarea">
        <div class="item2">⑤</div>
        <div class="vertical_line"></div>
        <div class="item2">⑥</div>
      </div>
    </v-card>
    <!-- CardPattern03 -->
    <v-card v-if="pattern === pattern03" flat class="cardsumple" @click="selectCard(pattern03)">
      <div class="cardheight flexarea">
        <div class="item3">①</div>
        <div class="vertical_line"></div>
        <div class="item3">②</div>
        <div class="vertical_line"></div>
        <div class="item3">③</div>
      </div>
      <div class="holizontal_line"></div>
      <div class="cardheight flexarea">
        <div class="item3">④</div>
        <div class="vertical_line"></div>
        <div class="item3">⑤</div>
        <div class="vertical_line"></div>
        <div class="item3">⑥</div>
      </div>
      <div class="holizontal_line"></div>
      <div class="cardheight flexarea">
        <div class="item3">⑦</div>
        <div class="vertical_line"></div>
        <div class="item3">⑧</div>
        <div class="vertical_line"></div>
        <div class="item3">⑨</div>
      </div>
    </v-card>
    <!-- CardPattern04 -->
    <v-card v-if="pattern === pattern04" flat class="cardsumple" @click="selectCard(pattern04)">
      <div class="cardheight">
        <div class="item1">①</div>
      </div>
      <div class="holizontal_line"></div>
      <div class="cardheight flexarea">
        <div class="item1">②</div>
      </div>
      <div class="holizontal_line"></div>
      <div class="cardheight flexarea">
        <div class="item1">③</div>
      </div>
    </v-card>
  </div>
</template>

<script>
import Define from "@/define.js";
export default {
  props: {
    pattern: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      pattern01: Define.TILE_TEMP_0,
      pattern02: Define.TILE_TEMP_1,
      pattern03: Define.TILE_TEMP_2,
      pattern04: Define.TILE_TEMP_3
    };
  },
  methods: {
    selectCard(pattern) {
      this.$emit("select", pattern);
    }
  }
};
</script>

<style scoped>
.cardsumple {
  width: 310px;
  height: 168px;
  border: solid 4px #888;
  background-color: #fff9c4;
  margin-right: 60px;
}
.cardheight {
  height: 52px;
}
.item1 {
  width: 302px;
  text-align: center;
  padding-top: 14px;
}
.item2 {
  width: 150px;
  text-align: center;
  padding-top: 14px;
}
.item3 {
  width: 96px;
  text-align: center;
  padding-top: 14px;
}
.vertical_line {
  width: 0px;
  height: 52px;
  border-left: solid 2px #aaa;
}
.holizontal_line {
  width: 302px;
  height: 0px;
  border-top: solid 2px#aaa;
}
</style>
