<template>
  <div>
    <v-dialog v-model="dialog" width="800px" persistent>
      <v-card width="800px" class="mx-auto">
        <v-card-title>{{ $t("PAGE_TITLE_WORKSPACE_DELETE") }}</v-card-title>
        <v-card-text>{{ $t("TEXT_WORKSPACE_DELETE_ATTENSION") }}</v-card-text>
        <v-card-text style="color:#ff5252">
          {{ $t("TEXT_WORKSPACE_NOT_RESTORATION_ATTENSION") }}
        </v-card-text>
        <v-card-text> {{ $t("TEXT_WORKSPACE_NAME") }}{{ selectedWorkspace.name }} </v-card-text>
        <div class="area"></div>
        <v-card-actions class="justify-center">
          <v-btn width="25%" class="error" @click="submit">{{ $t("WORD_DELETE") }}</v-btn>
          <v-btn width="25%" class="" @click="closeDialog">{{ $t("WORD_CANCEL") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "WorkSpaceDeleteDialog",
  props: {
    dialog: Boolean
  },
  computed: {
    user() {
      return this.$store.getters["auth/user"];
    },
    selectedWorkspaceId() {
      return this.$store.getters["auth/selectedWorkSpaceId"];
    },
    selectedWorkspace() {
      return this.$store.getters["workspace/selectedWorkspace"];
    },
    displayDaysDiff() {
      return this.$store.getters["display/displayDaysDiff"];
    },
    displayFirstDay() {
      return this.$store.getters["display/displayFirstDay"];
    }
  },
  methods: {
    /**
     * 入力情報をAPIに伝搬する
     */
    submit() {
      let isSelected =
        this.selectedWorkspaceId === this.selectedWorkspace.workSpaceId;
      this.$store
        .dispatch(`workspace/updateWorkspace`, {
          work_space_id: this.selectedWorkspace.workSpaceId,
          param: { is_valid: false }
        })
        .then(() => {
          // 選択中のボードを削除した場合は「メインボード」で初期化する
          if (isSelected) {
            this.$store
              .dispatch("auth/updateUser", {
                user_id: this.user.userId,
                param: { work_space_id: "" }
              })
              .then(() => {
                this.$store.dispatch("initialData", {
                  workSpaceId: null,
                  daysDiff: this.displayDaysDiff,
                  displayFirstDay: this.displayFirstDay
                });
              });
          }
          this.closeDialog();
        });
    },
    /**
     * クローズイベントを親部品に通知する
     */
    closeDialog() {
      this.$emit("close");
    }
  }
};
</script>

<style scoped>
.before {
  width: 400px;
}
.after {
  width: 400px;
}
.area {
  display: flex;
}
</style>
