<template>
  <div class="linelistprogress">
    <div v-if="arealist.lineName !== null" class="linecolor">
      <v-tooltip
        left
        open-delay="300"
        color="rgba(60,64,67,0.95)"
        transition="fade"
      >
        <template v-slot:activator="{ on: disp }">
          <div class="linename" @click="changeRowOpen" v-on="disp">
            {{ orthopedyName(arealist.lineName, isRowOpen) }}
          </div>
        </template>
        <div>
          <div v-for="(tooltipText, index) in tooltipTextArray" :key="index">
            {{ tooltipText }}
          </div>
        </div>
      </v-tooltip>
      <div v-if="arealist.progressbarFlg && isRowOpen" class="progress">{{ $t("WORD_LOAD") }}</div>
    </div>
    <ListProgressbar
      v-for="(datelist, i) in arealist.tileList"
      :key="datelist.dateInfo"
      :lineid="arealist.lineId"
      :enabled="enabled"
      :cardtempnum="cardtempnum"
      :tilelist="datelist"
      :keyword="keyword"
      :progressbarflg="arealist.progressbarFlg"
      :is-row-open="isRowOpen"
      :is-col-open="isColOpens[i]"
    ></ListProgressbar>
  </div>
</template>

<script>
import ListProgressbar from "@/components/parts/ListProgressbar.vue";
import Define from "@/define.js";
import * as Util from "@/util/utils.js";
export default {
  components: {
    ListProgressbar
  },
  props: {
    enabled: {
      type: Boolean,
      default: true
    },
    arealist: {
      type: Object,
      default: () => ({
        lineId: "",
        lineName: "",
        tileList: []
      })
    },
    cardtempnum: {
      type: Number,
      default: 0
    },
    keyword: {
      type: Object,
      default: () => {}
    },
    isColOpens: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isRowOpen: true
    };
  },
  computed: {
    selectedWorkspaceId() {
      return this.$store.getters["auth/selectedWorkSpaceId"];
    },
    displayRow() {
      return this.$store.getters["display/displayRow"];
    },
    tooltipTextArray() {
      return Util.splitStringBySpace(this.arealist.lineName);
    }
  },
  watch: {
    selectedWorkspaceId: {
      handler: function(value) {
        this.isRowOpen = Util.getIsOpen(
          this.displayRow,
          value,
          this.arealist.lineId
        );
      }
    },
    displayRow: {
      immediate: true,
      deep: true,
      handler: function(value) {
        this.isRowOpen = Util.getIsOpen(
          value,
          this.selectedWorkspaceId,
          this.arealist.lineId
        );
      }
    }
  },
  methods: {
    /**
     * 行タイトルを表示文字数上限で切り、それ以降を「...」とする
     * @param {String} getText - 行タイトル
     * @param {Boolean} isRowOpen - 行の折りたたみ状態
     * @returns {String} - 整形後行タイトル
     */
    orthopedyName(getText, isRowOpen) {
      let maxLineName = isRowOpen
        ? Define.MAX_LINE_NAME
        : Define.MAX_LINE_NAME_CLOSE;
      return Util.checkNotUndefined(getText)
        ? Util.orthopedyText(getText, maxLineName)
        : "";
    },
    /**
     * 計画エリア分類の折りたたみ状態を変更する
     */
    changeRowOpen() {
      let value = {
        boardId: this.selectedWorkspaceId,
        areaId: this.arealist.lineId
      };
      this.$store.commit("display/changeRowOpen", value);
    }
  }
};
</script>

<style scoped>
.linelistprogress {
  display: flex;
  height: auto;
  margin-top: 3px;
  margin-bottom: 3px;
  background-color: #fff;
}
.linename {
  white-space: normal;
  padding: 2px;
  min-width: 80px;
  width: 80px;
  height: calc(100% - 21px);
  color: #fff;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}
.progress {
  border-top: solid 1px #bbb;
  color: #fff;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
}
.linecolor {
  background-color: #1976d2ca;
}
</style>
