var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { attrs: { outlined: "" } },
        [
          _c(
            "v-list",
            _vm._l(_vm.cardDetailInfo.tileDetailData, function(detail) {
              return _c(
                "v-list-item",
                { key: detail.mappingId, attrs: { "three-line": "" } },
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c(
                        "v-list-item-title",
                        { staticClass: "font-weight-bold" },
                        [_vm._v(_vm._s(_vm.mappingName(detail.mappingId)))]
                      ),
                      _c(
                        "v-list-item-subtitle",
                        { staticClass: "text-right" },
                        [_vm._v(_vm._s(detail.value))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }