import { render, staticRenderFns } from "./MobileCardDetailJissekiInfo.vue?vue&type=template&id=2b979ae3&scoped=true&"
import script from "./MobileCardDetailJissekiInfo.vue?vue&type=script&lang=js&"
export * from "./MobileCardDetailJissekiInfo.vue?vue&type=script&lang=js&"
import style0 from "./MobileCardDetailJissekiInfo.vue?vue&type=style&index=0&id=2b979ae3&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b979ae3",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
installComponents(component, {VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VExpansionPanels})


/* hot reload */
if (module.hot) {
  var api = require("C:\\Freely\\repo_nck_plan_board\\nck_plan_board_frontend\\vue\\code\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2b979ae3')) {
      api.createRecord('2b979ae3', component.options)
    } else {
      api.reload('2b979ae3', component.options)
    }
    module.hot.accept("./MobileCardDetailJissekiInfo.vue?vue&type=template&id=2b979ae3&scoped=true&", function () {
      api.rerender('2b979ae3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/mobile/parts/MobileCardDetailJissekiInfo.vue"
export default component.exports