<template>
  <v-form ref="form" v-model="valid" class="pagestemplate">
    <div :style="basePageStyle">
      <PageTitle
        :titles="pagetitle.title"
        :descriptions="pagetitle.description"
      ></PageTitle>

      <!-- カード抽出条件 -->
      <div :style="dataAreaStyle">
        <div :style="areaTitleStyle">
          {{ $t("PAGE_SUBTITLE_OUTPUT_FILE_SETTING") }}
          <HelpButton :helpobject="csvExportConditionHelpDialogData"></HelpButton>
        </div>
        <v-row class="d-flex flex-row pl-10" align="center">
          <span class="pr-5">{{ $t("PAGE_SUBTITLE_OUTPUT_FILE_SETTING_PERIOD") }}</span>
          <div :style="datePickerStyle">
            <EditingItem
              v-model="fromDate"
              :type="TYPE_DATE"
              :rules="[]"
              @input="fromDateInput"
            ></EditingItem>
          </div>
          <span class="px-5">～</span>
          <div :style="datePickerStyle">
            <EditingItem
              ref="endDate"
              v-model="endDate"
              :type="TYPE_DATE"
              :rules="[isSameOrBeforeFromDate]"
            ></EditingItem>
          </div>
        </v-row>
        <div :style="extractUnplannedStyle" class="pl-7">
          <v-switch
            v-model="extractUnplanned"
            :label="$t('WORD_OUTPUT_FILE_EXTRACT_UNPLANNED')"
            :color="toggleColor"
          ></v-switch>
        </div>
      </div>

      <!-- データ出力順 -->
      <div :style="dataAreaStyle">
        <div :style="areaTitleStyle">
          {{ $t("PAGE_SUBTITLE_OUTPUT_FILE_SORT") }}
          <HelpButton :helpobject="csvExportSortHelpDialogData"></HelpButton>
        </div>
        <v-radio-group v-model="sortStyle">
          <v-radio
            value="plannedSort"
            :label="$t('WORD_OUTPUT_FILE_SORT_PLANNED_AREA')"
          ></v-radio>
          <v-radio
            value="dateSort"
            :label="$t('WORD_OUTPUT_FILE_SORT_DATE')"
          ></v-radio>
       </v-radio-group>
      </div>

      <!-- ファイル名 -->
      <div :style="dataAreaStyle">
        <div :style="areaTitleStyle">
          {{ $t("PAGE_SUBTITLE_OUTPUT_FILE_NAME") }}
        </div>
        <div :style="fileNameStyle" class="pl-5">
          <EditingItem
            v-model="fileName"
            :type="TYPE_STRING"
            :rules="[check_required]"
          ></EditingItem>
        </div>
      </div>

      <!-- ダウンロードボタン -->
      <div :style="dataAreaStyle">
        <v-btn :style="downloadBtnStyle" :disabled="downloading || !authFileOutput" class="info" @click="download">
          {{ downloadBtnTitle }}
        </v-btn>
      </div>
    </div>
  </v-form>
</template>

<script>
import moment from "moment";
import Swal from "sweetalert2";
import PageTitle from "@/components/parts/PageTitle";
import HelpButton from "@/components/parts/HelpButton";
import Define from "@/define.js";
import EditingItem from "@/components/parts/EditingItem";
import * as Util from "@/util/utils.js";
import { mixinUtil } from "@/mixins/mixinUtils.js";
import { mixinError } from "@/mixins/mixinError.js";
import { mixinValidator } from "@/mixins/mixinValidator.js";
export default {
  name: 'OutputFilePage',
  components: {
    PageTitle,
    HelpButton,
    EditingItem
  },
  mixins: [mixinUtil, mixinError, mixinValidator],
  data() {
    return {
      valid: true,
      downloading: false,

      basePageStyle: Define.STYLE_BASE_PAGE,
      dataAreaStyle: Define.STYLE_DATA_AREA,
      areaTitleStyle: Define.STYLE_AREA_TITLE,
      datePickerStyle: Define.STYLE_DATE_PICKER,
      extractUnplannedStyle: { "width": "200px" },
      fileNameStyle: { "width": "600px" },
      downloadBtnStyle: Define.STYLE_BAGE_BTN,
      toggleColor: Define.TOGGLE_COLOR,

      TYPE_DATE: Define.TYPE_DATE,
      TYPE_STRING: Define.TYPE_STRING,
      check_required: value => this.isValidRequired(value),

      fromDate: "",
      endDate: "",
      extractUnplanned: true,
      sortStyle: "plannedSort",
      fileName: ""
    }
  },
  computed: {
    downloadBtnTitle() {
      return this.downloading ? this.$t("WORD_OUTPUT_FILE_OUTPUT_RUNNING") : this.$t("WORD_OUTPUT_FILE_OUTPUT_START");
    },
    selectedWorkspaceId() {
      return this.$store.getters["auth/selectedWorkSpaceId"];
    },
    authFileOutput() {
      let user = this.$store.getters["auth/user"];
      return Util.getAuthority(Define.EVENT_FILE_OUTPUT, user.role);
    },
    pagetitle() {
      return this.getPageTitleDescriptionObj(Define.PAGE_CODE_EXPORT);
    },
    csvExportConditionHelpDialogData() {
      return this.$t("EXPLANATION_DIALOG.csvExportConditionHelpDialogData");
    },
    csvExportSortHelpDialogData() {
      return this.$t("EXPLANATION_DIALOG.csvExportSortHelpDialogData");
    }
  },
  watch: {
  },
  beforeMount() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.fileName = `freely_${moment().format(Define.DATE_FORMAT_DAY_NOT_SEPARATE)}.csv`;
    },
    isSameOrBeforeFromDate(val) {
      if (!val) return true;

      const criteria = moment(val, Define.DATE_FORMAT_DAY_SLASH);
      let comparison = moment("2000-01-01");
      if  (this.fromDate) {
        comparison = moment(this.fromDate, Define.DATE_FORMAT_DAY_SLASH);
      }
      return criteria.isSameOrAfter(comparison) || this.$t("MSG_FROM_END_DATE_INVALID");
    },
    fromDateInput() {
      if (this.$refs.endDate) this.$refs.endDate.validate(); // 描画されていないことを考慮
    },
    checkInput() {
      let errCode = Define.SUCCESS;
      if (!this.fromDate && !this.endDate) {
        errCode = Define.ERR_EXTRACTION_RANGE_FROM_END_NOT_SET;
      } else if (!this.$refs.form.validate()) {
        errCode = Define.ERR_INPUT_ITEM_INVALID;
      }
      return errCode;
    },
    download() {
      const errorCode = this.checkInput();
      if (errorCode !== Define.SUCCESS) {
        const messageObj = this._getErrorMessage(errorCode);
        Swal.fire({
          title: messageObj.mainText,
          html: messageObj.subText,
          icon: "error"
        });
        return;
      }

      let query = `extractUnplanned=${this.extractUnplanned}`;
      query += `&sortStyle=${this.sortStyle}`;
      if (this.fromDate) {
        query += `&fromDate=${moment(this.fromDate, Define.DATE_FORMAT_DAY_SLASH).format(Define.DATE_FORMAT_DAY_HYPHEN)}`;
      }
      if (this.endDate) {
        query += `&endDate=${moment(this.endDate, Define.DATE_FORMAT_DAY_SLASH).format(Define.DATE_FORMAT_DAY_HYPHEN)}`;
      }
      const workSpaceId = this.selectedWorkspaceId;
      query += `&work_space_id=${workSpaceId}`;
      query += `&locale=${this.$i18n.locale}`;
      this.downloading = true;
      Util.axiosGetReq(`${Define.API_EXPORT}?${query}`, { responseType: 'arraybuffer' })
        .then(response => {
          var blob = new Blob([ response.data ], { "type": "text/plain; charset=Shift-JIS" });
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = this.fileName;
          link.click();
          this.downloading = false;
        })
        .catch(error => {
          console.log(error);
          this.downloading = false;
        });
      Swal.fire({
        title: this.$t("MSG_MAIN_FILE_EXPORT_START"),
        html: this.$t("MSG_SUB_FILE_EXPORT_START"),
        icon: "info",
        width: '700px'
      });
    },
  }
};
</script>
