/**
 * バリデーター - * - 入力必須
 * @param {String} value - 入力文字列
 * @param {String} isShortMsg - 短縮文字にするかどうか
 * @returns {Boolean || String} - 結果
 */
export function isValidRequired(value, isShortMsg = false) {
  // eslint-disable-next-line prettier/prettier
  let msg = isShortMsg ? "入力必須" : "必ず入力してください。";

  return !!value || msg;
}

/**
 * バリデーター - 文字数
 * @param {String} value - 入力文字列
 * @param {Number} minLength - 入力文字最小数、未使用時はnull
 * @param {Number} maxLength - 入力文字最大数、未使用時はnull
 * @param {String} isShortMsg - 短縮文字にするかどうか
 * @returns {Boolean || String} - 結果
 */
export function isValidLength(value, minLength, maxLength, isShortMsg = false) {
  // eslint-disable-next-line prettier/prettier
  let msgMax = isShortMsg ? `最大${maxLength}文字` : `最大${maxLength}文字で入力してください。`;
  // eslint-disable-next-line prettier/prettier
  let msgMin = isShortMsg ? `最小${minLength}文字` : `最小${minLength}文字で入力してください。`;
  // eslint-disable-next-line prettier/prettier
  let msgMinMax = isShortMsg ? `最小${minLength}文字、最大${maxLength}文字` : `最小${minLength}文字、最大${maxLength}文字で入力してください。`;
  // eslint-disable-next-line prettier/prettier
  let msgEqual = isShortMsg ? `${maxLength}文字` : `${maxLength}文字で入力してください。`;

  if (value === undefined || value === null) {
    return true;
  } else {
    if (!minLength) {
      return value.length <= maxLength || msgMax;
    } else if (!maxLength) {
      return value.length >= minLength || msgMin;
    } else {
      return (
        (minLength <= value.length && value.length <= maxLength) ||
        (minLength !== maxLength ? msgMinMax : msgEqual)
      );
    }
  }
}

/**
 * バリデーター - Email - 形式
 * @param {String} value - 入力文字列
 * @returns {Boolean || String} - 結果
 */
export function isValidEmailFormat(value) {
  if (value === undefined || value === null) {
    return true;
  } else {
    const regexp = /^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return regexp.test(value) || "メールアドレス形式で入力してください。";
  }
}

/**
 * バリデーター - パスワード - 形式
 * @param {String} value - 入力文字列
 * @returns {Boolean || String} - 結果
 */
export function isValidPasswordLFormat(value) {
  if (value === undefined || value === null) {
    return true;
  } else {
    const regexp = /^(?=.*?[a-z])(?=.*?\d)[a-z\d!-/:-@[-`{-~]{8,}$/i;
    return regexp.test(value) || "半角英数混合8文字以上で入力してください。(記号可)";
  }
}

/**
 * バリデーター - 電話番号 - 形式
 * @param {String} value - 入力文字列
 * @returns {Boolean || String} - 結果
 */
export function isValidPhoneFormat(value) {
  if (value === undefined || value === null) {
    return true;
  } else {
    const regexp = /^[0-9]+-[0-9]+-[0-9]+$/;
    return regexp.test(value) || "電話番号形式で入力してください。(ハイフンあり)";
  }
}

/**
 * バリデーター - URL(https始まり) - 形式
 * @param {String} value - 入力文字列
 * @returns {Boolean || String} - 結果
 */
export function isValidUrl(value) {
  if (value === undefined || value === null) {
    return true;
  } else {
    const regexp = /^https.*/;
    return regexp.test(value);
  }
}