<template>
  <div>
    <v-dialog v-model="dialog" width="800px" persistent>
      <v-card width="800px" class="mx-auto">
        <v-card-title>{{ $t("PAGE_TITLE_USER_DELETE") }}</v-card-title>
        <v-card-text>{{ $t("TEXT_USER_DELETE_ATTENSION") }}</v-card-text>
        <v-card-text>{{ $t("TEXT_USER_CHANGE_NAME") }}{{ selectedUser.username }}</v-card-text>
        <v-card-text>{{ $t("TEXT_USER_CHANGE_MAILADDRESS") }}{{ selectedUser.email }}</v-card-text>
        <div class="area"></div>
        <v-card-actions class="justify-center">
          <v-btn width="25%" class="error" @click="submit">{{ $t("WORD_DELETE") }}</v-btn>
          <v-btn width="25%" class="" @click="closeDialog">{{ $t("WORD_CANCEL") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Swal from "sweetalert2";
export default {
  name: "UserDeleteDialog",
  props: {
    dialog: Boolean
  },
  data: function() {
    return {};
  },
  computed: {
    manager() {
      return this.$store.getters["user/manager"];
    },
    selectedUser() {
      return this.$store.getters["user/selectedUser"];
    },
    user() {
      return this.$store.getters["auth/user"];
    }
  },
  methods: {
    /**
     * 入力情報をAPIに伝搬する
     */
    submit() {
      if (this.isSelf(this.selectedUser, this.user)) {
        Swal.fire({
          icon: "error",
          title: this.$t("MSG_ERR_MAIN_DELETE"),
          text: this.$t("MSG_ERR_SUB_DELETE_LOGIN")
        });
        this.closeDialog();
      } else if (this.isLastManager(this.selectedUser, this.manager)) {
        this.closeDialog();
        Swal.fire({
          icon: "error",
          title: this.$t("MSG_ERR_MAIN_DELETE"),
          text: this.$t("MSG_ERR_SUB_DELETE_MANAGE")
        });
        this.closeDialog();
      } else {
        this.$store
          .dispatch("user/deleteUser", {
            userId: this.selectedUser.userId
          })
          .then(() => {
            this.closeDialog();
          });
      }
    },
    /**
     * クローズイベントを親部品に通知する
     */
    closeDialog() {
      this.$emit("close");
    },
    /**
     * 選択行のユーザーが自身かどうか判定する
     * @param {Object} selectedUser - 選択行のユーザー
     * @param {Object} user - 自身
     * @return {Object} - 判定結果
     */
    isSelf(selectedUser, user) {
      return selectedUser.userId === user.userId;
    },
    /**
     * 選択行のユーザーが最後の管理者かどうか判定する
     * @param {Object} selectedUser - 選択行のユーザー
     * @param {Array} managers - 管理者配列
     * @return {Object} - 判定結果
     */
    isLastManager(selectedUser, managers) {
      return selectedUser.role === 0 && managers.length < 2;
    }
  }
};
</script>

<style scoped>
.before {
  width: 400px;
}
.after {
  width: 400px;
}
.area {
  display: flex;
}
</style>
