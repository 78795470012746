var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "subtitle" }, [
        _vm._v(_vm._s(_vm.$t("PAGE_SUBTITLE_CARD_JISSEKI_SUMMARY")))
      ]),
      _c(
        "v-row",
        { staticClass: "mx-1", attrs: { dense: "" } },
        _vm._l(_vm.jissekiCalcMappings, function(item) {
          return _c(
            "v-col",
            { key: item.mappingId, attrs: { cols: "2" } },
            [
              _c("CardDetailJissekiSummaryCard", {
                attrs: {
                  title: item.name,
                  yotei: _vm.yotei(item.mappingId),
                  jisseki: _vm.cardJissekiSum(item.mappingId)
                }
              })
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }