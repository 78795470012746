var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("CardDetail"),
      _c("CardDetailMultipleCopy"),
      _c("UserChangeDialog", {
        attrs: { dialog: _vm.isUserChange },
        on: { close: _vm.callbackUserChangeDialogClose }
      }),
      _c("PasswordChangeDialog", {
        attrs: { dialog: _vm.isPasswordChange },
        on: { close: _vm.callbackPasswordChangeDialogClose }
      }),
      _c(
        "v-navigation-drawer",
        {
          attrs: { app: "", temporary: "", clipped: "" },
          model: {
            value: _vm.drawer,
            callback: function($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer"
          }
        },
        [
          _c(
            "v-container",
            [
              _c(
                "v-list",
                { attrs: { dense: "", nav: "" } },
                [
                  _vm._l(_vm.menuItems, function(menuItem) {
                    return _c(
                      "v-list-item",
                      {
                        key: menuItem.id,
                        attrs: { to: menuItem.to, disabled: menuItem.disabled },
                        on: {
                          click: function($event) {
                            _vm.drawer = false
                          }
                        }
                      },
                      [
                        _c(
                          "v-list-item-icon",
                          [_c("v-icon", [_vm._v(_vm._s(menuItem.icon))])],
                          1
                        ),
                        _c(
                          "v-list-item-content",
                          [
                            _c("v-list-item-title", [
                              _vm._v(_vm._s(menuItem.name))
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  !_vm.isModePc
                    ? _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              return _vm.logout()
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [_c("v-icon", [_vm._v("mdi-exit-to-app")])],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(_vm._s(_vm.$t("MENU_LOGOUT")))
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                2
              ),
              _c("div", { staticClass: "version" }, [
                _vm._v("Version：" + _vm._s(_vm.version))
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-app-bar",
        {
          attrs: {
            color: "grey darken-3",
            dark: "",
            app: "",
            "clipped-left": "",
            height: "50"
          }
        },
        [
          _c(
            "v-tooltip",
            {
              attrs: {
                bottom: "",
                "open-delay": "300",
                color: "rgba(60,64,67,0.95)",
                transition: "fade"
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var mainmenu = ref.on
                    return [
                      _c(
                        "v-app-bar-nav-icon",
                        _vm._g(
                          {
                            on: {
                              click: function($event) {
                                _vm.drawer = !_vm.drawer
                              }
                            }
                          },
                          mainmenu
                        )
                      )
                    ]
                  }
                }
              ])
            },
            [_c("span", [_vm._v(_vm._s(_vm.$t("TOOLTIP_MAINMENU")))])]
          ),
          _c(
            "router-link",
            { attrs: { to: _vm.toPage } },
            [
              _c("v-img", {
                attrs: {
                  src: require("@/assets/Freely_side.png"),
                  "max-height": "30",
                  "max-width": "72"
                }
              })
            ],
            1
          ),
          _vm.isModePc
            ? _c("span", { staticClass: "board" }, [_c("SelectBoard")], 1)
            : _vm._e(),
          _c("v-spacer"),
          _vm.isModePc
            ? _c(
                "v-toolbar-items",
                [
                  _vm.tenant.plan === _vm.planTrial
                    ? _c("v-toolbar-title", { staticClass: "pt-2" }, [
                        _vm._v(_vm._s(_vm.printTrialDate()))
                      ])
                    : _vm._e(),
                  _vm.tenant.plan === _vm.planTrial &&
                  _vm.user.role === _vm.roleManager
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "planbtn",
                          attrs: {
                            text: "",
                            href: _vm.agreementUrl,
                            target: "_blank"
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("WORD_CONTACT")) + " ")]
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm.isModePc
            ? _c(
                "span",
                { staticClass: "languageboard" },
                [_c("SelectLunguage")],
                1
              )
            : _vm._e(),
          _vm.isModePc
            ? _c(
                "v-toolbar-items",
                [
                  _c("SelectFontSize", { staticClass: "mt-1" }),
                  _c(
                    "v-tooltip",
                    {
                      attrs: {
                        bottom: "",
                        "open-delay": "300",
                        color: "rgba(60,64,67,0.95)",
                        transition: "fade"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var help = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      attrs: {
                                        text: "",
                                        href: _vm.manualUrl,
                                        target: "_blank"
                                      }
                                    },
                                    help
                                  ),
                                  [
                                    _c("v-icon", [
                                      _vm._v("mdi-help-circle-outline")
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1231208773
                      )
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.$t("TOOLTIP_MANUAL")))])]
                  ),
                  _vm.authAccessManagement
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: {
                            bottom: "",
                            "open-delay": "300",
                            color: "rgba(60,64,67,0.95)",
                            transition: "fade"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var management = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          attrs: {
                                            text: "",
                                            to: "/management",
                                            disabled:
                                              _vm.tenant.plan ===
                                                _vm.planTrial &&
                                              _vm.tenant.trialEndFlg
                                          }
                                        },
                                        management
                                      ),
                                      [
                                        _c("v-icon", [
                                          _vm._v("mdi-cog-outline")
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3292705924
                          )
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("TOOLTIP_MANAGEMENT")))
                          ])
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "v-menu",
                    {
                      attrs: { "offset-y": "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var menu = ref.on
                              return [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: {
                                      bottom: "",
                                      "open-delay": "300",
                                      color: "rgba(60,64,67,0.95)",
                                      transition: "fade"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var tooltip = ref.on
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  { attrs: { text: "" } },
                                                  Object.assign(
                                                    {},
                                                    tooltip,
                                                    menu
                                                  )
                                                ),
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-account")
                                                  ])
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _c("div", [
                                      _c("div", [
                                        _vm._v(_vm._s(_vm.getRoleName))
                                      ]),
                                      _c("div", { staticClass: "user_name" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.user.username || _vm.wordNotSet
                                          )
                                        )
                                      ]),
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.user.email || _vm.wordNotSet
                                          )
                                        )
                                      ])
                                    ])
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2143468816
                      )
                    },
                    [
                      _c(
                        "v-list",
                        [
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function($event) {
                                  _vm.isUserChange = true
                                }
                              }
                            },
                            [
                              _c(
                                "v-list-item-icon",
                                [_c("v-icon", [_vm._v("mdi-account-circle")])],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(_vm.$t("MENU_USER_UPDATE")))
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              attrs: {
                                disabled:
                                  _vm.tenant.plan === _vm.planTrial &&
                                  _vm.tenant.trialEndFlg
                              },
                              on: {
                                click: function($event) {
                                  _vm.isPasswordChange = true
                                }
                              }
                            },
                            [
                              _c(
                                "v-list-item-icon",
                                [_c("v-icon", [_vm._v("mdi-lock-outline")])],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      _vm._s(_vm.$t("MENU_PASSWORD_UPDATE"))
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.logout()
                                }
                              }
                            },
                            [
                              _c(
                                "v-list-item-icon",
                                [_c("v-icon", [_vm._v("mdi-exit-to-app")])],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(_vm.$t("MENU_LOGOUT")))
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-main",
        { staticClass: "content" },
        [
          _c(
            "v-fade-transition",
            { attrs: { mode: "out-in" } },
            [_c("router-view")],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }