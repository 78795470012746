var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "templatepage" },
    [
      _c(
        "v-card",
        {
          staticClass: "templatecard mx-auto mt-16",
          attrs: { outlined: "", tile: "", color: "rgb(0, 0, 0, 0)" }
        },
        [
          _c(
            "div",
            { staticClass: "templatetitle" },
            [
              _c(
                "v-layout",
                { staticClass: "justify-center" },
                [
                  _c("v-img", {
                    attrs: {
                      src: require("@/assets/Freely_squair.png"),
                      "max-height": "250",
                      "max-width": "250"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-card-actions",
                { staticClass: "justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "templatebtn",
                      attrs: { height: "50", color: "success" },
                      on: { click: _vm.submit }
                    },
                    [_vm._v(" アカウントを有効化する ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }