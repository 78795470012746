var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { attrs: { outlined: "" } },
        [
          _c(
            "v-list",
            _vm._l(_vm.tileInfo.tileDetailData, function(detail) {
              return _c(
                "v-list-item",
                { key: detail.mappingId, attrs: { "three-line": "" } },
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c(
                        "v-list-item-title",
                        { staticClass: "font-weight-bold" },
                        [_vm._v(_vm._s(_vm.mappingName(detail.mappingId)))]
                      ),
                      _c(
                        "v-list-item-subtitle",
                        { staticClass: "text-right" },
                        [_vm._v(_vm._s(detail.value))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _c("v-select", {
        staticClass: "mt-2",
        attrs: {
          dense: "",
          outlined: "",
          "hide-details": "auto",
          items: _vm.colors,
          "item-value": "color",
          "item-text": "label",
          rules: [],
          label: _vm.colorTitle,
          disabled: _vm.tileInfo.isComplete
        },
        on: { change: _vm.updateCard },
        scopedSlots: _vm._u([
          {
            key: "selection",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("div", { style: _vm.colorBoxlStyle(item.color) }),
                _c("span", { staticClass: "pl-2" }, [
                  _vm._v(_vm._s(item.label))
                ])
              ]
            }
          },
          {
            key: "item",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("div", { style: _vm.colorBoxlStyle(item.color) }),
                _c("span", { staticClass: "pl-2" }, [
                  _vm._v(_vm._s(item.label))
                ])
              ]
            }
          }
        ]),
        model: {
          value: _vm.tileInfo.color,
          callback: function($$v) {
            _vm.$set(_vm.tileInfo, "color", $$v)
          },
          expression: "tileInfo.color"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }