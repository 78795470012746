<template>
  <v-select
    v-model="selectedWorkspace"
    dense
    outlined
    hide-details
    :items="workspacesSelectList"
  ></v-select>
</template>

<script>
import * as Util from "@/util/utils.js";
import Define from "@/define.js";
export default {
  computed: {
    user() {
      return this.$store.getters["auth/user"];
    },
    displayCol() {
      return this.$store.getters["display/displayCol"];
    },
    displayFirstDay() {
      return this.$store.getters["display/displayFirstDay"];
    },
    selectedWorkspace: {
      set(id) {
        this.$store.commit("setIsLoading", true);
        this.$store.dispatch("auth/updateUser", {user_id: this.user.userId, param: { work_space_id: id === null ? "" : id }})
          .then(() => {
            let isColOpen = Util.getIsOpen(this.displayCol, id, null);
            let days = isColOpen ? Define.DISP_DAYS_7 : Define.DISP_DAYS_10;
            this.$store.commit("display/setDisplayDays", days);
            this.$store.dispatch("initialData", {workSpaceId: id, daysDiff: days - 1, displayFirstDay: this.displayFirstDay});
          });
      },
      get() {
        return this.$store.getters["auth/selectedWorkSpaceId"];
      }
    },
    workspacesSelectList() {
      let workspacesList = this.$store.getters["workspace/workspacesSelectList"];
      workspacesList[0].text = this.$t("WORD_MAINBOARD");
      return workspacesList;
    },
  }
};
</script>