<template>
  <div class="pagestemplate planboard">
    <div v-if="isInitDataLoaded && !isExistSetting" style="width: 100%;">
      <v-alert tile type="warning">
        {{ $t("PAGE_INFO_SETTING") }}
      </v-alert>
    </div>
    <div v-if="isExistSetting">
      <FreeArea
        :enabled="enabled"
        :lineslists="lineslists"
        :freetilelist="getTiles.freeTileList"
        :cardtempnum="getTiles.cardTempNum"
      ></FreeArea>
    </div>
    <div v-if="isExistSetting">
      <PlanArea
        :enabled="enabled"
        :lineslists="lineslists"
        :plantilelist="getTiles.planTileList"
        :cardtempnum="getTiles.cardTempNum"
        @catchDate="searchData($event)"
      ></PlanArea>
    </div>
  </div>
</template>

<script>
import FreeArea from "@/components/areas/FreeArea.vue";
import PlanArea from "@/components/areas/PlanArea.vue";
import Define from "@/define.js";
import * as Util from "@/util/utils.js";
import { mapState } from "vuex";
import moment from "moment";
export default {
  components: {
    FreeArea,
    PlanArea
  },
  data() {
    return {
      date: null,
      startDate: "",
      endDate: ""
    };
  },
  computed: {
    ...mapState(["setting/enabled"]),
    ...mapState(["freedatelists"]),
    ...mapState(["lineslists"]),
    getTiles: {
      get() {
        let result = {};
        if (
          Util.checkNotEmpty(this.startDate) &&
          Util.checkNotEmpty(this.endDate)
        ) {
          result = this.$store.getters["tile/getTileData"](
            this.startDate,
            this.endDate,
            this.displayDays
          );
          result.planTileList.tileList[0].lineName = this.$t("WORD_NOTSET")
        }
        return result;
      }
    },
    enabled: {
      get() {
        let user = this.$store.getters["auth/user"];
        if (user.role === Define.USER_ROLE_PRACTITIONER) {
          return false;
        } else {
          return this.$store.getters["setting/getEnabled"];
        }
      }
    },
    displayDays() {
      return this.$store.getters["display/displayDays"];
    },
    displayDaysDiff() {
      return this.$store.getters["display/displayDaysDiff"];
    },
    isExistSetting() {
        return this.$store.getters["setting/isExistSetting"];
    },
    isInitDataLoaded() {
      return this.$store.getters["isInitDataLoaded"];
    },
  },
  watch: {
    displayDaysDiff: function(value) {
      this.setDateRange(value);
    }
  },
  methods: {
    /**
     * 日付変更イベントをPlanArea.vueから取得
     * @param {String} date - 一日目の日付(YYYY-MM-DD)
     */
    searchData(date) {
      this.date = date;
      this.setDateRange(this.displayDaysDiff);
    },
    setDateRange(displayDaysDiff) {
      let format = Define.DATE_FORMAT_DAY_HYPHEN;
      this.startDate = moment(this.date).format(format);
      this.endDate = moment(this.date)
        .add(displayDaysDiff, "days")
        .format(format);
    }
  }
};
</script>

<style scoped>
.planboard {
  display: flex;
}
</style>
