var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "retain-focus": false, persistent: "" },
      model: {
        value: _vm.isOpenCardDetail,
        callback: function($$v) {
          _vm.isOpenCardDetail = $$v
        },
        expression: "isOpenCardDetail"
      }
    },
    [
      _c(
        "v-form",
        {
          ref: "form",
          staticClass: "overlay carddetail",
          model: {
            value: _vm.valid,
            callback: function($$v) {
              _vm.valid = $$v
            },
            expression: "valid"
          }
        },
        [
          _c(
            "div",
            { staticClass: "overlayarea scroll" },
            [
              _c(
                "v-card",
                {
                  staticClass: "cardsize",
                  attrs: { flat: "", color: "#f0f0f0" }
                },
                [
                  _c(
                    "div",
                    { staticClass: "text-right" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            id: "closebtn_" + _vm.cardDetailInfo.tileId,
                            icon: ""
                          },
                          on: { click: _vm.closeDetail }
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-tabs",
                    {
                      ref: "tabs",
                      staticClass: "pb-2 px-2",
                      attrs: { "background-color": "#f0f0f0", height: "20" },
                      model: {
                        value: _vm.tab,
                        callback: function($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab"
                      }
                    },
                    [
                      _c("v-tab", [
                        _vm._v(_vm._s(_vm.$t("TABNAME_CARD_INFO")))
                      ]),
                      _vm.isVisibleTabJisseki
                        ? _c("v-tab", [
                            _vm._v(_vm._s(_vm.$t("TABNAME_CARD_JISSEKI")))
                          ])
                        : _vm._e(),
                      _vm.isVisibleTabHistory
                        ? _c("v-tab", [
                            _vm._v(_vm._s(_vm.$t("TABNAME_CARD_HISTORY")))
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-tabs-items",
                    {
                      staticClass: "px-2 py-2",
                      style: { "background-color": "#f0f0f0" },
                      attrs: { touchless: true },
                      model: {
                        value: _vm.tab,
                        callback: function($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab"
                      }
                    },
                    [
                      _c(
                        "v-tab-item",
                        [
                          _c("div", { staticClass: "subtitle" }, [
                            _vm._v(_vm._s(_vm.$t("PAGE_TITLE_CARD_DETAIL")))
                          ]),
                          _c(
                            "div",
                            { staticClass: "pl-8 pr-4" },
                            [
                              _c(
                                "v-row",
                                { staticClass: "pr-8" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-0 pb-1 pl-0",
                                      attrs: { col: "6" }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { align: "center" } },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "dispdetail1 font-weight-bold dispfont pl-3",
                                              style: _vm.getStyleFontSize(
                                                _vm.DISPLAY_TYPE_CARD_DETAIL,
                                                _vm.displayFontSize
                                              )
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("WORD_DATE")) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "dispdetail2 pr-5" },
                                            [
                                              _c("EditingItem", {
                                                attrs: {
                                                  type: _vm.TYPE_DATE,
                                                  bgcolor: "#fff",
                                                  rules: [],
                                                  hideDetails: true,
                                                  editable: _vm.authCardAction
                                                },
                                                on: {
                                                  input: function($event) {
                                                    return _vm.changeDate(
                                                      $event
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value:
                                                    _vm.tempTileInfo.posCol,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.tempTileInfo,
                                                      "posCol",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "tempTileInfo.posCol"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-0 pb-1 pl-0",
                                      attrs: { col: "6" }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { align: "center" } },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "dispdetail1 font-weight-bold dispfont pl-3",
                                              style: _vm.getStyleFontSize(
                                                _vm.DISPLAY_TYPE_CARD_DETAIL,
                                                _vm.displayFontSize
                                              )
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("WORD_LINENAME")
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "dispdetail2 pr-4" },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  dense: "",
                                                  outlined: "",
                                                  "hide-details": "",
                                                  "background-color": "#fff",
                                                  items: _vm.areaItems,
                                                  editable: _vm.authCardAction
                                                },
                                                on: {
                                                  input: function($event) {
                                                    return _vm.changeArea(
                                                      $event
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value:
                                                    _vm.tempTileInfo.posRow,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.tempTileInfo,
                                                      "posRow",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "tempTileInfo.posRow"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-card",
                            {
                              staticClass: "area1 scroll areamargin",
                              attrs: { flat: "" }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "datatable" },
                                [
                                  _c(
                                    "v-row",
                                    _vm._l(
                                      _vm.tempTileInfo.tileDetailData,
                                      function(detail) {
                                        return _c(
                                          "div",
                                          {
                                            key: detail.mappingId,
                                            staticClass: "flexarea detail"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "dispdetail1 d-flex flex-row"
                                              },
                                              [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      bottom: "",
                                                      "open-delay": "300",
                                                      color:
                                                        "rgba(60,64,67,0.95)",
                                                      transition: "fade"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var tbl = ref.on
                                                            return [
                                                              _c(
                                                                "div",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "font-weight-bold dispfont detailtitle",
                                                                    style: _vm.getStyleFontSize(
                                                                      _vm.DISPLAY_TYPE_CARD_DETAIL,
                                                                      _vm.displayFontSize
                                                                    )
                                                                  },
                                                                  tbl
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.getShortString(
                                                                          _vm.mappingName(
                                                                            detail.mappingId
                                                                          ),
                                                                          18
                                                                        )[0]
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                  _c("br"),
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.getShortString(
                                                                          _vm.mappingName(
                                                                            detail.mappingId
                                                                          ),
                                                                          18
                                                                        )[1]
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.mappingName(
                                                            detail.mappingId
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                ),
                                                _vm.isKeySettings(
                                                  detail.mappingId
                                                )
                                                  ? _c(
                                                      "div",
                                                      {
                                                        style: { color: "red" }
                                                      },
                                                      [_vm._v(" * ")]
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "dispdetail2" },
                                              [
                                                _c("EditingItem", {
                                                  attrs: {
                                                    type: _vm.getType(
                                                      detail.mappingId
                                                    ),
                                                    inputType: _vm.getInputType(
                                                      detail.mappingId
                                                    ),
                                                    rules: _vm.rules(
                                                      detail.mappingId
                                                    ),
                                                    items: _vm.listDataItems(
                                                      _vm.getCategory(
                                                        detail.mappingId
                                                      ),
                                                      detail.value
                                                    ),
                                                    editable:
                                                      _vm.authCardAction &&
                                                      _vm.getType(
                                                        detail.mappingId
                                                      ) !== _vm.TYPE_CALC
                                                  },
                                                  on: {
                                                    update: function($event) {
                                                      return _vm.changeTileDetail()
                                                    }
                                                  },
                                                  model: {
                                                    value: detail.value,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        detail,
                                                        "value",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "detail.value"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "d-flex flex-row mt-10 mb-5",
                                      attrs: { align: "center" }
                                    },
                                    [
                                      _c("span", { staticClass: "px-3" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("PAGE_SUBTITLE_CARD_COLOR")
                                          )
                                        )
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "selecter" },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              dense: "",
                                              outlined: "",
                                              "hide-details": "auto",
                                              items: _vm.colors,
                                              "item-value": "color",
                                              "item-text": "label",
                                              rules: [],
                                              disabled: !_vm.authCardAction
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "selection",
                                                fn: function(ref) {
                                                  var item = ref.item
                                                  return [
                                                    _c("div", {
                                                      style: _vm.colorBoxlStyle(
                                                        item.color
                                                      )
                                                    }),
                                                    _c(
                                                      "span",
                                                      { staticClass: "pl-2" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.label)
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              },
                                              {
                                                key: "item",
                                                fn: function(ref) {
                                                  var item = ref.item
                                                  return [
                                                    _c("div", {
                                                      style: _vm.colorBoxlStyle(
                                                        item.color
                                                      )
                                                    }),
                                                    _c(
                                                      "span",
                                                      { staticClass: "pl-2" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.label)
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ]),
                                            model: {
                                              value: _vm.tempTileInfo.color,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.tempTileInfo,
                                                  "color",
                                                  $$v
                                                )
                                              },
                                              expression: "tempTileInfo.color"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c("HelpButton", {
                                        attrs: {
                                          helpobject:
                                            _vm.colorSpacifyHelpDialogData
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center"
                              }
                            },
                            [
                              _c("div", { staticClass: "subtitle" }, [
                                _vm._v(_vm._s(_vm.$t("PAGE_TITLE_CARD_ASSIST")))
                              ]),
                              _c("HelpButton", {
                                attrs: {
                                  helpobject: _vm.auxiliaryHelpDialogData
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-card",
                            {
                              staticClass: "area2 scroll areamargin",
                              attrs: { flat: "" }
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "px-3 py-1",
                                  attrs: { "no-gutters": "" }
                                },
                                _vm._l(_vm.fileList, function(file, index) {
                                  return _c(
                                    "v-col",
                                    {
                                      key: "fileConrol-" + index,
                                      attrs: { cols: "3" }
                                    },
                                    [
                                      _c("span", { staticClass: "pl-1 no" }, [
                                        _vm._v("No." + _vm._s(index + 1))
                                      ]),
                                      _c("FileControl", {
                                        ref: "fileControl",
                                        refInFor: true,
                                        attrs: {
                                          parameter: file,
                                          editable: _vm.authCardAction
                                        },
                                        on: {
                                          "set-file-size": function($event) {
                                            return _vm.setFileSize($event)
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "areamargin flexarea" },
                            [
                              !_vm.isAddCard
                                ? _c(
                                    "v-btn",
                                    {
                                      directives: [
                                        {
                                          name: "shortkey",
                                          rawName: "v-shortkey.once",
                                          value: ["shift", "c"],
                                          expression: "['shift', 'c']",
                                          modifiers: { once: true }
                                        }
                                      ],
                                      staticClass: "success",
                                      attrs: { disabled: !_vm.authCardAction },
                                      on: {
                                        click: _vm.copyCard,
                                        shortkey: function($event) {
                                          return _vm.keyCopy()
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("WORD_COPY")))]
                                  )
                                : _vm._e(),
                              !_vm.isAddCard
                                ? _c(
                                    "v-btn",
                                    {
                                      directives: [
                                        {
                                          name: "shortkey",
                                          rawName: "v-shortkey.once",
                                          value: ["shift", "m"],
                                          expression: "['shift', 'm']",
                                          modifiers: { once: true }
                                        }
                                      ],
                                      staticClass: "warning",
                                      attrs: { disabled: !_vm.authCardAction },
                                      on: {
                                        click: _vm.copyMultipleCard,
                                        shortkey: function($event) {
                                          return _vm.keyMultiCopy()
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("WORD_COPY_MULTIPLE"))
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _c("v-spacer"),
                              _vm.getOption002 && !_vm.isAddCard
                                ? _c(
                                    "div",
                                    { class: _vm.setCheckWidth },
                                    [
                                      _c("v-checkbox", {
                                        staticClass: "checkmargin",
                                        attrs: {
                                          disabled: !_vm.authCardAction,
                                          color: "blue",
                                          label: _vm.$t("WORD_CONFIRM"),
                                          "hide-details": "true"
                                        },
                                        model: {
                                          value: _vm.tempTileInfo.isConfirmed,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.tempTileInfo,
                                              "isConfirmed",
                                              $$v
                                            )
                                          },
                                          expression: "tempTileInfo.isConfirmed"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !_vm.isAddCard
                                ? _c(
                                    "div",
                                    { class: _vm.setCheckWidth },
                                    [
                                      _c("v-checkbox", {
                                        staticClass: "checkmargin",
                                        attrs: {
                                          disabled: !_vm.authCardAction,
                                          color: "blue",
                                          label: _vm.$t("WORD_COMPLETE"),
                                          "hide-details": "true"
                                        },
                                        model: {
                                          value: _vm.tempTileInfo.isComplete,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.tempTileInfo,
                                              "isComplete",
                                              $$v
                                            )
                                          },
                                          expression: "tempTileInfo.isComplete"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.isAddCard
                                ? _c(
                                    "v-btn",
                                    {
                                      directives: [
                                        {
                                          name: "shortkey",
                                          rawName: "v-shortkey.once",
                                          value: ["shift", "s"],
                                          expression: "['shift', 's']",
                                          modifiers: { once: true }
                                        }
                                      ],
                                      staticClass: "info",
                                      attrs: { disabled: !_vm.authCardAction },
                                      on: {
                                        click: _vm.createCard,
                                        shortkey: function($event) {
                                          return _vm.keyCreate()
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("WORD_CREATE")))]
                                  )
                                : _vm._e(),
                              !_vm.isAddCard
                                ? _c(
                                    "v-btn",
                                    {
                                      directives: [
                                        {
                                          name: "shortkey",
                                          rawName: "v-shortkey.once",
                                          value: ["shift", "s"],
                                          expression: "['shift', 's']",
                                          modifiers: { once: true }
                                        }
                                      ],
                                      staticClass: "info",
                                      attrs: { disabled: !_vm.authCardAction },
                                      on: {
                                        click: _vm.updateCard,
                                        shortkey: function($event) {
                                          return _vm.keyUpdate()
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("WORD_UPDATE")))]
                                  )
                                : _vm._e(),
                              !_vm.isAddCard
                                ? _c(
                                    "v-btn",
                                    {
                                      directives: [
                                        {
                                          name: "shortkey",
                                          rawName: "v-shortkey.once",
                                          value: ["shift", "d"],
                                          expression: "['shift', 'd']",
                                          modifiers: { once: true }
                                        }
                                      ],
                                      staticClass: "error",
                                      attrs: { disabled: !_vm.authCardAction },
                                      on: {
                                        click: _vm.deleteCard,
                                        shortkey: function($event) {
                                          return _vm.keyDelete()
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("WORD_DELETE")))]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.isVisibleTabJisseki
                        ? _c("v-tab-item", [_c("CardDetailJisseki")], 1)
                        : _vm._e(),
                      _vm.isVisibleTabHistory
                        ? _c("v-tab-item", [_c("CardDetailHistory")], 1)
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }