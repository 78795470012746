var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c(
            "v-form",
            { ref: "jissekiform" },
            _vm._l(_vm.jissekis, function(jisseki) {
              return _c(
                "v-row",
                { key: jisseki.mappingId, attrs: { dense: "" } },
                [
                  _c("v-col", [
                    _c("div", { staticClass: "font-weight-bold detailtitle" }, [
                      _vm._v(
                        " " + _vm._s(_vm.mappingName(jisseki.mappingId)) + " "
                      )
                    ]),
                    _c(
                      "div",
                      [
                        _c("EditingItem", {
                          attrs: {
                            type: _vm.getType(jisseki.mappingId),
                            inputType: _vm.getInputType(jisseki.mappingId),
                            rules: _vm.rules(jisseki.mappingId),
                            items: _vm.listDataItems(
                              _vm.getCategory(jisseki.mappingId),
                              jisseki.value
                            ),
                            min: _vm.getMin(jisseki.mappingId),
                            max: _vm.getMax(jisseki.mappingId),
                            step: _vm.getStep(jisseki.mappingId),
                            bgcolor:
                              _vm.getJisseki(jisseki.mappingId) ===
                              _vm.JISSEKI_REQUIRED
                                ? "pink lighten-5"
                                : "",
                            calcObject1Type:
                              _vm.getType(jisseki.mappingId) === _vm.TYPE_CALC
                                ? _vm.getCalcObject1Type(jisseki.mappingId)
                                : null
                          },
                          model: {
                            value: jisseki.value,
                            callback: function($$v) {
                              _vm.$set(jisseki, "value", $$v)
                            },
                            expression: "jisseki.value"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            }),
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "justify-center" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "btn-regist",
                  attrs: { outlined: "", width: "90%" },
                  on: {
                    click: function($event) {
                      return _vm.regist(false)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("WORD_REGIST")))]
              )
            ],
            1
          ),
          !this.tileInfo.isComplete
            ? _c(
                "v-card-actions",
                { staticClass: "justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn-complete",
                      attrs: { outlined: "", width: "90%" },
                      on: {
                        click: function($event) {
                          return _vm.regist(true)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("WORD_REGIST_COMPLETE")))]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }