<template>
  <div :class="cardClass">
    <div :style="styles">
      <!-- 行 -->
      <div v-for="(row, index) in cardData" :key="index" :class="index == cardData.length - 1 ? 'rows' : 'rows base_holizontal_line'">
        <!-- 列 -->
        <v-tooltip v-for="(col, index) in row" :key="index" bottom open-delay="300" color="rgba(60,64,67,0.95)" transition="fade" :disabled="!mappingName(col.display)">
          <template v-slot:activator="{ on: disp }">
            <div :style="getStyleFontSize(DISPLAY_TYPE_CARD, displayFontSize)" :class="col.class" v-on="isModePc ? disp : undefined">
              {{ displayDataValue(col.display, true) }}
            </div>
          </template>
          <span>【{{ mappingName(col.display) }}】<br>{{ displayDataValue(col.display, false) }}</span>
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import Define from "@/define.js";
import * as Util from "@/util/utils.js";
export default {
  props: {
    cardTempNum: {
      type: Number,
      default: 0
    },
    tileinfo: {
      type: Object,
      default: () => ({
        tileId: "",
        isComplete: false,
        order: 0,
        posCol: "",
        posRow: "",
        isConfirmed: false,
        status: 0,
        displayData: [
          {value: "", mappingId: ""}
        ],
        tileDetailData: [],
        fileDataNum: 0
      })
    }
  },
  data() {
    return {
      pattern01: Define.TILE_TEMP_0,
      pattern02: Define.TILE_TEMP_1,
      pattern03: Define.TILE_TEMP_2,
      pattern04: Define.TILE_TEMP_3,
      cardData1: [
        [
          {display:Define.TILE_DISPLAY1, class:"cardtext line_1 tile_value"}
        ],
        [
          {display:Define.TILE_DISPLAY2, class:"cardtext line_2 tile_value base_vertical_line"},
          {display:Define.TILE_DISPLAY3, class:"cardtext line_2 tile_value"}
        ],
        [
          {display:Define.TILE_DISPLAY4, class:"cardtext line_3 tile_value base_vertical_line"},
          {display:Define.TILE_DISPLAY5, class:"cardtext line_3 tile_value base_vertical_line"},
          {display:Define.TILE_DISPLAY6, class:"cardtext line_3 tile_value"}
        ]
      ],
      cardData2: [
        [
          {display:Define.TILE_DISPLAY1, class:"cardtext line_2 tile_value base_vertical_line base_vertical_line"},
          {display:Define.TILE_DISPLAY2, class:"cardtext line_2 tile_value"}
        ],
        [
          
          {display:Define.TILE_DISPLAY3, class:"cardtext line_2 tile_value base_vertical_line"},
          {display:Define.TILE_DISPLAY4, class:"cardtext line_2 tile_value"}
        ],
        [
          
          {display:Define.TILE_DISPLAY5, class:"cardtext line_2 tile_value base_vertical_line"},
          {display:Define.TILE_DISPLAY6, class:"cardtext line_2 tile_value"}
        ]
      ],
      cardData3: [
        [
          {display:Define.TILE_DISPLAY1, class:"cardtext line_3 tile_value base_vertical_line base_vertical_line"},
          {display:Define.TILE_DISPLAY2, class:"cardtext line_3 tile_value base_vertical_line"},
          {display:Define.TILE_DISPLAY3, class:"cardtext line_3 tile_value"}
        ],
        [
          {display:Define.TILE_DISPLAY4, class:"cardtext line_3 tile_value base_vertical_line"},
          {display:Define.TILE_DISPLAY5, class:"cardtext line_3 tile_value base_vertical_line"},
          {display:Define.TILE_DISPLAY6, class:"cardtext line_3 tile_value"}
        ],
        [
          {display:Define.TILE_DISPLAY7, class:"cardtext line_3 tile_value base_vertical_line"},
          {display:Define.TILE_DISPLAY8, class:"cardtext line_3 tile_value base_vertical_line"},
          {display:Define.TILE_DISPLAY9, class:"cardtext line_3 tile_value"}
        ]
      ],
      cardData4: [
        [
          {display:Define.TILE_DISPLAY1, class:"cardtext line_1 tile_value"},
        ],
        [
          {display:Define.TILE_DISPLAY2, class:"cardtext line_1 tile_value"},
        ],
        [
          {display:Define.TILE_DISPLAY3, class:"cardtext line_1 tile_value"}
        ]
      ],
      DISPLAY_TYPE_CARD: Define.DISPLAY_TYPE_CARD
    };
  },
  computed: {
    cardData() {
      switch(this.cardTempNum) {
        case this.pattern01:
          return this.cardData1;
        case this.pattern02:
          return this.cardData2;
        case this.pattern03:
          return this.cardData3;
        case this.pattern04:
          return this.cardData4;
        default:
          return this.cardData1;
      }
    },
    styles() {
      const colorConditionList = Util.parseObject(this.$store.getters["setting/colorConditionList"]);
      const mappingDataList = Util.parseObject(this.$store.getters["setting/getMappingList"]);
      return {
        backgroundColor: Util.getTileColorCode(this.tileinfo, mappingDataList, colorConditionList),
      };
    },
    displayFontSize() {
      return this.$store.getters["display/displayFontSize"];
    },
    mappings() {
      return this.$store.getters["setting/getMappingList"];
    },
    dateDisplayType(){
      return this.$store.getters["setting/getDateDisplayType"];
    },
    isModePc() {
      return this.$store.getters["isModePc"];
    },
    cardClass() {
      return this.isModePc ? "cardwidth" : "mobile-cardwidth";
    }
  },
  methods: {
    /**
     * indexから画面表示するデータ名称を返す
     * @param {Number} index - 配列番号
     * @returns {String} - indexに対するデータ名称
     */
    mappingName(index) {
      let disp;
      let result;
      if (!Util.checkDisplayData(this.tileinfo)) {
        result = "";
      } else {
        disp = Util.parseObject(this.tileinfo.displayData[index]);
        result = Util.checkNotUndefined(disp.mappingId)
          ? this.$store.getters["setting/getMappingName"](
              this.tileinfo.displayData[index].mappingId
            )
          : "";
      }
      return result;
    },
    /**
     * indexから画面表示値を返す
     * @param {Number} index - 配列番号
     * @returns {String} - indexに対するデータの値
     */
    displayDataValue(index, isCard) {
      return Util.getDisplayDataValue(this.tileinfo, index, this.mappings, isCard, this.dateDisplayType);
    },
    /**
     * フォントサイズのスタイルを取得する
     * @param {Number} type
     * @param {Number} size
     * @returns {String}
     */
    getStyleFontSize(type, size) {
      return Util.getStyleFontSize(type, size);
    }
  }
};
</script>

<style scoped>
.cardwidth {
  width: 166px;
}
.mobile-cardwidth {
  width: 266px;
}
.rows {
  display: flex;
  height: 26px;
}
.cardtext {
  padding: 6px 2px;
  font-weight: bold;
  color: #333;
}
.line_1 {
  text-align: center;
  width: 100%;
}
.line_2 {
  text-align: center;
  width: 50%;
}
.line_3 {
  text-align: center;
  width: 33.3%;
}
.base_vertical_line {
  height: 100%;
  border-right: solid 1px #aaa;
}
.base_holizontal_line {
  width: 100%;
  border-bottom: solid 1px#aaa;
}
.tile_value {
  overflow: hidden;
  white-space: nowrap;
}
</style>
