var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tenantregist" },
    [
      _c(
        "v-card",
        {
          staticClass: "mx-auto mb-2",
          attrs: {
            outlined: "",
            tile: "",
            width: "650px",
            color: "rgb(0, 0, 0, 0)"
          }
        },
        [
          _c(
            "v-layout",
            { staticClass: "justify-center" },
            [
              _c("v-img", {
                attrs: {
                  src: require("@/assets/Freely_squair.png"),
                  "max-height": "150",
                  "max-width": "150"
                }
              })
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                { ref: "registform" },
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            attrs: {
                              placeholder: " ",
                              outlined: "",
                              label: "会社名",
                              rules: [_vm.required, _vm.max_length]
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function() {
                                  return [
                                    _vm._v("会社名"),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [_vm._v(" *")]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ]),
                            model: {
                              value: _vm.company,
                              callback: function($$v) {
                                _vm.company = $$v
                              },
                              expression: "company"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            staticClass: "ml-2",
                            attrs: {
                              placeholder: " ",
                              outlined: "",
                              label: "部署名",
                              rules: [_vm.required, _vm.max_length]
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function() {
                                  return [
                                    _vm._v("部署名"),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [_vm._v(" *")]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ]),
                            model: {
                              value: _vm.department,
                              callback: function($$v) {
                                _vm.department = $$v
                              },
                              expression: "department"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            attrs: {
                              placeholder: " ",
                              outlined: "",
                              label: "担当者名",
                              rules: [_vm.required, _vm.max_length]
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function() {
                                  return [
                                    _vm._v("担当者名"),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [_vm._v(" *")]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ]),
                            model: {
                              value: _vm.name,
                              callback: function($$v) {
                                _vm.name = $$v
                              },
                              expression: "name"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            staticClass: "ml-2",
                            attrs: {
                              placeholder: " ",
                              outlined: "",
                              label: "電話番号(ハイフンあり)",
                              rules: [
                                _vm.required,
                                _vm.max_length,
                                _vm.phone_format
                              ]
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function() {
                                  return [
                                    _vm._v("電話番号(ハイフンあり)"),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [_vm._v(" *")]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ]),
                            model: {
                              value: _vm.phone,
                              callback: function($$v) {
                                _vm.phone = $$v
                              },
                              expression: "phone"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            attrs: {
                              placeholder: " ",
                              outlined: "",
                              label: "メールアドレス",
                              rules: [
                                _vm.required,
                                _vm.email_max_length,
                                _vm.email_format
                              ],
                              height: "20px"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function() {
                                  return [
                                    _vm._v("メールアドレス"),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [_vm._v(" *")]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ]),
                            model: {
                              value: _vm.email,
                              callback: function($$v) {
                                _vm.email = $$v
                              },
                              expression: "email"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            staticClass: "ml-2",
                            attrs: {
                              placeholder: " ",
                              outlined: "",
                              label: "パスワード",
                              rules: [
                                _vm.required,
                                _vm.password_max_length,
                                _vm.password_format
                              ],
                              type: "password"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function() {
                                  return [
                                    _vm._v("パスワード"),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [_vm._v(" *")]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ]),
                            model: {
                              value: _vm.password,
                              callback: function($$v) {
                                _vm.password = $$v
                              },
                              expression: "password"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    { staticClass: "justify-center mb-5" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "templatebtn",
                          attrs: {
                            id: "btn_tenant_regist",
                            height: "50",
                            color: "success"
                          },
                          on: { click: _vm.submit }
                        },
                        [_vm._v("無料お試しプランに登録する")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" ～ 無料お試しプラン ～ "),
                  _c("div", { staticClass: "planborder mt-1 pl-2" }, [
                    _c("div", { staticClass: "policy" }, [
                      _vm._v(
                        "お申込みから30日間の期間限定で10ユーザまで無料利用可能なプランとなっております。"
                      )
                    ]),
                    _c("div", { staticClass: "policy" }, [
                      _vm._v(
                        "利用期間と利用人数に制限を設けさせていただきますが機能の制限はございません。"
                      )
                    ]),
                    _c("div", { staticClass: "policy" }, [
                      _vm._v(
                        "有料プランをご利用いただく前に操作感などをご確認いただけます。"
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "spolicy mt-3" }, [
                    _vm._v(
                      " ※「無料お試しプランに登録する」ボタンから申し込むことで、 "
                    ),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "https://kaizen-navi.biz/freely/policies",
                          target: "_blank"
                        }
                      },
                      [_vm._v(" Freelyの利用規約 ")]
                    ),
                    _vm._v(" と "),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "https://kaizen-navi.biz/privacy-policy",
                          target: "_blank"
                        }
                      },
                      [_vm._v(" プライバシーポリシー ")]
                    ),
                    _vm._v(" に同意したものといたします。 ")
                  ]),
                  _c("div", { staticClass: "spolicy" }, [
                    _vm._v(
                      " ※無料お試しプラン終了後、自動的に課金されることはありません。 "
                    )
                  ]),
                  _c("div", { staticClass: "spolicy" }, [
                    _vm._v(
                      " ※無料お試しプラン終了後も継続利用いただく場合は、 "
                    ),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "https://kaizen-navi.biz/contact-start",
                          target: "_blank"
                        }
                      },
                      [_vm._v(" カイゼンナビの「お問合せ」 ")]
                    ),
                    _vm._v(" からお申込みください。 ")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }