<template>
  <div v-if="isColOpen" class="freearea">
    <v-row>
      <v-col>
        <SearchBox :lineid="freeAreaCode" @getKey="setKeyword"></SearchBox>
      </v-col>
    </v-row>
    <div class="headerarea">
      <div class="headeropen" @click="changeColOpen">{{ $t("WORD_UNPLANNED") }}</div>
      <v-btn :disabled="!authCardAction" text x-small color="rgba(60,64,67,0.95)" @click="openDetail()" v-shortkey.once="['shift', 'a']" @shortkey="keyOpenDetail()">
        <v-icon x-small>mdi-plus</v-icon>
        {{ $t("WORD_CARD_ADD") }}
      </v-btn>
    </div>
    <div class="scroll">
      <LineListProgressbar
        :enabled="enabled"
        :cardtempnum="cardtempnum"
        :arealist="freetilelist.tileList"
        :keyword="keyword"
      ></LineListProgressbar>
    </div>
    <div class="text-center">
      <v-pagination
        v-model="pageNo"
        :length="pageNum"
        :total-visible="10"
        circle
      ></v-pagination>
    </div>
  </div>
  <div v-else class="freearea">
    <div class="headerclose" @click="changeColOpen">{{ $t("WORD_UNPLANNED") }}</div>
  </div>
</template>

<script>
import LineListProgressbar from "@/components/parts/LineListProgressbar.vue";
import SearchBox from "@/components/parts/SearchBox.vue";
import Define from "@/define.js";
import * as Util from "@/util/utils.js";
export default {
  components: {
    LineListProgressbar,
    SearchBox
  },
  props: {
    enabled: {
      type: Boolean,
      default: true
    },
    freetilelist: {
      type: Object,
      default: () => ({
        tileList: {}
      })
    },
    cardtempnum: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      freeAreaCode: Define.FREE_AREA_CODE,
      selected: "",
      keyword: { value: "", mappingId: "" },
      isColOpen: true,
      dateId: null
    };
  },
  computed: {
    protocard: {
      get() {
        return this.$store.getters["setting/getProtoCard"];
      }
    },
    freeTileNum() {
      return this.$store.getters["tile/getFreeTileNum"];
    },
    pageNo: {
      get() {
        return this.$store.getters["tile/pageNo"];
      },
      set(val) {
        this.$store.commit("tile/setPageNo", val);
      }
    },
    pageNum: {
      get() {
        return this.$store.getters["tile/pageNum"];
      },
      set(val) {
        this.$store.commit("tile/setPageNum", val);
      }
    },
    selectedWorkspaceId() {
      return this.$store.getters["auth/selectedWorkSpaceId"];
    },
    displayCol() {
      return this.$store.getters["display/displayCol"];
    },
    socket() {
      return this.$store.getters["socket"];
    },
    authCardAction() {
      let user = this.$store.getters["auth/user"];
      let role = this.$store.getters["role/roleByWorkspaceId"](this.selectedWorkspaceId, user.role);
      return Util.getAuthority(Define.EVENT_CARD_ACTION, role);
    },
    isOpenDialog() {
      return this.$store.getters["tile/isOpenDialog"];
    },
  },
  watch: {
    selectedWorkspaceId: {
      handler: function(value) {
        this.isColOpen = Util.getIsOpen(
          this.displayCol,
          value,
          this.dateId
        );
      }
    },
    displayCol: {
      immediate: true,
      deep: true,
      handler: function(value) {
        this.isColOpen = Util.getIsOpen(
          value,
          this.selectedWorkspaceId,
          this.dateId
        );
      }
    }
  },
  methods: {
    /**
     * 検索キーワードとそのマッピングIDを代入
     * @param {Object} keyword - キーワード、マッピングID
     */
    setKeyword(keyword) {
      this.keyword = keyword;
    },
    /**
     * カード詳細を表示する
     */
    openDetail() {
      // カード履歴情報を取得しセット
      this.$store.dispatch("tile/getTileMoveHistoryData", null);
      // タイル詳細情報を取得しセット
      this.$store.dispatch("tile/getTileDetailData", null);
      this.$store.commit("tile/setCardDetailInfo", this.protocard);
      this.$store.commit("tile/setIsAddCard", true);
      this.$store.commit("tile/setIsOpenCardDetail", true);
    },
    changeColOpen() {
      let value = {
        boardId: this.selectedWorkspaceId,
        dateId: this.dateId
      };
      this.$store.commit("display/changeColOpen", value);
    },
    /**
     * [ショートカットキー shift+a]カード詳細ダイアログを開く(作成)
     */
    keyOpenDetail(){
      if(this.authCardAction && !this.isOpenDialog){
        this.openDetail();
      }
    },
  }
};
</script>

<style scoped>
.freearea {
  margin: 5px 2px 5px 2px;
}
.headerarea {
  background-color: #1976d2f7;
  display: flex;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.headeropen {
  text-align: center;
  padding: 3px 3px 3px 100px;
  height: 30px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  flex-grow: 1;
}
.headerareaclose {
  display: flex;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.headerclose {
  background-color: #1976d2f7;
  border-radius: 4px;
  margin: 70px 0px 250px 0px;
  writing-mode: vertical-lr;
  text-align: center;
  line-height: 30px;
  width: 28px;
  height: 100px;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
}
.scroll {
  max-height: calc(82vh - 25px);
  overflow-x: hidden;
  overflow-y: scroll;
}
.v-btn {
  background-color: #fff;
  margin: 5px 3px;
}
.delete_button {
  margin-right: -194.94px;
  color: #fff !important;
  background-color: #ff5252;
  border-color: #ff5252;
}
</style>
