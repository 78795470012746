var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-select", {
    attrs: {
      dense: "",
      outlined: "",
      "hide-details": "",
      items: _vm.workspacesSelectList
    },
    model: {
      value: _vm.selectedWorkspace,
      callback: function($$v) {
        _vm.selectedWorkspace = $$v
      },
      expression: "selectedWorkspace"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }