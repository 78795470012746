var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isMonthView
        ? _c(
            "v-tooltip",
            {
              attrs: {
                bottom: "",
                "open-delay": "300",
                color: "rgba(60,64,67,0.95)",
                transition: "fade"
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      var attrs = ref.attrs
                      return [
                        _c(
                          "div",
                          _vm._g(_vm._b({}, "div", attrs, false), on),
                          [
                            _c(
                              "v-progress-linear",
                              {
                                staticClass: "progressbarliner",
                                attrs: {
                                  height: "20",
                                  color: _vm.berColor,
                                  "background-color": "grey darken-1"
                                },
                                model: {
                                  value: _vm.progressData,
                                  callback: function($$v) {
                                    _vm.progressData = $$v
                                  },
                                  expression: "progressData"
                                }
                              },
                              [_vm._v(" " + _vm._s(_vm.calcProgress()) + " ")]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                1578919670
              )
            },
            [_c("span", [_vm._v(_vm._s(_vm.sum) + " / " + _vm._s(_vm.max))])]
          )
        : _c(
            "v-progress-linear",
            {
              staticClass: "progressbarliner",
              attrs: {
                height: "20",
                color: _vm.berColor,
                "background-color": "grey darken-1"
              },
              model: {
                value: _vm.progressData,
                callback: function($$v) {
                  _vm.progressData = $$v
                },
                expression: "progressData"
              }
            },
            [_vm._v(" " + _vm._s(_vm.calcProgress()) + " ")]
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }