<template>
  <div class="templatepage">
    <v-card
      outlined
      tile
      class="templatecard mx-auto mt-16"
      color="rgb(0, 0, 0, 0)"
    >
      <div class="templatetitle">
        <v-layout class="justify-center">
          <v-img
            :src="require('@/assets/Freely_squair.png')"
            max-height="250"
            max-width="250"
          />
        </v-layout>
      </div>
      <v-card-text>
        <v-form>
          <v-radio-group v-model="mode" row>
            <v-radio label="PC版" value="0"></v-radio>
            <v-radio label="モバイル版" value="1"></v-radio>
          </v-radio-group>
          <v-text-field
            v-model="email"
            placeholder=" "
            outlined
            label="メールアドレス"
            @keydown.enter="submit"
          />
          <v-text-field
            v-model="password"
            placeholder=" "
            outlined
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            label="パスワード"
            @click:append="showPassword = !showPassword"
            @keydown.enter="submit"
          />
          <v-card-actions class="justify-end">
            <v-btn text color="blue" @click="toReset">
              パスワードを忘れた方
            </v-btn>
          </v-card-actions>
          <v-card-actions class="justify-center">
            <VueLoadingButton
              class="templatebtn v-btn v-btn--contained theme--light v-size--default success"
              :loading="isLoginLoading"
              :styled="false"
              @click.native="submit"
            >
              ログイン
            </VueLoadingButton>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import VueLoadingButton from "vue-loading-button";
export default {
  name: "Login",
  components: {
    VueLoadingButton
  },
  data() {
    return {
      mode: "0", // 0：PC版, 1：モバイル版
      showPassword: false,
      email: null,
      password: null
    };
  },
  computed: {
    isLoginLoading() {
      return this.$store.getters["auth/isLoginLoading"];
    },
    isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    },
    isModePc() {
      return this.$store.getters["isModePc"];
    },
    aMode() {
      return this.$store.getters["mode"];
    },
    aEmail() {
      return this.$store.getters["auth/email"];
    },
  },
  methods: {
    submit() {
      this.$store.dispatch("auth/login", {
        mode: this.mode,
        email: this.email,
        password: this.password
      });
    },
    toReset() {
      this.$router.push("/resetmail");
    }
  },
  mounted() {
    // モバイル版でログイン済みはトップへ
    if(this.isLoggedIn && !this.isModePc){
      this.$router.push("/mobilejisseki");
    }
    // 自動入力
    this.mode = this.aMode;
    this.email = this.aEmail;
  }
};
</script>

<style>
.templatepage {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #333;
  background-color: #f0f0f0;
  height: 100%;
  width: 100%;
}
.templatecard {
  width: 500px;
  height: 400px;
}
.templatetitle {
  height: 250px;
}
.templatebtn {
  width: 100%;
}
</style>
