import { render, staticRenderFns } from "./FreeArea.vue?vue&type=template&id=cf175e9c&scoped=true&"
import script from "./FreeArea.vue?vue&type=script&lang=js&"
export * from "./FreeArea.vue?vue&type=script&lang=js&"
import style0 from "./FreeArea.vue?vue&type=style&index=0&id=cf175e9c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cf175e9c",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCol,VIcon,VPagination,VRow})


/* hot reload */
if (module.hot) {
  var api = require("C:\\Freely\\repo_nck_plan_board\\nck_plan_board_frontend\\vue\\code\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('cf175e9c')) {
      api.createRecord('cf175e9c', component.options)
    } else {
      api.reload('cf175e9c', component.options)
    }
    module.hot.accept("./FreeArea.vue?vue&type=template&id=cf175e9c&scoped=true&", function () {
      api.rerender('cf175e9c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/areas/FreeArea.vue"
export default component.exports