var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isColOpen
    ? _c(
        "div",
        { staticClass: "freearea" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("SearchBox", {
                    attrs: { lineid: _vm.freeAreaCode },
                    on: { getKey: _vm.setKeyword }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "headerarea" },
            [
              _c(
                "div",
                { staticClass: "headeropen", on: { click: _vm.changeColOpen } },
                [_vm._v(_vm._s(_vm.$t("WORD_UNPLANNED")))]
              ),
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "shortkey",
                      rawName: "v-shortkey.once",
                      value: ["shift", "a"],
                      expression: "['shift', 'a']",
                      modifiers: { once: true }
                    }
                  ],
                  attrs: {
                    disabled: !_vm.authCardAction,
                    text: "",
                    "x-small": "",
                    color: "rgba(60,64,67,0.95)"
                  },
                  on: {
                    click: function($event) {
                      return _vm.openDetail()
                    },
                    shortkey: function($event) {
                      return _vm.keyOpenDetail()
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { "x-small": "" } }, [
                    _vm._v("mdi-plus")
                  ]),
                  _vm._v(" " + _vm._s(_vm.$t("WORD_CARD_ADD")) + " ")
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "scroll" },
            [
              _c("LineListProgressbar", {
                attrs: {
                  enabled: _vm.enabled,
                  cardtempnum: _vm.cardtempnum,
                  arealist: _vm.freetilelist.tileList,
                  keyword: _vm.keyword
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "text-center" },
            [
              _c("v-pagination", {
                attrs: { length: _vm.pageNum, "total-visible": 10, circle: "" },
                model: {
                  value: _vm.pageNo,
                  callback: function($$v) {
                    _vm.pageNo = $$v
                  },
                  expression: "pageNo"
                }
              })
            ],
            1
          )
        ],
        1
      )
    : _c("div", { staticClass: "freearea" }, [
        _c(
          "div",
          { staticClass: "headerclose", on: { click: _vm.changeColOpen } },
          [_vm._v(_vm._s(_vm.$t("WORD_UNPLANNED")))]
        )
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }