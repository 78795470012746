import rootState from "./index";
import Define from "@/define.js";
import SwalMsg from "@/swalMsg.js";
import Swal from "sweetalert2";
import moment from "moment";
import * as Util from "@/util/utils.js";
import camelcaseKeys from "camelcase-keys";

const state = {
  calendarSettingData: []
};
const getters = {
  /**
   * 日付・エリア情報による整形したカレンダー設定情報を取得する
   * @returns {Array} カレンダー設定リスト
   */
  getCalendarSettingData: () => (startDate, endDate) => {
    // アプリケーション設定の存在チェック
    let setting = rootState.state.settingData;
    if (!Util.isExistSetting(setting)) { return []; }

    // エリアデータを取得 (リスト)
    let al = Util.parseObject(setting.area);
    if (al.length) {
      al.sort(function(a, b) {
        if (a.order < b.order) return -1;
        if (a.order > b.order) return 1;
        return 0;
      });
    }

    // 日付配列
    let dl = [];
    let df = Define.DATE_FORMAT_DAY_HYPHEN;
    let s = moment(startDate).format(df);
    let e = moment(endDate).format(df);
    for (let date = s; date <= e; date = moment(date).add(1, "days").format(df)) {
      dl.push(date);
    }

    // カレンダー設定情報を取得
    let calendarSettingData = Util.deepCopy(state.calendarSettingData);

    // 結果データの整形
    let calendarSettingList = [];
    for (let i = 0; i < al.length; i++) {
      // エリアの表示順が未設定であれば成形対象外
      if (!Util.checkNotEmpty(al[i].order)) { continue; }

      let tmpAreaList = {
        lineId: al[i].areaId,
        lineName: al[i].name,
        calendarSettingList: []
      };

      let calendarSettingByArea = calendarSettingData.filter(e => e.posRow == al[i].areaId);
      for (let j = 0; j < dl.length; j++) {
        let calendarSettingByDate = calendarSettingByArea.filter(e => e.posCol == dl[j]);
        let tmpDateList = {
          dateInfo: dl[j],
          calendarId:  calendarSettingByDate.length !== 0 ? calendarSettingByDate[0].calendarId : null,
          max: calendarSettingByDate.length !== 0 ? calendarSettingByDate[0].max : "",
          version: calendarSettingByDate.length !== 0 ? calendarSettingByDate[0].version : "",
          isChange: false
        };
        tmpAreaList.calendarSettingList.push(tmpDateList);
      }
      calendarSettingList.push(tmpAreaList);
    }
    return calendarSettingList;
  }
}

const actions = {
  /**
   * [WS通信-送信] カレンダー設定取得
   */
  getCalendarSetting(state, condition) {
    const socket = rootState.state.socket;
    if (!socket) {
      Swal.fire(SwalMsg.WS_NOT_CONNECT_ERR);
      return false;
    }
    // クエリの都合上、nullは許容できないのでnullの場合空文字に変換して投げる
    socket.sendObj({
      type: Define.WS_CODE_GET_CALENDAR_SETTING,
      start_date: condition.startDate,
      end_date: condition.endDate,
      work_space_id: state.rootState.auth.user.workSpaceId || null
    });
  },
  /**
   * [WS通信-送信] カレンダー設定作成/更新/削除
   */
  createUpdateDeleteCalendarSetting(state, param) {
    const socket = rootState.state.socket;
    if (!socket) {
      Swal.fire(SwalMsg.WS_NOT_CONNECT_ERR);
      return false;
    }
    socket.sendObj({
      type: Define.WS_CODE_SAVE_CALENDAR_SETTING,
      start_date: param.startDate,
      end_date: param.endDate,
      insertUpdateList: param.insertUpdateList,
      deleteList: param.deleteList,
      work_space_id: state.rootState.auth.user.workSpaceId || null
    });
  }
};

const mutations = {
  initState(state) {
    state.calendarSettingData = [];
  },
  setCalendarSetting(state, { calendarSettingData }) {
    state.calendarSettingData = camelcaseKeys(calendarSettingData, { deep: true });
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
