<template>
  <div>
    <!-- 文字サイズ：小 -->
    <v-tooltip bottom open-delay="300" color="rgba(60,64,67,0.95)" transition="fade">
      <template v-slot:activator="{ on: small }">
        <v-btn :outlined="displayFontSize==FONT_SIZE_SMALL" @click="setDisplayFontSize(FONT_SIZE_SMALL)"  v-on="small">{{ $t("WORD_SMALL") }}</v-btn>
      </template>
      <span>{{ $t("TOOLTIP_SMALL") }}</span>
    </v-tooltip>
    <!-- 文字サイズ：中 -->
    <v-tooltip bottom open-delay="300" color="rgba(60,64,67,0.95)" transition="fade">
      <template v-slot:activator="{ on: mediium }">
        <v-btn :outlined="displayFontSize==FONT_SIZE_MEDIUM" @click="setDisplayFontSize(FONT_SIZE_MEDIUM)"  v-on="mediium">{{ $t("WORD_MEDIUM") }}</v-btn>
      </template>
      <span>{{ $t("TOOLTIP_MEDIUM") }}</span>
    </v-tooltip>
    <!-- 文字サイズ：大 -->
    <v-tooltip bottom open-delay="300" color="rgba(60,64,67,0.95)" transition="fade">
      <template v-slot:activator="{ on: large }">
        <v-btn :outlined="displayFontSize==FONT_SIZE_LARGE" @click="setDisplayFontSize(FONT_SIZE_LARGE)"  v-on="large">{{ $t("WORD_LARGE") }}</v-btn>
      </template>
      <span>{{ $t("TOOLTIP_LARGE") }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import Define from "@/define.js";
export default {
  data() {
    return {
      FONT_SIZE_SMALL: Define.FONT_SIZE_SMALL,
      FONT_SIZE_MEDIUM: Define.FONT_SIZE_MEDIUM,
      FONT_SIZE_LARGE: Define.FONT_SIZE_LARGE
    };
  },
  computed: {
    displayFontSize() {
      return this.$store.getters["display/displayFontSize"];
    },
  },
  methods: {
    /**
     * フォントサイズ(小中大)を設定する
     * @param {Number} - 表示文字列
     */
    setDisplayFontSize: function(fontSize) {
      this.$store.commit("display/setDisplayFontSize", fontSize);
    }
  }
};
</script>