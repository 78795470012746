<template>
  <div v-if="isInitDataLoaded" class="pagestemplate pagearea">
    <div :class="headerCss">
      <DatePick class="headertext mx-auto my-auto" ref="datePick" @setDate="setTarget($event)"></DatePick>
    </div>
      <!-- ライン毎 -->
      <v-list outlined class="listarea" :color="bgColor" v-for="line in lines" :key="line.lineId">
        <v-list-group :value="line.tileList[0].tileList.length > 0">
          <template v-slot:activator>
            <v-icon class="icon">mdi-clipboard-outline</v-icon>
            <v-list-item-content class="hcontent">
              <v-list-item-title>{{ line.lineName }}</v-list-item-title>
            </v-list-item-content>
            <v-spacer></v-spacer>
            <v-chip small class="ma-0 ml-4" color="info" >
              {{ $t("WORD_CARD_INCOMPLETE") }}
              <v-avatar small right class="info darken-2">{{ line.tileList[0].tileList.filter(d => d.isComplete == false).length }}</v-avatar>
            </v-chip>
            <v-chip small class="ma-0 ml-2" color="grey" text-color="white">
              {{ $t("WORD_CARD_COMPLETED") }}
            <v-avatar small right class="grey darken-2">{{ line.tileList[0].tileList.filter(d => d.isComplete == true).length }}</v-avatar>
            </v-chip>
          </template>
          <!-- カード毎 -->
          <div class="linearea">
            <v-row>
              <CardBase class="mr-3" v-for="tile in line.tileList[0].tileList" :key="tile.tileId" :tileinfo="tile" />
            </v-row>
          </div>
        </v-list-group>
      </v-list>
  </div>
</template>

<script>
import Define from "@/define.js";
import moment from "moment";
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("tile");
import DatePick from "@/components/parts/DatePick.vue";
import CardBase from "@/components/parts/CardPattern/CardBase.vue";
export default {
  components: {
    DatePick,
    CardBase
  },
  data() {
    return {
      targetDay: null,
      formatHyphen: Define.DATE_FORMAT_DAY_HYPHEN,
    };
  },
  computed: {
    isInitDataLoaded() {
      return this.$store.getters["isInitDataLoaded"];
    },
    lines() {
      let result = {};
      if (this.targetDay != null) {
        result = this.$store.getters["tile/getPlanTileData"](this.targetDay, this.targetDay, 1);
        result = result.tileList.filter(d => d.lineId != null);
      }
      return result;
    },
    headerCss() {
      let today = moment().format(this.formatHyphen);
      return (this.targetDay == today) ? "headerarea istoday":"headerarea";
    },
    days() {
      return moment(this.targetDay).day();
    },
    bgColor(){
      let color = undefined;
      switch(this.days){
        case Define.DAYS_SUNDAY:
          color = "rgba(248, 187, 208, 0.2)";
          break;
        case Define.DAYS_SATURDAY:
          color = "rgba(187, 222, 251, 0.2)";
          break;
        default:
          break;
      }
      return color;
    }
  },
  watch: {

  },
  methods: {
    ...mapActions(["getTileDataByChangeDate"]),
    setTarget(date){
      this.targetDay = date;
    }
  }
};
</script>

<style scoped>
.pagearea{
  overflow-x: hidden;
}
.listarea{
  padding: 0px;
}
.headerarea {
  height: 40px;
  display: flex;
  background-color: #1976d2f7;

}
.istoday {
  background-color: #ffa726;
}
.headertext {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}
.linearea {
  margin: 0px 60px 5px 80px;
}
.icon {
  padding-right: 30px;
}
.hcontent {
  padding: 0px;
}
</style>
