<template>
  <div>
    <!-- 共通 -->
    <div class="mb-4">
      <div>{{ $t("WORD_MULTILINGUAL_API_COMMON") }}</div>
      <div class="d-flex flex-row align-baseline mb-4">
        <div class="front-div">
          <v-select v-model="item.renkeisakiId" placeholder=" " dense hide-details="auto" :items="renkeisakiItems" :label="$t('WORD_MULTILINGUAL_API_DESTINATION')" class="ml-5" :disabled="!isValidRole" @change="cbChangeRenkeisaki($event)" />
        </div>
        <v-checkbox v-model="item.sync" :disabled="!isValidRole || item.renkeisakiId != WEBHOOK_RENKEISAKI_ID_KINTONE" :label="$t('WORD_MULTILINGUAL_API_SYNCHRONIZATION')" hide-details />
      </div>
      <div class="d-flex flex-row align-baseline">
        <div class="front-div">
          <v-select v-model="item.method" placeholder=" " dense hide-details="auto" :items="methodItems" :label="$t('WORD_MULTILINGUAL_API_METHOD')" class="ml-5" :disabled="!isValidRole" />
        </div>
        <v-text-field v-model="item.url" placeholder=" " dense hide-details="auto" label="URL" :disabled="!isValidRole" :rules="[required, https]" />
      </div>
    </div>
    <!-- ヘッダー -->
    <div class="mb-4">
      <div class="d-flex flex-row align-baseline">
        <div class="mb-4">{{ $t("WORD_MULTILINGUAL_API_HEADER") }}</div>
        <v-btn v-if="item.header.length<UPPER_LIMIT_HEADER_NUM" :disabled="!isValidRole" class="ma-2" color="info" rounded small text @click="addRow()">
          <v-icon small :disabled="!isValidRole" >mdi-plus</v-icon>
          {{ $t("WORD_MULTILINGUAL_API_HEADER_ADDITION") }}
        </v-btn>
      </div>
      <div v-for="(header, i) in item.header" :key="i" class="d-flex flex-row align-baseline mb-4">
        <div class="front-div">
          <v-text-field v-model="header.name" placeholder=" " dense hide-details="auto" :label="$t('WORD_MULTILINGUAL_API_HEADER_NAME')" :readonly="isContentTypeJson(header.name, header.value)" :disabled="!isValidRole" :rules="[required]" class="ml-5" />
        </div>
        <v-text-field v-model="header.value" placeholder=" " dense hide-details="auto" :label="$t('WORD_MULTILINGUAL_API_VALUE')" :readonly="isContentTypeJson(header.name, header.value)" :disabled="!isValidRole" :rules="[required]" />
        <v-icon small @click="deleteRow(i)" :disabled="!isValidRole || isContentTypeJson(header.name, header.value)" >mdi-close</v-icon>
      </div>
    </div>
    <!-- ボディ -->
    <div class="mb-4">
      <div class="mb-4">{{ $t("WORD_MULTILINGUAL_API_BODY") }}</div>
      <v-textarea v-model="item.body" placeholder=" " dense outlined hide-details="auto" :label="$t('WORD_MULTILINGUAL_API_DATA_FORMAT')" :disabled="!isValidRole" :rules="[required, jsonFormat]" class="ml-5" ></v-textarea>
    </div>
    <!-- テスト実行 -->
    <div class="mb-4">
      <div class="mb-4">{{ $t("WORD_MULTILINGUAL_API_TEST_EXECUTION") }}</div>
      <div class="d-flex flex-row align-baseline">
        <v-textarea v-model="testResult" placeholder=" " dense outlined hide-details="auto" :label="$t('WORD_MULTILINGUAL_API_EXECUTION_RESULTS')" class="ml-5" ></v-textarea>
        <v-btn color="info" :disabled="!isValidRole" @click="execTest()">{{ $t("WORD_MULTILINGUAL_API_EXECUTION") }}</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Define from "@/define.js";
import * as Util from "@/util/utils.js";
import { mixinValidator } from "@/mixins/mixinValidator.js";
import { mixinControl } from "@/mixins/mixinControl.js";
export default {
  components: {

  },
  mixins: [mixinValidator, mixinControl],
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      renkeisakiItems: this.getRenkeisaki(),
      methodItems: [
        // { text: Define.METHOD_NAME_GET,    value: Define.METHOD_ID_GET },
        // [STEP1] kintone限定対応のためPOST固定
        { text: Define.METHOD_NAME_POST,   value: Define.METHOD_ID_POST },
        // { text: Define.METHOD_NAME_PUT,    value: Define.METHOD_ID_PUT },
        // { text: Define.METHOD_NAME_PATCH,  value: Define.METHOD_ID_PATCH },
        // { text: Define.METHOD_NAME_DELETE, value: Define.METHOD_ID_DELETE }
      ],
      testResult: "",
      UPPER_LIMIT_HEADER_NUM: 5,
      // 入力規則
      required: value => this.isValidRequired(value),
      jsonFormat: value => this.isValidJsonFormat(value),
      https: value => this.isValidUrl(value),

      WEBHOOK_RENKEISAKI_ID_KINTONE: Define.WEBHOOK_RENKEISAKI_ID_KINTONE
    };
  },
  computed: {
    isValidRole() {
      let user = this.$store.getters["auth/user"];
      return Util.getAuthority(Define.EVENT_APPLICATION_SETTING, user.role);
    },
    selectedWorkspaceId() {
      return this.$store.getters["auth/selectedWorkSpaceId"];
    },
  },
  watch: {

  },
  methods: {
    addRow() {
      this.item.header.push({ name: "", value: ""});
    },
    deleteRow(index) {
      this.item.header.splice(index, 1);
    },
    cbChangeRenkeisaki(renkeisakiId) {
      if(renkeisakiId != this.WEBHOOK_RENKEISAKI_ID_KINTONE) {
        this.item.sync = false;
      }
    },
    
    isContentTypeJson(hName, hValue) {
      return hName == "Content-Type" && hValue == "application/json"
    },
    execTest() {
      // v-formのネストができないので無理やり親コンポーネントにアクセス
      if (!this.$parent.$parent.validate()) {
        Swal.fire({
          icon: "error",
          title: this.$t("MSG_ERR_INPUT_ITEM_INVALID"),
          text: this.$t("MSG_ERR_SUB_INPUT_ITEM_INVALID")
        });
        return;
      }

      let item = Util.deepCopy(this.item);

      // ヘッダーの整形
      let tmp = {}
      for(let header of item.header) {
        tmp[header.name] = header.value
      }
      item.header = tmp;

      // パース対策でJSON文字列化
      item.header = JSON.stringify(item.header);
      item.body = JSON.stringify(JSON.parse(item.body));

      Util.axiosPostReq(Define.API_WEBHOOK_TEST, { api_setting: item, work_space_id: this.selectedWorkspaceId}, false)
        .then(response => {
          // console.log(response);
          console.log(response); // ステータスコードのみ表示(秘匿情報は外部に出さない)
          // loadingダイアログ非表示
          this.$store.commit("setIsLoading", false);
          let code = response.data.code;
          let text = JSON.stringify(JSON.parse(response.data.text), null, "\t");
          this.testResult = `STATUS_CODE：${code}\n${text}`

        })
        .catch(error => {
          // loadingダイアログ非表示
          this.$store.commit("setIsLoading", false);
          this.testResult = error.message;
          for(let item of error.response.data) {
            this.testResult += "\n" + item;
          }
        });
    }
  }
};
</script>

<style scoped>
.front-div {
  width: 350px;
  margin-right: 5px;
}
</style>
