<template>
  <v-dialog v-model="isOpenCardDetail" :retain-focus="false" persistent>
    <v-form ref="form" v-model="valid" class="overlay carddetail">
      <div class="overlayarea scroll">
        <v-card flat color="#f0f0f0" class="cardsize">
          <div class="text-right">
            <v-btn :id="'closebtn_' + cardDetailInfo.tileId" icon @click="closeDetail">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <v-tabs
            v-model="tab"
            background-color="#f0f0f0"
            height="20"
            class="pb-2 px-2"
            ref="tabs"
          >
            <v-tab>{{ $t("TABNAME_CARD_INFO") }}</v-tab>
            <v-tab v-if="isVisibleTabJisseki">{{ $t("TABNAME_CARD_JISSEKI") }}</v-tab>
            <v-tab v-if="isVisibleTabHistory">{{ $t("TABNAME_CARD_HISTORY") }}</v-tab>
          </v-tabs>
          <v-tabs-items
            v-model="tab"
            class="px-2 py-2"
            :style="{ 'background-color': '#f0f0f0' }"
            :touchless="true"
          >
            <v-tab-item>
              <div class="subtitle">{{ $t("PAGE_TITLE_CARD_DETAIL") }}</div>
              <div class="pl-8 pr-4">
                <v-row class="pr-8">
                  <v-col col="6" class="pt-0 pb-1 pl-0">
                    <v-row align="center">
                      <span class="dispdetail1 font-weight-bold dispfont pl-3" :style="getStyleFontSize(DISPLAY_TYPE_CARD_DETAIL, displayFontSize)" >
                        {{ $t("WORD_DATE") }}
                      </span>
                      <div class="dispdetail2 pr-5">
                        <EditingItem
                          v-model="tempTileInfo.posCol"
                          :type="TYPE_DATE"
                          :bgcolor="'#fff'"
                          :rules="[]"
                          :hideDetails="true"
                          :editable="authCardAction"
                          @input="changeDate($event)"
                        ></EditingItem>
                      </div>
                    </v-row>
                  </v-col>
                  <v-col col="6" class="pt-0 pb-1 pl-0">
                    <v-row align="center">
                      <span class="dispdetail1 font-weight-bold dispfont pl-3" :style="getStyleFontSize(DISPLAY_TYPE_CARD_DETAIL, displayFontSize)" >
                        {{ $t("WORD_LINENAME") }}
                      </span>
                      <div class="dispdetail2 pr-4">
                        <v-select
                          v-model="tempTileInfo.posRow"
                          dense
                          outlined
                          hide-details
                          background-color="#fff"
                          :items="areaItems"
                          :editable="authCardAction"
                          @input="changeArea($event)"
                        ></v-select>
                      </div>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
              <v-card flat class="area1 scroll areamargin">
                <div class="datatable">
                  <v-row>
                    <div
                      v-for="detail in tempTileInfo.tileDetailData"
                      :key="detail.mappingId"
                      class="flexarea detail"
                    >
                      <div class="dispdetail1 d-flex flex-row">
                        <v-tooltip
                          bottom
                          open-delay="300"
                          color="rgba(60,64,67,0.95)"
                          transition="fade"
                        >
                          <template v-slot:activator="{ on: tbl }">
                            <div
                              :style="getStyleFontSize(DISPLAY_TYPE_CARD_DETAIL, displayFontSize)" 
                              class="font-weight-bold dispfont detailtitle"
                              v-on="tbl"
                            >
                              {{ getShortString( mappingName(detail.mappingId), 18, )[0] }}
                              <br />
                              {{ getShortString( mappingName(detail.mappingId), 18, )[1] }}
                            </div>
                          </template>
                          <label>{{ mappingName(detail.mappingId) }}</label>
                        </v-tooltip>
                        <div
                          v-if="isKeySettings(detail.mappingId)"
                          :style="{ color: 'red' }"
                        >
                          *
                        </div>
                      </div>
                      <div class="dispdetail2">
                        <EditingItem
                          v-model="detail.value"
                          :type="getType(detail.mappingId)"
                          :inputType="getInputType(detail.mappingId)"
                          :rules="rules(detail.mappingId)"
                          :items="listDataItems(getCategory(detail.mappingId), detail.value)"
                          :editable="authCardAction && getType(detail.mappingId) !== TYPE_CALC"
                          @update="changeTileDetail()"
                        ></EditingItem>
                      </div>
                    </div>
                  </v-row>
                  <v-row class="d-flex flex-row mt-10 mb-5" align="center">
                    <span class="px-3">{{ $t("PAGE_SUBTITLE_CARD_COLOR") }}</span>
                    <div class="selecter">
                      <v-select
                        v-model="tempTileInfo.color"
                        dense
                        outlined
                        hide-details="auto"
                        :items="colors"
                        item-value="color"
                        item-text="label"
                        :rules="[]"
                        :disabled="!authCardAction"
                      >
                        <template v-slot:selection="{ item }">
                          <div :style="colorBoxlStyle(item.color)"></div>
                          <span class="pl-2">{{ item.label }}</span>
                        </template>
                        <template v-slot:item="{ item }">
                          <div :style="colorBoxlStyle(item.color)"></div>
                          <span class="pl-2">{{ item.label }}</span>
                        </template>
                      </v-select>
                    </div>
                    <HelpButton
                      :helpobject="colorSpacifyHelpDialogData"
                    ></HelpButton>
                  </v-row>
                </div>
              </v-card>

              <div style="display:flex;align-items:center;">
                <div class="subtitle">{{ $t("PAGE_TITLE_CARD_ASSIST") }}</div>
                <HelpButton :helpobject="auxiliaryHelpDialogData"></HelpButton>
              </div>
              <v-card flat class="area2 scroll areamargin">
                <v-row no-gutters class="px-3 py-1">
                  <v-col
                    v-for="(file, index) in fileList"
                    :key="`fileConrol-${index}`"
                    cols="3"
                  >
                    <span class="pl-1 no">No.{{ index + 1 }}</span>
                    <FileControl ref="fileControl" :parameter="file" :editable="authCardAction" @set-file-size="setFileSize($event)" />
                  </v-col>
                </v-row>
              </v-card>

              <div class="areamargin flexarea">
                <v-btn :disabled="!authCardAction" v-if="!isAddCard" class="success" @click="copyCard" v-shortkey.once="['shift', 'c']" @shortkey="keyCopy()">{{ $t("WORD_COPY") }}</v-btn>
                <v-btn :disabled="!authCardAction" v-if="!isAddCard" class="warning" @click="copyMultipleCard" v-shortkey.once="['shift', 'm']" @shortkey="keyMultiCopy()">{{ $t("WORD_COPY_MULTIPLE") }}</v-btn>
                <v-spacer></v-spacer>
                <div v-if="getOption002 && !isAddCard" :class="setCheckWidth">
                  <v-checkbox :disabled="!authCardAction" v-model="tempTileInfo.isConfirmed" color="blue" :label="$t('WORD_CONFIRM')" class="checkmargin" hide-details="true"></v-checkbox>
                </div>
                <div v-if="!isAddCard" :class="setCheckWidth">
                  <v-checkbox :disabled="!authCardAction" v-model="tempTileInfo.isComplete" color="blue" :label="$t('WORD_COMPLETE')" class="checkmargin" hide-details="true"></v-checkbox>
                </div>
                <v-btn :disabled="!authCardAction" v-if="isAddCard" class="info" @click="createCard" v-shortkey.once="['shift', 's']" @shortkey="keyCreate()">{{ $t("WORD_CREATE") }}</v-btn>
                <v-btn :disabled="!authCardAction" v-if="!isAddCard" class="info"  @click="updateCard" v-shortkey.once="['shift', 's']" @shortkey="keyUpdate()">{{ $t("WORD_UPDATE") }}</v-btn>
                <v-btn :disabled="!authCardAction" v-if="!isAddCard" class="error" @click="deleteCard" v-shortkey.once="['shift', 'd']" @shortkey="keyDelete()">{{ $t("WORD_DELETE") }}</v-btn>
              </div>
            </v-tab-item>
            <!-- 実績 -->
            <v-tab-item v-if="isVisibleTabJisseki">
              <CardDetailJisseki />
            </v-tab-item>
            <!-- カード履歴 -->
            <v-tab-item v-if="isVisibleTabHistory">
              <CardDetailHistory />
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </div>
    </v-form>
  </v-dialog>
</template>

<script>
import Define from "@/define.js";
import * as Util from "@/util/utils.js";
import Swal from "sweetalert2";
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("tile");
const { mapGetters } = createNamespacedHelpers("setting");
import HelpButton from "@/components/parts/HelpButton";
import EditingItem from "@/components/parts/EditingItem";
import FileControl from "@/components/parts/FileControl";
import CardDetailJisseki from "@/components/parts/CardDetailJisseki";
import CardDetailHistory from "@/components/parts/CardDetailHistory";
import { mixinControl } from "@/mixins/mixinControl.js";
import { mixinError } from "@/mixins/mixinError.js";
export default {
  components: {
    HelpButton,
    EditingItem,
    FileControl,
    CardDetailJisseki,
    CardDetailHistory
  },
  mixins: [mixinControl, mixinError],
  data() {
    return {
      valid: true,
      tab: Define.CARD_DETAIL_TAB_INFO,
      tempTileInfo: {},
      fileList: [
        // eslint-disable-next-line prettier/prettier
        { tileId: "", index: 0, isEdited: false, fileDataId: "", name: "", source: "", version: 0, size: 0 },
        // eslint-disable-next-line prettier/prettier
        { tileId: "", index: 0, isEdited: false, fileDataId: "", name: "", source: "", version: 0, size: 0 },
        // eslint-disable-next-line prettier/prettier
        { tileId: "", index: 0, isEdited: false, fileDataId: "", name: "", source: "", version: 0, size: 0 },
        // eslint-disable-next-line prettier/prettier
        { tileId: "", index: 0, isEdited: false, fileDataId: "", name: "", source: "", version: 0, size: 0 }
      ],
      isTileDetailChange: false,

      isDateChange: false,
      isAreaChange: false,

      DISPLAY_TYPE_CARD_DETAIL: Define.DISPLAY_TYPE_CARD_DETAIL,

      TYPE_DATE: Define.TYPE_DATE,
      TYPE_CALC: Define.TYPE_CALC
    };
  },
  computed: {
    ...mapGetters(["getMappingList"]),
    isOpenCardDetail() {
      return this.$store.getters["tile/isOpenCardDetail"];
    },
    cardDetailInfo() {
      return this.$store.getters["tile/cardDetailInfo"];
    },
    colors() {
      return this.getCardColors();
    },
    isAddCard() {
      return this.$store.getters["tile/isAddCard"];
    },
    deleteButtonDisplayFlg() {
      return this.$store.getters["deleteButtonDisplayFlg"];
    },
    getType() {
      return id => {
        return this.$store.getters["setting/getMappingType"](id);
      };
    },
    getOption002() {
      let option = this.$store.getters["option/getOption"];
      return option.option002;
    },
    getNextCardStatus() {
      return Util.getNextCardStatusByDetail(this.tempTileInfo.status, this.getOption002, this.tempTileInfo.isConfirmed);
    },
    authCardAction() {
      let user = this.$store.getters["auth/user"];
      return Util.getAuthority(Define.EVENT_CARD_ACTION, user.role);
    },
    hasKeySettings() {
      return Util.hasKeySettings(this.getMappingList);
    },
    isKeySettings() {
      return mappingId => {
        return Util.isKeySettings(this.getMappingList, mappingId);
      };
    },
    mappingKeyList() {
      return this.getMappingList.filter(item => item.key);
    },
    rules() {
      return mappingId => {
        if (Util.isKeySettings(this.getMappingList, mappingId)) {
          return [
            value => Util.checkNotEmpty(value) || this.$t("V_ERR_MSG_KEY_IS_EMPTY")
          ];
        } else {
          return [];
        }
      };
    },
    selectedWorkspaceId() {
      return this.$store.getters["auth/selectedWorkSpaceId"];
    },
    getInputType() {
      return id => {
        let inputType = this.$store.getters["setting/getMappingInputType"](id);
        // カード詳細画面では、「標準入力」及び「リスト選択入力」以外は見た目に統一感がなくなるので「標準入力」に戻す
        if (
          inputType !== Define.INPUT_TYPE_DEFAULT &&
          inputType !== Define.INPUT_TYPE_LIST
        ) {
          inputType = Define.INPUT_TYPE_DEFAULT;
        }
        return inputType;
      };
    },
    listDataItems() {
      return (category, value) => {
        let items = this.$store.getters["info/getInfoDataItems"](category);
        // 設定値が現在選択中のカテゴリに存在しない場合はリストの先頭に追加する
        if (value !== "" && !items.some(d => d.text === value)) {
          items.unshift({ text: value, value: value });
        }
        return items;
      };
    },
    getCategory() {
      return id => {
        return this.$store.getters["setting/getMappingCategory"](id);
      };
    },
    isVisibleTabJisseki() {
      let isValidJisseki = this.$store.getters["setting/getIsValidJisseki"];
      return !this.isAddCard && isValidJisseki;
    },
    isVisibleTabHistory() {
      return !this.isAddCard;
    },
    isUpdatingJisseki() {
      return this.$store.getters["isUpdatingJisseki"];
    },
    isDeletingJisseki() {
      return this.$store.getters["isDeletingJisseki"];
    },
    displayFontSize() {
      return this.$store.getters["display/displayFontSize"];
    },
    setCheckWidth() {
      if (this.$i18n.locale == "ja") {
        return "checkwidth";
      } else {
        return "checkwidthen";
      }
    },
    colorSpacifyHelpDialogData() {
      return this.$t("EXPLANATION_DIALOG.colorSpacifyHelpDialogData");
    },
    auxiliaryHelpDialogData() {
      return this.$t("EXPLANATION_DIALOG.auxiliaryHelpDialogData");
    },
    tenant() {
      return this.$store.getters["tenant/getTenantData"];
    },
    tenantLimitFileSize() {
      // テナントごとのファイルサイズ上限をメガバイトからバイトに変換して返す
      return this.$store.getters["tenant/getTenantData"].fileSizeLimit * 1024 * 1024;
    },
    areaItems() {
      let items = [
        { text: "", value: "" }
      ];
      let areas = this.$store.getters["setting/getAreaList"];
      if(areas){
        areas = areas.filter(d => d.order != "");
        if (areas.length) {
          areas.sort(function(a, b) {
            if (a.order < b.order) return -1;
            if (a.order > b.order) return 1;
            return 0;
          });
        }
        for (let area of areas) {
          items.push({ text: area.name, value: area.areaId });
        }
      }
      return items;
    },
    freeTileNum() {
      return this.$store.getters["tile/getFreeTileNum"];
    }
  },
  watch: {
    isOpenCardDetail: {
      immediate: true,
      handler: function() {
        if (this.isOpenCardDetail) {
          this.tempTileInfo = Util.parseObject(this.cardDetailInfo);
          this.tempTileInfo.posCol = this.tempTileInfo.posCol ? Util.convertDateFormat(this.tempTileInfo.posCol, Define.DATE_FORMAT_DAY_SLASH)  : "";
          this.tempTileInfo.posRow = this.tempTileInfo.posRow ? this.tempTileInfo.posRow : "";
          this.tab = Define.CARD_DETAIL_TAB_INFO;
          this.isTileDetailChange = false;
          this.isDateChange = false;
          this.isAreaChange = false;
          this.setImageControls();
          if (this.$refs.tabs) {
            this.$refs.tabs.callSlider();
          }
        } else {
          this.tempTileInfo = [];
        }
      }
    },
    isUpdatingJisseki: {
      immediate: true,
      handler: function() {
        if (!this.isUpdatingJisseki) {
          this.tempTileInfo.isComplete = Util.parseObject(this.cardDetailInfo).isComplete;
          this.tempTileInfo.version = Util.parseObject(this.cardDetailInfo).version;
        }
      }
    },
    isDeletingJisseki: {
      immediate: true,
      handler: function() {
        if (!this.isDeletingJisseki) {
          this.tempTileInfo.version = Util.parseObject(this.cardDetailInfo).version;
        }
      }
    }
  },
  beforeMount() {
    this.tempTileInfo = Util.parseObject(this.cardDetailInfo);
  },
  methods: {
    ...mapActions([
      "deleteTile",
      "createTileData",
      "detailUpdateTile",
      "getTileMoveHistoryData"
    ]),
    closeDetail() {
      this.$store.commit("tile/setIsOpenCardDetail", false);
      this.$store.dispatch("removeOpenDetailTileId");
    },
    /**
     * マッピングIDからデータ名称文字列を返す
     * @param {String} id - マッピングID
     * @returns {String}} - データ名称
     */
    mappingName(id) {
      if (!id) return ""; // 描画のタイミング（初期化時）を考慮
      return this.$store.getters["setting/getMappingName"](id);
    },
    /**
     * 文字列を改行・省略表示する
     * @param {String} target - 対象文字列
     * @param {Number}} newLineNo - 改行位置
     * @param {Number} lastNo - 最終位置(以下...)
     * @return {Array}} - 結果
     */
    getShortString(target, newLineNo, lastNo) {
      return Util.getShortString(target, newLineNo, lastNo);
    },
    /**
     * カード削除イベント
     */
    deleteCard() {
      Swal.fire({
        icon: "question",
        html: this.$t("MSG_DELETE_CONFIRM"),
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          let result = [
            {
              tile_id: this.tempTileInfo.tileId
            }
          ];
          this.deleteTile(result);
          this.closeDetail();
          // 削除したカードがそのページにある一枚のタイルだった場合、前のページに遷移する
          let freeAreaTileNum = this.freeTileNum - 1;
          if (
            freeAreaTileNum % Define.PLANAREA_MAX_PAGE_NUM === 0 &&
            freeAreaTileNum !== 0
          ) {
            this.$store.commit(
              "tile/setPageNo",
              freeAreaTileNum / Define.PLANAREA_MAX_PAGE_NUM
            );
          }
        }
      });
    },
    /**
     * カード作成イベント
     */
    async createCard() {
      if (!this.$refs.form.validate()) {
        this.showErrorMsg(Define.ERR_INPUT_ITEM_INVALID);
        return;
      }
      if(!this.checkFileSize()) {
        Swal.fire({
          html: this.$t("V_ERR_MSG_MAX_FILE_SIZE", { size: Util.convertSizeLabel(this.tenantLimitFileSize) }),
          icon: "error"
        });
        return;
      }
      const fileDataList = [];
      this.fileList.forEach(element => {
        if (element.isEdited && element.source) {
          fileDataList.push(element);
        }
      });
      let code = Define.SUCCESS;

      // カード位置情報の整形
      const posColAfter = Util.convertDateFormat(this.tempTileInfo.posCol, Define.DATE_FORMAT_DAY_HYPHEN);
      const posRowAfter = this.tempTileInfo.posRow && this.tempTileInfo.posCol ? this.tempTileInfo.posRow : null;

      // 未計画エリアへのカード作成時は上限チェック
      if(posColAfter == null && this.freeTileNum >= Define.FREEAREA_MAX_TILENUM){
        Swal.fire({
          title: this.$t("MSG_ERR_MAIN_TILE_ADD"),
          html: this.$t("MSG_ERR_SUB_TILE_ADD"),
          icon: "error"
        });
        return;
      }

      let result = [
        {
          pos_col: posColAfter,
          pos_row: posRowAfter,
          is_complete: false,
          color: this.tempTileInfo.color,
          order: "0",
          is_confirmed: false,
          status: 0,
          tile_detail_data: [],
          file_data_list: fileDataList,
          tile_move_history_data: {
            history_type: Define.HISTORY_TYPE_MOVED,
            pos_col_before: null,
            pos_col_after: posColAfter,
            pos_row_before: null,
            pos_row_id_after: posRowAfter,
            pos_row_after: this.$store.getters["setting/getAreaName"](posRowAfter)
          }
        }
      ];
      if (!Util.checkArrayLength(this.tempTileInfo.tileDetailData)) {
        this.showErrorMsg(Define.ERR_MAP_NO_SETTINGS);
      } else {
        for (let i in this.tempTileInfo.tileDetailData) {
          let dataType = this.$store.getters["setting/getMappingType"](
            this.tempTileInfo.tileDetailData[i].mappingId
          );
          let value =
            dataType === Define.TYPE_FLOAT &&
            Util.checkNotEmpty(this.tempTileInfo.tileDetailData[i].value)
              ? String(Util.computeRoundNum(
                  this.tempTileInfo.tileDetailData[i].value,
                  Define.ROUND_NUM
                ))
              : this.tempTileInfo.tileDetailData[i].value;
          let get = {
            mapping_id: this.tempTileInfo.tileDetailData[i].mappingId,
            value: value
          };
          result[0].tile_detail_data.push(get);
        }
        if (code === Define.SUCCESS && this.hasKeySettings) {
          // 同一キーのカードを検索 ⇒ 存在すればエラー
          if (await this.checkSameKeyTile(result[0])) {
            code = Define.ERR_VIOLATE_UNIQUE_KEY;
          }
        }

        if (code !== Define.SUCCESS) {
          this.showErrorMsg(code);
        } else {
          // 新規作成されるタイルは未計画エリアの末尾に配置する
          let freeAreaTileNum = this.freeTileNum;
          result[0].order = String(freeAreaTileNum + 1);
          this.createTileData({ tileData: result });
          this.closeDetail();
          if(posColAfter == null){
            let pageNum = Math.ceil((this.freeTileNum + 1) / Define.PLANAREA_MAX_PAGE_NUM);
            this.$store.commit("tile/setPageNo", pageNum);
          }
        }
      }
    },
    /**
     * カード詳細更新イベント
     */
    async updateCard() {
      if (!this.$refs.form.validate()) {
        this.showErrorMsg(Define.ERR_INPUT_ITEM_INVALID);
        return;
      }
      if(!this.checkFileSize()) {
        Swal.fire({
          html: this.$t("V_ERR_MSG_MAX_FILE_SIZE", { size: Util.convertSizeLabel(this.tenantLimitFileSize) }),
          icon: "error"
        });
        return;
      }
      const fileDataList = [];

      const beforeTileDatail = this.cardDetailInfo.tileDetailData.map(item => {
          let dataType = this.$store.getters["setting/getMappingType"](
            item.mappingId
          );
          return {
            tile_detail_id: item.tileDetailId,
            mapping_id: item.mappingId,
            value:
              dataType === Define.TYPE_FLOAT && Util.checkNotEmpty(item.value)
                ? String(Util.computeRoundNum(item.value, Define.ROUND_NUM))
                : item.value,
            version: item.version
          };
      });
      const afterTileDatail = this.tempTileInfo.tileDetailData.map(item => {
        let dataType = this.$store.getters["setting/getMappingType"](
          item.mappingId
        );
        return {
          tile_detail_id: item.tileDetailId,
          mapping_id: item.mappingId,
          value:
            dataType === Define.TYPE_FLOAT && Util.checkNotEmpty(item.value)
              ? String(Util.computeRoundNum(item.value, Define.ROUND_NUM))
              : item.value,
          version: item.version
        };
      });
      //更新前と更新後のパラメータを比較し、変化したパラメータをリストに格納
      let tileDetailChangeList = this.checkTileDatailChange(beforeTileDatail, afterTileDatail);

      let isFreeArea = this.tempTileInfo.posCol === null;
      let isChangeConfirmed = this.cardDetailInfo.isConfirmed != this.tempTileInfo.isConfirmed; // 確定状態に変更が発生するか
      let isChangeComplete = this.cardDetailInfo.isComplete != this.tempTileInfo.isComplete;    // 完了状態に変更が発生するか

      // 未計画エリアのカードを確定状態に変更しようとした場合はエラー表示
      if (isFreeArea && isChangeConfirmed && this.tempTileInfo.isConfirmed) {
        this.showErrorMsg(Define.ERR_UPDATE_STATUS_CONFIRM);
        return;
      }

      // 未計画エリアのカードを完了状態に変更しようとした場合はエラー表示
      if (isFreeArea && isChangeComplete && this.tempTileInfo.isComplete) {
        this.showErrorMsg(Define.ERR_UPDATE_STATUS_COMPLETE);
        return;
      }

      this.fileList.forEach(element => {
        if (element.isEdited) {
          fileDataList.push(element);
        }
      });

      // カード位置情報の整形
      const posColBefore = Util.convertDateFormat(this.cardDetailInfo.posCol, Define.DATE_FORMAT_DAY_HYPHEN);
      const posColAfter = Util.convertDateFormat(this.tempTileInfo.posCol, Define.DATE_FORMAT_DAY_HYPHEN);
      const posRowBefore = this.cardDetailInfo.posRow ? this.cardDetailInfo.posRow : null;
      const posRowAfter = this.tempTileInfo.posRow && this.tempTileInfo.posCol ? this.tempTileInfo.posRow : null;
      // 日付 = 未設定の時、エリア名も自動的に未設定に登録する場合、エリア名の変更状態を再判定する
      // ※初期位置 = 未計画エリアであれば、エリア名 = 未変更
      if (!posColAfter && posRowAfter == posRowBefore) {
        this.isAreaChange = false;
      }

      const result = {
        tile_id: this.tempTileInfo.tileId,
        pos_col: posColAfter,
        pos_row: posRowAfter,
        order: this.tempTileInfo.order,
        is_complete: this.tempTileInfo.isComplete,
        is_confirmed: this.tempTileInfo.isConfirmed,
        status: this.getNextCardStatus,
        color: this.tempTileInfo.color,
        version: this.tempTileInfo.version,
        tile_detail_data: afterTileDatail,
        file_data_list: fileDataList
      };
      if (isChangeComplete) {
        result.tile_complete_history_data = {
          history_type: Define.HISTORY_TYPE_COMPLETE,
          flg_type: Define.FLG_TYPE_COMPLETE,
          flg_before: this.cardDetailInfo.isComplete,
          flg_after: this.tempTileInfo.isComplete
        };
      }
      if (isChangeConfirmed) {
        result.tile_confirmed_history_data = {
          history_type: Define.HISTORY_TYPE_CONFIRMED,
          flg_type: Define.FLG_TYPE_CONFIRMED,
          flg_before: this.cardDetailInfo.isConfirmed,
          flg_after: this.tempTileInfo.isConfirmed
        };
      }
      if (tileDetailChangeList.length > 0) {
        result.tile_detail_change_history_data = {
          history_type: Define.HISTORY_TYPE_DETAIL_CHANGED,
          tile_detail_change_list: tileDetailChangeList
        };
      }
      if (this.hasKeySettings) {
        // 同一キーのカードを検索 ⇒ 存在すればエラー
        if (await this.checkSameKeyTileForUpdate(result)) {
          this.showErrorMsg(Define.ERR_VIOLATE_UNIQUE_KEY);
          return;
        }
      }
      // 未計画エリアへのカード移動時は上限チェック
      if(this.isDateChange && posColAfter == null && this.freeTileNum >= Define.FREEAREA_MAX_TILENUM){
        Swal.fire({
          title: this.$t("MSG_ERR_MAIN_UPDATE"),
          html: this.$t("MSG_ERR_SUB_MOVE"),
          icon: "error"
        });
        return;
      }
      if (this.isDateChange || this.isAreaChange) {
        // カードの位置情報（日付、あるいは、エリア名）が変更された場合、カード移動履歴情報を追加する
        result.tile_move_history_data = {
          history_type: Define.HISTORY_TYPE_MOVED,
          pos_col_before: posColBefore,
          pos_col_after: posColAfter,
          pos_row_id_before: posRowBefore,
          pos_row_id_after: posRowAfter,
          pos_row_before: this.$store.getters["setting/getAreaName"](posRowBefore),
          pos_row_after: this.$store.getters["setting/getAreaName"](posRowAfter)
        };
      }
      this.detailUpdateTile({ tileData: [result] });
      this.closeDetail();

    },
    async getTileDataByKey(tileData) {
      const workSpaceId = this.selectedWorkspaceId;
      const data = {
        work_space_id: workSpaceId,
        key_value_list: tileData.tile_detail_data.filter(item =>
          this.mappingKeyList.some(
            mapping => mapping.mappingId == item.mapping_id
          )
        )
      };
      return await Util.axiosPostReq(
        Define.API_GET_TILE_DATE_BY_KEY,
        data,
        false
      );
    },
    async checkSameKeyTile(tileData) {
      const response = await this.getTileDataByKey(tileData);
      // データが空じゃない場合（つまり同一キーのデータが存在）
      return Object.keys(response.data).length > 0;
    },
    async checkSameKeyTileForUpdate(tileData) {
      const response = await this.getTileDataByKey(tileData);
      // データが空じゃない場合（つまり同一キーのデータが存在）
      // 当該カードと一致する場合はエラーとしない
      return (
        Object.keys(response.data).length > 0 &&
        response.data.tile.tileId != tileData.tile_id
      );
    },
    showErrorMsg(code) {
      if (code === Define.ERR_MAP_NO_SETTINGS) {
        Swal.fire({
          html: this.$t("MSG_SETTING_MAP_DATA"),
          icon: "error"
        });
      } else {
        let msg = this.createErrorMessageBySettingSave(0, code);
        Swal.fire({
          title: msg.mainText,
          text: msg.subText,
          icon: "error"
        });
      }
    },
    colorBoxlStyle(color) {
      return [Define.STYLE_COLOR_BOX, { "background-color": color }];
    },
    setImageControls() {
      for (let i = 0; i < this.fileList.length; i++) {
        this.fileList[i].tileId = this.tempTileInfo.tileId;
        this.fileList[i].index = i;
        this.$nextTick(() => {
          this.$refs.fileControl[i].initialize();
        });
      }
    },
    copyCard() {
      this.tempTileInfo = Util.parseObject(this.cardDetailInfo);
      this.tempTileInfo.posCol = this.tempTileInfo.posCol ? Util.convertDateFormat(this.tempTileInfo.posCol, Define.DATE_FORMAT_DAY_SLASH) : "";
      this.tempTileInfo.posRow = this.tempTileInfo.posRow ? this.tempTileInfo.posRow : "";
      this.tab = Define.CARD_DETAIL_TAB_INFO;
      this.fileCopy();
      this.$store.dispatch("setOpenDetailTileId", null);
      this.$store.dispatch("tile/getTileMoveHistoryData", null);
      this.$store.commit("tile/setIsAddCard", true);
    },
    fileCopy() {
      for (let i = 0; i < this.fileList.length; i++) {
        if (this.fileList[i].fileDataId !== undefined) {
          this.fileList[i].isEdited = true;
          this.fileList[i].fileDataId = "";
          this.fileList[i].tileId = "";
          this.fileList[i].version = 1;
        }
      }
    },
    copyMultipleCard() {
      this.fileCopy();
      this.$store.commit("tile/setcopyMultipleCardFileList", this.fileList);
      this.$store.commit("tile/setIsOpenCardDetailMultipleCopy", true);
      this.closeDetail();
    },
    /**
     * フォントサイズのスタイルを取得する
     * @param {Number} type
     * @param {Number} size
     * @returns {String}
     */
    getStyleFontSize(type, size) {
      return Util.getStyleFontSize(type, size);
    },
    /**
     * カード更新時に詳細部分の内容が変化しているか判別し、変化したパラメータを格納する
     * @param {list} beforeTileDetail
     * @param {list} afterTileDetail
     * @returns {boolean}
     */
    checkTileDatailChange(beforeTileDatail, afterTileDatail) {
      let result = [];
      for (let i = 0; i < beforeTileDatail.length; i++) {
        if (beforeTileDatail[i].value != afterTileDatail[i].value) {
          result.push({
            param_name: this.$store.getters["setting/getMappingName"](beforeTileDatail[i].mapping_id),
            param_before: beforeTileDatail[i].value,
            param_after: afterTileDatail[i].value
          });
        }
      }
      return result;
    },
    /**
     * 各ファイルのサイズを取得する
     */
    setFileSize(param) {
      let index = param.index;
      let fileSize = param.size;
      this.fileList[index].size = fileSize;
    },
    /**
     * ファイルサイズ上限を超えるか確認
     */
    checkFileSize() {
      let result = true;
      let totalFileSize = 0;
      for (let i = 0; i < this.fileList.length; i++) {
        totalFileSize += this.fileList[i].size;
      }

      if (totalFileSize > this.tenantLimitFileSize){
        result = false;
      }
      return result;
    },
    /**
     * カード詳細情報が変更される際に、変更前後のカード詳細を比較し、変化しているか判別する
     * 1つの詳細情報でも変更される場合、変化したと判別する
     */
    changeTileDetail() {
      const beforeTileDatail = Util.deepCopy(this.cardDetailInfo.tileDetailData);
      const afterTileDatail = Util.deepCopy(this.tempTileInfo.tileDetailData);
      for (let i = 0; i < beforeTileDatail.length; i++) {
        if (beforeTileDatail[i].value != afterTileDatail[i].value) {
          this.isTileDetailChange = true;
          break;
        }
        this.isTileDetailChange = false;
      }
      // カード確定状態を変別して変更する
      this.changeIsConfirmed();
    },
    /**
     * カードの日付が変更される際に、変更前後の日付を比較し、変化しているか判別する
     */
    changeDate(value) {
      // 日付が未設定に変更されると、エリア名も未設定にする
      if (value == "") {
        this.tempTileInfo.posRow = "";
        // エリア名を未設定した後、エリア名の変更状態を再判定する
        this.changeArea("");
      }
      // 変更前後の日付整形
      let posColBefore = Util.convertDateFormat(this.cardDetailInfo.posCol, Define.DATE_FORMAT_DAY_HYPHEN);
      let posColAfter = Util.convertDateFormat(value, Define.DATE_FORMAT_DAY_HYPHEN);
      // 日付が異なると、isDateChange = true
      if (posColAfter == posColBefore) {
        this.isDateChange = false;
      }
      else {
        this.isDateChange = true;
      }
      // カード確定状態を変別して変更する
      this.changeIsConfirmed();
    },
    /** 
    * カードのエリア名が変更される際に、変更前後のエリアを比較し、変化しているか判別する
    */
    changeArea(value) {
      // 変更前後のエリア情報整形
      let posRowBefore = this.cardDetailInfo.posRow ? this.cardDetailInfo.posRow : null;
      let posRowAfter = value ? value : null;
      // エリアが異なると、isAreaChange = true
      if (posRowBefore == posRowAfter) {
        this.isAreaChange = false;
      }
      else {
        this.isAreaChange = true;
      }
      // カード確定状態を変別して変更する
      this.changeIsConfirmed();
    },
    /**
     * カード詳細情報、日付、エリア名、変化しているか判別して、カード確定状態を変更する
     */
    changeIsConfirmed() {
      // 変更通知機能 = ON の時のみ、カード確定状態を操作する
      if (!this.getOption002) { return; }

      // 詳細情報、日付、エリア名、いずれか変更されている場合、確定状態を解除する
      if (this.isTileDetailChange || this.isDateChange || this.isAreaChange) {
        this.tempTileInfo.isConfirmed = false;
      }
      // 詳細情報、日付、エリア名、全てが変更されていない場合、確定状態を初期状態に戻す
      else {
        this.tempTileInfo.isConfirmed = this.cardDetailInfo.isConfirmed;
      }
    },
    /**
     * [ショートカットキー shift+c]複製
     */
    keyCopy() {
      if(this.authCardAction && this.isOpenCardDetail && this.tab == Define.CARD_DETAIL_TAB_INFO){
        this.copyCard();
      }
    },
    /**
     * [ショートカットキー shift+m]一括複製
     */
    keyMultiCopy() {
      if(this.authCardAction && this.isOpenCardDetail && this.tab == Define.CARD_DETAIL_TAB_INFO){
        this.copyMultipleCard();
      }
    },
    /**
     * [ショートカットキー shift+s]作成
     */
    keyCreate() {
      if(this.authCardAction && this.isOpenCardDetail && this.tab == Define.CARD_DETAIL_TAB_INFO){
        this.createCard();
      }
    },
    /**
     * [ショートカットキー shift+s]更新
     */
    keyUpdate() {
      if(this.authCardAction && this.isOpenCardDetail && this.tab == Define.CARD_DETAIL_TAB_INFO){
        this.updateCard();
      }
    },
    /**
     * [ショートカットキー shift+d]削除
     */
    keyDelete() {
      if(this.authCardAction && this.isOpenCardDetail && this.tab == Define.CARD_DETAIL_TAB_INFO){
        this.deleteCard();
      }
    }
  }
};
</script>

<style scoped>
.overlay {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
.overlayarea {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.cardsize {
  margin-top: 20px;
  width: 1700px;
  max-height: 900px;
}
.areamargin {
  margin: 0px 10px 5px 10px;
}
.area1 {
  max-height: 225px;
  border: solid 1px #aaa;
}
.area2 {
  height: 435px;
  border: solid 1px #aaa;
}
.subtitle {
  border-left: solid 6px #1976d2ca;
  color: #333;
  margin: 2px 4px 2px 4px;
  padding-left: 2px;
}
.scroll {
  overflow: auto;
  white-space: nowrap;
}
.checkwidth {
  width: 70px;
  height: 30px;
  margin-right: 4px;
}
.checkwidthen {
  width: 105px;
  height: 30px;
  margin-right: 4px;
}
.checkmargin {
  margin-top: 2px;
}
.datatable {
  width: 99%;
  padding: 0 10px;
  right: 0;
}
.detail {
  width: 50%;
  height: auto;
  padding: 2px 10px 2px 10px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
}
.dispdetail1 {
  width: 45%;
  height: 100%;
}
.dispdetail2 {
  width: 55%;
  height: 100%;
}
.dispfont {
  color: #333;
}
.detailtitle {
  margin-top: 3px;
}
.selecter {
  margin-left: 25px;
  width: 250px;
  background-color: "#fff";
}
.no {
  font-size: 12px;
}
</style>
