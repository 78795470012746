<template>
  <div class="calendar">
    <div v-if="!isValidRole" class="attention">
      <v-icon color="error">mdi-alert</v-icon>
      {{ $t("TEXT_CALENDAR_SETTING_ROLE") }}
    </div>
    <div class="areatitle">
        {{ $t("PAGE_TITLE_APPLICATION_SETTING_CALENDAR") }}
        <HelpButton :helpobject="calendarHelpDialogData"></HelpButton>
      </div>
    <div class="area-flame">
      <!-- DatePickMonth -->
      <DatePickMonth ref="datePickMonth" class="mt-2" @setDate="setHeader($event)"/>
      <!-- ヘッダー部 -->
      <div class="days-headerarea mt-2">
        <div v-for="(day, index) in days" :key="index" :class="(day.isToday) ? 'days-header-today' : 'days-header'">
          {{ day.day }}
        </div>
      </div>
      <v-form ref="form">
        <!-- データ部・エリア単位データ -->
        <div class="list-scroll">
          <ApplicationSettingCalendarLineList 
            v-for="calendarSettingListByArea in calendarSettingList"
            :calendarSettingListByArea="calendarSettingListByArea"
            :key="calendarSettingListByArea.lineId"
            :isValidRole="isValidRole"
            :calcProgressDataType="getTypeFromMappingId(settingData.calcProgressData)"
            :calcObject1Type="getTypeFromMappingId(settingData.calcProgressData) === TYPE_CALC ? getCalcObject1Type(settingData.calcProgressData) : null"  
          />
        </div>
      </v-form>
    </div>
    <!-- 保存ボタン -->
    <div class="dataarea">
      <v-btn :disabled="!isValidRole" class="info savebtn" @click="save()">
        {{ $t("WORD_APPLICATION_SETTING_SAVE") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import Define from "@/define.js";
import moment from "moment";
import * as Util from "@/util/utils.js";
import Swal from "sweetalert2";
import DatePickMonth from "@/components/parts/DatePickMonth.vue";
import ApplicationSettingCalendarLineList from "@/components/parts/ApplicationSettingCalendarLineList";
import HelpButton from "@/components/parts/HelpButton";
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("calendarSetting");
export default {
  components: {
    HelpButton,
    DatePickMonth,
    ApplicationSettingCalendarLineList
  },
  props: {
    tab: Boolean
  },
  data() {
    return {
      target: null,
      calendarSettingList: []
    };
  },
  computed: {
    isValidRole () {
      let user = this.$store.getters["auth/user"];
      let role = this.$store.getters["role/roleByWorkspaceId"](this.selectedWorkspaceId, user.role);
      return Util.getAuthority(Define.EVENT_CALENDAR_SETTING, role);
    },
    selectedWorkspaceId() {
      return this.$store.getters["auth/selectedWorkSpaceId"];
    },
    calendarHelpDialogData() {
      return this.$t("EXPLANATION_DIALOG.applicationSettingCalendarHelp");
    },
    calendarSettingData () {
      return this.$store.getters["calendarSetting/getCalendarSettingData"] (this.startDate.format(Define.DATE_FORMAT_DAY_HYPHEN), this.endDate.format(Define.DATE_FORMAT_DAY_HYPHEN));
    },
    startDate () {
      return (this.target !== null) ? this.target.startOf('month').clone() : moment();
    },
    endDate () {
      return (this.target !== null) ? this.target.endOf('month').clone() : moment();
    },
    days () {
      let result = [];
      let today = moment();
      if(this.target !== null){
        for (let date = this.startDate; date <= this.endDate; date = moment(date).add(1, "days")) {
          result.push({ day: date.format("D"), isToday: date.isSame(today, 'day') });
        }
      }
      return result;
    },
    settingData() {
      return this.$store.getters["setting/getSettingData"];
    },
    // 計算対象１のデータ形式
    getCalcObject1Type() {
      return mappingId => {
        return this.$store.getters["setting/getCalcObject1Type"](mappingId);
      };
    }
  },
  watch: {
    tab: function(isOpen) {
      if(isOpen){
        let locale = this.$i18n.locale;
        moment.locale(locale);
        this.target = moment();
        if(this.$refs.datePickMonth){
          this.$refs.datePickMonth.initMonth()
        }
      }
    },
    target: function() {
      let condition = {
        startDate: this.startDate.format(Define.DATE_FORMAT_DAY_SLASH_CHECK),
        endDate: this.endDate.format(Define.DATE_FORMAT_DAY_SLASH_CHECK)
      };
      this.$store.dispatch("calendarSetting/getCalendarSetting", condition);
    },
    calendarSettingData: {
      immediate: true,
      handler: function(value) {
        this.calendarSettingList = Util.deepCopy(value);
      }
    }
  },
  methods: {
    ...mapActions(["createUpdateDeleteCalendarSetting"]),
    /**
     * DatePickMonth.vueで取得した日付を設定
     * @param {Date} date - 取得した日付
     */
    setHeader(date) {
      this.target = date;
    },
    getInsertUpdateList(items) {
      return items.filter(e => e.max !== "");
    },
    getDeleteList(items) {
      return items.filter(e => e.calendarId !== null && e.max === "");
    },
    getChangedList(items, lineId) {
      items = items.filter(e => e.isChange == true);
      return items.map(item => ({
        calendarId: item.calendarId,
        posRow: lineId,
        posCol: item.dateInfo,
        max: item.max,
        version: item.version
      }))
    },
    /**
     * マッピングIDからデータ形式を取得する
     */
    getTypeFromMappingId(mappingId) {
      let mappings = this.settingData.mappingDataList;
      let mapping = mappings.filter(d => d.mappingId == mappingId);      
      return (mapping.length != 0) ? mapping[0].type : Define.TYPE_NOTHING;
    },
    save() {
      // バリデート
      if (!this.$refs.form.validate()) {
        Swal.fire({
          icon: "error",
          title: this.$t("MSG_ERR_INPUT_ITEM_INVALID"),
          text: this.$t("MSG_ERR_SUB_INPUT_ITEM_INVALID")
        });
        return;
      }

      let insertUpdateList = [];
      let deleteList = [];
      
      let items = Util.deepCopy(this.calendarSettingList);
      items.forEach(element => {
        // 変更された枠の値のみバックエンドに送信する
        let itemsByArea = this.getChangedList(element.calendarSettingList, element.lineId);
        // 作成/更新するカレンダー設定情報
        let insertUpdateItems = this.getInsertUpdateList(itemsByArea);
        insertUpdateList = insertUpdateList.concat(insertUpdateItems);
        // 削除するカレンダー設定情報
        let deleteItems = this.getDeleteList(itemsByArea);
        deleteList = deleteList.concat(deleteItems);
      });

      let param = {
        startDate: this.startDate.format(Define.DATE_FORMAT_DAY_SLASH_CHECK),
        endDate: this.endDate.format(Define.DATE_FORMAT_DAY_SLASH_CHECK),
        insertUpdateList: insertUpdateList,
        deleteList: deleteList
      }

      // APIコール
      this.createUpdateDeleteCalendarSetting(param);
    }
  }
};
</script>

<style scoped>
.calendar {
  margin: 5px;
  width: 1880px;
  min-height: 735px;
}
.attention {
  color: #ff5252;
  margin: 0 12px 24px 12px;
}
.areatitle {
  border-left: solid 6px #1976d2ca;
  margin-bottom: 8px;
  padding-left: 8px;
}
.area-flame {
  margin-right: auto;
  width: 96%;
}
.days-headerarea {
  height: 30px;
  padding-left: 100px;
  padding-right: 16px;
  display: flex;
  background-color: #1976d2ca;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.days-header {
  text-align: center;
  margin-left: -1px;
  padding: 3px;
  width: 160px;
  color: #fff;
  font-weight: bold;
  border-right: solid 1px #bbb;
  border-left: solid 1px #bbb;
}
.days-header-today {
  text-align: center;
  margin-left: -1px;
  padding: 3px;
  width: 160px;
  color: #fff;
  font-weight: bold;
  border-right: solid 1px #bbb;
  border-left: solid 1px #bbb;
  cursor: pointer;
  background-color: #ffa726;
}
.dataarea {
  margin: 20px 5px 20px 5px;
  width: 1820px;
}
.savebtn {
  margin-left: 28%;
  width: 40%;
}
.list-scroll {
  margin-bottom: -3px;
  background-color: #ededed;
  overflow-x: hidden;
  overflow-y: scroll;
}
</style>
