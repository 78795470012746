<template>
  <div>
    <div class="d-flex flex-row align-baseline">
      <div class="event-title mb-5">{{ $t("PAGE_SUBTITLE_APPLICATON_SETTING_MULTILINGUAL_API_RESULT_REGISTRATION") }}</div>
      <v-btn v-if="items.length<UPPER_LIMIT_RENKEISAKI_NUM" :disabled="!isValidRole" class="ma-2" color="info" rounded small text @click="addRow(eventId)">
        <v-icon small :disabled="!isValidRole" >mdi-plus</v-icon>
        {{ $t("WORD_MULTILINGUAL_API_ADD_DESTINATION") }}
      </v-btn>
    </div>
    <div>
      <div  v-for="(item, i) in items" :key="i" class="ml-5 mb-5">
        <label class="renkei-title">{{ $t("WORD_MULTILINGUAL_API_DESTINATION") }} {{ i + 1 }}</label>
        <v-icon small @click="deleteRow(i)" class="ml-2">mdi-close</v-icon>
        <ApiSetting :item="item" class="ml-5 mt-4" />
      </div>
    </div>
  </div>
</template>

<script>
import Define from "@/define.js";
import * as Util from "@/util/utils.js";
import ApiSetting from "@/components/parts/ApiSetting";
export default {
  components: {
    ApiSetting,
  },
  props: {
    eventId: {
      type: Number,
      default: 0
    },
    items: { 
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      UPPER_LIMIT_RENKEISAKI_NUM: 3
    };
  },
  computed: {
    isValidRole() {
      let user = this.$store.getters["auth/user"];
      let role = this.$store.getters["role/roleByWorkspaceId"](this.selectedWorkspaceId, user.role);
      return Util.getAuthority(Define.EVENT_APPLICATION_SETTING, role);
    },
    selectedWorkspaceId() {
      return this.$store.getters["auth/selectedWorkSpaceId"];
    },
  },
  watch: {

  },
  methods: {
    addRow(eventId) {
      this.items.push({
        body: "",
        renkeisakiId: Define.WEBHOOK_RENKEISAKI_ID_KINTONE,
        sync: false,
        eventId: eventId,
        header: [
          { name: "Content-Type", value: "application/json" }
        ],
        id: null,
        method: Define.METHOD_ID_POST,
        order: this.items.length + 1,
        url: "",
        version: 1,
        workSpaceId: this.selectedWorkspaceId,
      });
    },
    deleteRow(index) {
      this.items.splice(index, 1);
    },
  }
};
</script>

<style scoped>
.event-title {
  border-left: solid 3px #1976d2ca;
  margin-top: 8px;
  margin-bottom: 8px;
  padding-left: 8px;
}
.renkei-title {
  padding: 0.5em 0;
  border-top: solid 2px #1976d2ca;
  border-bottom: solid 2px #1976d2ca;
}
</style>
