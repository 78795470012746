import rootState from "./index";
import Define from "@/define.js";
import SwalMsg from "@/swalMsg.js";
import Swal from "sweetalert2";

const state = {
  enabled: true,
  //通信中の判定フラグ(カード移動制御などに使用)
  loading: false,
  settingData: {},
  //ファイル取り込みの設定周り(ローカルストレージ保存)
  importSetting: {},
  defaultSettingObject: {
    tileTempNum: 0,
    mappingDataList: [],
    calcProgressData: {},
    colorConditionList: [],
    planAreaList: [],
    settingId: null,
    cardMoveJudge: 0,
    loadDisplayType: 0,
    fileDisplayType: 0,
    dateDisplayType: 0,
    inputFileSetting: {},
    version: 0
  },
  defaultInputFileSetting: {
    header: "1",
    character: "0",
    location: "0",
    absoluteCol: 1,
    absoluteRow: 1,
    prosessingMethodSameDataExists: "1",
    updateCardConfirmedStatus: "1"
  }
};

const getters = {
  importSetting: state => state.importSetting,
  loading: state => state.loading,
  colorConditionList: () => rootState.state.settingData.colorConditions,
  isExistSetting() {
    let settingData = rootState.state.settingData;
    return settingData !== null && settingData !== undefined && Object.keys(settingData).length !== 0;
  },
  /**
   * websocketより送られてきたデータから設定データのみ抽出して返す
   * @param {Object} state - stateのstate
   * @returns {Object} state.settingData - 抽出した設定データ
   */
  getSettingData() {
    let settingData = rootState.state.settingData;
    if (!settingData) {
      return state.defaultSettingObject;
    }
    // 0:文字列、1:数値(小数点なし)、2:数値(小数点あり)、3:日付
    let result = {
      tileTempNum: settingData.templateNo,
      mappingDataList: [],
      calcProgressData: {},
      colorConditionList: settingData.colorConditions,
      planAreaList: settingData.area,
      settingId: settingData.settingId,
      cardMoveJudge: settingData.cardMoveJudge,
      loadDisplayType: settingData.loadDisplayType,
      fileDisplayType: settingData.fileDisplayType,
      dateDisplayType: settingData.dateDisplayType,
      version: settingData.version
    };
    let mappingData = settingData.mapping;
    if (!mappingData) {
      return state.defaultSettingObject;
    }
    let computeData = mappingData.find(e => e.isCompute === true);
    if (computeData) {
      result.calcProgressData = computeData.mappingId;
    }
    for (let i = 0; i < mappingData.length; i++) {
      // マッピングJSON キー追加分の初期値を設定(データ入力形式)
      if (mappingData[i].inputType === undefined) {
        mappingData[i].inputType = Define.INPUT_TYPE_DEFAULT;
      }

      // マッピングJSON キー追加分の初期値を設定(リスト選択入力 - カテゴリ)
      if (mappingData[i].category === undefined) {
        mappingData[i].category = null;
      }

      // マッピングJSON キー追加分の初期値を設定(スピナー/スライダー - 最小値)
      if (mappingData[i].min === undefined) {
        mappingData[i].min = "";
      }

      // マッピングJSON キー追加分の初期値を設定(スピナー/スライダー - 最大値)
      if (mappingData[i].max === undefined) {
        mappingData[i].max = "";
      }

      // マッピングJSON キー追加分の初期値を設定(スピナー/スライダー - ステップ)
      if (mappingData[i].step === undefined) {
        mappingData[i].step = "1";
      }

      // マッピングJSON キー追加分の初期値を設定(計算対象1)
      if (mappingData[i].calcObject1 === undefined) {
        mappingData[i].calcObject1 = "";
      }

      // マッピングJSON キー追加分の初期値を設定(四則演算子)
      if (mappingData[i].shisokuEnzanshi === undefined) {
        mappingData[i].shisokuEnzanshi = "";
      }

      // マッピングJSON キー追加分の初期値を設定(計算対象2)
      if (mappingData[i].calcObject2 === undefined) {
        mappingData[i].calcObject2 = "";
      }

      // マッピングJSON キー追加分の初期値を設定(実績入力)
      if (mappingData[i].jisseki === undefined) {
        mappingData[i].jisseki = Define.JISSEKI_NOTHING;
      }

      // マッピングJSON キー追加分の初期値を設定(実績集計方法)
      if (mappingData[i].jissekicalc === undefined) {
        mappingData[i].jissekicalc = Define.JISSEKICALC_NOTHING;
      }

      result.mappingDataList.push(mappingData[i]);
    }
    if (settingData.colorConditionList) {
      result.colorConditionList = settingData.colorConditionList;
    }
    if (!settingData.inputFileSetting) {
      result.inputFileSetting = state.defaultInputFileSetting;
    } else {
      result.inputFileSetting = settingData.inputFileSetting;
    }
    if (!result.inputFileSetting.updateCardConfirmedStatus) {
      result.inputFileSetting.updateCardConfirmedStatus = "1";
    }
    return result;
  },
  /**
   * マッピングデータからデータ型が日付型のデータのみ抽出(ドロップダウンで使用できるように整形する)
   * @returns {Array} - 検索結果
   */
  getMappingDateList() {
    if (!rootState.state.settingData) {
      return null;
    }
    let mappingData = rootState.state.settingData.mapping;
    let result = [];
    let object = {};
    if (!mappingData) {
      return result;
    }
    for (let i = 0; i < mappingData.length; i++) {
      if (mappingData[i].type === Define.TYPE_DATE && mappingData[i].column) {
        object = {
          text: mappingData[i].name,
          value: mappingData[i].column
        };
        result.push(object);
      }
    }
    return result;
  },
  /**
   * マッピングデータからデータ型が数値型(小数点なし)のデータのみ抽出(ドロップダウンで使用できるように整形する)
   * @returns {Array} - 検索結果
   */
  getMappingNumList() {
    if (!rootState.state.settingData) {
      return null;
    }
    let mappingData = rootState.state.settingData.mapping;
    let result = [];
    let object = {};
    if (!mappingData) {
      return result;
    }
    for (let i = 0; i < mappingData.length; i++) {
      if (
        mappingData[i].type === Define.TYPE_INTEGER &&
        mappingData[i].column
      ) {
        object = {
          text: mappingData[i].name,
          value: mappingData[i].column
        };
        result.push(object);
      }
    }
    return result;
  },
  /**
   * マッピングデータからデータを取得(ドロップダウンで使用できるように整形する)
   * @returns {Array} - 検索結果
   */
  getMappingRowList() {
    if (!rootState.state.settingData) {
      return null;
    }
    let mappingData = rootState.state.settingData.mapping;
    let result = [];
    let object = {};
    if (!mappingData) {
      return result;
    }
    for (let i = 0; i < mappingData.length; i++) {
      if (mappingData[i].column) {
        object = {
          text: mappingData[i].name,
          value: mappingData[i].column
        };
        result.push(object);
      }
    }
    return result;
  },
  /**
   * マッピング情報を取得する
   * @returns {Array} - 取得結果
   */
  getMappingList() {
    return !rootState.state.settingData
      ? null
      : rootState.state.settingData.mapping;
  },
  /**
   * マッピング情報を取得する。columnデータの照準でソートする
   * @returns {Array} - 取得結果
   */
  getMappingCsvList() {
    return rootState.state.settingData
      ? rootState.state.settingData.mapping
          .filter(e => e.column !== "")
          .sort(function(a, b) {
            if (a.column < b.column) return -1;
            if (a.column > b.column) return 1;
            return 0;
          })
      : null;
  },
  /**
   * エリア情報を取得する
   * @returns {Array} - 取得結果
   */
  getAreaList() {
    return !rootState.state.settingData
      ? null
      : rootState.state.settingData.area;
  },
  /**
   * mappingIdを引数に対象の表示名を取得する
   * @param {String} id - mappingId
   * @returns {String} - 取得結果
   */
  getMappingName: () => id => {
    let mappingList = rootState.state.settingData
      ? rootState.state.settingData.mapping
      : null;
    if (mappingList === null) {
      return null;
    }
    let data = mappingList.find(e => e.mappingId === id);
    return data ? data.name : null;
  },
  /**
   * areaIdを引数に対象の表示名を取得する
   * @param {String} id -areaId
   * @returns {String} - 取得結果
   */
  getAreaName: () => id => {
    let areaList = rootState.state.settingData
      ? rootState.state.settingData.area
      : null;
    if (areaList === null) {
      return null;
    }
    let data = areaList.find(e => e.areaId === id);
    return data ? data.name : null;
  },
  /**
   * カード詳細表示のデータを取得する
   * 無ければテンプレートの形でからオブジェクトを返す
   * @returns {Object} - 取得結果
   */
  getProtoCard() {
    let proto = {
      tileId: "",
      isComplete: false,
      order: 0,
      posCol: "",
      posRow: "",
      displayData: [],
      tileDetailData: []
    };
    if (!rootState.state.settingData) {
      return proto;
    }
    let mappingData = rootState.state.settingData.mapping;
    if (!mappingData) {
      return proto;
    }
    for (let i = 0; i < mappingData.length; i++) {
      let get = {
        mappingId: "",
        value: ""
      };
      get.mappingId = mappingData[i].mappingId;
      proto.tileDetailData.push(get);
    }
    return proto;
  },
  /**
   * カード移動の可不可
   * @returns {Boolean} - trueで可能
   */
  getEnabled: (state, getters, rootState, rootGetters) => {
    // 読込中であればカード移動をロックする
    if (state.loading) return false;
    let tenantData = rootGetters.getTenantData;
    if (!tenantData.createdAt) {
      return !state.enabled;
    }
    let trialEndDate = rootGetters["tenant/getTrialEndDate"](
      tenantData.createdAt
    );
    let trialEndFlg =
      tenantData.plan === Define.PLAN_TRIAL
        ? rootGetters["tenant/getTrialEndFlg"](trialEndDate)
        : false;
    return trialEndFlg ? false : state.enabled;
  },
  getMappingType: () => id => {
    let mappingList = rootState.state.settingData
      ? rootState.state.settingData.mapping
      : null;
    if (mappingList === null) {
      return null;
    }
    let data = mappingList.find(e => e.mappingId === id);
    return data ? data.type : null;
  },
  getMappingInputType: () => id => {
    let mappingList = rootState.state.settingData
      ? rootState.state.settingData.mapping
      : null;
    if (mappingList === null) {
      return null;
    }
    let data = mappingList.find(e => e.mappingId === id);
    return data ? data.inputType : Define.INPUT_TYPE_DEFAULT;
  },
  getMappingCategory: () => id => {
    let mappingList = rootState.state.settingData
      ? rootState.state.settingData.mapping
      : null;
    if (mappingList === null) {
      return null;
    }
    let data = mappingList.find(e => e.mappingId === id);
    return data ? data.category : null;
  },
  getMappingMin: () => id => {
    let mappingList = rootState.state.settingData
      ? rootState.state.settingData.mapping
      : null;
    if (mappingList === null) {
      return null;
    }
    let data = mappingList.find(e => e.mappingId === id);
    return data ? data.min : null;
  },
  getMappingMax: () => id => {
    let mappingList = rootState.state.settingData
      ? rootState.state.settingData.mapping
      : null;
    if (mappingList === null) {
      return null;
    }
    let data = mappingList.find(e => e.mappingId === id);
    return data ? data.max : null;
  },
  getMappingStep: () => id => {
    let mappingList = rootState.state.settingData
      ? rootState.state.settingData.mapping
      : null;
    if (mappingList === null) {
      return null;
    }
    let data = mappingList.find(e => e.mappingId === id);
    return data ? data.step : null;
  },
  getMappingJisseki: () => id => {
    let mappingList = rootState.state.settingData
      ? rootState.state.settingData.mapping
      : null;
    if (mappingList === null) {
      return null;
    }
    let data = mappingList.find(e => e.mappingId === id);
    return data ? data.jisseki : null;
  },
  getJissekiMappings() {
    let mappingList = rootState.state.settingData
      ? rootState.state.settingData.mapping
      : null;
    return mappingList === null
      ? []
      : mappingList.filter(d => d.jisseki !== Define.JISSEKI_NOTHING);
  },
  getJissekiCalcMappings() {
    let mappingList = rootState.state.settingData
      ? rootState.state.settingData.mapping
      : null;
    return mappingList === null
      ? []
      : mappingList.filter(
          d =>
            d.jisseki !== Define.JISSEKI_NOTHING &&
            d.jissekicalc !== Define.JISSEKICALC_NOTHING
        );
  },
  getIsValidJisseki() {
    let mappingList = rootState.state.settingData
      ? rootState.state.settingData.mapping
      : null;

    if (!mappingList) {
      return false;
    }

    let jissekiList = mappingList.filter(
      d =>
        d.jisseki == Define.JISSEKI_OPTIONAL || d.jisseki == Define.JISSEKI_REQUIRED
    );

    return jissekiList.length > 0;
  },
  getIsSettingMappingCategory: () => id => {
    let mappingList = rootState.state.settingData
      ? rootState.state.settingData.mapping
      : null;

    let data = mappingList.filter(d => d.inputType===Define.INPUT_TYPE_LIST && d.category === id);
    return data.length > 0;
  },
  getIsValidJissekiCalc() {
    let mappingList = rootState.state.settingData
      ? rootState.state.settingData.mapping
      : null;

    if (!mappingList) {
      return false;
    }

    let jissekiList = mappingList.filter(
      d => d.jissekicalc == Define.JISSEKICALC_SUM
    );

    return jissekiList.length > 0;
  },
  getCardMoveJudge() {
    return !rootState.state.settingData
      ? Define.CARD_MOVE_JUDGE_AREA
      : rootState.state.settingData.cardMoveJudge;
  },
  /**
   * ファイル取り込み設定を取得する
   * @returns {Array} - 取得結果
   */
  getInputFileSetting() {
    if (!rootState.state.settingData) {
      return state.defaultInputFileSetting;
    }
    if (!rootState.state.settingData.inputFileSetting) {
      return state.defaultInputFileSetting;
    }
    return rootState.state.settingData.inputFileSetting;
  },
  /**
   * 負荷表示切替設定を取得する
   * @returns {int} - 取得結果
   */
  getLoadDisplayType(){
    return !rootState.state.settingData
      ? 0
      : rootState.state.settingData.loadDisplayType;
  },
  /**
   * 日付表示タイプを取得する
   * @returns {int} - 取得結果
   */
  getDateDisplayType(){
    return !rootState.state.settingData
      ? 0
      : rootState.state.settingData.dateDisplayType;
  },
  /**
   * カード補助情報のバッジ表示切替設定を取得する
   * @returns {int} - 取得結果
   */
  getFileDisplayType(){
    return !rootState.state.settingData
      ? 0
      : rootState.state.settingData.fileDisplayType;
  },
  /**
   * 計算対象1のデータ形式を取得
   * @param {String} mappingId - 計算項目のマッピングID
   * @returns {Number} - 計算対象1のデータ形式
   */
  getCalcObject1Type: () => mappingId => {
    let mappingList = rootState.state.settingData
      ? rootState.state.settingData.mapping
      : null;

    if (!mappingId || !mappingList) {
      return null;
    }

    const mapping = mappingList.find(item => item.mappingId == mappingId);
    if (mapping.type != Define.TYPE_CALC) {
      return null;
    }

    const calcObject1Mapping = mappingList.find(item => item.mappingId == mapping.calcObject1);
    return calcObject1Mapping.type;
  }
};

const actions = {
  /**
   * [WS通信-送信] アプリケーション設定作成/更新
   */
  createUpdateSetting(state, settingData) {
    const socket = rootState.state.socket;
    if (!socket) {
      Swal.fire(SwalMsg.WS_NOT_CONNECT_ERR);
      return false;
    }
    socket.sendObj({
      type: Define.WS_CODE_SAVE_SETTING,
      setting_data: settingData,
      work_space_id: state.rootState.auth.user.workSpaceId || null
    });
  },

  /**
   * [WS通信-送信] オートコンプリート取得
   */
  getAutocomplete(state, keys) {
    const socket = rootState.state.socket;
    if (!socket) {
      return null;
    }
    if (!socket.sendObj) {
      return null;
    }
    // クエリの都合上、nullは許容できないのでnullの場合空文字に変換して投げる
    socket.sendObj({
      type: Define.WS_CODE_GET_AUTOCOMPLETE,
      mapping_id: keys.mappingId,
      value: keys.value === null ? "" : keys.value,
      work_space_id: state.rootState.auth.user.workSpaceId || null
    });
  },
  setImportSetting({ commit }, settingObj) {
    commit("setImportSetting", settingObj);
  }
};

const mutations = {
  initState(state) {
    state.enabled = true;
    state.settingData = {};
    state.importSetting = {};
  },
  switchEnabled(state) {
    state.enabled = !state.enabled;
  },
  setImportSetting(state, settingObj) {
    state.importSetting = settingObj;
  },
  switchLoading(state) {
    state.loading = !state.loading;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
