<template>
  <div>
    <!-- カード詳細ダイアログ -->
    <CardDetail />
    <!-- カード一括複製ダイアログ -->
    <CardDetailMultipleCopy />
    <!-- ユーザー情報変更のダイアログ -->
    <UserChangeDialog
      :dialog="isUserChange"
      @close="callbackUserChangeDialogClose"
    >
    </UserChangeDialog>
    <!-- パスワード変更のダイアログ -->
    <PasswordChangeDialog
      :dialog="isPasswordChange"
      @close="callbackPasswordChangeDialogClose"
    >
    </PasswordChangeDialog>
    <!-- メインメニューリスト -->
    <v-navigation-drawer v-model="drawer" app temporary clipped>
      <v-container>
        <v-list dense nav>
          <v-list-item
            v-for="menuItem in menuItems"
            :key="menuItem.id"
            :to="menuItem.to"
            :disabled="menuItem.disabled"
            @click="drawer = false"
          >
            <v-list-item-icon>
              <v-icon>{{ menuItem.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ menuItem.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- ログアウト -->
          <v-list-item @click="logout()" v-if="!isModePc">
            <v-list-item-icon>
              <v-icon>mdi-exit-to-app</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("MENU_LOGOUT") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <div class="version">Version：{{ version }}</div>
      </v-container>
    </v-navigation-drawer>
    <!-- App bar -->
    <v-app-bar color="grey darken-3" dark app clipped-left height="50">
      <!-- メインメニュー -->
      <v-tooltip bottom open-delay="300" color="rgba(60,64,67,0.95)" transition="fade">
        <template v-slot:activator="{ on: mainmenu }">
          <v-app-bar-nav-icon
            @click="drawer = !drawer"
            v-on="mainmenu"
          ></v-app-bar-nav-icon>
        </template>
        <span>{{ $t("TOOLTIP_MAINMENU") }}</span>
      </v-tooltip>
      <!-- Freelyアイコン -->
      <router-link :to="toPage">
        <v-img
          :src="require('@/assets/Freely_side.png')"
          max-height="30"
          max-width="72"
        />
      </router-link>
      <!-- ボード切替 -->
      <span class="board" v-if="isModePc">
        <SelectBoard />
      </span>
      <v-spacer></v-spacer>
      <!-- 無料お試しプラン -->
      <v-toolbar-items v-if="isModePc">
        <v-toolbar-title class="pt-2" v-if="tenant.plan === planTrial">{{ printTrialDate() }}</v-toolbar-title>
        <v-btn
          v-if="tenant.plan === planTrial && user.role === roleManager"
          class="planbtn"
          text
          :href="agreementUrl"
          target="_blank"
        >
          {{ $t("WORD_CONTACT") }}
        </v-btn>
      </v-toolbar-items>
      <!-- 言語切替 -->
      <span class="languageboard" v-if="isModePc">
        <SelectLunguage />
      </span>
      <v-toolbar-items v-if="isModePc">
        <!-- 文字サイズ -->
        <SelectFontSize class="mt-1" />
        <!-- マニュアル -->
        <v-tooltip bottom open-delay="300" color="rgba(60,64,67,0.95)" transition="fade">
          <template v-slot:activator="{ on: help }">
            <v-btn text :href="manualUrl" target="_blank" v-on="help">
              <v-icon>mdi-help-circle-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("TOOLTIP_MANUAL") }}</span>
        </v-tooltip>
        <!-- 管理 -->
        <v-tooltip v-if="authAccessManagement" bottom open-delay="300" color="rgba(60,64,67,0.95)" transition="fade">
          <template v-slot:activator="{ on: management }">
            <v-btn
              text
              to="/management"
              :disabled="tenant.plan === planTrial && tenant.trialEndFlg"
              v-on="management"
            >
              <v-icon>mdi-cog-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("TOOLTIP_MANAGEMENT") }}</span>
        </v-tooltip>
        <!-- ユーザー -->
        <v-menu offset-y>
          <template v-slot:activator="{ on: menu }">
            <v-tooltip bottom open-delay="300" color="rgba(60,64,67,0.95)" transition="fade">
              <template v-slot:activator="{ on: tooltip }">
                <v-btn text v-on="{ ...tooltip, ...menu }">
                  <v-icon>mdi-account</v-icon>
                </v-btn>
              </template>
              <div>
                <div>{{ getRoleName }}</div>
                <div class="user_name">{{ user.username || wordNotSet }}</div>
                <div>{{ user.email || wordNotSet }}</div>
              </div>
            </v-tooltip>
          </template>
          <v-list>
            <v-list-item @click="isUserChange = true">
              <v-list-item-icon>
                <v-icon>mdi-account-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t("MENU_USER_UPDATE") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              :disabled="tenant.plan === planTrial && tenant.trialEndFlg"
              @click="isPasswordChange = true"
            >
              <v-list-item-icon>
                <v-icon>mdi-lock-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t("MENU_PASSWORD_UPDATE") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="logout()">
              <v-list-item-icon>
                <v-icon>mdi-exit-to-app</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t("MENU_LOGOUT") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-app-bar>
    <!-- メインコンテンツ -->
    <v-main class="content">
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </v-main>
  </div>
</template>

<script>
import CardDetail from "@/components/parts/CardDetail";
import CardDetailMultipleCopy from "@/components/parts/CardDetailMultipleCopy";
import UserChangeDialog from "@/components/parts/ManageUser/UserChangeDialog.vue";
import PasswordChangeDialog from "@/components/parts/PasswordChangeDialog.vue";
import SelectBoard from "@/components/parts/SelectBoard";
import SelectLunguage from "@/components/parts/SelectLunguage";
import SelectFontSize from "@/components/parts/SelectFontSize";
import * as Util from "@/util/utils.js";
import Define from "@/define.js";
import moment from "moment";
import Swal from "sweetalert2";
export default {
  name: "TopPage",
  components: {
    CardDetail,
    CardDetailMultipleCopy,
    UserChangeDialog,
    PasswordChangeDialog,
    SelectBoard,
    SelectLunguage,
    SelectFontSize
  },

  data() {
    return {
      drawer: false,
      version: process.env.VUE_APP_SYSTEM_VERSION,
      isUserChange: false,
      isPasswordChange: false,
      planTrial: Define.PLAN_TRIAL,
      roleManager: Define.USER_ROLE_MANAGER,
      manualUrl: Define.URL_MANUAL,
      agreementUrl: Define.URL_AGREEMENT
    };
  },
  computed: {
    menuItems() {
      if(this.isModePc){
        return [
          {id: 0, icon: "mdi-view-dashboard", name: this.$t("MENU_PLANBOARD"), to: "/planboard", disabled: false },
          {id: 1, icon: "mdi-clipboard", name: this.$t("MENU_TODAY"), to: "/today", disabled: false },
          {id: 2, icon: "mdi-file", name: this.$t("MENU_INPUTFILE"), to: "/inputfile", disabled: this.tenant.plan === this.planTrial && this.tenant.trialEndFlg },
          {id: 3, icon: "mdi-file-download", name: this.$t("MENU_OUTPUTFILE"), to: "/outputfile", disabled: this.tenant.plan === this.planTrial && this.tenant.trialEndFlg },
          {id: 4, icon: "mdi-tools", name: this.$t("MENU_APPLICATON_SETTING"), to: "/applicationsetting", disabled: this.tenant.plan === this.planTrial && this.tenant.trialEndFlg }
        ]
      }else{
        return [
          {id: 0, icon: "mdi-clipboard", name: this.$t("MENU_TODAY"), to: "/mobilejisseki", disabled: false },
          {id: 1, icon: "mdi-tools", name: this.$t("MENU_APPLICATON_SETTING"), to: "/mobilesetting", disabled: this.tenant.plan === this.planTrial && this.tenant.trialEndFlg }
        ]
      }
      
    },
    user() {
      return this.$store.getters["auth/user"];
    },
    tenant() {
      return this.$store.getters["tenant/getTenantData"];
    },
    isInitDataLoaded() {
      return this.$store.getters["isInitDataLoaded"];
    },
    authAccessManagement() {
      return Util.getAuthority(Define.EVENT_ACCESS_MANAGEMENT, this.user.role);
    },
    getRoleName() {
      return this.$t("WORD_ROLENAME")[this.user.role];
    },
    wordNotSet() {
      return this.$t("WORD_NOTSET");
    },
    msgSw() {
      return this.$store.getters["msgSw"];
    },
    msgCode() {
      return this.$store.getters["msgCode"];
    },
    isModePc() {
      return this.$store.getters["isModePc"];
    },
    toPage() {
      return this.isModePc ? '/planboard' : '/mobilejisseki';
    }
  },
  watch: {
    msgSw: {
      immediate: false,
      handler: function() {
        if (this.msgCode) {
          let msgs = this.$t("BACKEND_MESSAGE_OBJECT");
          let msg = msgs.find(e => e.code === this.msgCode);
          Swal.fire({
            icon: "success",
            title: this.$t("MSG_SUCCESSS"),
            text: msg.text
          });
        }
      }
    }
  },
  created: function() {
    // 言語情報を読み込み
    this.$i18n.locale = this.$store.getters["global/selectedLanguage"];
    // ボード情報を読み込み
    this.$store.dispatch("workspace/getWorkspace", {});
    // WebSocketによる初期読み込みが完了していない場合はloadingダイアログ表示
    if (!this.isInitDataLoaded) {
      this.$store.commit("setIsLoading", true);
    }
  },
  methods: {
    /**
     * 「ユーザー変更ダイアログ」を閉じる
     */
    callbackUserChangeDialogClose() {
      this.isUserChange = false;
    },
    /**
     * 「パスワード変更ダイアログ」を閉じる
     */
    callbackPasswordChangeDialogClose() {
      this.isPasswordChange = false;
    },
    /**
     * ログアウト処理
     */
    logout: function() {
      this.$store.dispatch("auth/logout");
    },
    /**
     * 無料お試しプラン表示文字列の生成
     * @returns {String} - 表示文字列
     */
    printTrialDate: function() {
      let format = Define.DATE_FORMAT_DAY_SLASH;
      if (
        !Util.checkNotUndefined(this.tenant.trialEndDate) ||
        !Util.checkNotUndefined(this.tenant.trialEndFlg)
      ) {
        return "";
      }
      let date = moment(this.tenant.trialEndDate).format(format);
      return this.tenant.trialEndFlg
        ? this.$t("TEXT_TRIAL_PRIOD_OVER")
        : this.$t("TEXT_TRIAL_PRIOD_LIMIT", { date: date });
    }
  }
};
</script>

<style>
.content {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #333;
}
.version {
  font-size: 12px;
  text-align: center;
}
.pagestemplate {
  height: calc(100vh - 50px);
  width: 100vw;
  background-color: #f0f0f0;
  overflow-x: auto;
  white-space: nowrap;
}
.flexarea {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
.planbtn {
  background-color: #ef6c00;
  margin-left: 10px;
  margin-right: 5px;
}
.board {
  margin-left: 15px;
  width: 400px;
}
.languageboard {
  margin-right: 15px;
  width: 250px;
}
.centered-input input {
  text-align: center
}
.sunday {
  background-color: rgba(248, 187, 208, 0.2);
}
.saturday {
  background-color: rgba(187, 222, 251, 0.2);
}
.v-list-item {
  align-items: center;
  display: flex;
  flex: 1 1 100%;
  letter-spacing: normal;
  min-height: 36px !important;
  outline: none;
  padding: 0 16px;
  position: relative;
  text-decoration: none;
}
</style>
