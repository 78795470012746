var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "areamargin" },
    [
      _c(
        "v-expansion-panels",
        {
          model: {
            value: _vm.isOpen,
            callback: function($$v) {
              _vm.isOpen = $$v
            },
            expression: "isOpen"
          }
        },
        [
          _c(
            "v-expansion-panel",
            [
              _c("v-expansion-panel-header", [
                _c("div", { staticClass: "subtitle" }, [
                  _vm._v(_vm._s(_vm.$t("PAGE_SUBTITLE_CARD_JISSEKI_INPUT")))
                ])
              ]),
              _c(
                "v-expansion-panel-content",
                [_c("MobileCardDetailJissekiInput")],
                1
              )
            ],
            1
          ),
          _vm.getIsValidJissekiCalc
            ? _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", [
                    _c("div", { staticClass: "subtitle" }, [
                      _vm._v(
                        _vm._s(_vm.$t("PAGE_SUBTITLE_CARD_JISSEKI_SUMMARY"))
                      )
                    ])
                  ]),
                  _c(
                    "v-expansion-panel-content",
                    [_c("MobileCardDetailJissekiSummary")],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-expansion-panel",
            [
              _c("v-expansion-panel-header", [
                _c("div", { staticClass: "subtitle" }, [
                  _vm._v(_vm._s(_vm.$t("PAGE_SUBTITLE_CARD_JISSEKI_TABLE")))
                ])
              ]),
              _c(
                "v-expansion-panel-content",
                [_c("MobileCardDetailJissekiTable")],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }