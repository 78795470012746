var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.tileinfo.tileId != null
    ? _c(
        "div",
        {
          class: _vm.cardStyle,
          on: {
            click: function($event) {
              return _vm.cardClick()
            },
            dblclick: function($event) {
              return _vm.cardDbClick()
            }
          }
        },
        [
          _c(
            "v-badge",
            {
              attrs: {
                value: _vm.isDysplayStatusBadge,
                bordered: "",
                right: "",
                color: "error",
                overlap: "",
                "offset-x": "12",
                "offset-y": "12"
              }
            },
            [
              _c(
                "v-badge",
                {
                  attrs: {
                    value: _vm.isDysplayFileBadge,
                    content: _vm.fileNum,
                    bordered: "",
                    left: "",
                    color: "blue",
                    dot: _vm.fileIsDot,
                    "offset-x": _vm.fileOffset,
                    "offset-y": _vm.fileOffset
                  }
                },
                [
                  _c("Card", {
                    attrs: {
                      cardTempNum: _vm.cardTempNum,
                      tileinfo: _vm.tileinfo
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }