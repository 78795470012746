var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "templatepage" },
    [
      _c(
        "v-card",
        {
          staticClass: "templatecard mx-auto mt-16",
          attrs: { outlined: "", tile: "", color: "rgb(0, 0, 0, 0)" }
        },
        [
          _c(
            "div",
            { staticClass: "templatetitle" },
            [
              _c(
                "v-layout",
                { staticClass: "justify-center" },
                [
                  _c("v-img", {
                    attrs: {
                      src: require("@/assets/Freely_squair.png"),
                      "max-height": "250",
                      "max-width": "250"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                [
                  _c(
                    "v-radio-group",
                    {
                      attrs: { row: "" },
                      model: {
                        value: _vm.mode,
                        callback: function($$v) {
                          _vm.mode = $$v
                        },
                        expression: "mode"
                      }
                    },
                    [
                      _c("v-radio", { attrs: { label: "PC版", value: "0" } }),
                      _c("v-radio", {
                        attrs: { label: "モバイル版", value: "1" }
                      })
                    ],
                    1
                  ),
                  _c("v-text-field", {
                    attrs: {
                      placeholder: " ",
                      outlined: "",
                      label: "メールアドレス"
                    },
                    on: {
                      keydown: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.submit($event)
                      }
                    },
                    model: {
                      value: _vm.email,
                      callback: function($$v) {
                        _vm.email = $$v
                      },
                      expression: "email"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      placeholder: " ",
                      outlined: "",
                      type: _vm.showPassword ? "text" : "password",
                      "append-icon": _vm.showPassword
                        ? "mdi-eye"
                        : "mdi-eye-off",
                      label: "パスワード"
                    },
                    on: {
                      "click:append": function($event) {
                        _vm.showPassword = !_vm.showPassword
                      },
                      keydown: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.submit($event)
                      }
                    },
                    model: {
                      value: _vm.password,
                      callback: function($$v) {
                        _vm.password = $$v
                      },
                      expression: "password"
                    }
                  }),
                  _c(
                    "v-card-actions",
                    { staticClass: "justify-end" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "", color: "blue" },
                          on: { click: _vm.toReset }
                        },
                        [_vm._v(" パスワードを忘れた方 ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    { staticClass: "justify-center" },
                    [
                      _c(
                        "VueLoadingButton",
                        {
                          staticClass:
                            "templatebtn v-btn v-btn--contained theme--light v-size--default success",
                          attrs: { loading: _vm.isLoginLoading, styled: false },
                          nativeOn: {
                            click: function($event) {
                              return _vm.submit($event)
                            }
                          }
                        },
                        [_vm._v(" ログイン ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }