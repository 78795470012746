<template>
  <div class="templatepage">
    <v-card
      outlined
      tile
      class="templatecard mx-auto mt-8"
      color="rgb(0, 0, 0, 0)"
    >
      <div class="templatetitle">
        <v-layout class="justify-center">
          <v-img
            :src="require('@/assets/Freely_squair.png')"
            max-height="250"
            max-width="250"
          />
        </v-layout>
      </div>
      <v-card-text>
        <v-form ref="form">
          <v-card-subtitle>
            新しいパスワードを入力してください。
          </v-card-subtitle>
          <v-text-field
            v-model="password"
            placeholder="8文字以上 半角英数字をそれぞれ1種類以上含む"
            outlined
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            label="新パスワード"
            :rules="[required, password_max_length, password_format]"
            counter="128"
            @click:append="showPassword = !showPassword"
          />
          <v-card-actions class="justify-center">
            <v-btn
              height="50"
              class="templatebtn"
              color="success"
              @click="submitPassword"
              >パスワード初期化</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import * as Util from "@/util/utils.js";
import * as Validator from "@/util/validator.js";
import router from "../../router";
import Define from "@/define.js";
export default {
  name: "PasswordReset",
  data() {
    return {
      showPassword: false,
      password: null,

      // 入力規則
      required: value => Validator.isValidRequired(value),
      password_max_length: value => Validator.isValidLength(value, null, Define.LENGTH_MAX_PASSWORD),
      password_format: value => Validator.isValidPasswordLFormat(value)
    };
  },
  computed: {
    uid() {
      return this.$route.query.tg;
    },
    token() {
      return this.$route.query.tk;
    }
  },
  methods: {
    /**
     * 入力情報をAPIに伝搬する
     */
    submitPassword() {
      if (this.$refs.form.validate()) {
        // loadingダイアログ表示
        this.$store.commit("setIsLoading", true);
        Util.axiosPostReq(Define.API_AUTH_PASSRESET_CONFIRM, {
          uid: this.uid,
          token: this.token,
          new_password1: this.password,
          new_password2: this.password
        })
          .then(response => {
            // console.log(response);
            console.log(response.status); // ステータスコードのみ表示(秘匿情報は外部に出さない)
            // loadingダイアログ非表示
            this.$store.commit("setIsLoading", false);
            Swal.fire({
              icon: "success",
              title: "パスワード初期化成功",
              text: "ログイン画面からログインしてください。"
            });
            router.push("/login");
          })
          .catch(error => {
            console.log(error.message);
            // loadingダイアログ非表示
            this.$store.commit("setIsLoading", false);
            Swal.fire({
              icon: "warning",
              title: "パスワード初期化失敗",
              text: "もう一度パスワードを初期化してください"
            });
          });
      } else {
        Swal.fire({
          icon: "error",
          title: "入力エラー",
          text: "正しい情報を入力してください。"
        });
      }
    }
  }
};
</script>

<style scoped></style>
