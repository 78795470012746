var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-card", { attrs: { outlined: "" } }, [
        _c(
          "div",
          { staticClass: "mx-2" },
          [
            _c(
              "v-row",
              _vm._l(_vm.fileList, function(file, index) {
                return _c(
                  "v-col",
                  { key: "fileConrol-" + index, attrs: { cols: "12" } },
                  [
                    _c("span", [_vm._v("No." + _vm._s(index + 1))]),
                    _c("FileControl", {
                      ref: "fileControl",
                      refInFor: true,
                      attrs: {
                        imageHeight: _vm.MOBILE_HEIGHT_THMBNAIL,
                        imageWidth: _vm.MOBILE_WIDTH_THMBNAIL,
                        parameter: file,
                        disp: false
                      }
                    })
                  ],
                  1
                )
              }),
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }