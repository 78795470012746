<template>
  <div class="templatepage">
    <v-card
      outlined
      tile
      class="templatecard mx-auto mt-16"
      color="rgb(0, 0, 0, 0)"
    >
      <div class="templatetitle">
        <v-layout class="justify-center">
          <v-img
            :src="require('@/assets/Freely_squair.png')"
            max-height="250"
            max-width="250"
          />
        </v-layout>
      </div>
      <v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            height="50"
            class="templatebtn"
            color="success"
            @click="submit"
          >
            アカウントを有効化する
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import * as Util from "@/util/utils.js";
import router from "../../router";
import Define from "@/define.js";
export default {
  name: "Activate",
  computed: {
    key() {
      return this.$route.query.tk;
    }
  },
  methods: {
    submit() {
      // loadingダイアログ表示
      this.$store.commit("setIsLoading", true);
      Util.axiosPostReq(Define.API_AUTH_REG_CONFIRM, { key: this.key })
        .then(response => {
          // console.log(response);
          console.log(response.status); // ステータスコードのみ表示(秘匿情報は外部に出さない)
          // loadingダイアログ非表示
          this.$store.commit("setIsLoading", false);
          Swal.fire({
            icon: "success",
            title: "アカウント有効化成功",
            text: "ログインしてサービスをご利用ください。"
          });
          router.push("/login");
        })
        .catch(error => {
          console.log(error.message);
          // loadingダイアログ非表示
          this.$store.commit("setIsLoading", false);
          Swal.fire({
            icon: "warning",
            title: "アカウント有効化失敗",
            text: "もう一度アカウントを有効化してください。"
          });
        });
    }
  }
};
</script>

<style scoped></style>
