var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upper" },
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "24%", persistent: "" },
          model: {
            value: _vm.loadingDialog,
            callback: function($$v) {
              _vm.loadingDialog = $$v
            },
            expression: "loadingDialog"
          }
        },
        [
          _c(
            "div",
            { staticClass: "loadingdialog" },
            [
              _c("v-card", { staticClass: "pa-4" }, [
                _c(
                  "div",
                  { staticClass: "text-center" },
                  [
                    _c("v-progress-circular", {
                      attrs: {
                        size: 70,
                        width: 7,
                        color: "info",
                        indeterminate: ""
                      }
                    }),
                    _c("div", { staticClass: "mt-6 text-center" }, [
                      _c("p", [_vm._v(_vm._s(_vm.$t("WORD_LOADING")))])
                    ])
                  ],
                  1
                )
              ])
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }