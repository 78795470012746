import Vue from "vue";
import store from "@/store";
import VueNativeSock from "vue-native-websocket";

export default function WebSocketMiddleware(router) {
  router.beforeEach((to, from, next) => {
    if (from.meta.ws) {
      let socket = store.getters["socket"];
      if (!socket) {
        const index = Vue._installedPlugins.indexOf(VueNativeSock);
        if (index > -1) {
          Vue._installedPlugins.splice(index, 1);
        }
      }
    }
    if (to.meta.ws) {
      let socket = store.getters["socket"];
      if (!socket) {
        let url = process.env.VUE_APP_WEBSOCKET_ENDPOINT + "/ws/planboard/";
        let token = store.getters["auth/token"];
        if (token != "") url = `${url}?token=${token}`;
        Vue.use(VueNativeSock, url, {
          connectManually: true,
          reconnection: true,
          reconnectionAttempts: 3,
          reconnectionDelay: 3000,
          format: "json",
          store
        });
        Vue.prototype.$connect();
      }
    }
    next();
  });
}
