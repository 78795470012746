var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "list" },
    [
      _c(
        "draggable",
        {
          staticClass: "draggablearea flexarea",
          attrs: {
            id: _vm.posid,
            group: "kanban",
            animation: "200",
            disabled: !_vm.enabled
          },
          on: { start: _vm.onStart, end: _vm.onEnd },
          model: {
            value: _vm.filteredPagenationList,
            callback: function($$v) {
              _vm.filteredPagenationList = $$v
            },
            expression: "filteredPagenationList"
          }
        },
        _vm._l(_vm.filteredPagenationList, function(tile) {
          return _c("CardBase", {
            key: tile.tileId,
            staticClass: "item",
            attrs: { tileinfo: tile }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }