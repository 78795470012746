import * as Util from "@/util/utils.js";
import Define from "@/define.js";

const state = {
  // 行の折りたたみ状態を管理する
  displayRow: {},
  // 列の折りたたみ状態を管理する
  displayCol: {},
  // 表示する日数を管理する
  displayDays: Define.DISP_DAYS_7,
  // 表示日付の初日
  displayFirstDay: null,
  // 文字サイズ設定
  displayFontSize: Define.FONT_SIZE_SMALL
};

const getters = {
  displayRow: state => state.displayRow,
  displayCol: state => state.displayCol,
  displayDays: state => state.displayDays,
  displayDaysDiff: state => state.displayDays - 1,
  displayFirstDay: state => state.displayFirstDay,
  displayFontSize: state => state.displayFontSize
};

const mutations = {
  initState(state) {
    state.displayRow = {};
    state.displayCol = {};
    state.displayDays = Define.DISP_DAYS_7;
    state.displayFirstDay = null;
    // 文字サイズ設定はログアウトで初期化しない
      // state.displayFontSize = Define.FONT_SIZE_SMALL;
  },
  changeRowOpen(state, value) {
    let boardId = value.boardId;
    let areaId = value.areaId;
    state.displayRow = Util.displayConstruct(state.displayRow, boardId, areaId);
  },
  changeColOpen(state, value) {
    let boardId = value.boardId;
    let dateId = value.dateId;
    state.displayCol = Util.displayConstruct(state.displayCol, boardId, dateId);
    state.displayCol = Util.CleanStateMonthAgo(state.displayCol, boardId);
  },
  setDisplayDays(state, value) {
    state.displayDays = value;
  },
  setDisplayFirstDay(state, value) {
    state.displayFirstDay = value;
  },
  setDisplayFontSize(state, value) {
    state.displayFontSize = value;
  }
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
