var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c("v-slider", {
        attrs: {
          value: _vm.value,
          min: _vm.min,
          max: _vm.max,
          step: _vm.step,
          "hide-details": "",
          "thumb-label": ""
        },
        on: {
          change: function($event) {
            _vm.innerValue = String($event)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "append",
            fn: function() {
              return [
                _c("v-text-field", {
                  key: _vm.resetKey,
                  staticClass: "mt-0 pt-0",
                  staticStyle: { width: "120px" },
                  attrs: {
                    outlined: "",
                    type: "number",
                    "hide-details": "auto",
                    dense: "",
                    value: _vm.innerValue,
                    min: _vm.min,
                    max: _vm.max,
                    rules: _vm.rules,
                    "background-color": _vm.bgcolor
                  },
                  on: { input: _vm.inputValue }
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }