var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _vm.isLoading
        ? _c("div", { staticClass: "pt-10 pl-10" }, [
            _vm._v(_vm._s(_vm.MSG_LOADING_FILE))
          ])
        : _vm._e(),
      _c(
        "v-row",
        { attrs: { align: "center", justify: "center", stretch: "" } },
        _vm._l(_vm.imageSources, function(image, i) {
          return _c("v-img", {
            key: i,
            attrs: {
              src: image,
              contain: "",
              "max-height": _vm.height,
              "max-width": _vm.width
            }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }