<template>
  <v-row>
    <v-slider
      :value="value"
      :min="min"
      :max="max"
      :step="step"
      hide-details
      thumb-label
      @change="innerValue = String($event)"
    >
      <template v-slot:append>
        <v-text-field
          :key="resetKey"
          class="mt-0 pt-0"
          style="width: 120px"
          outlined
          type="number"
          hide-details="auto"
          dense
          :value="innerValue"
          :min="min"
          :max="max"
          :rules="rules"
          :background-color="bgcolor"
          @input="inputValue"
        ></v-text-field>
      </template>
    </v-slider>
  </v-row>
</template>

<script>
export default {
  name: "SliderInput",

  props: {
    value: {
      type: String,
      default: ""
    },
    min: {
      default: 0,
      type: Number
    },
    max: {
      default: 10,
      type: Number
    },
    step: {
      default: 1,
      type: Number
    },
    rules: { type: Array, default: () => [], require: false },
    bgcolor: { type: String, default: "", require: false }
  },

  data: function() {
    return {
      resetKey: 0,
      innerValue: this.value
    };
  },

  watch: {
    innerValue: function(value) {
      let intValue = parseInt(value);

      if (intValue < this.min) {
        this.innerValue = String(this.min);
        this.refresh();
      }

      if (intValue > this.max) {
        this.innerValue = String(this.max);
        this.refresh();
      }

      if (intValue <= this.max && intValue >= this.min) {
        this.$emit("input", value);
      }
    }
  },

  methods: {
    refresh() {
      // 強制的にリフレッシュ ←「key」を更新するとレンダリングが発生する
      this.resetKey++;
    },

    inputValue(value) {
      if (value === undefined) {
        this.innerValue = "";
        return;
      }
      this.innerValue = String(value);
    }
  }
};
</script>

<style scoped>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
