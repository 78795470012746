var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pagestemplate planboard" }, [
    _vm.isInitDataLoaded && !_vm.isExistSetting
      ? _c(
          "div",
          { staticStyle: { width: "100%" } },
          [
            _c("v-alert", { attrs: { tile: "", type: "warning" } }, [
              _vm._v(" " + _vm._s(_vm.$t("PAGE_INFO_SETTING")) + " ")
            ])
          ],
          1
        )
      : _vm._e(),
    _vm.isExistSetting
      ? _c(
          "div",
          [
            _c("FreeArea", {
              attrs: {
                enabled: _vm.enabled,
                lineslists: _vm.lineslists,
                freetilelist: _vm.getTiles.freeTileList,
                cardtempnum: _vm.getTiles.cardTempNum
              }
            })
          ],
          1
        )
      : _vm._e(),
    _vm.isExistSetting
      ? _c(
          "div",
          [
            _c("PlanArea", {
              attrs: {
                enabled: _vm.enabled,
                lineslists: _vm.lineslists,
                plantilelist: _vm.getTiles.planTileList,
                cardtempnum: _vm.getTiles.cardTempNum
              },
              on: {
                catchDate: function($event) {
                  return _vm.searchData($event)
                }
              }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }