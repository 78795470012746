<template>
  <div>
    <div class="areatitle">
      {{ $t("PAGE_TITLE_APPLICATION_SERRING_CATEGORY") }}
      <HelpButton :helpobject="categoryHelpDialogData"></HelpButton>
    </div>
    <v-form ref="form">
      <v-data-table
        :headers="categoryTableHeaders"
        :items="items"
        :footer-props="TABLE_FOOTER_SETTING_MIDDLE"
      >
        <template v-slot:[`item.category`]="{ item }">
          <v-text-field
            v-model="item.category"
            dense
            outlined
            hide-details="auto"
            :disabled="authApplicationSetting"
            :rules="[required, category_max_length]"
            :placeholder="$t('PLACEHOLDER_APPLICATION_SETTING_CATEGORY')"
          ></v-text-field>
        </template>

        <template v-slot:[`item.data`]="{ item }">
          <v-text-field
            v-model="item.data"
            dense
            outlined
            hide-details="auto"
            :disabled="authApplicationSetting"
            :rules="[required]"
            :placeholder="$t('PLACEHOLDER_APPLICATION_SETTING_DATA')"
          ></v-text-field>
        </template>

        <template v-slot:[`item.action`]="{ item }">
          <v-icon small :disabled="authApplicationSetting" @click="deleteRow(item)">
            mdi-close
          </v-icon>
        </template>
      </v-data-table>

      <div class="flexarea">
        <v-spacer></v-spacer>
        <v-btn :disabled="authApplicationSetting || items.length >= 50" class="info" @click="addRow()">
          {{ $t("WORD_ADD") }}
        </v-btn>
      </div>
    </v-form>

    <!-- 保存ボタン -->
    <div class="dataarea">
      <v-btn :disabled="authApplicationSetting || (items.length === 0 && categorys.length === 0)" class="info savebtn" @click="save()">
        {{ $t("WORD_APPLICATION_SETTING_SAVE") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import Define from "@/define.js";
import * as Util from "@/util/utils.js";
import Swal from "sweetalert2";
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("info");
import HelpButton from "@/components/parts/HelpButton";
import { mixinValidator } from "@/mixins/mixinValidator.js";
export default {
  components: {
    HelpButton
  },
  mixins: [mixinValidator],
  props: {
    authApplicationSetting: { type: Boolean, default: true, require: true }
  },
  data() {
    return {
      // items
      items: [],
      // 入力規則
      required: value => this.isValidRequired(value),
      category_max_length: value => this.isValidLength(value, null, Define.LENGTH_MAX_CATEGORY),
    };
  },
  computed: {
    categoryTableHeaders() {
      return this.$t("APP_SETTING_CATEGORY_HEADER");
    },
    categorys() {
      return this.$store.getters["info/getInfo"];
    },
    isSettingMappingCategory() {
      return id => {
        return this.$store.getters["setting/getIsSettingMappingCategory"](id);
      };
    },
    categoryHelpDialogData() {
      // ヘルプ
      return this.$t("EXPLANATION_DIALOG.applicationSettingCategoryHelp");
    },
    TABLE_FOOTER_SETTING_MIDDLE() {
      // テーブル定義
      return this.$t("TABLE_FOOTER_SETTING_MIDDLE");
    },
  },
  watch: {
    categorys: {
      immediate: true,
      handler: function(value) {
        this.items = Util.deepCopy(value);
      }
    }
  },
  methods: {
    ...mapActions(["createUpdateDeleteInfo"]),
    addRow() {
      this.items.push({ category: "", data: "" });
    },
    deleteRow(item) {
      // データマッピングに設定されているカテゴリは削除不可能
      if (this.isSettingMappingCategory(item.id)) {
        Swal.fire({
          icon: "error",
          title: this.$t("MSG_ERR_NOT_DELETE_CATEGORY"),
          text: this.$t("MSG_ERR_SUB_NOT_DELETE_CATEGORY")
        });
        return;
      }

      const index = this.items.findIndex(d => d.id == item.id);
      this.items.splice(index, 1);
    },
    getModifiedItems(items) {
      let tempItems = Util.deepCopy(items);
      for (let i = 0; i < tempItems.length; i++) {
        tempItems[i].data = Util.getMarkStringNoSpace(tempItems[i].data, ",");
      }
      return tempItems;
    },
    getInsertList(items) {
      return items.filter(d => !d.id);
    },
    getUpdateList(items) {
      return items.filter(d => d.id);
    },
    getDeleteList(items, categorys) {
      const idList = items.map(d => d.id).filter(d => d);
      return categorys.filter(d => !idList.includes(d.id));
    },
    save() {
      // バリデート
      if (!this.$refs.form.validate()) {
        Swal.fire({
          icon: "error",
          title: this.$t("MSG_ERR_INPUT_ITEM_INVALID"),
          text: this.$t("MSG_ERR_SUB_INPUT_ITEM_INVALID")
        });
        return;
      }

      // パラメータ整形
      this.items = this.getModifiedItems(this.items);

      // カテゴリの重複チェック
      let categorys = this.items.map(d => d.category);
      if (Util.isDuplicated(categorys)) {
        Swal.fire({
          icon: "error",
          title: this.$t("MSG_ERR_MAIN_CATEGORY_SETTINGS"),
          text: this.$t("MSG_ERR_SUB_CATEGORY_DUPLICATE")
        });
        return;
      }

      // 値の項目数チェック
      let datas = this.items.map(d => d.data);
      for (let data of datas) {
        let values = data.split(",");
        if (values.length > Define.NUM_MAX_CATEGORY_VALUE) {
          Swal.fire({
            icon: "error",
            title: this.$t("MSG_ERR_MAIN_CATEGORY_VALUE_SETTINGS"),
            text: this.$t("MSG_ERR_SUB_CATEGORY_VALUE_NUM")
          });
          return;
        }
      }

      // 値の項目毎の文字数チェック
      for (let data of datas) {
        let values = data.split(",");
        let overLengthValues = values.filter(d => d.length > Define.LENGTH_MAX_STRING);
        if (overLengthValues.length > 0) {
          Swal.fire({
            icon: "error",
            title: this.$t("MSG_ERR_MAIN_CATEGORY_VALUE_SETTINGS"),
            text: this.$t("MSG_ERR_SUB_CATEGORY_VALUE_LENGTH")
          });
          return;
        }
      }

      // パラメータ設定
      let param = {
        insertList: this.getInsertList(this.items),
        updateList: this.getUpdateList(this.items),
        deleteList: this.getDeleteList(this.items, this.categorys)
      };

      // APIコール
      this.createUpdateDeleteInfo(param);
    }
  }
};
</script>

<style scoped>
.areatitle {
  border-left: solid 6px #1976d2ca;
  margin-bottom: 8px;
  padding-left: 8px;
}
.area {
  margin-left: 25px;
  margin-right: 25px;
  padding: 2px 0;
}
.form-area {
  height: 550px;
  overflow-x: auto;
  overflow-y: auto;
}
.dataarea {
  margin: 20px 5px 20px 5px;
  width: 1820px;
}
.savebtn {
  margin-left: 28%;
  width: 40%;
}
.deletebtn {
  width: 300px;
}
</style>
