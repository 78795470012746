<template>
  <div class="line-flame">
    <!-- 計画エリア名 -->
    <div class="linecolor">
      <v-tooltip
        left
        open-delay="300"
        color="rgba(60,64,67,0.95)"
        transition="fade"
      >
        <template v-slot:activator="{ on: disp }">
          <div class="linename" v-on="disp">
            {{ orthopedyName(calendarSettingListByArea.lineName) }}
          </div>
        </template>
        <div>
          <div v-for="(tooltipText, index) in tooltipTextArray" :key="index">
            {{ tooltipText }}
          </div>
        </div>
      </v-tooltip>
    </div>
    <!-- 日付単位データ -->
    <ApplicationSettingCalendarList 
      v-for="calendarSettingListByDate in calendarSettingListByArea.calendarSettingList"
      :calendarSettingListByDate="calendarSettingListByDate"
      :key="calendarSettingListByDate.dateInfo"
      :isValidRole="isValidRole"
      :calcProgressDataType="calcProgressDataType"
      :calcObject1Type="calcObject1Type"
    />
  </div>
</template>

<script>
import Define from "@/define.js";
import * as Util from "@/util/utils.js";
import ApplicationSettingCalendarList from "@/components/parts/ApplicationSettingCalendarList";
export default {
  components: {
    ApplicationSettingCalendarList
  },
  props: {
    calendarSettingListByArea: {
      type: Object,
      default: () => {}
    },
    isValidRole: {
      type: Boolean,
      default: true,
      require: true
    },
    calcProgressDataType: { 
      type: Number,
      default: Define.TYPE_STRING,
      require: true
    },
    calcObject1Type: {
      type: Number,
      default: null,
      require: true
    }
  },
  computed: {
    tooltipTextArray() {
      return Util.splitStringBySpace(this.calendarSettingListByArea.lineName);
    }
  },
  methods: {
    /**
     * 行タイトルを表示文字数上限で切り、それ以降を「...」とする
     * @param {String} getText - 行タイトル
     * @returns {String} - 整形後行タイトル
     */
    orthopedyName(getText) {
      let maxLineName = this.calendarSettingListByArea.progressbarFlg ? Define.MAX_MONTH_VIEW_LINE_NAME : Define.MAX_MONTH_VIEW_LINE_NAME_NO_LOAD;
      if(getText === this.$t("WORD_NOTSET")) {maxLineName = Define.MAX_MONTH_VIEW_LINE_NAME_NOT_SET;}
      return Util.checkNotUndefined(getText)
        ? Util.orthopedyText(getText, maxLineName)
        : "";
    }
  }
};
</script>

<style scoped>
.line-flame {
  display: flex;
  justify-content: space-between;
  height: auto;
  margin-top: 3px;
  margin-bottom: 3px;
  background-color: #fff;
}
.linename {
  white-space: normal;
  padding: 2px;
  min-width: 100px;
  width: 100px;
  height: calc(100% - 21px);
  color: #fff;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}
.linecolor {
  background-color: #1976d2ca;
}
</style>
