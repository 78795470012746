<template>
  <v-app>
    <div v-if="isLoading" class="pt-10 pl-10">{{ MSG_LOADING_FILE }}</div>
    <v-row align="center" justify="center" stretch>
      <v-img
        v-for="(image, i) in imageSources"
        :key="i"
        :src="image"
        contain
        :max-height="height"
        :max-width="width"
      ></v-img>
    </v-row>
  </v-app>
</template>

<script>
import * as Util from "@/util/utils.js";
import Define from "@/define.js";

export default {
  name: "ShowImage",
  components: {},
  data() {
    return {
      isLoading: false,
      refreshKey: 0,
      conversionStat: Define.STATE_IDLE,
      imageSources: [],
      fileName: "",
      height: Define.HEIGHT_OPEN_CONTENT,
      width: Define.WIDTH_OPEN_CONTENT,

      MSG_LOADING_FILE: Define.MSG_LOADING_FILE
    };
  },
  computed: {
    selectedWorkspaceId() {
      return this.$store.getters["auth/selectedWorkSpaceId"];
    }
  },
  watch: {},
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.isLoading = true;
      const tileId = this.$route.params.tileId;
      const index = this.$route.params.index;
      const workSpaceId = this.selectedWorkspaceId;
      const query = `tile_id=${tileId}&index=${index}&work_space_id=${workSpaceId}`;
      Util.axiosGetReq(`${Define.API_GET_FILE_DATA}?${query}`)
        .then(response => {
          this.fileName = response.data.name;
          if (this.isPdf()) {
            this.convertPdfBase64ToImageData(response.data.source);
          } else {
            this.imageSources = [ response.data.source ];
          }
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error);
        });
    },
    async convertPdfBase64ToImageData(source) {
      const result = await Util.getImageDataFromPdfBase64All(
        source,
        this.height,
        this.width
      );
      this.conversionState = result.state;
      this.imageSources = result.pages
    },
    isPdf() {
      return Util.isPdf(this.fileName);
    }
  }
};
</script>

<style scoped></style>
