<template>
  <div>
    <!-- ヘッダー行 -->
    <div>
      <label>{{ $t("PAGE_SUBTITLE_INPUT_FILE_SETTING_HEADER") }}</label>
      <v-radio-group v-model="importSetting.header" :disabled="userRole" row class="mt-1 ml-2" dense hide-details>
        <v-radio :label="$t('WORD_INPUT_FILE_SETTING_HEADER_SET')" :value="IMPORT_SETTING_HEADER_WITH"></v-radio>
        <v-radio :label="$t('WORD_INPUT_FILE_SETTING_HEADER_NOT')" :value="IMPORT_SETTING_HEADER_WITHOUT"></v-radio>
      </v-radio-group>
    </div>
    <!-- 値の囲い文字 -->
    <div class="pt-5">
      <label>{{ $t("PAGE_SUBTITLE_INPUT_FILE_SETTING_QUOTATION") }}</label>
      <v-radio-group v-model="importSetting.character" :disabled="userRole" row class="mt-1 ml-2" dense hide-details>
        <v-radio :label="$t('WORD_INPUT_FILE_SETTING_QUOTATION_DOUBLE')" :value="IMPORT_SETTING_CHARACTER_DOUBLE"></v-radio>
        <v-radio :label="$t('WORD_INPUT_FILE_SETTING_QUOTATION_SINGLE')" :value="IMPORT_SETTING_CHARACTER_SINGLE"></v-radio>
      </v-radio-group>
    </div>
    <!-- 取り込みカードの初期位置 -->
    <div class="pt-5">
      <label class="display_inline">{{ $t("PAGE_SUBTITLE_INPUT_FILE_SETTING_POSITION") }}</label>
      <v-row dense>
        <v-col cols="2" class="mt-0 pt-0">
          <v-radio-group v-model="importSetting.location" :disabled="userRole" row class="mt-1 ml-2" dense hide-details>
            <v-radio :label="$t('WORD_INPUT_FILE_SETTING_POSTION_UNPLANNED')" :value="IMPORT_SETTING_LOCATION_NOPLAN"></v-radio>
            <v-radio :label="$t('WORD_INPUT_FILE_SETTING_POSTION_ASSIGN')" :value="IMPORT_SETTING_LOCATION_ABSOLUTE"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col v-if="hasKeySettings && importSetting.prosessingMethodSameDataExists == IMPORT_SETTING_SAME_KEY_UPDATE" cols="10"  class="mt-0 pt-0">
          <v-checkbox
            v-model="importSetting.isFixedPos"
            :label="$t('PAGE_SUBTITLE_INPUT_FILE_SETTING_FIXPOS')"
            :disabled="userRole"
            hide-details
            class="mt-1"
          ></v-checkbox>
        </v-col>
      </v-row>
      <div class="d-flex flex-column">
        <div v-if="importSetting.location == IMPORT_SETTING_LOCATION_ABSOLUTE">
          <div class="d-flex flex-row pl-9">
            <div :style="{ width: '350px', 'padding-top': '13px' }" class="poslabel">{{ $t("PAGE_SUBTITLE_INPUT_FILE_SETTING_COL_DATE") }}</div>
            <div>
              <v-select
                v-model="importSetting.absoluteCol"
                :disabled="userRole"
                class="pt-0 mt-0"
                :items="listColNum"
                hide-details
              ></v-select>
            </div>
          </div>
          <div class="d-flex flex-row pl-9">
            <div :style="{ width: '350px', 'padding-top': '13px' }" class="poslabel">{{ $t("PAGE_SUBTITLE_INPUT_FILE_SETTING_COL_PLANNED_AREA") }}</div>
            <div>
              <v-select
                v-model="importSetting.absoluteRow"
                :disabled="userRole"
                class="pt-0 mt-0"
                :items="listColNum"
                hide-details
              ></v-select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- キーが同一のデータが存在した場合の動作 -->
    <div v-if="hasKeySettings" class="pt-5">
      <label>{{ $t("PAGE_SUBTITLE_INPUT_FILE_SETTING_SAME_KEY") }}</label>
      <v-radio-group v-model="importSetting.prosessingMethodSameDataExists" :disabled="userRole" row class="mt-1 ml-2" dense hide-details>
        <v-radio :label="$t('WORD_INPUT_FILE_SETTING_SAME_KEY_UPDATE')" :value="IMPORT_SETTING_SAME_KEY_UPDATE"></v-radio>
        <v-radio :label="$t('WORD_INPUT_FILE_SETTING_SAME_KEY_ERROR')" :value="IMPORT_SETTING_SAME_KEY_ERROR"></v-radio>
        <v-radio :label="$t('WORD_INPUT_FILE_SETTING_SAME_KEY_SKIP')" :value="IMPORT_SETTING_SAME_KEY_SKIP"></v-radio>
      </v-radio-group>
    </div>
    <!-- ファイル取り込み(更新)時の確定状態の動作 -->
    <div v-if="option002 && isSelectedSameCardUpdate" class="pt-5">
      <label>{{ $t("PAGE_SUBTITLE_INPUT_FILE_SETTING_UPDATE_CONFIRM") }}</label>
      <v-radio-group v-model="importSetting.updateCardConfirmedStatus" :disabled="userRole" row class="mt-1 ml-2" dense hide-details>
        <v-radio :label="$t('WORD_INPUT_FILE_SETTING_UPDATE_CONFIRM_UNCONFIRM')" :value="IMPORT_SETTING_UPDATE_CONFIRM_UNCONFIRM"></v-radio>
        <v-radio :label="$t('WORD_INPUT_FILE_SETTING_UPDATE_CONFIRM_KEEP')" :value="IMPORT_SETTING_UPDATE_CONFIRM_KEEP"></v-radio>
      </v-radio-group>
    </div>
  </div>
</template>

<script>
import * as Util from "@/util/utils.js";
import Define from "@/define.js";
export default {
  props: {
    inputFileSetting: { type: Object, default: () => {}, require: true },
    userRole: { type: Boolean, default: true, require: true },
    mappingDataList: { type: Array, default: () => [], require: true }
  },
  data() {
    return {
      importSetting: {},
      // ヘッダー行
      IMPORT_SETTING_HEADER_WITH: Define.IMPORT_SETTING_HEADER_WITH,
      IMPORT_SETTING_HEADER_WITHOUT: Define.IMPORT_SETTING_HEADER_WITHOUT,
      // 値の囲い文字
      IMPORT_SETTING_CHARACTER_DOUBLE: Define.IMPORT_SETTING_CHARACTER_DOUBLE,
      IMPORT_SETTING_CHARACTER_SINGLE: Define.IMPORT_SETTING_CHARACTER_SINGLE,
      // 取り込みカードの初期位置
      IMPORT_SETTING_LOCATION_NOPLAN: Define.IMPORT_SETTING_LOCATION_NOPLAN,
      IMPORT_SETTING_LOCATION_ABSOLUTE: Define.IMPORT_SETTING_LOCATION_ABSOLUTE,
      IMPORT_SETTING_LOCATION_RELATIVE: Define.IMPORT_SETTING_LOCATION_RELATIVE,
      // カード位置固定
      IMPORT_SETTING_NOT_FIXED_POS: Define.IMPORT_SETTING_NOT_FIXED_POS,
      IMPORT_SETTING_FIXED_POS: Define.IMPORT_SETTING_FIXED_POS,
      // キーが同一のデータが存在した場合の動作
      IMPORT_SETTING_SAME_KEY_UPDATE: Define.IMPORT_SETTING_SAME_KEY_UPDATE,
      IMPORT_SETTING_SAME_KEY_ERROR: Define.IMPORT_SETTING_SAME_KEY_ERROR,
      IMPORT_SETTING_SAME_KEY_SKIP: Define.IMPORT_SETTING_SAME_KEY_SKIP,
      // ファイル取り込み(更新)時の確定状態の動作
      IMPORT_SETTING_UPDATE_CONFIRM_UNCONFIRM: Define.IMPORT_SETTING_UPDATE_CONFIRM_UNCONFIRM,
      IMPORT_SETTING_UPDATE_CONFIRM_KEEP: Define.IMPORT_SETTING_UPDATE_CONFIRM_KEEP
    };
  },
  computed: {
    loadingFlg: {
      get() {
        return this.$store.getters["isInitDataLoaded"];
      }
    },
    freeTileNum() {
      return this.$store.getters["tile/getFreeTileNum"];
    },
    listColNum() {
      let array = [];
      for (let i = 1; i <= Define.CSV_COL_LIMIT; i++) {
        array.push({ text: i, value: i });
      }
      return array;
    },
    hasKeySettings() {
      return Util.hasKeySettings(this.mappingDataList);
    },
    option002() {
      let option = this.$store.getters["option/getOption"];
      return option.option002;
    },
    isSelectedSameCardUpdate() {
      let ret = false;
      if (!this.hasKeySettings) {
        return ret;
      }
      switch (this.importSetting.prosessingMethodSameDataExists) {
        case "1":
          ret = true;
          break;
        case "2":
        case "3":
          break;
        default:
          break;
      }
      return ret;
    }
  },
  watch: {
    loadingFlg: {
      immediate: true,
      handler: function(val) {
        if (val) {
          this.initiaize();
        }
      }
    },
    importSetting: {
      deep: true,
      handler: function(value) {
        this.$emit("input", value);
      }
    }
  },

  methods: {
    initiaize() {
      let FileSettingData = this.inputFileSetting;
      if (!FileSettingData.header) {
        FileSettingData = {
          header: Define.IMPORT_SETTING_HEADER_WITH,
          character: Define.IMPORT_SETTING_CHARACTER_DOUBLE,
          location: Define.IMPORT_SETTING_LOCATION_NOPLAN,
          isFixedPos: Define.IMPORT_SETTING_NOT_FIXED_POS,
          absoluteCol: 1,
          absoluteRow: 1,
          prosessingMethodSameDataExists: Define.IMPORT_SETTING_SAME_KEY_UPDATE,
          updateCardConfirmedStatus: Define.IMPORT_SETTING_UPDATE_CONFIRM_UNCONFIRM
        };
      }
      this.importSetting = Util.parseObject(FileSettingData);
      this.$emit("input", this.importSetting);
    },
  }
};
</script>

<style>
.poslabel {
  color: rgba(0, 0, 0, 0.6);
}
</style>
