import rootState from "./index";
import Define from "@/define.js";
import SwalMsg from "@/swalMsg.js";
import Swal from "sweetalert2";

const state = {};
const getters = {
  /**
   * API設定を取得する
   * @returns {Array} API設定リスト
   */
  getApiSetting() {
    return rootState.state.apiSetting;
  },

  }
const mutations = {
};
const actions = {
  /**
   * [WS通信-送信] API設定作成/更新/削除
   */
  createUpdateDeleteApiSetting(state, apiSettings) {
    const socket = rootState.state.socket;
    if (!socket) {
      Swal.fire(SwalMsg.WS_NOT_CONNECT_ERR);
      return false;
    }
    socket.sendObj({
      type: Define.WS_CODE_SAVE_API_SETTING,
      api_settings: apiSettings,
      work_space_id: state.rootState.auth.user.workSpaceId || null
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
