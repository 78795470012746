var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "d-flex flex-row align-baseline" },
      [
        _c("div", { staticClass: "event-title mb-5" }, [
          _vm._v(
            _vm._s(
              _vm.$t(
                "PAGE_SUBTITLE_APPLICATON_SETTING_MULTILINGUAL_API_RESULT_REGISTRATION"
              )
            )
          )
        ]),
        _vm.items.length < _vm.UPPER_LIMIT_RENKEISAKI_NUM
          ? _c(
              "v-btn",
              {
                staticClass: "ma-2",
                attrs: {
                  disabled: !_vm.isValidRole,
                  color: "info",
                  rounded: "",
                  small: "",
                  text: ""
                },
                on: {
                  click: function($event) {
                    return _vm.addRow(_vm.eventId)
                  }
                }
              },
              [
                _c(
                  "v-icon",
                  { attrs: { small: "", disabled: !_vm.isValidRole } },
                  [_vm._v("mdi-plus")]
                ),
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("WORD_MULTILINGUAL_API_ADD_DESTINATION")) +
                    " "
                )
              ],
              1
            )
          : _vm._e()
      ],
      1
    ),
    _c(
      "div",
      _vm._l(_vm.items, function(item, i) {
        return _c(
          "div",
          { key: i, staticClass: "ml-5 mb-5" },
          [
            _c("label", { staticClass: "renkei-title" }, [
              _vm._v(
                _vm._s(_vm.$t("WORD_MULTILINGUAL_API_DESTINATION")) +
                  " " +
                  _vm._s(i + 1)
              )
            ]),
            _c(
              "v-icon",
              {
                staticClass: "ml-2",
                attrs: { small: "" },
                on: {
                  click: function($event) {
                    return _vm.deleteRow(i)
                  }
                }
              },
              [_vm._v("mdi-close")]
            ),
            _c("ApiSetting", {
              staticClass: "ml-5 mt-4",
              attrs: { item: item }
            })
          ],
          1
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }