<template>
  <div class="areamargin">
    <v-expansion-panels v-model="isOpen">

      <!-- カード詳細情報 -->
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="subtitle">{{ $t("PAGE_TITLE_CARD_DETAIL") }}</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <MobileCardDetailInfoDetail />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- カード補助情報 -->
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="subtitle">{{ $t("PAGE_TITLE_CARD_ASSIST") }}</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <MobileCardDetailInfoFile />
        </v-expansion-panel-content>
      </v-expansion-panel>

    </v-expansion-panels>
  </div>
</template>

<script>
import MobileCardDetailInfoDetail from "@/components/mobile/parts/MobileCardDetailInfoDetail.vue";
import MobileCardDetailInfoFile from "@/components/mobile/parts/MobileCardDetailInfoFile.vue";
export default {
  components: {
    MobileCardDetailInfoDetail,
    MobileCardDetailInfoFile
  },
  data() {
    return {
      isOpen: 0
    };
  },
  computed: {
    isOpenMobileCardDetail() {
      return this.$store.getters["tile/isOpenMobileCardDetail"];
    }
  },
  watch: {
    isOpenMobileCardDetail: {
      immediate: true,
      handler: function() {
        if (this.isOpenMobileCardDetail) {
          this.isOpen = 0;
        }
      }
    },
  }
};
</script>

<style scoped>
.areamargin {
  margin: 10px;
}
.subtitle {
  border-left: solid 4px #1976d2ca;
  color: #333;
  padding-left: 4px;
}
</style>
