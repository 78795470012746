import Define from "../define.js";

export const mixinError = {
  name: "mixinError",
  methods: {
    /**
     * 設定保存時のエラーメッセージを返す。エラーがなければ空
     * @param {Number} - マッピングリストのエラー判定結果
     * @param {Number} - エリアリストのエラー判定結果
     * @returns {Object} - メッセージオブジェクト
     */
    createErrorMessageBySettingSave(mappingResult, areaResult) {
      let result = {
        mainText: "",
        subText: ""
      };
      if (mappingResult !== Define.SUCCESS) {
        result = this._getErrorMessage(mappingResult);
      } else if (areaResult !== Define.SUCCESS) {
        result = this._getErrorMessage(areaResult);
      }
      return result;
    },
    /**
     * エラーコードからセージオブジェクトを返す。該当コードがなければ空を返す
     * @param {Number} - エラーコード
     * @returns {Object} - メッセージオブジェクト
     */
    _getErrorMessage(errorCode) {
      let result = {
        mainText: "",
        subText: ""
      };
      switch (errorCode) {
        case Define.ERR_LENGTH_MAX:
          result.mainText = this.$t("MSG_ERR_MAIN_LENGTH");
          result.subText = this.$t("MSG_ERR_SUB_LENGTH");
          break;
        case Define.ERR_DUPLICATE:
          result.mainText = this.$t("MSG_ERR_MAIN_DUPLICATE");
          result.subText = this.$t("MSG_ERR_SUB_DUPLICATE");
          break;
        case Define.ERR_DATA_TYPE:
          result.mainText = this.$t("MSG_ERR_MAIN_TYPE");
          result.subText = this.$t("MSG_ERR_SUB_TYPE");
          break;
        case Define.ERR_NUMBER_RANGE:
          result.mainText = this.$t("MSG_ERR_MAIN_NUMBER_RANGE");
          result.subText = this.$t("MSG_ERR_SUB_NUMBER_RANGE");
          break;
        case Define.ERR_INPUT_ITEM_INVALID:
          result.mainText = this.$t("MSG_ERR_INPUT_ITEM_INVALID");
          result.subText = this.$t("MSG_ERR_SUB_INPUT_ITEM_INVALID");
          break;
        case Define.ERR_EXTRACTION_RANGE_FROM_END_NOT_SET:
          result.mainText = this.$t("MSG_ERR_MAIN_EXTRACTION_RANGE_FROM_END_NOT_SET");
          result.subText = this.$t("MSG_ERR_SUB_EXTRACTION_RANGE_FROM_END_NOT_SET");
          break;
        case Define.CONFIRM_DELETE_DATAMAPPING:
          result.mainText = this.$t("MSG_MAIN_CONFIRM_DELETE_DATAMAPPING");
          result.subText = this.$t("MSG_SUB_CONFIRM_DELETE_DATAMAPPING");
          break;
        case Define.CONFIRM_DELETE_PLANAREA:
          result.mainText = this.$t("MSG_MAIN_CONFIRM_DELETE_PLANAREA");
          result.subText = this.$t("MSG_SUB_CONFIRM_DELETE_PLANAREA");
          break;
        case Define.ERR_KEY_SETTINGS:
          result.mainText = this.$t("MSG_ERR_MAIN_KEY_SETTINGS");
          result.subText = this.$t("MSG_ERR_SUB_KEY_SETTINGS");
          break;
        case Define.ERR_VIOLATE_UNIQUE_KEY:
          result.mainText = this.$t("MSG_ERR_MAIN_VIOLATE_UNIQUE_KEY");
          result.subText = this.$t("MSG_ERR_SUB_VIOLATE_UNIQUE_KEY");
          break;
        case Define.ERR_COPY_OVER_10:
          result.mainText = this.$t("MSG_ERR_MAIN_COPY_OVER_10");
          result.subText = this.$t("MSG_ERR_SUB_COPY_OVER_10");
          break;
        case Define.ERR_JISSEKI_SETTINGS:
          result.mainText = this.$t("MSG_ERR_MAIN_JISSEKI_SETTINGS");
          result.subText = this.$t("MSG_ERR_SUB_JISSEKI_SETTINGS");
          break;
        case Define.ERR_UPDATE_STATUS_CONFIRM:
          result.mainText = this.$t("MSG_ERR_MAIN_UPDATE");
          result.subText = this.$t("MSG_ERR_SUB_UPDATE_STATUS_CONFIRM");
          break;
        case Define.ERR_UPDATE_STATUS_COMPLETE:
          result.mainText = this.$t("MSG_ERR_MAIN_UPDATE");
          result.subText = this.$t("MSG_ERR_SUB_UPDATE_STATUS_COMPLETE");
          break;
        case Define. ERR_DEL_DATAMAPPING:
          result.mainText = this.$t("MSG_ERR_MAIN_DELETE");
          result.subText = this.$t("MSG_ERR_SUB__USECALC_DATAMAPPING");
          break;  
      }
      return result;
    }
  }
}