<template>
  <div>
    <v-card outlined>
      <div class="mx-2">
        <v-row>
          <v-col v-for="(file, index) in fileList" :key="`fileConrol-${index}`" cols="12">
            <span>No.{{ index + 1 }}</span>
            <FileControl :imageHeight="MOBILE_HEIGHT_THMBNAIL" :imageWidth="MOBILE_WIDTH_THMBNAIL" ref="fileControl" :parameter="file" :disp="false" />
          </v-col>
        </v-row>
      </div>
    </v-card>
  </div>
</template>

<script>
import Define from "@/define.js";
import FileControl from "@/components/parts/FileControl";
export default {
  components: {
    FileControl
  },
  data() {
    return {
      fileList: [
        { tileId: "", index: 0, isEdited: false, fileDataId: "", name: "", source: "", version: 0, size: 0 },
        { tileId: "", index: 0, isEdited: false, fileDataId: "", name: "", source: "", version: 0, size: 0 },
        { tileId: "", index: 0, isEdited: false, fileDataId: "", name: "", source: "", version: 0, size: 0 },
        { tileId: "", index: 0, isEdited: false, fileDataId: "", name: "", source: "", version: 0, size: 0 }
      ],
      MOBILE_HEIGHT_THMBNAIL: Define.MOBILE_HEIGHT_THMBNAIL,
      MOBILE_WIDTH_THMBNAIL: Define.MOBILE_WIDTH_THMBNAIL
    }
  },
  computed: {
    isOpenMobileCardDetail() {
      return this.$store.getters["tile/isOpenMobileCardDetail"];
    },
    cardDetailInfo() {
      return this.$store.getters["tile/cardDetailInfo"];
    }
  },
  watch: {
    isOpenMobileCardDetail: {
      immediate: true,
      handler: function() {
        if (this.isOpenMobileCardDetail) {
          this.setImageControls();
        }
      }
    },
  },
  methods: {
    setImageControls() {
      for (let i = 0; i < this.fileList.length; i++) {
        this.fileList[i].tileId = this.cardDetailInfo.tileId;
        this.fileList[i].index = i;
        this.$nextTick(() => {
          this.$refs.fileControl[i].initialize();
        });
      }
    }
  }
};
</script>

<style scoped>

</style>
