var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tenantregistconfirm" },
    [
      _c("v-alert", { attrs: { type: "success" } }, [
        _vm._v(" 無料お試しプランの登録が成功しました ")
      ]),
      _c(
        "v-card",
        {
          staticClass: "templatecard mx-auto mt-8",
          attrs: { outlined: "", tile: "", color: "rgb(0, 0, 0, 0)" }
        },
        [
          _c("div", { staticClass: "infobox" }, [
            _c(
              "div",
              [
                _c("v-icon", [_vm._v(" mdi-domain ")]),
                _vm._v("サービスご利用までのステップ")
              ],
              1
            ),
            _c("br"),
            _c("div", [
              _c("ol", [
                _c("li", [_vm._v("アカウントを有効化する")]),
                _c("br"),
                _c("p", [
                  _vm._v(
                    " 入力いただいたメールアドレス宛にアカウントを有効化するためのURLを送信いたしました。URLをクリックし、アカウントを有効化してください。 "
                  )
                ]),
                _c("br"),
                _c("li", [_vm._v("サービスにログインする")]),
                _c("br"),
                _c("p", [
                  _vm._v(
                    " アカウントの有効化が完了したら、サービスにログインしてご利用を開始してください。 "
                  )
                ])
              ])
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }