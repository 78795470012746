var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { dense: "", align: "center" } },
    [
      _c(
        "v-col",
        { attrs: { cols: "2", align: "center" } },
        [
          _c(
            "v-btn",
            {
              attrs: { small: "", height: "40", outlined: "" },
              on: {
                click: _vm.decreaseNumber,
                mousedown: function($event) {
                  return _vm.whileMouseDown(_vm.decreaseNumber)
                },
                mouseup: _vm.clearTimer
              }
            },
            [_vm._v(" - ")]
          )
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "8", align: "center" } },
        [
          _c("v-text-field", {
            key: _vm.resetKey,
            staticStyle: { "margin-left": "4px" },
            attrs: {
              outlined: "",
              type: "number",
              "hide-details": "auto",
              dense: "",
              value: _vm.innerValue,
              min: _vm.min,
              max: _vm.max,
              rules: _vm.rules,
              "background-color": _vm.bgcolor
            },
            on: { input: _vm.inputValue }
          })
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "2", align: "center" } },
        [
          _c(
            "v-btn",
            {
              attrs: { small: "", height: "40", outlined: "" },
              on: {
                click: _vm.increaseNumber,
                mousedown: function($event) {
                  return _vm.whileMouseDown(_vm.increaseNumber)
                },
                mouseup: _vm.clearTimer
              }
            },
            [_vm._v(" + ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }